import React, { useContext } from 'react';
import { Flex, Box, keyframes } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { NavLink, useHistory, useRouteMatch } from 'react-router-dom';
import { ReactComponent as OverviewIcon } from '../../../../assets/icons/overviewIcon.svg';
import { ReactComponent as MerchantIcon } from '../../../../assets/icons/merchants.svg';
import { ReactComponent as ChartIcon } from '../../../../assets/icons/chartIcon.svg';
import { ReactComponent as WalletIcon } from '../../../../assets/icons/walletIcon.svg';
import { ReactComponent as DocumentIcon } from '../../../../assets/icons/documentIcon.svg';
import { ReactComponent as RoleIcon } from '../../../../assets/icons/role.svg';
import { ReactComponent as DiscountIcon } from '../../../../assets/icons/discount.svg';
import { ReactComponent as TopUpIcon } from '../../../../assets/icons/topup.svg';
import { ReactComponent as TicketIcon } from '../../../../assets/icons/ticketIcon.svg';
import { ReactComponent as SettingsIcon } from '../../../../assets/icons/settingsIcon.svg';
import { ReactComponent as LogoutIcon } from '../../../../assets/icons/logoutIcon.svg';
import { AuthContext, LOGOUT } from '../../../../context/auth';

const Icons = ({ isDropdownOpen }) => {
  const match = useRouteMatch('/admin/applications');
  const match2 = useRouteMatch('/admin/merchant');
  const { dispatch, authState } = useContext(AuthContext);
  const history = useHistory();

  const logout = () => {
    dispatch({ type: LOGOUT, payload: '' });
    history.push('/admin/login');
  };

  return (
    <Box>
      <Box mt="40px">
        <Flex
          h="31px"
          w="100%"
          align="center"
          justify="center"
          mb="11px"
          as={NavLink}
          to="/admin/overview"
          name="icon"
        >
          <OverviewIcon />
        </Flex>
        <Flex
          h="31px"
          w="100%"
          align="center"
          justify="center"
          mb="11px"
          as={NavLink}
          to="/admin/merchants"
          name="icon"
          className={(match || match2) && 'active'}
        >
          <MerchantIcon />
        </Flex>
        {isDropdownOpen && (
          <MotionFlex
            w="100%"
            h="21px"
            align="center"
            pl="16px"
            mb="11px"
            mt="-5px"
          ></MotionFlex>
        )}
        <Flex
          h="31px"
          w="100%"
          align="center"
          justify="center"
          mb="11px"
          as={NavLink}
          to="/admin/revenue"
          name="icon"
        >
          <ChartIcon />
        </Flex>

        <Flex
          h="31px"
          w="100%"
          align="center"
          justify="center"
          mb="11px"
          as={NavLink}
          to="/admin/fund-request"
          name="icon"
        >
          <WalletIcon />
        </Flex>
        <Flex
          h="31px"
          w="100%"
          align="center"
          justify="center"
          mb="11px"
          as={NavLink}
          to="/admin/audit-trail"
          name="icon"
        >
          <DocumentIcon />
        </Flex>
        {authState.isSuperAdmin && (
          <Flex
            h="31px"
            w="100%"
            align="center"
            justify="center"
            pr="8px"
            as={NavLink}
            to="/admin/user-management"
            name="icon"
          >
            <RoleIcon />
          </Flex>
        )}
      </Box>
      <Box mt="74px">
        <Flex
          h="31px"
          w="100%"
          align="center"
          justify="center"
          mb="11px"
          as={NavLink}
          to="/admin/top-up"
          name="icon"
        >
          <TopUpIcon />
        </Flex>
        <Flex
          h="31px"
          w="100%"
          align="center"
          justify="center"
          mb="11px"
          as={NavLink}
          to="/admin/discount"
          name="icon"
        >
          <DiscountIcon />
        </Flex>
        <Flex
          as={NavLink}
          h="31px"
          w="100%"
          align="center"
          justify="center"
          mb="11px"
          to="/admin/ticket"
          name="icon"
        >
          <TicketIcon />
        </Flex>
        <Flex
          h="31px"
          w="100%"
          align="center"
          justify="center"
          as={NavLink}
          to="/admin/settings"
          name="icon"
        >
          <SettingsIcon />
        </Flex>
      </Box>
      <Box mt="150px">
        <Flex
          h="31px"
          w="100%"
          align="center"
          justify="center"
          cursor="pointer"
          onClick={logout}
          name="icon"
        >
          <LogoutIcon />
        </Flex>
      </Box>
    </Box>
  );
};

export { Icons };

const example = keyframes`
    from {
      height: 0;
    }
    to {
      height: 21px;
    }
`;

const MotionFlex = styled(Flex)`
  animation: ${example} 0.5s;
`;
