import React, { useContext } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { Flex, Box } from '@chakra-ui/react';
import { PrivateRoute } from '../../components/auth';
import {
  Navbar,
  SidebarContext,
  SidebarContextProvider,
} from '../../components/layout';
import { SideBar } from '../../components/layout/admin';

import {
  Merchant,
  SingleMerchant,
  AuditTrail,
  FundRequest,
  DiscountRate,
  SalesRevenue,
  Settings,
  Applications,
  UserManagement,
  Ticket,
  Overview,
  Login,
  TopUp,
  ResetPassword,
  ForgotPassword,
  SingleDiscountRate,
} from './index';
import { ErrorOccured } from '../../components/common/errors';

const AdminDashboard = () => {
  return (
    <SidebarContextProvider>
      <AdminDashboardChild />
    </SidebarContextProvider>
  );
};

const AdminDashboardChild = () => {
  const { isOpen, setIsOpen } = useContext(SidebarContext);

  const { pathname } = useLocation();

  const pathsToHideLayout = [
    '/admin/login',
    '/admin/forgot-password',
    '/admin/reset-password',
  ];

  const hideLayout = pathsToHideLayout.includes(pathname);

  let contentMargin = 0;

  if (hideLayout) {
    contentMargin = 0;
  } else if (!hideLayout && isOpen) {
    contentMargin = '240px';
  } else {
    contentMargin = '52px';
  }

  return (
    <Flex h="100vh" w="100%">
      {!hideLayout && <SideBar isOpen={isOpen} setIsOpen={setIsOpen} />}

      <Box
        w="100%"
        ml={contentMargin}
        // w={`calc(100% - ${contentMargin}px)`}
      >
        {!hideLayout && <Navbar isOpen={isOpen} setIsOpen={setIsOpen} />}
        <Box
          mt={contentMargin === 0 ? '0px' : '10rem'}
          px={contentMargin === 0 ? '0px' : '0'}
          maxW={hideLayout ? '100%' : '95%'}
          mx="auto"
          pb={!hideLayout && '6rem'}
        >
          <Switch>
            <Route exact path="/admin/login">
              <Login />
            </Route>
            <PrivateRoute
              exact
              path="/admin/discount"
              component={DiscountRate}
            />
            <PrivateRoute
              exact
              path="/admin/revenue"
              component={SalesRevenue}
            />
            <PrivateRoute exact path="/admin/merchants" component={Merchant} />
            <PrivateRoute
              exact
              path="/admin/applications"
              component={Applications}
            />
            <PrivateRoute
              exact
              path="/admin/user-management"
              component={UserManagement}
            />
            <PrivateRoute
              exact
              path="/admin/merchant/:id"
              component={SingleMerchant}
            />
            <PrivateRoute
              exact
              path="/admin/merchant/:id/discount"
              component={SingleDiscountRate}
            />
            <PrivateRoute
              path="/admin/audit-trail"
              component={AuditTrail}
              exact
            />
            <PrivateRoute
              exact
              path="/admin/fund-request"
              component={FundRequest}
            />
            <PrivateRoute exact path="/admin/ticket" component={Ticket} />
            <PrivateRoute path="/admin/settings" component={Settings} />
            <PrivateRoute exact path="/admin/overview" component={Overview} />
            <PrivateRoute exact path="/admin/top-up" component={TopUp} />
            <Route
              exact
              path="/admin/forgot-password"
              component={ForgotPassword}
              isOpen={isOpen}
            />
            <Route
              exact
              path="/admin/reset-password"
              component={ResetPassword}
              isOpen={isOpen}
            />
            <Route path="*" exact={true}>
              <ErrorOccured message="Page not found" page="/admin/overview" />
            </Route>
          </Switch>
        </Box>
      </Box>
    </Flex>
  );
};

export default AdminDashboard;
