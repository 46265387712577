import React from 'react';

import { Box, Flex, Text } from '@chakra-ui/react';
import { ReactComponent as ResetIcon } from '../../assets/icons/reset3.svg';
import { SolidBtn } from '../common/buttons';

function LinkExpired() {
  return (
    <Box bgColor="#F8F9FB" w="100vw" h="100vh" pt="10rem">
      <Box bgColor="#fff" w="70rem" h="50rem" m="auto" pt="88px">
        <Box w="36rem" m="auto" textAlign="center">
          <Flex
            w="7.4rem"
            h="7.4rem"
            bgColor="rgba(0, 65, 190, 0.1)"
            m="auto"
            borderRadius="50%"
            align="center"
            justify="center"
          >
            <ResetIcon />
          </Flex>
          <Box mt=".8rem">
            <Text
              fontFamily="medium"
              fontSize="18px"
              lineHeight="22px"
              letterSpacing="-0.02em"
              color="#444"
            >
              Link expired
            </Text>
            <Text
              mt="1rem"
              fontFamily="regular"
              lineHeight="140%"
              color="#808080"
              letterSpacing="-0.02em"
              fontSize="13px"
            ></Text>
          </Box>
          <Box mt="3rem">
            <SolidBtn
              mt="2rem"
              as={Link}
              to="/merchant/login"
              fontFamily="medium"
            >
              Return to Login
            </SolidBtn>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export { LinkExpired };
