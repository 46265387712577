import React, { useState } from 'react';
import { Box, Text, Flex } from '@chakra-ui/react';

import { SolidBtn } from '../../components/common/buttons';

import {
  TopupModal,
  TopupStatistics,
  TopupTable,
} from '../../components/admin';

const TopUp = () => {
  const [open, setOpen] = useState(false);
  const [shouldRefetch, toggleRefetch] = useState(false);

  return (
    <Box w="100%">
      <Flex justify="space-between" align="center">
        <Text fontFamily="bold" fontSize="3.2rem" lineHeight="3.8rem">
          Top-Up
        </Text>
        <SolidBtn w="17rem" onClick={() => setOpen(true)}>
          Top-up
        </SolidBtn>
      </Flex>
      <TopupStatistics shouldRefetch={shouldRefetch} />
      <TopupTable shouldRefetch={shouldRefetch} />
      <TopupModal toggleRefetch={toggleRefetch} open={open} setOpen={setOpen} />
    </Box>
  );
};
export { TopUp };
