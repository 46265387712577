import { Flex, Box, Text } from '@chakra-ui/react';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { SolidBtn } from '../buttons/SolidBtn';

function ErrorOccured({ message, page }) {
  const history = useHistory();

  const handleClick = () => {
    if (page) {
      history.push(page);
    } else {
      window.location.reload();
    }
  };

  return (
    <Flex justify="center" align="center">
      <Box mt="5rem" textAlign="center">
        <Text fontSize="20rem" fontFamily="black">
          Oops!
        </Text>
        <Text fontFamily="regular" fontSize="2rem">
          {message || 'Something went wrong'}
        </Text>
        <SolidBtn w="17rem" mt="4rem" onClick={handleClick}>
          {page ? 'Go back to login' : 'Reload Page'}
        </SolidBtn>
      </Box>
    </Flex>
  );
}

export { ErrorOccured };
