import React, { useEffect } from 'react';
import { Box, Text, Flex, Skeleton } from '@chakra-ui/react';
import { useQuery } from 'react-query';

import { nFormatter } from '../../../utils';
import { getTopupStatistics } from '../../../queries';
import { ErrorOccured } from '../../common/errors';

const TopupStatistics = ({ shouldRefetch }) => {
  const { isLoading, isError, data, refetch } = useQuery(
    'getTopupStatistics',
    getTopupStatistics
  );

  useEffect(() => {
    refetch();
  }, [shouldRefetch]);

  if (isLoading) {
    return <Skeleton w="100%" h="15.7rem" />;
  }

  if (isError) {
    return <ErrorOccured />;
  }

  const objData = Object.fromEntries(
    data?.map((stats) => [
      stats.network,
      {
        network: stats.network,
        amount: stats.amount,
      },
    ])
  );
  return (
    <>
      <Box mt="10.8rem" w="100%" h="15.7rem" border="1px solid #F3F3F3">
        <Flex justify="space-between">
          <Box mt="3.5rem" ml="3.6rem">
            <Box d="flex" flexDirection="column">
              <Text
                color="#808080"
                fontFamily="medium"
                fontSize="1.6rem"
                lineHeight="1.9rem"
              >
                Mtn
              </Text>
              <Text
                color="#444444"
                fontFamily="bold"
                fontSize="3rem"
                lineHeight="4.3rem"
                mt="2.1rem"
              >
                {nFormatter(objData.mtn.amount)}
              </Text>
            </Box>
          </Box>
          <Box
            borderRight="1px solid #E8EEF4"
            mt="2.4rem"
            h="10.9rem"
            pr="2.6rem"
            pl="2.6rem"
          />
          <Box mt="3.5rem" ml="5.6rem">
            <Box d="flex" flexDirection="column">
              <Text
                color="#808080"
                fontFamily="medium"
                fontSize="1.6rem"
                lineHeight="1.9rem"
              >
                Glo
              </Text>
              <Text
                color="#444444"
                fontFamily="bold"
                fontSize="3rem"
                lineHeight="4.3rem"
                mt="2.1rem"
              >
                {nFormatter(objData.glo.amount)}
              </Text>
            </Box>
          </Box>
          <Box
            borderRight="1px solid #E8EEF4"
            mt="2.4rem"
            h="10.9rem"
            pr="2.6rem"
            pl="2.6rem"
          />
          <Box mt="3.5rem" ml="5.6rem">
            <Box d="flex" flexDirection="column">
              <Text
                color="#808080"
                fontFamily="medium"
                fontSize="1.6rem"
                lineHeight="1.9rem"
              >
                Airtel
              </Text>
              <Text
                color="#444444"
                fontFamily="bold"
                fontSize="3rem"
                lineHeight="4.3rem"
                mt="2.1rem"
              >
                {nFormatter(objData.airtel.amount)}
              </Text>
            </Box>
          </Box>
          <Box
            borderRight="1px solid #E8EEF4"
            mt="2.4rem"
            h="10.9rem"
            pr="2.6rem"
            pl="2.6rem"
          />
          <Box mt="3.5rem" mr="5rem" ml="4rem">
            <Box d="flex" flexDirection="column">
              <Text
                color="#808080"
                fontFamily="medium"
                fontSize="1.6rem"
                lineHeight="1.9rem"
              >
                9mobile
              </Text>
              <Text
                color="#444444"
                fontFamily="bold"
                fontSize="3rem"
                lineHeight="4.3rem"
                mt="2.1rem"
              >
                {nFormatter(objData['9mobile'].amount)}
              </Text>
            </Box>
          </Box>
        </Flex>
      </Box>
    </>
  );
};

export { TopupStatistics };
