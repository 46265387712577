import React from 'react';
import { Flex, Box, Button, Image, Text } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { motion } from 'framer-motion';
import image from '../assets/icons/17.svg';

const Page404 = () => {
  const history = useHistory();
  const MotionImg = motion.custom(Image);
  const MotionDiv = motion.custom(Flex);
  return (
    <Flex h="100vh" pl="5rem">
      <MotionDiv
        direction="column"
        justify="center"
        w="50vw"
        initial={{ x: '-5vw' }}
        animate={{ x: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Text as="h2" fontSize="15rem" m="0">
          404
        </Text>
        <Text as="p" fontSize="1.5rem" m="0">
          The page you are looking for does not exist.
        </Text>
        <Button
          bg="#0041BE"
          color="#fff"
          w="100px"
          mt="20px"
          border="none"
          focusBorderColor="1px solid #053794"
          _hover={{ bg: '#053794' }}
          _active={{ bg: '#053794' }}
          _focus={{ bg: '#053794', focusBorderColor: '1px solid #053794' }}
          onClick={() => history.goBack()}
        >
          Go Back
        </Button>
      </MotionDiv>
      <Box w="50vw">
        <MotionImg
          width="600px"
          objectFit="cover"
          src={image}
          initial={{ opacity: 0, y: '-50vh' }}
          animate={{ opacity: 1, y: '20vh' }}
          transition={{ duration: 1.7 }}
          alt="404-image"
        />
      </Box>
    </Flex>
  );
};

export { Page404 };
