import React from 'react';
import { Box, Flex, Text, Image } from '@chakra-ui/react';
import styled from '@emotion/styled';

import TopImg from '../../assets/images/topimg.svg';
import LeftImg from '../../assets/images/leftimg.svg';
import RightImg from '../../assets/images/rightimg.svg';

const AboutUs = () => {
  return (
    <>
      <WebView>
        <Flex w="100%" justifyContent="space-between">
          <Box>
            <CustomTop src={TopImg} />
            <Box mt="3rem" d="flex">
              <CustomLeft src={LeftImg} />
              <CustomRight src={RightImg} />
            </Box>
          </Box>
          <StyledBox>
            <Text
              color="rgba(232, 238, 244, 0.6)"
              fontFamily="medium"
              fontSize="1.4rem"
              lineHeight="120%"
              mb=".8rem"
            >
              About Us
            </Text>
            <Text
              color="#FFFFFF"
              fontFamily="regular"
              fontSize="5.6rem"
              lineHeight="110%"
            >
              Awake <br /> Interlink
            </Text>
            <CustomText>
              Awake focuses on delivering great value on services to our <br />
              customers. With our simple and transparent pricing; we <br />
              offer flexible rates on airtime, data and utilities. Awake acts
              <br /> as the middleman between Telecommunication companies <br />
              and Nigerian businesses. We are committed to delivering <br />
              strong operational performance in providing sustainable <br />
              value to our clients.
            </CustomText>
          </StyledBox>
        </Flex>
      </WebView>
      <MobileView>
        <Flex justify="center" w="100%">
          <Image src={TopImg} w="80%" borderRadius="3.3px" h="14.7rem" />
        </Flex>
        <Flex justify="center" w="100%" mt="2%">
          <Flex justify="space-between" w="80%">
            <Image src={LeftImg} w="49%" borderRadius="3.3px" h="11.5rem" />
            <Image src={RightImg} w="49%" borderRadius="3.3px" h="11rem" />
          </Flex>
        </Flex>
        <Box
          mt="1.2rem"
          w="100%"
          background="linear-gradient(165.86deg, #0041BE 10.06%, #9057C3 99.84%)"
          pt="3.2rem"
          pl="3.2rem"
          pr="4rem"
          pb="3.2rem"
        >
          <Text
            color="rgba(232, 238, 244, 0.6)"
            fontFamily="medium"
            fontSize="1.4rem"
            lineHeight="120%"
          >
            About Us
          </Text>
          <Text
            mt=".6rem"
            color="#FFFFFF"
            fontFamily="regular"
            fontSize="2.4rem"
            lineHeight="110%"
          >
            Awake Interlink
          </Text>
          <Text
            mt="2.4rem"
            color=" rgba(255, 255, 255, 0.8)"
            fontFamily="medium"
            fontSize="1.4rem"
            lineHeight="160%"
          >
            Awake focuses on delivering great value on services to our
            customers. With our simple and transparent pricing; we offer
            flexible rates on airtime, data and utilities. Awake acts as the
            middleman between Telecommunication companies and Nigerian
            businesses. We are committed to delivering strong operational
            performance in providing sustainable value to our clients.
          </Text>
        </Box>
      </MobileView>
    </>
  );
};

const CustomTop = styled(Image)`
  border-radius: 8px;
  @media only screen and (min-width: 1441px) {
    width: 94.6rem;
  }
`;
const CustomLeft = styled(Image)`
  border-radius: 8px !important;
  height: 26.5rem;
  @media only screen and (min-width: 1441px) {
    width: 47rem;
    height: 34rem;
  }
`;
const CustomRight = styled(Image)`
  border-radius: 8px !important;
  width: 40.5rem;
  height: 24.9rem;
  margin-left: 1rem;
  @media only screen and (min-width: 1441px) {
    height: 32rem;
    width: 50rem;
  }
`;

const StyledBox = styled(Box)`
  width: 66.6rem;
  height: 53.3rem;
  background: linear-gradient(165.86deg, #0041be 10.06%, #9057c3 99.84%);
  margin-top: 5rem;
  padding-left: 6.5rem;
  padding-top: 5.6rem;
  @media only screen and (min-width: 1441px) {
    margin-top: 12rem;
    width: 45.6vw;
    height: 58.3rem;
    padding-left: 12rem;
  }
`;

const CustomText = styled(Text)`
  color: rgba(255, 255, 255, 0.8);
  line-height: 200%;
  margin-top: 2.4rem;
  font-family: 'TT Norms Medium';
`;

const WebView = styled(Box)`
  @media (max-width: 768px) {
    display: none;
  }
`;

const MobileView = styled(Box)`
  margin-top: 3.5rem;
  @media (min-width: 768px) {
    display: none;
  }
`;

export { AboutUs };
