import React from 'react';
import Search from '../../../assets/icons/search_icon.svg';
import { ReactComponent as Export } from '../../../assets/icons/Export.svg';
import { CustomInput } from '../../common/form/Input';
import { Flex, HStack, Image } from '@chakra-ui/react';

const Actions = ({ handleOpen, handleChange, name, handleSearchData }) => {
  return (
    <Flex maxW="100%" mt="5rem" alignItems="center" justify="space-between">
      <form onSubmit={handleSearchData}>
        <CustomInput
          style={{ marginTop: 0 }}
          w="33.2rem"
          borderRadius="4px"
          label="Search"
          onChange={handleChange}
          value={name}
        >
          <Image src={Search} alt="search_icon" />
        </CustomInput>
      </form>
      <HStack spacing="3.5rem">
        <Export cursor="pointer" onClick={handleOpen} />
      </HStack>
    </Flex>
  );
};

export { Actions };
