export * from './Contact';
export * from './Services';
export * from './NavBar';
export * from './Footer';
export * from './BecomeAMerchant';
export * from './Help';
export * from './OurPartners';
export * from './AboutUs';
export * from './Stats';
export * from './Slider';
