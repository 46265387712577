import React from 'react';
import styled from '@emotion/styled';
import { Box, Flex, Text, Image, Link, HStack } from '@chakra-ui/react';
import FacebookLogo from '../../assets/images/facebook.svg';
import TwitterLogo from '../../assets/images/twitter.svg';
import InstaLogo from '../../assets/images/insta.svg';

const Footer = () => {
  return (
    <Box bg="#f8f9fb" w="100%" overflow="hidden">
      <Box
        maxW="1140px"
        mx="auto"
        py={{ base: '5rem' }}
        px={{ base: '2rem', lg: '1rem' }}
      >
        <Flex
          direction={{ base: 'column', lg: 'row' }}
          justifyContent="space-between"
        >
          <Box textAlign={{ base: 'center', lg: 'left' }}>
            <Box>
              <HeaderText>Awake</HeaderText>
            </Box>
            <HStack
              spacing="2rem"
              pt={{ base: '3rem', lg: '5.2rem' }}
              justify="center"
            >
              <LinkedImage
                href="https://twitter.com/awake_interlink"
                target="blank"
              >
                <Image src={TwitterLogo} alt="Twitter" />
              </LinkedImage>
              <LinkedImage
                href="https://instagram.com/awake_interlink"
                target="blank"
              >
                <Image src={InstaLogo} alt="Instagram" />
              </LinkedImage>
              <LinkedImage
                href="https://facebook.com/awakeinterlinkict"
                target="blank"
              >
                <Image src={FacebookLogo} alt="Facebook" />
              </LinkedImage>
            </HStack>
          </Box>
          <Box
            mt={{ base: '5rem', lg: '0' }}
            textAlign={{ base: 'center', lg: 'left' }}
          >
            <Box>
              <HeaderText>Office</HeaderText>
            </Box>
            <Box pt="20px">
              <Text
                lineHeight="178%"
                fontFamily="medium"
                fontSize="16px"
                color="#626363"
              >
                64, UI-Secretariat road, Kongi, Bodija
              </Text>
              <Text
                lineHeight="178%"
                fontFamily="medium"
                fontSize="16px"
                color="#626363"
              >
                Ibadan, Oyo State.
              </Text>
            </Box>
          </Box>
          <Box
            mt={{ base: '5rem', lg: '0' }}
            textAlign={{ base: 'center', lg: 'left' }}
          >
            <Box>
              <HeaderText>Get in touch</HeaderText>
            </Box>
            <Box pt="20px">
              <Text
                lineHeight="170%"
                fontFamily="medium"
                fontSize="16px"
                color="#626363"
              >
                info@awakeventures.com
              </Text>
              <Text
                lineHeight="170%"
                fontFamily="medium"
                fontSize="16px"
                color="#626363"
              >
                +234 708 0099 345
              </Text>
            </Box>
          </Box>
        </Flex>
        <Flex
          direction={{ base: 'column', lg: 'row' }}
          justifyContent="space-between"
          pt="60px"
        >
          <Box textAlign={{ base: 'center', lg: 'left' }}>
            <Text
              lineHeight="178%"
              fontFamily="medium"
              fontSize="16px"
              color="#979797"
            >
              Copyright by{' '}
              <Text as="span" fontFamily="bold">
                <strong>Awake</strong>
              </Text>
              . All rights reserved.
            </Text>
          </Box>
          <Flex display={{ base: 'none', lg: 'flex' }}>
            <HyperLinkText href="/">Home</HyperLinkText>
            <HyperLinkText href="#howitworks">How It Works</HyperLinkText>
            <HyperLinkText
              href="https://docs.awakeinterlink.ng/"
              target="__blank"
            >
              API
            </HyperLinkText>
            <HyperLinkText href="/contact-us">Contact Us</HyperLinkText>
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
};

export { Footer };

const LinkedImage = styled(Link)`
  outline: none !important;
  border: none;

  &:focus {
    box-shadow: none;
  }
`;

const HyperLinkText = styled(Link)`
  font-size: 16px;
  line-height: 130%;
  color: #979797;
  padding-left: 40px;
  font-family: 'TT Norms Medium', sans-serif;

  &:hover {
    text-decoration: none !important;
  }
  &:focus {
    box-shadow: none;
  }
`;

const HeaderText = styled(Text)`
  font-size: 18px;
  line-height: 130%;
  letter-spacing: -0.02em;
  color: #444444;
  font-family: 'TT Norms Medium', sans-serif;
`;
