import axios from 'axios';
import instance from '../axios.config';
import { handleError } from '../../utils';

const walletData = async (key, page = 1) => {
  try {
    const [bankRes, walletRes, walletTopups] = await axios.all([
      instance.get('/merchant/bank'),
      instance.get('/merchant/wallet'),
      instance.get(`/merchant/fund-request?page=${page}`),
    ]);

    return {
      bankDetails: bankRes.data.data,
      walletStats: walletRes.data.data,
      walletTopups: walletTopups.data.data,
    };
  } catch (error) {
    handleError(error);
  }
};

const createFundRequest = async (body) => {
  const { data } = await instance.post(`/merchant/fund-request`, body);
  return data;
};

const deleteFundRequest = async (id) => {
  const { data } = await instance.delete(`/merchant/fund-request/${id}`);
  return data;
};

const editFundRequest = async (id, amount) => {
  const { data } = await instance.put(`/merchant/fund-request/${id}`, {
    amount,
  });
  return data;
};

export { walletData, createFundRequest, deleteFundRequest, editFundRequest };
