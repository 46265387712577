import styled from '@emotion/styled';
import React from 'react';

function Toggle() {
  return (
    <Wrapper>
      <label className="toggle">
        <input className="toggle-checkbox" type="checkbox" />
        <div className="toggle-switch"></div>
      </label>
    </Wrapper>
  );
}

export { Toggle };

const Wrapper = styled.label`
  .toggle {
    cursor: pointer;
    display: inline-block;
  }

  .toggle-switch {
    display: inline-block;
    background: red;
    border-radius: 16px;
    width: 50px;
    height: 28px;
    position: relative;
    vertical-align: middle;
    transition: background 0.25s;
  }
  .toggle-switch:before,
  .toggle-switch:after {
    content: '';
  }
  .toggle-switch:before {
    display: block;
    background: linear-gradient(to bottom, #fff 0%, #eee 100%);
    border-radius: 50%;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.25);
    width: 24px;
    height: 24px;
    position: absolute;
    top: 2px;
    left: 2px;
    transition: left 0.25s;
  }
  .toggle:hover .toggle-switch:before {
    background: linear-gradient(to bottom, #fff 0%, #fff 100%);
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5);
  }
  .toggle-checkbox:checked + .toggle-switch {
    background: #56c080;
  }
  .toggle-checkbox:checked + .toggle-switch:before {
    left: 24px;
  }

  .toggle-checkbox {
    position: absolute;
    visibility: hidden;
  }
`;
