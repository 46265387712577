import React, { useContext, useState } from 'react';
import { Box, Text } from '@chakra-ui/react';
import { useQuery } from 'react-query';

import { SpinningText } from '../../components/common/loaders';
import { AuthContext } from '../../context/auth';

import { getMerchantOverviewData } from '../../queries';
import {
  WalletInfo,
  SalesRevenue,
  TransactionHistory,
  FundWalletModal,
} from '../../components/merchant';
import { ErrorOccured } from '../../components/common/errors';

function Overview() {
  const [showCenterModal, setShowCenterModal] = useState(false);
  const { authState } = useContext(AuthContext);

  const { isError, isLoading, data } = useQuery(
    'merchantOverview',
    getMerchantOverviewData
  );

  if (isLoading) {
    return <SpinningText />;
  }

  if (isError) {
    return <ErrorOccured />;
  }

  return (
    <Box w="100%" pb="4rem">
      <Text fontFamily="bold" fontSize="3.2rem" lineHeight="3.8rem">
        Overview
      </Text>
      <Box mt="2rem">
        <Text
          color="#444444"
          fontSize="1.6rem"
          fontFamily="regular"
          lineHeight="1.9rem"
        >
          Hello,{' '}
          <Box
            as="span"
            color="#444444"
            fontSize="1.6rem"
            fontFamily="bold"
            textTransform="capitalize"
            lineHeight="1.9rem"
          >
            {authState.first_name}
          </Box>
        </Text>
        <Text
          fontFamily="regular"
          color="#808080"
          fontSize="1.4rem"
          lineHeight="120%"
          mt="4px"
        >
          Here are the summary of what is happening on your board this month.
        </Text>
      </Box>
      <WalletInfo
        walletBalance={data?.overviewData.walletBalance}
        amountFundedPerYear={data?.overviewData?.amountFundedPerYear}
        setShowCenterModal={setShowCenterModal}
        bankDetails={data.bankDetails}
      />
      <SalesRevenue />
      <TransactionHistory
        lastFiveTx={data.overviewData.lastFiveTx}
        topSellingProducts={data.overviewData.topSellingProducts}
      />
      <FundWalletModal
        setShowCenterModal={setShowCenterModal}
        showCenterModal={showCenterModal}
      />
    </Box>
  );
}

export { Overview };
