import React, { useContext, useEffect, useState } from 'react';
import { Box, Flex, HStack, Image, Input, Text } from '@chakra-ui/react';

import timeline from '../../assets/images/timline.svg';
import upload from '../../assets/icons/upload.svg';
import { ReactComponent as File } from '../../assets/icons/file.svg';
import { ReactComponent as Success } from '../../assets/icons/success.svg';

import { SolidBtn } from '../../components/common/buttons';
import { uploadDoc } from '../../queries';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../context/auth';
import styled from '@emotion/styled';

function DocumentUpload() {
  const [uploadComplete, setUploadComplete] = useState(false);
  const { authState } = useContext(AuthContext);

  useEffect(() => {
    if (authState.isVerified) {
      history.push('/merchant/login');
    }
  }, []);

  return (
    <Flex align="center" h="100vh" w="100%" maxW="1140px" mx="auto">
      <Flex w="100%">
        <Box w="50%">
          <Text color="brand.blue" fontSize="4rem" fontFamily="light">
            Upload Files
          </Text>
          <Text
            fontFamily="regular"
            color="#808080"
            fontSize="1.6rem"
            maxW="500px"
            mt="2.4rem"
          >
            You are required to provide legal information and upload documents
            to get activated on the platform.
          </Text>
          <HStack mt="5.5rem" spacing="5.5rem">
            <Image src={timeline} />
            <Box>
              <Text fontFamily="regular" fontSize="1.4rem" color="#444">
                Certificate of Incorporation (COI)
              </Text>
              <Text
                mt="2rem"
                fontFamily="regular"
                fontSize="1.4rem"
                color="#444"
              >
                CAC / Form 7 (Details of Directors)
              </Text>
              <Text
                mt="2rem"
                fontFamily="regular"
                fontSize="1.4rem"
                color="#444"
              >
                CAC Form 2 (Details of shareholding/shareholders)
              </Text>
            </Box>
          </HStack>
        </Box>
        <Box w="50%">
          <Box border="1px solid #F3F3F3" w="100%" px="3rem" py="6rem">
            <HStack spacing="2rem">
              <Image src={upload} w="5rem" />
              <Text color="#626363" fontFamily="medium" fontSize="2.6rem">
                Upload files
              </Text>
            </HStack>
            <Text color="#444" fontFamily="medium" fontSize="2rem" mt="8rem">
              Select document or file
            </Text>
            <Box mt="3.2rem">
              <UploaderAccordion setUploadComplete={setUploadComplete} />
            </Box>
            <Box mt="9.5rem" w="14.5rem" ml="auto">
              <SolidBtn
                as={Link}
                to={!uploadComplete ? '#' : '/merchant/waiting'}
                isDisabled={!uploadComplete}
              >
                Proceed
              </SolidBtn>
            </Box>
          </Box>
        </Box>
      </Flex>
    </Flex>
  );
}

export { DocumentUpload };

function UploaderAccordion({ setUploadComplete }) {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    if (activeIndex > 2) setUploadComplete(true);
  }, [activeIndex]);

  const documents = [
    {
      title: 'Certificate of Incorporation (COI)',
      classTitle: '',
    },
    {
      title: 'CAC /Form 7 (Details of Directors)',
      classTitle: '',
    },
    {
      title: 'CAC Form 2',
      classTitle: 'last-doc',
    },
  ];

  const setCurrentDoc = (title) => {
    const index = documents.findIndex((item) => item.title === title);
    setActiveIndex(index + 1);
  };

  return (
    <Box>
      {documents.map((item, i) => (
        <Box key={item.title}>
          <DocUploader
            active={activeIndex === i}
            setCurrentDoc={setCurrentDoc}
            title={item.title}
            done={i < activeIndex}
            classTitle={item.classTitle}
          />
        </Box>
      ))}
    </Box>
  );
}

function DocUploader({ setCurrentDoc, title, active, done, classTitle }) {
  const [currentFile, setCurrentFile] = useState(null);
  const [percentage, setPercentage] = useState(0);
  const [isUploading, setIsUploading] = useState(false);

  const handleFile = async (e) => {
    const file = e.target.files[0];
    const { size, type } = file;

    const allowedTypes = ['image/jpeg', 'image/png', 'application/pdf'];

    if (!allowedTypes.includes(type)) {
      toast.error('File type not allowed');
      return;
    }

    if (size > 3000000) {
      toast.error('File size too large. Max size is 3mb');
      return;
    }

    const formData = new FormData();
    formData.append('title', title);
    formData.append('file', file);

    try {
      setIsUploading(true);
      setCurrentFile(file);
      await uploadDoc(formData, setPercentage);
      setIsUploading(false);
      setCurrentFile(null);
      setCurrentDoc(title);
      toast.success('Uploaded');
    } catch (err) {
      setPercentage(0);
      setCurrentFile(null);
      setIsUploading(false);
      toast.error(err.response?.data?.message || 'Upload failed');
    }
  };

  return (
    <UploadWidgetWrapper
      pl="2.5rem"
      borderLeft={
        done ? '1px solid #2EAE65' : '1px solid rgba(53, 88, 199, 0.2)'
      }
      pb="2.4rem"
      pos="relative"
      className={classTitle}
    >
      <Box
        w="1rem"
        h="1rem"
        bg={done ? '#2EAE65' : '#C4C4C4'}
        borderRadius="50%"
        pos="absolute"
        top=".8rem"
        left="-5px"
      />
      <HStack spacing="2rem">
        <Text fontFamily="regular" fontSize="1.4rem" color="#808080">
          {title}
        </Text>
        {done && <Success />}
      </HStack>
      {active && (
        <Box w="100%">
          {isUploading ? (
            <HStack
              spacing="2.6rem"
              boxShadow="0px 8px 30px rgba(53, 88, 199, 0.22)"
              bg="#fff"
              borderRadius="4px"
              p="1rem 2rem"
              mt="1.6rem"
            >
              <File />
              <Box flex="1">
                <Flex justify="space-between" mb="1rem">
                  <Text fontFamily="regular" fontSize="1.4rem" color="#808080">
                    {currentFile?.name}
                  </Text>
                  <Text fontFamily="regular" fontSize="1.4rem" color="#808080">
                    {percentage} %
                  </Text>
                </Flex>
                <Box
                  pos="relative"
                  bg="#E8EEF4"
                  h="7px"
                  w="100%"
                  borderRadius="8px"
                >
                  <Box
                    pos="absolute"
                    left="0"
                    top="0"
                    h="100%"
                    borderRadius="inherit"
                    transition="width .5s"
                    w={`${percentage}%`}
                    bg="brand.blue"
                  />
                </Box>
              </Box>
            </HStack>
          ) : (
            <Flex
              align="center"
              background="rgba(53, 88, 199, 0.05)"
              border="2px dashed rgba(53, 88, 199, 0.3)"
              w="100%"
              h="7rem"
              borderRadius="8px"
              mt="1.6rem"
              px="7rem"
              pos="relative"
            >
              <HStack
                spacing="2rem"
                pos="absolute"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
              >
                <Image src={upload} w="5rem" />
                <Text
                  color="#626363"
                  fontFamily="regular"
                  fontSize="1.4rem"
                  whiteSpace="nowrap"
                >
                  Click here to{' '}
                  <Text color="brand.blue" fontFamily="medium" as="span">
                    browse
                  </Text>{' '}
                  to upload
                </Text>
              </HStack>
              <Input
                onChange={handleFile}
                type="file"
                position="absolute"
                top="0"
                left="0"
                w="100%"
                h="100%"
                opacity="0"
              />
            </Flex>
          )}
        </Box>
      )}
    </UploadWidgetWrapper>
  );
}

const UploadWidgetWrapper = styled(Box)`
  &.last-doc {
    padding-bottom: 0.8rem !important;
  }
`;
