import React from 'react';
import {
  NavBar,
  BecomeAMerchant,
  Hero,
  Stats,
  AboutUs,
  OurPartners,
  Services,
  Footer,
  Help,
} from '../../components/landingPage';

const LandingPage = () => {
  return (
    <>
      <NavBar />
      <Hero />
      <Stats />
      <AboutUs />
      <Services />
      <BecomeAMerchant />
      <OurPartners />
      <Help />
      <Footer />
    </>
  );
};

export default LandingPage;
