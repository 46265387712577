import React, { useContext } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { Flex, Box } from '@chakra-ui/react';
import { PrivateRoute } from '../../components/auth';
import {
  Navbar,
  SidebarContext,
  SidebarContextProvider,
} from '../../components/layout';
import { SideBar } from '../../components/layout/merchant';

import {
  Ticket,
  Settings,
  ApiDocumentation,
  Wallet,
  Overview,
  WaitingScreen,
  DocumentDeclined,
  ProductList,
  Signup,
  Login,
  TransactionHistory,
  ForgotPassword,
  ResetPassword,
} from './index';
import { DocumentUpload } from './DocumentUpload';
import { ErrorBoundary, ErrorOccured } from '../../components/common/errors';

const MerchantDashboard = () => {
  return (
    <SidebarContextProvider>
      <MerchantDashboardChild />
    </SidebarContextProvider>
  );
};

const MerchantDashboardChild = () => {
  const { isOpen, setIsOpen } = useContext(SidebarContext);

  const { pathname } = useLocation();

  const pathsToHideLayout = [
    '/merchant/waiting',
    '/merchant/declined',
    '/merchant/upload',
    '/merchant/signup',
    '/merchant/login',
    '/merchant/forgot-password',
    '/merchant/reset-password',
  ];

  const hideLayout = pathsToHideLayout.includes(pathname);

  let contentMargin = 0;

  if (hideLayout) {
    contentMargin = 0;
  } else if (!hideLayout && isOpen) {
    contentMargin = '240px';
  } else {
    contentMargin = '52px';
  }

  return (
    <ErrorBoundary>
      <Flex h="100vh" w="100vw">
        {!hideLayout && <SideBar isOpen={isOpen} setIsOpen={setIsOpen} />}

        <Box w="100%" ml={contentMargin}>
          {!hideLayout && <Navbar isOpen={isOpen} setIsOpen={setIsOpen} />}
          <Box
            mt={contentMargin === 0 ? '0' : '10rem'}
            px={contentMargin === 0 ? '0' : '4rem'}
            pb={!hideLayout && '6rem'}
          >
            <Switch>
              <Route path="/merchant/signup">
                <Signup />
              </Route>
              <Route path="/merchant/login">
                <Login />
              </Route>
              <PrivateRoute exact path="/merchant/ticket" component={Ticket} />
              <PrivateRoute
                exact
                path="/merchant/settings"
                component={Settings}
              />
              <PrivateRoute
                exact
                path="/merchant/documentation"
                component={ApiDocumentation}
              />
              <PrivateRoute
                exact
                path="/merchant/wallet"
                component={Wallet}
                isOpen={isOpen}
              />
              <PrivateRoute
                exact
                path="/merchant/overview"
                component={Overview}
                isOpen={isOpen}
              />
              <PrivateRoute
                exact
                path="/merchant/waiting"
                component={WaitingScreen}
                isOpen={isOpen}
              />
              <PrivateRoute
                exact
                path="/merchant/declined"
                component={DocumentDeclined}
                isOpen={isOpen}
              />
              <PrivateRoute
                exact
                path="/merchant/products"
                component={ProductList}
                isOpen={isOpen}
              />
              <PrivateRoute
                exact
                path="/merchant/upload"
                component={DocumentUpload}
                isOpen={isOpen}
              />
              <PrivateRoute
                exact
                path="/merchant/transactions"
                component={TransactionHistory}
                isOpen={isOpen}
              />
              <Route
                exact
                path="/merchant/forgot-password"
                component={ForgotPassword}
                isOpen={isOpen}
              />
              <Route
                exact
                path="/merchant/reset-password"
                component={ResetPassword}
                isOpen={isOpen}
              />
              <Route path="*" exact={true}>
                <ErrorOccured
                  message="Page not found"
                  page="/merchant/overview"
                />
              </Route>
            </Switch>
          </Box>
        </Box>
      </Flex>
    </ErrorBoundary>
  );
};

export default MerchantDashboard;
