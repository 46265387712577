import { Box, Flex, Image, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';

import BecomeAMerchantImg from '../../assets/images/become.svg';
import { SolidBtn } from '../common/buttons';

function BecomeAMerchant() {
  return (
    <Box
      id="howitworks"
      py="12rem"
      w="100%"
      maxW="1140px"
      m="auto"
      px={{ base: '2rem', lg: '1rem' }}
      overflow="hidden"
    >
      <Box>
        <Text fontFamily="regular" fontSize="1.4rem" color="#C4C4C4">
          How it works
        </Text>
        <Text
          maxW="500px"
          fontFamily="regular"
          fontSize="4rem"
          lineHeight="4.8rem"
          color="#444"
        >
          Become a Merchant and start earning.
        </Text>
      </Box>
      <Flex
        direction={{ base: 'column', lg: 'row' }}
        mt={{ base: '4rem', md: '7rem' }}
      >
        <Box
          h={{ base: '650px', md: '766px' }}
          w={{ base: '100%', md: '50%' }}
          pos="relative"
        >
          <Box h="100%" w={{ base: '80%', md: '385px' }} bgColor="brand.blue" />
          <Image
            src={BecomeAMerchantImg}
            pos="absolute"
            top="50%"
            transform="translateY(-50%)"
            left={{ base: '50px', md: '150px' }}
            w={{ base: '30rem', sm: '40rem' }}
          />
        </Box>
        <VStack
          w={{ sm: '100%', md: '500px', lg: '310px' }}
          ml={{ base: '0', lg: '150px' }}
          mt={{ base: '3rem', lg: '-3rem' }}
        >
          <Box
            border="2px solid rgba(0,0,0,0)"
            px={{ base: '0', md: '2.5rem' }}
            py="1.7rem"
            _hover={{
              background: '#F8F9FB',
              border: '2px solid #F3F3F3',
              borderRadius: '4px',
            }}
          >
            <Text color="#C4C4C4" fontSize="2rem" fontFamily="bold">
              01.
            </Text>
            <Text color="#626363" fontSize="2rem" fontFamily="bold">
              Signup on the website
            </Text>
            <Text
              color="#808080"
              fontSize="1.6rem"
              fontFamily="regular"
              lineHeight="2.6rem"
              mt="1.2rem"
              textAlign="justify"
            >
              Input your correct company email and password and enjoy security
              around your digital financial payments. <br />
              <Text
                as={Link}
                to="/merchant/signup"
                color="brand.blue"
                textDecor="underline"
                fontFamily="medium"
              >
                Sign up
              </Text>
            </Text>
          </Box>
          <Box
            border="2px solid rgba(0,0,0,0)"
            px={{ base: '0', md: '2.5rem' }}
            py="1.7rem"
            _hover={{
              background: '#F8F9FB',
              border: '2px solid #F3F3F3',
              borderRadius: '4px',
            }}
          >
            <Text color="#C4C4C4" fontSize="2rem" fontFamily="bold">
              02.
            </Text>
            <Text color="#626363" fontSize="2rem" fontFamily="bold">
              Upload certificate
            </Text>
            <Text
              color="#808080"
              fontSize="1.6rem"
              fontFamily="regular"
              lineHeight="2.6rem"
              mt="1.2rem"
              textAlign="justify"
            >
              To help us ensure the information you provide us is correct, we
              will require certain certificates of identification about your
              business.
            </Text>
          </Box>
          <Box
            border="2px solid rgba(0,0,0,0)"
            px={{ base: '0', md: '2.5rem' }}
            py="1.7rem"
            _hover={{
              background: '#F8F9FB',
              border: '2px solid #F3F3F3',
              borderRadius: '4px',
            }}
          >
            <Text color="#C4C4C4" fontSize="2rem" fontFamily="bold">
              03.
            </Text>
            <Text color="#626363" fontSize="2rem" fontFamily="bold">
              Topup Wallet
            </Text>
            <Text
              color="#808080"
              fontSize="1.6rem"
              fontFamily="regular"
              lineHeight="2.6rem"
              mt="1.2rem"
              textAlign="justify"
            >
              Deposit money into your wallet from your account to carry out bulk
              purchases on airtime, data and utilities. Our service also accepts
              multiple recharge on wallets.
            </Text>
          </Box>
          <Box
            border="2px solid rgba(0,0,0,0)"
            px={{ base: '0', md: '2.5rem' }}
            py="1.7rem"
            _hover={{
              background: '#F8F9FB',
              border: '2px solid #F3F3F3',
              borderRadius: '4px',
            }}
          >
            <Text color="#C4C4C4" fontSize="2rem" fontFamily="bold">
              04.
            </Text>
            <Text color="#626363" fontSize="2rem" fontFamily="bold">
              Start selling
            </Text>
            <Text
              color="#808080"
              fontSize="1.6rem"
              fontFamily="regular"
              lineHeight="2.6rem"
              mt="1.2rem"
              textAlign="justify"
            >
              Now you are all set up and can start selling to your customers and
              earn big.
            </Text>
            <SolidBtn
              as={Link}
              to="/merchant/signup"
              mt="2rem"
              border="1px solid #0041BE"
              bgColor="rgba(53, 88, 199, 0.1)"
              color="#0041BE"
            >
              Become a merchant
            </SolidBtn>
          </Box>
        </VStack>
      </Flex>
    </Box>
  );
}

export { BecomeAMerchant };
