import axios from 'axios';
import instance from '../axios.config';

const getTickets = async () => {
  const url1 = '/merchant/ticket/?status=open';
  const url2 = '/merchant/ticket/?status=closed';

  const promise1 = instance.get(url1);
  const promise2 = instance.get(url2);

  const [openTickets, closedTickets] = await axios.all([promise1, promise2]);

  return {
    openTickets: openTickets.data.data,
    closedTickets: closedTickets.data.data,
  };
};

const createTicket = async (body) => {
  const { data } = await instance.post('/merchant/ticket', body);

  return data;
};

const getSingleTicket = async (id) => {
  const { data } = await instance.get(`/merchant/ticket/${id}`);
  return data.data;
};

const replyTicket = async (body, id, type) => {
  const content =
    type === 'file'
      ? body
      : {
          message: body,
        };
  const { data } = await instance.post(`/merchant/ticket/${id}/reply`, content);

  return data;
};

export { getTickets, createTicket, getSingleTicket, replyTicket };
