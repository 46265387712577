import instance from '../axios.config';
import { handleError } from '../../utils';

export const createContact = async (details) => {
  try {
    const { data } = await instance.post('/contact-us', details);
    return data.message;
  } catch (error) {
    handleError(error);
  }
};
