import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';

const Stats = () => {
  return (
    <>
      <WebView>
        <Box d="flex" w="50%" justifyContent="space-between">
          <Box>
            <Text
              textAlign="center"
              fontFamily="bold"
              fontSize="3.2rem"
              color="#444444"
              lineHeight="3.8rem"
            >
              60+
            </Text>
            <Text
              color="#AEAEAE"
              fontFamily="medium"
              fontSize="1.4rem"
              lineHeight="110%"
              mt="1.6rem"
            >
              Registered merchants
            </Text>
          </Box>
          <Box borderRight="1px solid #E8EEF4" />
          <Box>
            <Text
              fontFamily="bold"
              fontSize="3.2rem"
              color="#444444"
              lineHeight="3.8rem"
              textAlign="center"
            >
              500+
            </Text>
            <Text
              color="#AEAEAE"
              fontFamily="medium"
              fontSize="1.4rem"
              lineHeight="110%"
              mt="1.6rem"
            >
              Customers nationwide
            </Text>
          </Box>
          <Box borderRight="1px solid #E8EEF4" />
          <Box>
            <Text
              fontFamily="bold"
              fontSize="3.2rem"
              color="#444444"
              lineHeight="3.8rem"
              textAlign="center"
            >
              10m+
            </Text>
            <Text
              color="#AEAEAE"
              fontFamily="medium"
              fontSize="1.4rem"
              lineHeight="110%"
              mt="1.6rem"
            >
              Data subscription
            </Text>
          </Box>
          <Box borderRight="1px solid #E8EEF4" />
          <Box>
            <Text
              fontFamily="bold"
              fontSize="3.2rem"
              color="#444444"
              lineHeight="3.8rem"
              textAlign="center"
            >
              50m+
            </Text>
            <Text
              color="#AEAEAE"
              fontFamily="medium"
              fontSize="1.4rem"
              lineHeight="110%"
              mt="1.6rem"
            >
              Airtime sold
            </Text>
          </Box>
        </Box>
      </WebView>
      <MobileView>
        <Flex w="80%" justifyContent="space-between">
          <Box w="50%">
            <Text
              textAlign="center"
              fontFamily="bold"
              fontSize="1.6rem"
              color="#444444"
              lineHeight="1.9rem"
              letterSpacing="0.02em"
            >
              60+
            </Text>
            <Text
              color="#AEAEAE"
              fontFamily="medium"
              fontSize="1.4rem"
              lineHeight="110%"
              mt=".6rem"
              letterSpacing="-0.02em"
              textAlign="center"
            >
              Registered merchants
            </Text>
          </Box>
          <Box borderRight="1px solid #E8EEF4" />
          <Box w="50%">
            <Text
              textAlign="center"
              fontFamily="bold"
              fontSize="1.6rem"
              color="#444444"
              lineHeight="1.9rem"
              letterSpacing="0.02em"
            >
              500+
            </Text>
            <Text
              color="#AEAEAE"
              fontFamily="medium"
              fontSize="1.4rem"
              lineHeight="110%"
              mt=".6rem"
              letterSpacing="-0.02em"
              textAlign="center"
            >
              Customers nationwide
            </Text>
          </Box>
        </Flex>
        <Flex mt="3.8rem" w="80%" justifyContent="space-between">
          <Box w="50%">
            <Text
              textAlign="center"
              fontFamily="bold"
              fontSize="1.6rem"
              color="#444444"
              lineHeight="1.9rem"
              letterSpacing="0.02em"
            >
              10m+
            </Text>
            <Text
              color="#AEAEAE"
              fontFamily="medium"
              fontSize="1.4rem"
              lineHeight="110%"
              mt=".6rem"
              letterSpacing="-0.02em"
              textAlign="center"
            >
              Data subscription
            </Text>
          </Box>
          <Box borderRight="1px solid #E8EEF4" />
          <Box w="50%">
            <Text
              textAlign="center"
              fontFamily="bold"
              fontSize="1.6rem"
              color="#444444"
              lineHeight="1.9rem"
              letterSpacing="0.02em"
            >
              50m+
            </Text>
            <Text
              color="#AEAEAE"
              fontFamily="medium"
              fontSize="1.4rem"
              lineHeight="110%"
              mt=".6rem"
              letterSpacing="-0.02em"
              textAlign="center"
            >
              Airtime sold
            </Text>
          </Box>
        </Flex>
      </MobileView>
    </>
  );
};

export { Stats };

const WebView = styled(Flex)`
  padding-top: 7.8rem;
  padding-bottom: 10.9rem;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    display: none;
  }
`;

const MobileView = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 4.2rem;
  @media (min-width: 768px) {
    display: none;
  }
`;
