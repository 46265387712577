import instance from '../axios.config';

const getAllApplications = async (key, page = 1) => {
  const { data } = await instance.get(`/admin/merchant/pending?page=${page}`);

  return data;
};

const getMerchantDocuments = async (key, id) => {
  const { data } = await instance.get(`/admin/merchant/${id}/document`);

  return data;
};

const approveMerchant = async (id) => {
  const { data } = await instance.patch(`/admin/merchant/${id}/approve`);

  return data;
};

const addCommentToMerchantApplication = async (id, comment) => {
  const { data } = await instance.patch(`/admin/merchant/${id}/comment`, {
    comment,
  });

  return data;
};

const searchApplications = async (name) => {
  const { data } = await instance.get(`/admin/merchant/pending?q=%${name}%`);

  return data;
};

export {
  getAllApplications,
  getMerchantDocuments,
  approveMerchant,
  searchApplications,
  addCommentToMerchantApplication,
};
