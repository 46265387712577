import React from 'react';
import { Box, Text, Flex } from '@chakra-ui/react';
import { RightModal } from '../../../components/common/modals';
import dayjs from 'dayjs';

const Modal = ({ showRightModal, setShowRightModal, currentRequest }) => {
  return (
    <RightModal
      title=""
      showModal={showRightModal}
      setShowModal={setShowRightModal}
    >
      <Text
        color=" #939AA3"
        fontFamily="medium"
        css={{ lineHeight: '17px', letterSpacing: '0.02em' }}
      >
        Administrator
      </Text>
      <Text
        fontFamily="bold"
        fontSize="24px"
        mt="6px"
        css={{ lineHeight: '29px' }}
      >
        {currentRequest?.admin_first_name} {currentRequest?.admin_last_name}
      </Text>
      <Text
        color=" #939AA3"
        fontFamily="medium"
        css={{ lineHeight: '17px' }}
        mt="32px"
      >
        Email:
      </Text>
      <Text
        color=" #626363"
        fontFamily="bold"
        css={{ lineHeight: '18px' }}
        mt="6px"
        fontSize="15px"
        fontWeight="500!important"
      >
        {currentRequest?.admin_email}
      </Text>
      <Flex mt="20px">
        <Box>
          <Text
            color=" #939AA3"
            fontFamily="medium"
            css={{ lineHeight: '17px' }}
          >
            Date stamped
          </Text>
          <Text
            color=" #626363"
            fontFamily="bold"
            fontWeight="500!important"
            css={{ lineHeight: '21px' }}
            mt="6px"
            fontSize="15px"
          >
            {dayjs(currentRequest?.fund_request_updated_at).format(
              'MMM D, YYYY'
            )}
          </Text>
        </Box>
        <Box ml="4rem">
          <Text
            color=" #939AA3"
            fontFamily="medium"
            css={{ lineHeight: '17px' }}
          >
            Time
          </Text>
          <Text
            color=" #626363"
            fontFamily="bold"
            css={{ lineHeight: '21px' }}
            mt="6px"
            fontSize="15px"
          >
            {dayjs(currentRequest?.fund_request_updated_at).format('HH:mm A')}
          </Text>
        </Box>
      </Flex>
    </RightModal>
  );
};

export { Modal };
