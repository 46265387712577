import { handleError } from '../../utils';
import instance from '../axios.config';

const broadcastMessage = async (body) => {
  try {
    const { data } = await instance.post('/admin/notify', body);

    return data;
  } catch (error) {
    handleError(error);
  }
};

export { broadcastMessage };
