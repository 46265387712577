export const pickerMonths = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const pickerYears = (() => {
  let startYear = 2020;
  const currentYear = new Date().getFullYear();

  const years = [];

  while (startYear <= currentYear) {
    years.push(startYear);
    startYear++;
  }

  return years;
})();
