import React, { useState, createContext } from 'react';

const SidebarContext = createContext();

const SidebarContextProvider = (props) => {
  const { children } = props;
  const [isOpen, setIsOpen] = useState(true);

  return (
    <SidebarContext.Provider value={{ isOpen, setIsOpen }}>
      {children}
    </SidebarContext.Provider>
  );
};

export { SidebarContext, SidebarContextProvider };
