import React from 'react';

import { Box, Text } from '@chakra-ui/react';
import { CustomTable, TableWrapper } from '../../../components/common/tables';
import { nFormatter } from '../../../utils';
import dayjs from 'dayjs';

const Table = ({
  defaultData,
  currentPage,
  totalData,
  totalPages,
  myPage,
  setPage,
}) => {
  const defaultColumns = [
    {
      Header: 'Company name',
      accessor: 'col1',
      disableFilters: true,
    },
    {
      Header: 'Email',
      accessor: 'col2',
      disableFilters: true,
    },
    {
      Header: 'Amount',
      accessor: 'col3',
      Cell: ({ value }) => nFormatter(value),
      disableFilters: true,
    },
    {
      Header: 'Payment type',
      accessor: 'col4',
      disableFilters: true,
    },
    {
      Header: 'Request date',
      accessor: 'col5',
      Cell: ({ value }) => (
        <Text fontFamily="regular" fontWeight="bold!important">
          {dayjs(value).format('MMM D, YYYY')}
          <Text as="span" ml="10px" color="#808080" fontWeight="bold!important">
            {dayjs(value).format('HH:mm A')}
          </Text>
        </Text>
      ),
      disableFilters: true,
    },
    {
      Header: 'Approval date',
      accessor: 'col6',
      Cell: ({ value }) => (
        <Text fontFamily="regular" fontWeight="bold!important">
          {dayjs(value).format('MMM D, YYYY')}
          <Text as="span" ml="10px" color="#808080" fontWeight="bold!important">
            {dayjs(value).format('HH:mm A')}
          </Text>
        </Text>
      ),
      disableFilters: true,
    },
    {
      Header: 'Approved By',
      accessor: 'col7',
      disableSortBy: true,
      disableFilters: true,
    },
  ];
  return (
    <Box
      boxShadow=" 0px 0px 4px rgba(0, 0, 0, 0.15)"
      pt="1rem"
      pl="4.2rem"
      pr="4rem"
      mt="2.1rem"
      overflow="auto"
      minH="60vh"
    >
      <TableWrapper>
        <CustomTable
          data={defaultData}
          columns={defaultColumns}
          currentPage={currentPage}
          totalPages={totalPages}
          totalData={totalData}
          myPage={myPage}
          setPage={setPage}
        />
      </TableWrapper>
    </Box>
  );
};

export { Table };
