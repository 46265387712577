import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { PrivateRoute } from './components/auth';
import { Page404, Forbidden, Components } from './pages';

import theme from './theme';
import AdminDashboard from './pages/admin/AdminDashboard';
import MerchantDashboard from './pages/merchant/MerchantDashboard';
import { AuthContextProvider } from './context/auth';
import LandingPage from './pages/landing/LandingPage';
import { Contact } from './components/landingPage';

function App() {
  return (
    <AuthContextProvider>
      <ChakraProvider theme={theme}>
        <ToastContainer />
        <Router>
          <Switch>
            <Route exact path="/" component={LandingPage} />
            <Route exact path="/contact-us" component={Contact} />
            <Route path="/admin" component={AdminDashboard} />
            <Route path="/merchant" component={MerchantDashboard} />
            <PrivateRoute path="/components" component={Components} exact />
            <Route path="/forbidden" component={Forbidden} />
            <Route path="/404">
              <Page404 />
            </Route>
          </Switch>
        </Router>
      </ChakraProvider>
    </AuthContextProvider>
  );
}

export default App;
