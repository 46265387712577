import React, { useState } from 'react';
import { Box, Flex, HStack, Text } from '@chakra-ui/react';
import { SolidBtn, OutlineBtn } from '../components/common/buttons';
import {
  DropdownMenu,
  Dropdown,
  DropdownTrigger,
} from '../components/common/dropdowns';
import { CustomInput } from '../components/common/form';
import { MailIcon } from '../components/login';
import { CustomTable, TableWrapper } from '../components/common/tables';
import { CenterModal, RightModal } from '../components/common/modals';
import styled from '@emotion/styled';

function Components() {
  const [showCenterModal, setShowCenterModal] = useState(false);
  const [showRightModal, setShowRightModal] = useState(false);

  const dropDownData = [
    {
      key: 'main',
      items: [
        {
          content: 'Single Item',
          hasSubmenu: false,
          linkTo: '',
        },
        {
          content: 'Settings',
          hasSubmenu: true,
          linkTo: 'settings',
        },
        {
          content: 'Animals',
          hasSubmenu: false,
          linkTo: '',
        },
      ],
    },
    {
      key: 'settings',
      items: [
        {
          content: 'Go back',
          hasSubmenu: false,
          linkTo: 'main',
        },
        {
          content: 'Profile',
          hasSubmenu: false,
          linkTo: '',
        },
        {
          content: 'Bamk',
          hasSubmenu: false,
          linkTo: '',
        },
        {
          content: 'Animals',
          hasSubmenu: false,
          linkTo: '',
        },
      ],
    },
  ];

  return (
    <>
      <Box w="100%" maxW="1140px" margin="1rem auto">
        <Text fontFamily="medium" mb="2rem" fontSize="2rem">
          Buttons
        </Text>

        <Flex>
          <SolidBtn w="17rem" mr="2rem">
            Solid Button
          </SolidBtn>
          <OutlineBtn w="17rem">Outline Btn</OutlineBtn>
        </Flex>
      </Box>
      <Box mt="7rem" w="100%" maxW="1140px" mx="auto">
        <Text fontFamily="medium" mb="2rem" fontSize="2rem">
          Dropdown
        </Text>

        <Flex>
          <Dropdown>
            <DropdownTrigger>
              <DropdownMenu dropDownData={dropDownData} />
            </DropdownTrigger>
          </Dropdown>
        </Flex>
      </Box>
      <Box mt="7rem" w="100%" maxW="1140px" mx="auto">
        <Text fontFamily="medium" mb="2rem" fontSize="2rem">
          Input
        </Text>

        <Flex>
          <CustomInput
            label="Full Name"
            id="name"
            style={{ marginTop: '1rem' }}
          >
            <MailIcon />
          </CustomInput>
        </Flex>
      </Box>
      <Box mt="7rem" w="100%" maxW="1140px" mx="auto">
        <Text fontFamily="medium" mb="2rem" fontSize="2rem">
          Tables
        </Text>

        <Flex
          w="100%"
          bg="#fff"
          boxShadow="0px 0px 4px rgba(0, 0, 0, 0.15);"
          p="2rem"
        >
          <MyTableWrapper>
            <CustomTable />
          </MyTableWrapper>
        </Flex>
      </Box>
      <Box mt="7rem" w="100%" maxW="1140px" margin="1rem auto">
        <Text fontFamily="medium" mb="2rem" fontSize="2rem">
          Modals
        </Text>

        <Flex
          w="100%"
          bg="#fff"
          boxShadow="0px 0px 4px rgba(0, 0, 0, 0.15);"
          p="2rem"
        >
          <HStack spacing={10}>
            <OutlineBtn onClick={() => setShowCenterModal(true)}>
              Open Center Modal
            </OutlineBtn>
            <OutlineBtn onClick={() => setShowRightModal(true)}>
              Open Right Modal
            </OutlineBtn>
          </HStack>
          <CenterModal
            showModal={showCenterModal}
            setShowModal={setShowCenterModal}
            title="Demo Modal"
          >
            <Text fontFamily="medium">Put whatever you want here</Text>
          </CenterModal>
          <RightModal
            title="Demo Modal"
            showModal={showRightModal}
            setShowModal={setShowRightModal}
          >
            <Text fontFamily="medium">Put whatever you want here</Text>
          </RightModal>
        </Flex>
      </Box>
    </>
  );
}

const MyTableWrapper = styled(TableWrapper)`
  thead {
    background-color: blue;
  }

  th {
    color: #fff !important;
  }
`;

export { Components };
