/* eslint-disable max-len */
import React, { useState, useEffect, useRef } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { CSSTransition } from 'react-transition-group';

import { ReactComponent as ChevronIcon } from '../../../assets/icons/chevron.svg';

function DropdownMenu({ dropDownData }) {
  const [activeMenu, setActiveMenu] = useState('main');
  const [menuHeight, setMenuHeight] = useState(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    setMenuHeight(dropdownRef.current?.firstChild.offsetHeight);
  }, []);

  function calcHeight(el) {
    const height = el.offsetHeight;
    setMenuHeight(height);
  }

  function handleClick(e, props) {
    e.stopPropagation();
    props.goToMenu && setActiveMenu(props.goToMenu);
  }

  function DropdownItem(props) {
    return (
      <Box
        as={Flex}
        h="50px"
        color="#808080"
        fontFamily="medium"
        fontSize="1.6rem"
        alignItems="center"
        borderRadius="8px"
        transition="background 500ms"
        padding="0.5rem"
        cursor="pointer"
        _hover={{
          color: '#0041be',
          background: 'rgba(0, 65, 190, 0.2)',
        }}
        {...props}
        onClick={(e) => handleClick(e, props)}
      >
        {props.leftIcon && (
          <Box w="2rem" mr="1rem">
            {props.leftIcon}
          </Box>
        )}
        {props.children}
        <Text as="span" ml="auto" cursor="pointer">
          {props.rightIcon}
        </Text>
      </Box>
    );
  }

  return (
    <Box
      boxShadow="0px 0px 4px rgba(0, 0, 0, 0.15);"
      bg="#fff"
      color="#000"
      position="absolute"
      zIndex="15"
      top="58px"
      left="0"
      w="300px"
      p="1rem"
      overflow="hidden"
      transition="height 500ms"
      h={`calc(${menuHeight}px + 3rem)`}
      ref={dropdownRef}
    >
      {dropDownData.map((dropDownItem, i) => (
        <CSSTransition
          key={dropDownItem.key}
          in={activeMenu === dropDownItem.key}
          timeout={500}
          classNames={i === 0 ? 'menu-primary' : 'menu-secondary'}
          unmountOnExit
          onEnter={calcHeight}
        >
          <Box w="100%">
            {dropDownItem.items.map((item, i) => (
              <DropdownItem
                key={i}
                rightIcon={item.hasSubmenu && <ChevronIcon />}
                goToMenu={item.linkTo}
              >
                {item.content}
              </DropdownItem>
            ))}
          </Box>
        </CSSTransition>
      ))}
    </Box>
  );
}

export { DropdownMenu };
