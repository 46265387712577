import React from 'react';
import {
  useTable,
  useSortBy,
  usePagination,
  useFilters,
  useGlobalFilter,
} from 'react-table';
import styled from '@emotion/styled';
import {
  Box,
  HStack,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { Pagination } from '../pagination';
import { ReactComponent as SortIcon } from '../../../assets/icons/sort.svg';

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <Input
      color="#000"
      focusBorderColor="rgba(0, 0, 0, .3)"
      maxW="170px"
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  );
}

// This is a custom filter UI for selecting
// a unique option from a list
export function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    // <Select
    //   maxW="100px"
    //   value={filterValue}
    //   onChange={(e) => {
    //     setFilter(e.target.value || undefined);
    //   }}
    // >
    //   <option value="">All</option>
    //   {options.map((option, i) => (
    //     <option key={i} value={option}>
    //       {option}
    //     </option>
    //   ))}

    // </Select>
    <Menu>
      <MenuButton as={SortIcon}>{filterValue}</MenuButton>
      <MenuList>
        <MenuItem color="#000" onClick={() => setFilter('')}>
          All
        </MenuItem>
        {options.map((option, i) => (
          <MenuItem
            textTransform="capitalize"
            color="#000"
            key={i}
            onClick={() => setFilter(option)}
          >
            {option}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
}

export function NumberRangeColumnFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <div
      style={{
        display: 'flex',
      }}
    >
      <input
        value={filterValue[0] || ''}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            val ? parseInt(val, 10) : undefined,
            // old[1],
          ]);
        }}
        placeholder={`Min (${min})`}
        style={{
          width: '70px',
          marginRight: '0.5rem',
        }}
      />
      to
      <input
        value={filterValue[1] || ''}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            old[0],
            val ? parseInt(val, 10) : undefined,
          ]);
        }}
        placeholder={`Max (${max})`}
        style={{
          width: '70px',
          marginLeft: '0.5rem',
        }}
      />
    </div>
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

function CustomTable(props) {
  const data = React.useMemo(() => props.data || []);

  const columns = React.useMemo(() => props.columns || []);

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const { currentPage, totalPages, totalData, myPage, setPage } = props;

  const tableInstance = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      initialState: { pageIndex: 0 },
      manualPagination: true,
      pageCount: totalPages,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    gotoPage,
    pageCount,
    rows,
    prepareRow,
    pageOptions,
    state,
  } = tableInstance;

  const { pageIndex } = state;

  return (
    <>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, i) => (
            <tr key={i} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, i) => (
                <th
                  key={i}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  <HStack>
                    <Box>
                      {column.render('Header')}
                      {!column.disableSortBy && (
                        <SortIcon
                          style={{
                            marginLeft: '.5rem',
                            display: 'inline-block',
                          }}
                        />
                      )}
                    </Box>
                    <Box mt=".5rem">
                      {column.canFilter ? column.render('Filter') : null}
                    </Box>
                  </HStack>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr key={i} {...row.getRowProps()}>
                {row.cells.map((cell, i) => {
                  return (
                    <td key={i} {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>

      {totalPages > 1 && (
        <Pagination
          previousPage={previousPage}
          nextPage={nextPage}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
          currentPage={currentPage}
          totalPages={totalPages}
          gotoPage={gotoPage}
          pageCount={pageCount}
          page={page}
          totalData={totalData}
          myPage={myPage}
          setPage={setPage}
        />
      )}
    </>
  );
}

const TableWrapper = styled(Box)`
  width: 100%;
  max-width: 100%;
  overflow-x: scroll;

  table {
    background: transparent;
    overflow-x: scroll;
    border: none;
    width: 100%;
    max-width: 100%;
    font-family: 'TT Norms Regular', sans-serif;
  }

  th {
    color: #808080;
    text-align: left;
    padding: 0 4rem;
    font-weight: bold !important;
    white-space: nowrap;
  }

  tr {
    border-bottom: 1px solid #e4e4e4;
    /* width: 100%; */
    height: 64px;
    /* display: flex;
   align-items: center;
   justify-content: space-between; */
    text-align: center;
    padding: 2.4rem 0;

    &:hover {
      .options {
        opacity: 1;
      }
    }

    /* &:last-of-type {
     border-bottom: none;
   } */

    .options {
      opacity: 0;
      transition: opacity 0.5s;
    }
  }

  td {
    text-align: left;
    padding: 0 4rem;
    color: #444444;
    font-weight: bold !important;
    white-space: nowrap;
    text-transform: capitalize;
  }

  thead {
    border-bottom: 1px solid #e4e4e4;
  }

  tbody {
    tr {
      &:hover {
        background: rgba(53, 88, 199, 0.1);
      }
    }
  }
`;

export { CustomTable, TableWrapper };
