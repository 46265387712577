import instance from '../axios.config';

const updateFundRequest = async (body, id) => {
  const { data } = await instance.put(`/admin/fund-request/${id}`, body);

  return data;
};

const getAllFundRequest = async (key, page = 1) => {
  const { data } = await instance.get(`/admin/fund-request?page=${page}`);

  return data;
};

const searchFundRequest = async (name) => {
  const { data } = await instance.get(`/admin/fund-request?q=%${name}%`);

  return data;
};

export { updateFundRequest, getAllFundRequest, searchFundRequest };
