import { handleError } from '../../utils';
import instance from '../axios.config';

const getSalesRevenueStats = async (key, statsPeriod) => {
  try {
    let url = `/admin/revenue/statistics`;

    if (statsPeriod !== 'all') url += `?period=${statsPeriod}`;

    const { data } = await instance.get(url);

    return data.data;
  } catch (error) {
    handleError(error);
  }
};

const getTotalSalesRevenue = async (key, date, period) => {
  try {
    const { data } = await instance.get(
      `/admin/revenue/sales?period=${period}&year=${date.year}-01-01&month=${date.year}-${date.month}-01`
    );

    return data.data;
  } catch (error) {
    handleError(error);
  }
};

const getTopBuyingMerchant = async (key, date, period) => {
  try {
    const { data } = await instance.get(
      `/admin/revenue/merchant-growth?period=${period}&year=${date.year}-01-01&month=${date.year}-${date.month}-01`
    );

    return data.data;
  } catch (error) {
    handleError(error);
  }
};

const getProductSales = async (key, date, period) => {
  try {
    const { data } = await instance.get(
      `/admin/revenue/product-growth?period=${period}&year=${date.year}-01-01&month=${date.year}-${date.month}-01`
    );

    return data.data;
  } catch (error) {
    handleError(error);
  }
};

export {
  getSalesRevenueStats,
  getTotalSalesRevenue,
  getTopBuyingMerchant,
  getProductSales,
};
