import axios from 'axios';
import { handleError } from '../../utils';
import instance from '../axios.config';

const getAllTickets = async () => {
  try {
    const [openTickets, closedTickets] = await axios.all([
      instance.get('/admin/ticket/?status=open'),
      instance.get('/admin/ticket?status=closed'),
    ]);

    return {
      openTickets: openTickets.data.data,
      closedTickets: closedTickets.data.data,
    };
  } catch (error) {
    handleError(error);
  }
};

const closeTicket = async (id) => {
  const { data } = await instance.patch(`/admin/ticket/${id}/close`);

  return data;
};

export { getAllTickets, closeTicket };
