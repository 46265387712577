import React, { useState } from 'react';
import {
  Box,
  Text,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';

import { ReactComponent as SeachIcon } from '../../assets/icons/search.svg';
import { ReactComponent as OptionsIcon } from '../../assets/icons/options.svg';

import { CustomInput } from '../../components/common/form';
import { CustomTable, TableWrapper } from '../../components/common/tables';
import { OutlineBtn, SolidBtn } from '../../components/common/buttons';
import { CenterModal } from '../../components/common/modals';
import {
  getSingleMerchantDiscount,
  editSingleMerchantDiscount,
  deleteSingleMerchantDiscount,
} from '../../queries';
import { capitalize } from '../../utils';

import { SpinningText } from '../../components/common/loaders';
import { ErrorOccured } from '../../components/common/errors';
import styled from '@emotion/styled';

const tableCols = [
  {
    Header: 'Service Type',
    accessor: 'col1',
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: 'Network',
    accessor: 'col2',
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: 'Discount Rate (%)',
    accessor: 'col3',
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: 'Date Created',
    accessor: 'col4',
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: 'Custom Discount',
    accessor: 'col5',
    disableSortBy: true,
    disableFilters: true,
  },
  {
    Header: '',
    accessor: 'col6',
    disableSortBy: true,
    disableFilters: true,
  },
];

function SingleDiscountRate() {
  const { id } = useParams();
  const [page, setPage] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [currentDiscount, setCurrentDiscount] = useState(null);
  const [showDelModal, setShowDelModal] = useState(false);
  const { isLoading, isError, data, refetch } = useQuery(
    ['getSingleMerchantDiscount', id, page],
    getSingleMerchantDiscount
  );

  if (isLoading) {
    return <SpinningText />;
  }

  if (isError) {
    return <ErrorOccured />;
  }

  const totalPages = data?.totalPages;
  const totalData = data?.total;
  const currentPage = data?.currentPage;

  const handleClose = (setValue) => {
    setValue('');
    setIsOpen(false);
  };

  const handleEdit = (e) => {
    setIsOpen(true);
    setCurrentDiscount(e);
  };

  const handleDelete = (e) => {
    setShowDelModal(true);
    setCurrentDiscount(e);
  };

  const tableData = data?.discount?.map((e) => {
    return {
      col1: e.product_type,
      col2: e.product_name,
      col3: e.flat_discount,
      col4: dayjs(e.created_at).format('MMM D, YYYY'),
      col5: e.custom_discount,
      col6: (
        <Menu>
          <MenuButton
            cursor="pointer"
            className="options"
            as={OptionsIcon}
          ></MenuButton>
          <MenuList p="2rem 1rem">
            <MenuItem
              onClick={() => handleEdit(e)}
              color="#808080"
              p="1rem 2rem"
              borderRadius="8px"
            >
              Edit
            </MenuItem>
            {e.discount_id && (
              <MenuItem
                onClick={() => handleDelete(e)}
                color="#808080"
                p="1rem 2rem"
                borderRadius="8px"
              >
                Delete
              </MenuItem>
            )}
          </MenuList>
        </Menu>
      ),
    };
  });

  return (
    <>
      <Box w="100%" px="4rem">
        <Text color="#212D43" fontFamily="bold" fontSize="3.2rem">
          Discount rate
        </Text>
        <Box mt="5rem">
          <CustomInput label="Search" id="id" w="32rem">
            <SeachIcon />
          </CustomInput>
        </Box>
        <Box mt="3rem" w="100%" mx="auto">
          <Flex
            w="100%"
            bg="#fff"
            boxShadow="0px 0px 4px rgba(0, 0, 0, 0.15);"
            p="2rem"
            minH="60vh"
          >
            <TableWrapper>
              <CustomTable
                columns={tableCols}
                data={tableData}
                currentPage={currentPage}
                totalPages={totalPages}
                totalData={totalData}
                myPage={page}
                setPage={setPage}
              />
            </TableWrapper>
          </Flex>
        </Box>
      </Box>
      <EditDiscountModal
        isOpen={isOpen}
        handleClose={handleClose}
        currentDiscount={currentDiscount}
        id={id}
        refetch={refetch}
      />
      <DelDiscountModal
        showDelModal={showDelModal}
        setShowDelModal={setShowDelModal}
        id={currentDiscount?.discount_id}
        refetch={refetch}
      />
    </>
  );
}

function EditDiscountModal({
  isOpen,
  handleClose,
  currentDiscount,
  id,
  refetch,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [discount, setDiscount] = useState('');

  const handleChange = (e) => {
    const { value } = e.target;
    setDiscount(value);
  };

  const handleSubmit = async () => {
    const body = {
      status: currentDiscount?.custom_discount,
      id,
      data: {
        productId: +currentDiscount.id,
        flatDiscount: +discount,
      },
      discountId: currentDiscount.discount_id,
    };
    try {
      setIsLoading(true);
      const res = await editSingleMerchantDiscount(body);
      setIsLoading(false);
      toast.success(res.message);
      setDiscount('');
      handleClose(setDiscount);
      refetch();
    } catch (err) {
      setIsLoading(false);
      toast.error(err.response.data.message);
    }
  };

  return (
    <CenterModal
      showModal={isOpen}
      setShowModal={() => {
        handleClose(setDiscount);
      }}
      title="Edit discount"
    >
      <CustomInput
        style={{ marginTop: '1.6rem' }}
        hideIcon
        label={capitalize(currentDiscount?.product_type)}
        isDisabled
      />
      <CustomInput
        style={{ marginTop: '1.6rem' }}
        hideIcon
        label={capitalize(currentDiscount?.product_name)}
        isDisabled
      />
      <CustomInput
        style={{ marginTop: '1.6rem' }}
        type="number"
        hideIcon
        label="Discount rate (%)"
        value={currentDiscount?.flat_discount || discount}
        name="discount"
        onChange={handleChange}
      />
      <SolidBtn
        mt="3.2rem"
        onClick={handleSubmit}
        isLoading={isLoading}
        isDisabled={discount === ''}
      >
        Save
      </SolidBtn>
    </CenterModal>
  );
}

function DelDiscountModal({ showDelModal, setShowDelModal, id, refetch }) {
  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = async () => {
    try {
      setIsLoading(true);
      const res = await deleteSingleMerchantDiscount(id);
      setIsLoading(false);
      toast.success(res.message);
      setShowDelModal(false);
      refetch();
    } catch (err) {
      setIsLoading(false);
      toast.error(err.response.data.message);
    }
  };
  return (
    <StyledWrapper>
      <CenterModal
        title="This action is irreversible are you sure you want to proceed?"
        align="center"
        showModal={showDelModal}
        setShowModal={setShowDelModal}
      >
        <Box d="flex" justifyContent="space-between" alignItems="center">
          <OutlineBtn
            borderColor="#939AA3"
            color="#939AA3"
            w="15rem"
            onClick={() => setShowDelModal(false)}
            _hover={{ background: '#939AA3', color: '#fff' }}
          >
            No
          </OutlineBtn>
          <SolidBtn onClick={handleDelete} isLoading={isLoading} w="15rem">
            Yes
          </SolidBtn>
        </Box>
      </CenterModal>
    </StyledWrapper>
  );
}

export { SingleDiscountRate };

const StyledWrapper = styled(Box)`
  .closeBtn {
    display: none;
  }
`;
