import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import { SolidBtn } from '../../common/buttons';
import { CustomInput } from '../../common/form';
import { CenterModal } from '../../common/modals';

import { editFundRequest } from '../../../queries';

function EditFundRequestModal({
  showEditModal,
  setShowEditModal,
  requestToEdit,
  refetch,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [amount, setAmount] = useState('');

  useEffect(() => {
    setAmount('');
  }, [showEditModal]);

  const handleFundRequestEdit = async () => {
    try {
      setIsLoading(true);
      const res = await editFundRequest(requestToEdit, amount);
      setIsLoading(false);
      toast.success(res.message);
      refetch();
      setAmount('');
      setShowEditModal(false);
    } catch (err) {
      setIsLoading(false);
      toast.error(err?.response?.data?.message || 'Something went wrong');
    }
  };

  const handleChange = (e) => setAmount(e.target.value);

  return (
    <CenterModal
      showModal={showEditModal}
      setShowModal={setShowEditModal}
      title="Edit Topup Request"
    >
      <CustomInput
        type="number"
        hideIcon
        label="Amount"
        id="amount"
        name="amount"
        value={amount}
        onChange={handleChange}
        style={{ margin: '1.6rem 0 3.2rem' }}
      />
      <SolidBtn
        onClick={handleFundRequestEdit}
        isDisabled={amount === ''}
        isLoading={isLoading}
      >
        Send request
      </SolidBtn>
    </CenterModal>
  );
}

export { EditFundRequestModal };
