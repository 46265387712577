import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Flex, Text } from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { CustomInput, InputError } from '../common/form/Input';
import { SolidBtn } from '../common/buttons';
import { createContact } from '../../queries';

const Help = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { register, handleSubmit, errors, reset } = useForm();
  const onSubmit = async (inputData) => {
    try {
      setIsLoading(true);
      const message = await createContact(inputData);
      toast.success(message);
      reset();
      setIsLoading(false);
    } catch (error) {
      toast.error('Something went wrong');
      setIsLoading(false);
    }
  };
  return (
    <Box
      py="12rem"
      w="100%"
      maxW="1140px"
      mx="auto"
      justifyContent="center"
      overflow="hidden"
      px={{ base: '2rem', lg: '1rem' }}
    >
      <Box w={{ base: '100%', lg: '50%' }}>
        <Text
          fontSize={{ base: '1.8rem', md: '3.2rem' }}
          fontFamily="bold"
          color="#626363;"
          fontWeight="bold"
        >
          Are you a merchant in need of help?<br></br>
          That is what the Awake team is here for.
        </Text>
        <Text fontFamily="regular" fontSize={{ base: '1.4rem', md: '1.6rem' }}>
          Send us an email in the empty fields provided below.
        </Text>
      </Box>

      <Flex
        w="100%"
        direction={{ base: 'column', lg: 'row' }}
        as="form"
        onSubmit={handleSubmit(onSubmit)}
        justify="space-between"
        mt="5.5rem"
      >
        <Box>
          <CustomInput
            w={{ base: '100%', lg: '307px' }}
            label="Email"
            type="email"
            name="email"
            id="email"
            hideIcon
            hideRightIcon
            bgColor="transparent"
            border="none"
            borderBottom="1px solid #E4E4E4"
            mt="0"
            inputRef={register({
              required: 'Email is required',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'Invalid email address',
              },
            })}
          />
          {errors.email && (
            <InputError
              color="rgba(255, 255, 255, 0.8)"
              text={errors.email.message}
            />
          )}
        </Box>
        <Box>
          <CustomInput
            w={{ base: '100%', lg: '500px' }}
            label="Message"
            name="message"
            id="message"
            hideIcon
            hideRightIcon
            bgColor="transparent"
            border="none"
            borderBottom="1px solid #E4E4E4"
            mt="0"
            inputRef={register({
              required: 'Message is required',
            })}
          />
          {errors.email && (
            <InputError
              color="rgba(255, 255, 255, 0.8)"
              text={errors.email.message}
            />
          )}
        </Box>
        <SolidBtn
          type="submit"
          h="4.2rem"
          w="12rem"
          mt={{ base: '2rem', lg: '1rem' }}
          border="1px solid #939AA3"
          color="#444444"
          bgColor="#FFF"
          isLoading={isLoading}
          _hover={{ background: '#939AA3', color: '#fff' }}
        >
          Send
        </SolidBtn>
      </Flex>
    </Box>
  );
};

export { Help };
