import React, { useEffect, useState, createRef } from 'react';
import { Flex, Input, Text } from '@chakra-ui/react';
import { toast } from 'react-toastify';

import { createFundRequest } from '../../../queries';

import { CustomInput } from '../../../components/common/form';
import { CenterModal } from '../../../components/common/modals';
import { SolidBtn } from '../../../components/common/buttons';

import { ReactComponent as UploadIcon } from '../../../assets/icons/upload.svg';
function FundWalletModal({ setShowCenterModal, showCenterModal }) {
  const [file, setFile] = useState('');
  const [fileError, setFileError] = useState(null);
  const [amount, setAmount] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setFile('');
    setAmount('');
    setFileError(null);
  }, [showCenterModal]);

  const fileUpload = createRef();

  const handleFile = (e) => {
    e.preventDefault();
    const text = document.getElementById('labelText');
    text.textContent = fileUpload.current.files[0].name;
    const fileSize = Math.round(fileUpload.current.files[0].size / 1024);
    if (fileSize < 3000) {
      setFile(fileUpload.current.files[0]);
      setFileError(null);
    } else {
      setFileError('Please upload a file lesser than 3mb.');
    }
  };

  const handleFundRequest = async () => {
    if (amount < 1) {
      toast.error('Cannot submit a negetive amount');
      return;
    }

    const formData = new FormData();
    formData.append('amount', amount);
    formData.append('file', file);
    try {
      setIsLoading(true);
      const res = await createFundRequest(formData);
      setIsLoading(false);
      toast.success(res.message);
      setFile('');
      setAmount('');
      setShowCenterModal(false);
    } catch (err) {
      setIsLoading(false);
      toast.error(err?.response?.data?.message || 'Something went wrong');
    }
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setAmount(value);
  };

  return (
    <CenterModal
      showModal={showCenterModal}
      setShowModal={setShowCenterModal}
      title="Fund wallet"
    >
      <Flex
        align="center"
        h="5.5rem"
        bgColor="rgba(53, 88, 199, 0.05)"
        border="2px dashed rgba(53, 88, 199, 0.3)"
        borderRadius="8px"
        pl="3rem"
      >
        <label htmlFor="hiddenInput">
          <Flex cursor="pointer">
            <UploadIcon />
            <Text
              ml="1.5rem"
              color="#979797"
              lineHeight="130%"
              fontFamily="regular"
              id="labelText"
            >
              Upload payment receipt
            </Text>
            <Input
              type="file"
              id="hiddenInput"
              d="none"
              name="file"
              ref={fileUpload}
              onChange={handleFile}
              accept="image/*, .jpeg, .jpg, .png, .pdf"
            />
          </Flex>
        </label>
      </Flex>
      {fileError && (
        <Text fontFamily="regular" fontSize="1.2rem" color="#E24D4D" mt="1rem">
          {fileError}
        </Text>
      )}
      <CustomInput
        type="number"
        hideIcon
        label="Amount"
        id="amount"
        name="amount"
        value={amount}
        onChange={handleChange}
        style={{ margin: '1.6rem 0 3.2rem' }}
      />
      <SolidBtn
        onClick={handleFundRequest}
        isDisabled={amount === '' || file === ''}
        isLoading={isLoading}
      >
        Send request
      </SolidBtn>
    </CenterModal>
  );
}

export { FundWalletModal };
