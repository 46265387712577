import React from 'react';
import { Button } from '@chakra-ui/react';

function OutlineBtn(props) {
  return (
    <Button
      variant="outline"
      w="100%"
      py="2.3rem"
      backgroundColor="#fff"
      borderColor="brand.blue"
      color="brand.blue"
      borderRadius="4px"
      fontSize="1.4rem"
      fontFamily="medium"
      _hover={{
        opacity: 0.8,
      }}
      _focus={{
        borderColor: 'transparent',
      }}
      _disabled={{
        backgroundColor: '#939AA3',
      }}
      {...props}
    >
      {props.children}
    </Button>
  );
}

export { OutlineBtn };
