import React, { useState, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { Bar, defaults } from 'react-chartjs-2';
import { Box, Text, Flex } from '@chakra-ui/react';
import { useQuery } from 'react-query';
import Picker from 'react-month-picker';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';

import { ReactComponent as FallbackIcon } from '../../../assets/icons/fallback.svg';
import { DatePickerTrigger } from '../../common/form';
import { pickerMonths, pickerYears } from '../../../utils';
import { SolidBtn } from '../../common/buttons';
import { getCustomerGrowth } from '../../../queries';
import { ChartSkeletonLoader } from '../../common/loaders';

dayjs.extend(localizedFormat);
const CustomerGrowth = () => {
  defaults.global.legend = false;

  const pickAMonth = useRef();

  const [chartStepSIze, setChartStepSIze] = useState(0);
  const [chartData, setChartData] = useState([]);
  const [period, setPeriod] = useState('month');
  const [startDate, setStartDate] = useState({
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
  });

  const { isLoading, isError, data } = useQuery(
    ['getCustomerGrowth', startDate, period],
    getCustomerGrowth
  );

  useEffect(() => {
    const count = data?.map((item) => item.count);

    const maxAmt = Math.max(count);

    setChartStepSIze(maxAmt / 5);

    setChartData({
      labels:
        period === 'month'
          ? [
              'Jan',
              'Feb',
              'Mar',
              'Apr',
              'May',
              'Jun',
              'Jul',
              'Aug',
              'Sep',
              'Oct',
              'Nov',
              'Dec',
            ]
          : period === 'week'
          ? data?.map((item) => `${dayjs(item.date_trunc).format('MMM-DD')}th`)
          : data?.map((item) => `${dayjs(item.date_trunc).format('MMM-DD')}th`),
      datasets: [
        {
          barThickness: 8,
          backgroundColor: '#0041BE',
          minBarLength: 2,
          barValueSpacing: 20,
          data: count,
        },
      ],
    });
  }, [data]);

  if (isLoading || isError) {
    return <ChartSkeletonLoader title="Customer growth" />;
  }
  return (
    <>
      <Box
        pl="4rem"
        pt="3rem"
        pr="4rem"
        w="50%"
        h="54.3rem"
        boxShadow="0px 0px 8px rgba(0, 0, 0, 0.1)"
        maxW="52%"
      >
        <Box d="flex" alignItems="center" justifyContent="space-between">
          <Text
            color="#212D43"
            fontFamily="medium"
            fontSize="2rem"
            lineHeight="2.4rem"
          >
            Customer growth
          </Text>
          <Box>
            <Picker
              ref={pickAMonth}
              years={pickerYears}
              value={startDate}
              lang={pickerMonths}
              onChange={(year, month) => setStartDate({ year, month })}
            >
              <DatePickerTrigger
                value={{
                  month: pickerMonths[startDate.month - 1],
                  year: startDate.year,
                }}
                onClick={() => pickAMonth.current.show()}
              />
            </Picker>
          </Box>
        </Box>
        <Box mt="1.6rem" d="flex" w="100%" justifyContent="flex-end">
          <Flex w="18.7rem" borderRadius="4px" bg="#F8F9FB">
            <SolidBtn
              h="100%"
              backgroundColor={period === 'month' ? '#2EAE65' : 'transparent'}
              color={period === 'month' ? '#fff' : '#939AA3'}
              fontSize="1.4rem"
              fontFamily="medium"
              py="1rem"
              onClick={() => setPeriod('month')}
            >
              Month
            </SolidBtn>
            <SolidBtn
              h="100%"
              backgroundColor={period === 'week' ? '#2EAE65' : 'transparent'}
              color={period === 'week' ? '#fff' : '#939AA3'}
              fontSize="1.4rem"
              fontFamily="medium"
              py="1rem"
              onClick={() => setPeriod('week')}
            >
              Week
            </SolidBtn>
            <SolidBtn
              h="100%"
              backgroundColor={period === 'day' ? '#2EAE65' : 'transparent'}
              color={period === 'day' ? '#fff' : '#939AA3'}
              fontSize="1.4rem"
              fontFamily="medium"
              py="1rem"
              onClick={() => setPeriod('day')}
            >
              Day
            </SolidBtn>
          </Flex>
        </Box>
        {chartData.length < 1 ? (
          <Box>
            <Flex align="center" justify="center" mt="4rem">
              <FallbackIcon />
            </Flex>
            <Text fontSize="1.6rem" textAlign="center" fontFamily="medium">
              No Data for customer growth yet
            </Text>
          </Box>
        ) : (
          <Box>
            <Box
              d="flex"
              h="20rem"
              maxW="95%"
              w="95%"
              mt="7.4rem"
              pos="relative"
              left="-20px"
            >
              <Box>
                <VerticalText fontFamily="regular">Customer</VerticalText>
              </Box>
              <Bar
                data={chartData}
                options={{
                  maintainAspectRatio: false,
                  responsive: true,
                  scales: {
                    xAxes: [
                      {
                        gridLines: {
                          display: false,
                        },
                      },
                    ],
                    yAxes: [
                      {
                        ticks: {
                          beginAtZero: true,
                          stepSize: chartStepSIze,
                        },
                      },
                    ],
                  },
                }}
              />
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

const VerticalText = styled(Text)`
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg);
  color: #e4e4e4;
  font-size: 1.4rem;
  line-height: 1.7rem;
  margin-top: 6rem;
`;

export { CustomerGrowth };
