import { Box, Flex, HStack, Text } from '@chakra-ui/react';
import React, { useState, useRef } from 'react';
import { Bar, defaults } from 'react-chartjs-2';
import { useQuery } from 'react-query';
import dayjs from 'dayjs';
import Picker from 'react-month-picker';

import 'react-month-picker/css/month-picker.css';
import { DatePickerTrigger } from '../../common/form';

import { nFormatter, pickerMonths, pickerYears } from '../../../utils';
import { getMerchantSalesOverviewData } from '../../../queries';
import { ChartSkeletonLoader } from '../../common/loaders';
import { SolidBtn } from '../../common/buttons';
import { ReactComponent as FallbackIcon } from '../../../assets/icons/fallback.svg';

function extractProductMonthData(name, salesChartData) {
  return salesChartData.map((item) => {
    if (item.length > 0) {
      const product = item.find((prd) => prd.product_name === name);

      if (product) return product.amount;

      return 0;
    } else {
      return 0;
    }
  });
}

function extractProductWeekData(name, salesChartData) {
  return salesChartData.map((item) => {
    if (item.week?.length > 0) {
      const product = item.week.find((prd) => prd.product_name === name);

      if (product) {
        return product.amount;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  });
}

function SalesRevenue() {
  const [productType, setProductType] = useState('airtime');
  const [period, setPeriod] = useState('month');
  const [date, setDate] = useState({
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
  });
  const pickAMonth = useRef();

  const { isError, isLoading, data: salesChartData } = useQuery(
    ['merchantSalesOverview', productType, period, date],
    getMerchantSalesOverviewData
  );

  if (isLoading) {
    return <ChartSkeletonLoader />;
  }

  if (isError) {
    return <p>Error Occured</p>;
  }

  defaults.global.legend = false;

  const mtn =
    period === 'month'
      ? extractProductMonthData('mtn', salesChartData)
      : extractProductWeekData('mtn', salesChartData);

  const airtel =
    period === 'month'
      ? extractProductMonthData('airtel', salesChartData)
      : extractProductWeekData('airtel', salesChartData);

  const glo =
    period === 'month'
      ? extractProductMonthData('glo', salesChartData)
      : extractProductWeekData('glo', salesChartData);

  const nMobile =
    period === 'month'
      ? extractProductMonthData('9mobile', salesChartData)
      : extractProductWeekData('9mobile', salesChartData);

  const values = [...mtn, ...airtel, ...glo, ...nMobile].flat();
  const chartStepSize = Math.max(...values) / 5;

  const productSaleChartData = {
    labels:
      period === 'month'
        ? [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
          ]
        : salesChartData.map(
            (item) => `${dayjs(item.week[0]?.week).format('MMM-DD')}th`
          ),
    datasets: [
      {
        barThickness: 8,
        label: 'Glo',
        backgroundColor: '#2EAE65',
        data: glo,
      },
      {
        barThickness: 8,
        label: 'MTN',
        backgroundColor: '#FDB600',
        data: mtn,
      },
      {
        barThickness: 8,
        label: '9 Mobile',
        backgroundColor: '#000',
        data: nMobile,
      },
      {
        barThickness: 8,
        label: 'Airtel',
        backgroundColor: '#DC444C',
        data: airtel,
      },
    ],
  };

  return (
    <Box
      pr="4.4rem"
      pl="8.6rem"
      pt="3.2rem"
      pb="5rem"
      mt="4rem"
      w="100%"
      boxShadow="0px 0px 4px rgba(0, 0, 0, 0.15)"
      maxW="100%"
    >
      <Flex align="center" justify="space-between">
        <Text
          color="#808080"
          fontFamily="medium"
          fontSize="2rem"
          lineHeight="2.4rem"
          pl="3rem"
        >
          Sales Revenue
        </Text>
        <HStack spacing="4rem">
          <Flex w="14rem" borderRadius="4px" bg="#F8F9FB">
            <SolidBtn
              h="100%"
              backgroundColor={period === 'month' ? '#2EAE65' : 'transparent'}
              color={period === 'month' ? '#fff' : '#939AA3'}
              fontSize="1.4rem"
              fontFamily="medium"
              py="1rem"
              w="7rem"
              onClick={() => setPeriod('month')}
            >
              Month
            </SolidBtn>
            <SolidBtn
              h="100%"
              backgroundColor={period === 'week' ? '#2EAE65' : 'transparent'}
              color={period === 'week' ? '#fff' : '#939AA3'}
              fontSize="1.4rem"
              fontFamily="medium"
              py="1rem"
              w="7rem"
              onClick={() => setPeriod('week')}
            >
              Week
            </SolidBtn>
          </Flex>
          <Box>
            <Picker
              ref={pickAMonth}
              years={pickerYears}
              value={date}
              lang={pickerMonths}
              onChange={(year, month) => setDate({ year, month })}
            >
              <DatePickerTrigger
                value={{
                  month: pickerMonths[date.month - 1],
                  year: date.year,
                }}
                onClick={() => pickAMonth.current.show()}
              />
            </Picker>
          </Box>
        </HStack>
      </Flex>
      {salesChartData.length > 0 ? (
        <Box h="32.4rem" w="98%" mt="8.8rem">
          <Bar
            data={productSaleChartData}
            options={{
              maintainAspectRatio: false,
              responsive: true,
              scales: {
                xAxes: [
                  {
                    categoryPercentage: 1.0,
                    gridLines: {
                      display: false,
                    },
                  },
                ],
                yAxes: [
                  {
                    ticks: {
                      beginAtZero: true,
                      stepSize: chartStepSize,
                      userCallback: (value) => nFormatter(value),
                    },
                  },
                ],
              },
            }}
          />
        </Box>
      ) : (
        <Box>
          <Flex align="center" justify="center" mt="6rem">
            <FallbackIcon />
          </Flex>
          <Text fontSize="1.6rem" textAlign="center" fontFamily="medium">
            No data
          </Text>
        </Box>
      )}
      <Flex
        w="100%"
        justify="space-between"
        align="center"
        mt="6.6rem"
        pl="3rem"
      >
        {salesChartData.length > 0 ? (
          <HStack spacing="4rem" w="100%">
            <Box d="flex" alignItems="center">
              <Box bg="#2EAE65" w="1.3rem" h="1.3rem" />
              <Text
                ml="1.2rem"
                color="#979797"
                fontFamily="regular"
                fontSize="1.4rem"
                lineHeight="1.7rem"
              >
                Glo
              </Text>
            </Box>
            <Box d="flex" alignItems="center">
              <Box bg="#FDB600" w="1.3rem" h="1.3rem" />
              <Text
                ml="1.2rem"
                color="#979797"
                fontFamily="regular"
                fontSize="1.4rem"
                lineHeight="1.7rem"
              >
                MTN
              </Text>
            </Box>
            <Box d="flex" alignItems="center">
              <Box bg="#000" w="1.3rem" h="1.3rem" />
              <Text
                ml="1.2rem"
                color="#979797"
                fontFamily="regular"
                fontSize="1.4rem"
                lineHeight="1.7rem"
              >
                9 Mobile
              </Text>
            </Box>
            <Box d="flex" alignItems="center">
              <Box bg="#DC444C" w="1.3rem" h="1.3rem" />
              <Text
                ml="1.2rem"
                color="#979797"
                fontFamily="regular"
                fontSize="1.4rem"
                lineHeight="1.7rem"
              >
                Airtel
              </Text>
            </Box>
          </HStack>
        ) : (
          <Box></Box>
        )}

        <Flex w="20rem" borderRadius="4px" bg="#F8F9FB">
          <SolidBtn
            h="100%"
            backgroundColor={
              productType === 'airtime' ? '#2EAE65' : 'transaparent'
            }
            color={productType === 'airtime' ? '#fff' : '#939AA3'}
            fontSize="1.4rem"
            fontFamily="medium"
            py="1rem"
            w="10rem"
            onClick={() => setProductType('airtime')}
          >
            Airtime
          </SolidBtn>
          <SolidBtn
            h="100%"
            backgroundColor={
              productType === 'data' ? '#2EAE65' : 'transaparent'
            }
            color={productType === 'data' ? '#fff' : '#939AA3'}
            fontSize="1.4rem"
            fontFamily="medium"
            py="1rem"
            w="10rem"
            onClick={() => setProductType('data')}
          >
            Data
          </SolidBtn>
        </Flex>
      </Flex>
    </Box>
  );
}

export { SalesRevenue };
