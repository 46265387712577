import React, { useState, useEffect } from 'react';
import { Box, Text } from '@chakra-ui/react';
import { useQuery } from 'react-query';

import { walletData } from '../../queries';

import {
  Balance,
  BankDetailsModal,
  FundRequestModal,
  WalletHistory,
  EditFundRequestModal,
} from '../../components/merchant';
import { SpinningText } from '../../components/common/loaders';
import { ErrorOccured } from '../../components/common/errors';

const Wallet = () => {
  const [page, setPage] = useState(1);
  const { data, isLoading, isError, refetch } = useQuery(
    ['walletInfo', page],
    walletData
  );

  useEffect(() => {
    refetch();
  }, [page]);

  const totalPages = data?.walletTopups?.totalPages;
  const totalData = data?.walletTopups?.total;
  const currentPage = data?.walletTopups?.currentPage;

  const [showCenterModal, setShowCenterModal] = useState(false);
  const [showRightModal, setShowRightModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [requestToEdit, setRequestToEdit] = useState(null);

  if (isLoading) {
    return <SpinningText />;
  }

  if (isError) {
    return <ErrorOccured />;
  }

  return (
    <Box>
      <Text fontFamily="bold" fontSize="32px" color="#212d43" lineHeight="38px">
        Wallet
      </Text>
      <Box d="flex" justifyContent="flex-end">
        <Text
          color="#444444"
          fontFamily="regular"
          fontSize="1.4rem"
          lineHeight="1.7rem"
          mt="2.3rem"
        >
          View Bank details
          <Text
            as="span"
            fontFamily="bold"
            textDecor="underline"
            fontStyle="italic"
            cursor="pointer"
            color="brand.blue"
            ml=".5rem"
            onClick={() => setShowRightModal(true)}
          >
            here
          </Text>
        </Text>
      </Box>
      <Balance
        walletStats={data?.walletStats}
        setShowCenterModal={setShowCenterModal}
      />
      <WalletHistory
        walletTopups={data?.walletTopups.requests}
        currentPage={currentPage}
        totalPages={totalPages}
        totalData={totalData}
        myPage={page}
        setPage={setPage}
        refetch={refetch}
        setShowEditModal={setShowEditModal}
        setRequestToEdit={setRequestToEdit}
      />
      <FundRequestModal
        showCenterModal={showCenterModal}
        setShowCenterModal={setShowCenterModal}
        refetch={refetch}
      />
      <EditFundRequestModal
        showEditModal={showEditModal}
        setShowEditModal={setShowEditModal}
        requestToEdit={requestToEdit}
        refetch={refetch}
      />
      <BankDetailsModal
        bankDetails={data?.bankDetails}
        showRightModal={showRightModal}
        setShowRightModal={setShowRightModal}
      />
    </Box>
  );
};

export { Wallet };
