import axios from 'axios';
import { AES, enc } from 'crypto-js';

const ciphertext = localStorage.getItem('auth');

let apiToken = '';

if (ciphertext) {
  const bytes = AES.decrypt(ciphertext, process.env.REACT_APP_AUTH_KEY);
  const { token } = JSON.parse(bytes.toString(enc.Utf8));

  apiToken = token;
}

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASEURL,
});

instance.defaults.headers.common['Authorization'] = `Bearer ${apiToken}`;

export default instance;
