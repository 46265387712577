import axios from 'axios';
import { handleError } from '../../utils';
import instance from '../axios.config';

const getMerchantOverviewData = async () => {
  try {
    const [overviewRes, bankRes] = await axios.all([
      instance.get('/merchant/overview'),
      instance.get('/merchant/bank'),
    ]);

    return {
      overviewData: overviewRes.data.data,
      bankDetails: bankRes.data.data,
    };
  } catch (error) {
    handleError(error);
  }
};

const getMerchantSalesOverviewData = async (key, type, period, date) => {
  try {
    const { data } = await instance.get(
      `/merchant/sales?type=${type}&period=${period}&year=${date.year}-01-01&month=${date.year}-${date.month}-01`
    );
    return data.data;
  } catch (error) {
    handleError(error);
  }
};

export { getMerchantOverviewData, getMerchantSalesOverviewData };
