import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import dayjs from 'dayjs';
import styled from '@emotion/styled';
import { getSingleTicket } from '../../../queries';

function TicketList({ tickets, setActiveTicket, activeTicket }) {
  const getTicketMessages = async (id) => {
    try {
      const data = await getSingleTicket(id);
      setActiveTicket(data);
    } catch (error) {}
  };

  return (
    <Box
      w="30%"
      h="calc(100vh - 300px)"
      maxH="calc(100vh - 300px)"
      overflowY="auto"
      border="1px solid #F3F3F3"
      p="20px 0 30px"
    >
      {tickets.length > 0 ? (
        <Box>
          {tickets.map((el) => (
            <Box
              p="21px 18px 22px 30px"
              h="104"
              w="100%"
              cursor="pointer"
              bgColor={el.id === activeTicket?.[0]?.id && '#F6F7F9'}
              _hover={{ backgroundColor: '#F6F7F9' }}
              key={el.id}
              onClick={() => getTicketMessages(el.id)}
            >
              <Flex justify="space-between" align="flex-end">
                <Text
                  as="h3"
                  fontFamily="medium"
                  fontSize="16px"
                  lineHeight="19px"
                  textTransform="capitalize"
                >
                  {el.title}
                </Text>
                <Text
                  as="p"
                  color="#C4C4C4"
                  fontFamily="medium"
                  lineHeight="14px"
                  whiteSpace="nowrap"
                >
                  {dayjs(el.updated_at).format('MMM D, YYYY')}
                </Text>
              </Flex>
              <TicketText
                as="p"
                color="#939AA3"
                fontFamily="regular"
                lineHeight="17px"
                mt="8px"
              >
                {el.message} ...
              </TicketText>
            </Box>
          ))}
        </Box>
      ) : (
        <Text
          as="h3"
          fontFamily="medium"
          fontSize="2rem"
          lineHeight="19px"
          textTransform="capitalize"
          textAlign="center"
        >
          No Tickets
        </Text>
      )}
    </Box>
  );
}

export { TicketList };

const TicketText = styled(Text)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  width: 85%;
`;
