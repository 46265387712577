import React, { useState } from 'react';
import { Box, Text, Flex, Badge, Image } from '@chakra-ui/react';
import { useQuery } from 'react-query';

import { SpinningText } from '../../components/common/loaders';
import { getAllTickets } from '../../queries/';
import { TicketList, Messages } from '../../components/admin/tickets';
import chatIcon from '../../assets/icons/chat.svg';
import { ErrorOccured } from '../../components/common/errors';

const Ticket = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [, setIsClosed] = useState(false);
  const [activeTicket, setActiveTicket] = useState(null);

  const { isLoading, data, isError, refetch } = useQuery(
    'getAllTickets',
    getAllTickets
  );

  const handleClose = () => {
    setIsOpen(false);
    setIsClosed(true);
  };

  const handleOpen = () => {
    setIsClosed(false);
    setIsOpen(true);
  };

  if (isLoading) {
    return <SpinningText />;
  }

  if (isError) {
    return <ErrorOccured />;
  }

  return (
    <Box>
      <Box>
        <Box>
          <Box d="flex" alignContent="center" justifyContent="space-between">
            <Text
              color="brand.header"
              lineHeight="3.8rem"
              fontSize="3.2rem"
              fontFamily="bold"
            >
              Ticket
            </Text>
          </Box>
          <Flex
            w="30%"
            borderBottom="1px solid #F3F3F3"
            pb="10px"
            m="50px 0 35px"
          >
            <Flex
              p="7px 13px 7px 14px"
              h="42px"
              w="110px"
              bgColor={isOpen ? 'rgba(0, 65, 190, 0.15)' : '#fff'}
              borderRadius="8px"
              align="center"
              cursor="pointer"
              onClick={handleOpen}
            >
              <Text
                fontFamily={isOpen ? 'medium' : 'regular'}
                color={isOpen ? '#0041BE' : '#808080'}
              >
                Ticket
                {data?.openTickets.length > 0 ? (
                  <Badge
                    fontFamily="bold"
                    variant="solid"
                    colorScheme="red"
                    ml="8px"
                    p="2px 10px 3px"
                    fontSize="1.4rem"
                    borderRadius="8px"
                  >
                    {data?.openTickets.length}
                  </Badge>
                ) : (
                  ''
                )}
              </Text>
            </Flex>
            <Flex
              pr="11px"
              pl="14px"
              w="110px"
              h="42px"
              align="center"
              bgColor={!isOpen ? 'rgba(0, 65, 190, 0.15)' : '#fff'}
              borderRadius="8px"
              cursor="pointer"
              onClick={handleClose}
            >
              <Text
                fontFamily={!isOpen ? 'medium' : 'regular'}
                color={!isOpen ? '#0041BE' : '#808080'}
              >
                Closed ticket
              </Text>
            </Flex>
          </Flex>
          <Flex w="100%" justify="space-between">
            <TicketList
              setActiveTicket={setActiveTicket}
              tickets={isOpen ? data?.openTickets : data?.closedTickets}
              activeTicket={activeTicket}
            />
            <Box
              w="68%"
              h="calc(100vh - 300px)"
              maxH="calc(100vh - 300px)"
              boxShadow="0px 0px 4px rgba(0, 0, 0, 0.15)"
              p="20px 0 30px"
              pos="relative"
            >
              {!activeTicket ? (
                <Image
                  pos="absolute"
                  left="50%"
                  top="40%"
                  transform="translate(-50%, -40%)"
                  w="400px"
                  opacity=".7"
                  src={chatIcon}
                />
              ) : (
                <Messages
                  setActiveTicket={setActiveTicket}
                  messages={activeTicket}
                  isOpen={isOpen}
                  refetch={refetch}
                  setIsOpen={setIsOpen}
                />
              )}
            </Box>
          </Flex>
        </Box>
      </Box>
    </Box>
  );
};

export { Ticket };
