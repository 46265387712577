import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Box, Text, Flex, Badge, Image } from '@chakra-ui/react';

import { SolidBtn } from '../../components/common/buttons';
import { SpinningText } from '../../components/common/loaders';
import {
  TicketModal,
  NoTicket,
  TicketList,
  Messages,
} from '../../components/merchant/ticket';

import chatIcon from '../../assets/icons/chat.svg';

import { getTickets } from '../../queries';
import { ErrorOccured } from '../../components/common/errors';

const Ticket = () => {
  const [showOpenTicketModal, setShowOpenTicketModal] = useState(false);
  const [activeTicket, setActiveTicket] = useState(null);
  const [isOpen, setIsOpen] = useState(true);

  React.useEffect(() => {}, [activeTicket]);

  const { isLoading, refetch, isError, data } = useQuery(
    'getTickets',
    getTickets
  );

  if (isLoading) {
    return <SpinningText />;
  }

  if (isError) {
    return <ErrorOccured />;
  }

  return (
    <Box>
      <Box>
        {data && data.openTickets.length < 1 ? (
          <NoTicket openModal={setShowOpenTicketModal} />
        ) : (
          <Box>
            <Box d="flex" alignContent="center" justifyContent="space-between">
              <Text
                color="brand.header"
                lineHeight="3.8rem"
                fontSize="3.2rem"
                fontFamily="bold"
              >
                Ticket
              </Text>
              <SolidBtn onClick={() => setShowOpenTicketModal(true)} w="17rem">
                Open a new Ticket
              </SolidBtn>
            </Box>
            <Flex
              w="30%"
              borderBottom="1px solid #F3F3F3"
              pb="10px"
              m="50px 0 35px"
            >
              <Flex
                p="7px 13px 7px 14px"
                h="42px"
                w="110px"
                bgColor={isOpen ? 'rgba(0, 65, 190, 0.15)' : '#fff'}
                borderRadius="8px"
                align="center"
                cursor="pointer"
                onClick={() => setIsOpen(true)}
              >
                <Text
                  fontFamily={isOpen ? 'medium' : 'regular'}
                  color={isOpen ? '#0041BE' : '#808080'}
                  whiteSpace="nowrap"
                >
                  Tickets
                  {data && (
                    <Badge
                      fontFamily="bold"
                      variant="solid"
                      colorScheme="red"
                      ml="8px"
                      p="2px 10px 3px"
                      fontSize="1.4rem"
                      borderRadius="8px"
                    >
                      {data.openTickets.length}
                    </Badge>
                  )}
                </Text>
              </Flex>
              <Flex
                pr="11px"
                pl="14px"
                w="110px"
                h="42px"
                align="center"
                bgColor={!isOpen ? 'rgba(0, 65, 190, 0.15)' : '#fff'}
                borderRadius="8px"
                cursor="pointer"
                onClick={() => setIsOpen(false)}
              >
                <Text
                  fontFamily={!isOpen ? 'medium' : 'regular'}
                  color={!isOpen ? '#0041BE' : '#808080'}
                >
                  Closed ticket
                </Text>
              </Flex>
            </Flex>
            <Flex w="100%" justify="space-between">
              <TicketList
                setActiveTicket={setActiveTicket}
                tickets={isOpen ? data.openTickets : data.closedTickets}
              />
              <Box
                w="68%"
                h="calc(100vh - 300px)"
                maxH="calc(100vh - 300px)"
                boxShadow="0px 0px 4px rgba(0, 0, 0, 0.15)"
                p="20px 0 30px"
                pos="relative"
              >
                {!activeTicket ? (
                  <Image
                    pos="absolute"
                    left="50%"
                    top="40%"
                    transform="translate(-50%, -40%)"
                    w="400px"
                    opacity=".7"
                    src={chatIcon}
                  />
                ) : (
                  <Messages
                    setActiveTicket={setActiveTicket}
                    messages={activeTicket}
                    isOpen={isOpen}
                  />
                )}
              </Box>
            </Flex>
          </Box>
        )}
      </Box>
      <TicketModal
        isOpen={showOpenTicketModal}
        setIsOpen={setShowOpenTicketModal}
        refetch={refetch}
      />
    </Box>
  );
};

export { Ticket };
