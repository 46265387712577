import React, { useState } from 'react';
import {
  Box,
  Stack,
  InputGroup,
  InputLeftAddon,
  Input,
  FormControl,
  FormLabel,
  Text,
  InputRightAddon,
} from '@chakra-ui/react';
import styled from '@emotion/styled';

function CustomInput(props) {
  const { children, ...rest } = props;

  return (
    <Box>
      <ThemeInput {...rest}>{children}</ThemeInput>
    </Box>
  );
}

function ThemeInput(props) {
  const {
    type,
    children,
    label,
    id,
    labelColor,
    labelFamily,
    inputColor,
    hideIcon,
    name,
    inputRef,
    value,
    rightAddon,
    hideRightIcon,
    ...rest
  } = props;

  const [length, setLength] = useState(value?.length || 0);
  const [focus, setFocus] = useState(false);
  // const [displayedValue, setDisplayedValue] = useState(value);

  const updateValue = (e) => {
    setLength(e.target.value.length);
  };

  return (
    <FormControl
      mt="4.8rem"
      borderRadius="4px"
      position="relative"
      border={focus ? '1px solid #003FB9' : 'none'}
      {...rest}
    >
      <Stack
        spacing={4}
        bg={props.bgColor || '#f8f9fb'}
        h="5.5rem"
        borderRadius={props.borderRadius || '4px'}
        borderBottom={props.border || 'none'}
      >
        <InputGroup h="100%" border="none" py="1rem" position="relative">
          {!hideIcon && (
            <InputLeftAddon
              w="45px"
              h="100%"
              d="flex"
              justifyContent="center"
              border="none"
              borderRight="1px solid #E8EEF4"
              background="transparent"
            >
              {children}
            </InputLeftAddon>
          )}
          <StyledInput
            type={type || 'text'}
            name={name}
            roundedLeft="0"
            h="100%"
            border="none"
            focusBorderColor="transparent"
            background="transparent"
            id={id}
            fontFamily="medium"
            fontSize="1.4rem"
            color={inputColor || '#212D43'}
            onChange={updateValue}
            ref={inputRef}
            position="relative"
            zIndex="5"
            defaultValue={value}
            length={length}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
          />
          <FormLabel
            position="absolute"
            left={hideIcon ? '1rem' : '5.2rem'}
            top="50%"
            zIndex="50"
            transform="translateY(-40%)"
            fontSize="1.4rem"
            fontFamily={labelFamily || 'regular'}
            color={labelColor || '#939AA3'}
            opacity=".5"
            htmlFor={id}
            transition="all .7s"
          >
            {label}
          </FormLabel>
          {!hideRightIcon && (
            <InputRightAddon
              w="45px"
              h="100%"
              d="flex"
              justifyContent="center"
              border="none"
              borderRight="1px solid #E8EEF4"
              background="transparent"
              cursor="pointer"
            >
              {rightAddon}
            </InputRightAddon>
          )}
        </InputGroup>
      </Stack>
    </FormControl>
  );
}

function InputError(props) {
  return (
    <Text
      color={props.color || '#E24D4D'}
      fontFamily="regular"
      fontSize="1.2rem"
      mt=".5rem"
    >
      {props.text}
    </Text>
  );
}

const StyledInput = styled(Input)`
  transform: ${({ length, type }) =>
    length > 0 || type == 'date' ? 'translateY(10px)' : '0'};

  &:focus {
    transform: translateY(10px);

    & + label {
      transform: translateY(-20px);
      font-size: 1.1rem;
    }
  }

  & + label {
    transform: ${({ length, type }) =>
      length > 0 || type == 'date' ? 'translateY(-20px)' : '0'};
    font-size: ${({ length, type }) =>
      length > 0 || type == 'date' ? '1.1rem' : '1.4rem'};
  }
`;

export { CustomInput, InputError };
