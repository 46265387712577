import React, { useContext, useState } from 'react';
import { useQuery } from 'react-query';
import { Box, Flex, Text, Link as CLink, HStack } from '@chakra-ui/react';
import styled from '@emotion/styled';
import {
  NavLink,
  Redirect,
  Route,
  Switch,
  useRouteMatch,
} from 'react-router-dom';
import { toast } from 'react-toastify';

import { ReactComponent as ProfileIcon } from '../../assets/icons/profile.svg';
import { ReactComponent as BankIcon } from '../../assets/icons/home.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import { CustomInput } from '../../components/common/form';
import { OutlineBtn, SolidBtn } from '../../components/common/buttons';
import {
  updateAdminProfile,
  addBank,
  getBank,
  updateBank,
  removeBank,
} from '../../queries';
import { CenterModal } from '../../components/common/modals';
import { CustomTable, TableWrapper } from '../../components/common/tables';
import { AuthContext, UPDATE } from '../../context/auth';
import { removeEmpty } from '../../utils';
import { setAdminWalletMinimumAmount } from '../../queries';
import { ReactComponent as FallbackIcon } from '../../assets/icons/fallback.svg';

function Settings() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <Redirect to="/admin/settings/profile" />
      </Route>
      <Route path={`${path}/profile`}>
        <ProfileSettings />
      </Route>
      <Route path={`${path}/bank`}>
        <BankSettings />
      </Route>
    </Switch>
  );
}

export { Settings };

function ProfileSettings() {
  const { authState, dispatch } = useContext(AuthContext);

  const [state, setState] = useState({
    first_name: authState.first_name,
    last_name: authState.last_name,
    address: authState?.address,
    phone_number: authState?.phoneNumber,
  });
  const [disabled, setDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [minimumLoading, setMinimumLoading] = useState(false);
  const [amount, setAmount] = useState(authState.minWalletBal);

  const enable = () => setDisabled(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleAmountChange = (e) => {
    const { value } = e.target;
    setAmount(() => value);
  };

  const handleSetMinimum = async () => {
    if (!amount || amount < 1) {
      toast.error('Negetive amount not allowed');
      return;
    }

    const data = {
      amount: +amount,
    };
    try {
      setMinimumLoading(true);
      const res = await setAdminWalletMinimumAmount(data);
      dispatch({
        type: UPDATE,
        payload: {
          minWalletBal: amount,
        },
      });

      toast.success(res.message);
      setAmount('');
      setMinimumLoading(false);
    } catch (err) {
      setMinimumLoading(false);
      toast.error(err?.response?.data?.message);
    }
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const cleaned = removeEmpty(state);
      await updateAdminProfile(cleaned);
      dispatch({
        type: UPDATE,
        payload: {
          first_name: state.first_name,
          last_name: state.last_name,
          address: state.address,
          phoneNumber: state.phone_number,
        },
      });

      setIsLoading(false);
      toast.success('Profile updated successfully!');
      setDisabled(true);
    } catch (err) {
      setIsLoading(false);
      toast.error(err.response.data.message);
    }
  };

  return (
    <Box w="100%" h="100%">
      <Text color="#212D43" fontFamily="bold" fontSize="3.2rem">
        Settings
      </Text>
      <Flex
        bg="#fff"
        boxShadow="0px 0px 4px rgba(0, 0, 0, 0.15)"
        w="100%"
        my="5.2rem"
      >
        <SettingsMenu />
        <Box flex="1" p="4rem" mb="10rem">
          <Flex justify="space-between" maxW="772px">
            <Text color="#444" fontFamily="medium" fontSize="2.4rem">
              Update your profile here
            </Text>
            <OutlineBtn onClick={enable} border="none" w="120px">
              <EditIcon />
              <Text as="span" pl=".8rem">
                Edit profile
              </Text>
            </OutlineBtn>
          </Flex>
          <Box mt="5.5rem">
            <HStack spacing="4rem">
              <CustomInput
                hideIcon
                value={state.first_name}
                name="first_name"
                onChange={handleChange}
                label="First name"
                placeholder=""
                w="360px"
                style={{ marginTop: 0 }}
                isDisabled={disabled}
              />
              <CustomInput
                hideIcon
                value={state.last_name}
                onChange={handleChange}
                name="last_name"
                label="Last name"
                placeholder=""
                w="360px"
                style={{ marginTop: 0 }}
                isDisabled={disabled}
              />
            </HStack>
            <HStack spacing="4rem" mt="2rem">
              <CustomInput
                hideIcon
                label="Email"
                value={authState.email}
                onChange={handleChange}
                name="email"
                placeholder=""
                w="360px"
                style={{ marginTop: 0 }}
                isDisabled={true}
              />
              <CustomInput
                hideIcon
                value={state.address}
                onChange={handleChange}
                name="address"
                label="Address"
                placeholder=""
                w="360px"
                style={{ marginTop: 0 }}
                isDisabled={disabled}
              />
            </HStack>
            <HStack spacing="4rem" mt="2rem">
              <CustomInput
                hideIcon
                value={state.phone_number}
                onChange={handleChange}
                name="phone_number"
                label="Phone number"
                placeholder=""
                w="360px"
                style={{ marginTop: 0 }}
                isDisabled={disabled}
              />
            </HStack>
          </Box>
          <SolidBtn
            isDisabled={disabled}
            isLoading={isLoading}
            mt="5.5rem"
            w="160px"
            onClick={handleSubmit}
          >
            UPDATE
          </SolidBtn>
          {authState?.isSuperAdmin ? (
            <Box>
              <Box w="755px" h="1px" bg="#f3f3f3" my="4rem" />
              <HStack spacing="4rem" align="flex-start">
                <Box w="360px" pr="4rem">
                  <Text color="#444" fontFamily="medium" fontSize="2.4rem">
                    Set wallet minimum amount
                  </Text>
                  <Text
                    color="#939AA3"
                    fontFamily="regular"
                    fontSize="1.3rem"
                    mt="1rem"
                  >
                    You can set the minimum amount to have in your wallet and
                    get notification when the amount is reached for you to
                    top-up your wallet.
                  </Text>
                </Box>
                <Box w="360px">
                  <HStack spacing="1.6rem">
                    <CustomInput
                      hideIcon
                      type="number"
                      name="amount"
                      label="Set amount"
                      placeholder=""
                      w="270px"
                      value={amount}
                      onChange={handleAmountChange}
                      style={{ marginTop: 0 }}
                    />
                    <SolidBtn
                      onClick={handleSetMinimum}
                      isDisabled={amount?.length <= 0}
                      isLoading={minimumLoading}
                      w="103px"
                    >
                      Set
                    </SolidBtn>
                  </HStack>
                </Box>
              </HStack>
            </Box>
          ) : (
            ''
          )}
        </Box>
      </Flex>
    </Box>
  );
}

function BankSettings() {
  const [state, setState] = useState({
    accountName: '',
    bankName: '',
    accountNumber: '',
  });
  const [editState, setEditState] = useState({
    account_name: '',
    bank_name: '',
    account_number: '',
  });
  const [submitting, setSubmitting] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [currentRequest, setCurrentRequest] = useState(null);
  const [confirm, setConfirm] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const { data, refetch } = useQuery('getAdminBank', getBank);

  const handleAddBank = async () => {
    try {
      setSubmitting(true);
      const res = await addBank(state);
      setSubmitting(false);
      setIsOpen(false);
      refetch();
      toast.success(res.message);
    } catch (error) {
      setSubmitting(false);
      // show toast
      toast.error(error.response.data.message);
    }
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const btnDisabled =
    state.accountName === '' ||
    state.bankName === '' ||
    state.accountNumber === '';

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleClick = (e) => {
    setCurrentRequest(e);
    setShowDetails(true);
    setEditState({
      bank_name: e.bank_name,
      account_name: e.account_name,
      account_number: e.account_number,
    });
  };

  const editBank = async (id) => {
    try {
      setSubmitting(true);
      const res = await updateBank(editState, id);
      setSubmitting(false);
      setShowDetails(false);
      refetch();
      toast.success(res.message);
    } catch (error) {
      setSubmitting(false);
      toast.error(error.response.data.message);
    }
  };

  const handleDelete = (id) => {
    setCurrentId(id);
    setConfirm(true);
  };

  const deleteBank = async (id) => {
    try {
      setSubmitting(true);
      const res = await removeBank(id);
      setSubmitting(false);
      setConfirm(false);
      toast.success(res.message);
      refetch();
    } catch (error) {
      setSubmitting(false);
      toast.error(error.response.data.message);
      setConfirm(false);
    }
  };

  const defaultData =
    data &&
    data.map((e, id) => {
      return {
        col1: e.bank_name,
        col2: e.account_name,
        col3: e.account_number,
        col4: (
          <Box
            d="flex"
            cursor="pointer"
            alignItems="center"
            onClick={() => handleClick(e)}
          >
            <EditIcon />
            <Text
              color="brand.blue"
              fontSize="1.4rem"
              fontFamily="regular"
              lineHeight="1.7rem"
              ml="1.1rem"
            >
              Edit Bank
            </Text>
          </Box>
        ),
        col5: (
          <Text
            fontSize="1.4rem"
            fontFamily="regular"
            lineHeight="1.7rem"
            color="#E24D4D"
            cursor="pointer"
            onClick={() => handleDelete(e.id)}
          >
            X
            <Text ml="1.1rem" as="span">
              Remove
            </Text>
          </Text>
        ),
      };
    });

  const defaultColumns = [
    {
      Header: 'Bank name',
      accessor: 'col1',
      disableSortBy: true,
    },
    {
      Header: 'Account name',
      accessor: 'col2',
      disableSortBy: true,
    },
    {
      Header: 'Account number',
      accessor: 'col3',
      disableSortBy: true,
    },
    {
      Header: '',
      accessor: 'col4',
      disableSortBy: true,
    },
    {
      Header: '',
      accessor: 'col5',
      disableSortBy: true,
    },
  ];

  return (
    <Box w="100%" h="100%">
      <Text color="#212D43" fontFamily="bold" fontSize="3.2rem">
        Settings
      </Text>
      <Flex
        bg="#fff"
        boxShadow="0px 0px 4px rgba(0, 0, 0, 0.15)"
        w="100%"
        my="5.2rem"
      >
        <SettingsMenu />
        <Box flex="1" p="4rem" mb="10rem">
          <Flex justify="space-between">
            <Text color="#444" fontFamily="medium" fontSize="2.4rem">
              Update bank information
            </Text>
            <SolidBtn onClick={openModal} w="11rem">
              <Text fontSize="1.5rem">
                +
                <Text as="span" pl=".8rem">
                  Add bank
                </Text>
              </Text>
            </SolidBtn>
          </Flex>
          {data?.length < 1 ? (
            <Box>
              <Flex align="center" justify="center" mt="4rem">
                <FallbackIcon />
              </Flex>
              <Text fontSize="1.6rem" textAlign="center" fontFamily="medium">
                No bank details yet
              </Text>
            </Box>
          ) : (
            <TableWrapper>
              <CustomTable data={defaultData} columns={defaultColumns} />
            </TableWrapper>
          )}
        </Box>
      </Flex>
      <CenterModal title="Add Bank" showModal={isOpen} setShowModal={setIsOpen}>
        <CustomInput
          hideIcon
          label="Bank name"
          name="bankName"
          value={state.bankName}
          onChange={handleChange}
          style={{ margin: '1.6rem 0 3.2rem' }}
        />
        <CustomInput
          hideIcon
          label="Account number"
          name="accountNumber"
          value={state.accountNumber}
          onChange={handleChange}
          style={{ margin: '1.6rem 0 3.2rem' }}
        />
        <CustomInput
          hideIcon
          label="Account name"
          name="accountName"
          value={state.accountName}
          onChange={handleChange}
          style={{ margin: '1.6rem 0 3.2rem' }}
        />
        <SolidBtn
          isLoading={submitting}
          onClick={handleAddBank}
          isDisabled={btnDisabled}
        >
          Add
        </SolidBtn>
      </CenterModal>
      <CenterModal
        title="Edit Bank"
        showModal={showDetails}
        setShowModal={setShowDetails}
      >
        <CustomInput
          hideIcon
          label="Bank name"
          name="bank_name"
          value={editState.bank_name}
          onChange={handleEditChange}
          style={{ margin: '1.6rem 0 3.2rem' }}
        />
        <CustomInput
          hideIcon
          label="Account number"
          name="account_number"
          value={editState.account_number}
          onChange={handleEditChange}
          style={{ margin: '1.6rem 0 3.2rem' }}
        />
        <CustomInput
          hideIcon
          label="Account name"
          name="account_name"
          value={editState.account_name}
          onChange={handleEditChange}
          style={{ margin: '1.6rem 0 3.2rem' }}
        />
        <SolidBtn
          isLoading={submitting}
          onClick={() => {
            editBank(currentRequest.id);
          }}
        >
          Update
        </SolidBtn>
      </CenterModal>
      <CenterModal
        title="Are you sure you want to delete bank?"
        showModal={confirm}
        setShowModal={setConfirm}
      >
        <Box d="flex" justifyContent="space-between" alignItems="center">
          <OutlineBtn
            borderColor="#f00"
            color="#f00"
            w="15rem"
            onClick={() => setConfirm(false)}
          >
            No
          </OutlineBtn>
          <SolidBtn
            onClick={() => deleteBank(currentId)}
            isLoading={submitting}
            backgroundColor="#2EAE65"
            w="15rem"
          >
            Yes
          </SolidBtn>
        </Box>
      </CenterModal>
    </Box>
  );
}

function SettingsMenu() {
  return (
    <Box borderRight="1px solid #F3F3F3" minW="265px" pt="95px">
      <SideLink
        as={NavLink}
        activeClassName="selected"
        to="/admin/settings/profile"
        d="flex"
        pl="2.6rem"
        py="7px"
        alignItems="center"
        borderBottom="1px solid #f3f3f3"
        _hover={{
          textDecoration: 'none',
          background: '#f6f7fa',
        }}
      >
        <ProfileIcon />
        <Box ml="2.2rem">
          <Text
            fontFamily="medium"
            color="#444444"
            fontSize="1.4rem"
            className="main-text"
          >
            General
          </Text>
          <Text fontFamily="regular" color="#939AA3" fontSize="1.3rem">
            Update admin profile
          </Text>
        </Box>
      </SideLink>
      <SideLink
        as={NavLink}
        activeClassName="selected"
        to="/admin/settings/bank"
        d="flex"
        pl="2.6rem"
        py="7px"
        alignItems="center"
        borderBottom="1px solid #f3f3f3"
        _hover={{
          textDecoration: 'none',
          background: '#f6f7fa',
        }}
      >
        <BankIcon />
        <Box ml="2.2rem">
          <Text
            fontFamily="medium"
            color="#444444"
            fontSize="1.4rem"
            className="main-text"
          >
            Bank settings
          </Text>
          <Text fontFamily="regular" color="#939AA3" fontSize="1.3rem">
            Update bank information
          </Text>
        </Box>
      </SideLink>
    </Box>
  );
}

const SideLink = styled(CLink)`
  padding-right: 2rem;

  svg {
    fill: #808080;
  }

  &:hover,
  &.selected {
    border-right: 2px solid #0041be;
    background: #f6f7fa;

    .main-text {
      color: #0041be;
    }

    svg {
      fill: #0041be;
    }
  }
`;
