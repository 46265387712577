import React, { useEffect, useContext } from 'react';
import { Box, Flex, HStack, Text } from '@chakra-ui/react';
import { SolidBtn } from '../../components/common/buttons';
import { Link, useHistory } from 'react-router-dom';
import { AuthContext } from '../../context/auth';

// import { ReactComponent as DeclinedIcon } from '../../assets/icons/decline.svg';

function DocumentDeclined() {
  const { authState } = useContext(AuthContext);
  const history = useHistory();

  useEffect(() => {
    if (authState.isVerified) {
      history.push('/merchant/login');
    }
  }, []);

  return (
    <Flex w="100%" h="100vh" justify="center" align="center">
      <HStack>
        {/* <DeclinedIcon style={{ width: '200px' }} /> */}
        <Box>
          <Text fontFamily="regular" fontSize="4rem" color="#E24D4D">
            Documents not approved!
          </Text>
          <Text mt="1rem" color="#808080" fontFamily="regular" maxW="500px">
            {authState?.comment}
          </Text>
          <SolidBtn as={Link} to="/merchant/upload" w="140px" mt="4rem">
            Upload again
          </SolidBtn>
        </Box>
      </HStack>
    </Flex>
  );
}

export { DocumentDeclined };
