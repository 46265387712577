import React, { useState, useEffect } from 'react';
import { Box, Text } from '@chakra-ui/react';
import { CustomTable, TableWrapper } from '../../components/common/tables';
import { useQuery } from 'react-query';
import { getProductList } from '../../queries';
import { SpinningText } from '../../components/common/loaders/Loader';
import { ErrorOccured } from '../../components/common/errors';

function ProductList() {
  const [page, setPage] = useState(1);
  const { isLoading, data, refetch, isError } = useQuery(
    ['productList', page],
    getProductList
  );

  useEffect(() => {
    refetch();
  }, [page]);

  const totalPages = data?.totalPages;
  const totalData = data?.total;
  const currentPage = data?.currentPage;

  if (isLoading) {
    return <SpinningText />;
  }

  if (isError) {
    return <ErrorOccured />;
  }

  const defaultData = (() => {
    const list = data.discount.map((item) => ({
      col1: `${item.product_name} ${item.product_type}`,
      col2: `${item.flat_discount}%`,
    }));

    return list;
  })();

  const defaultColumns = [
    {
      Header: 'Product name',
      accessor: 'col1',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Discount',
      accessor: 'col2',
      disableSortBy: true,
      disableFilters: true,
    },
  ];

  return (
    <Box>
      <Box>
        <Text
          color="brand.header"
          lineHeight="3.8rem"
          fontSize="3.2rem"
          fontFamily="bold"
          mb="5rem"
        >
          Product List
        </Text>
      </Box>
      <Box
        boxShadow=" 0px 0px 4px rgba(0, 0, 0, 0.15)"
        pt="1rem"
        pl="4.2rem"
        pr="4rem"
        pb="1rem"
        mt="2.1rem"
        mb="8rem"
      >
        <TableWrapper>
          <CustomTable
            data={defaultData}
            columns={defaultColumns}
            currentPage={currentPage}
            totalPages={totalPages}
            totalData={totalData}
            myPage={page}
            setPage={setPage}
          />
        </TableWrapper>
      </Box>
    </Box>
  );
}

export { ProductList };
