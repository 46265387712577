import instance from '../axios.config';

const exportWallet = async ({ limit, start, end }) => {
  const data = await instance.get(
    `/merchant/export?type=fund-request&limit=${limit}&between=${start}&and=${end}`
  );

  return data.data;
};

const exportTransactionHistory = async ({ limit, start, end }) => {
  const data = await instance.get(
    `/merchant/export?type=tx-history&limit=${limit}&between=${start}&and=${end}`
  );

  return data.data;
};

export { exportWallet, exportTransactionHistory };
