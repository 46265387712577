import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { Box, Text, Button, Flex } from '@chakra-ui/react';

import { Actions, Table, Modal } from '../../components/admin';
import { getAuditTrail, searchAuditTrail } from '../../queries';
import { SpinningText } from '../../components/common/loaders';
import { ExportAuditTrail } from '../../components/export';
import { ReactComponent as FallbackIcon } from '../../assets/icons/fallback.svg';
import { ErrorOccured } from '../../components/common/errors';

const AuditTrail = () => {
  const [showRightModal, setShowRightModal] = useState(false);
  const [currentRequest, setCurrentRequest] = useState(null);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [name, setName] = useState('');
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showSearch, setShowSearch] = useState(false);

  const { isLoading, isError, data, refetch } = useQuery(
    ['getAuditTrail', page],
    getAuditTrail
  );

  useEffect(() => {
    refetch();
    setShowSearch(false);
  }, [page]);

  const totalPages = data?.data?.totalPages;
  const totalData = data?.data?.total;
  const currentPage = data?.data?.currentPage;

  const handleClick = (e) => {
    setCurrentRequest(e);
    setShowRightModal(true);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleChange = (e) => {
    setName(e.target.value);
  };

  // Search handler
  const handleSearchData = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const data = await searchAuditTrail(name);
      setLoading(false);
      setShowSearch(true);
      setTableData(data.data);
    } catch (err) {
      setLoading(false);
      setShowSearch(false);
      throw err;
    }
  };

  if (isLoading) {
    return <SpinningText />;
  }

  if (isError) {
    return <ErrorOccured />;
  }

  const defaultData = data?.data.requests.map((e, id) => {
    return {
      col1: e.company_name,
      col2: e.merchant_email,
      col3: e.amount,
      col4: 'Bank Transfer',
      col5: e.fund_request_created_at,
      col6: e.fund_request_updated_at,
      col7: (
        <Button
          variant="link"
          fontFamily="regular"
          fontSize="14px"
          fontWeight="bold!important"
          color="brand.blue"
          outline="none"
          _focus={{
            boxShadow: 'none',
          }}
          onClick={() => handleClick(e)}
        >
          View
        </Button>
      ),
    };
  });

  const showSearchData = tableData?.map((e, id) => {
    return {
      col1: e.company_name,
      col2: e.merchant_email,
      col3: e.amount,
      col4: 'Bank Transfer',
      col5: e.fund_request_created_at,
      col6: e.fund_request_updated_at,
      col7: (
        <Button
          variant="link"
          fontFamily="regular"
          fontSize="14px"
          fontWeight="bold!important"
          color="brand.blue"
          outline="none"
          _focus={{
            boxShadow: 'none',
          }}
          onClick={() => handleClick(e)}
        >
          View
        </Button>
      ),
    };
  });

  return (
    <Box maxW="82%">
      <Box>
        <Text
          color="brand.header"
          lineHeight="3.8rem"
          fontSize="3.2rem"
          fontFamily="bold"
        >
          Audit Trail
        </Text>
      </Box>
      <Actions
        name={name}
        handleChange={handleChange}
        handleOpen={handleOpen}
        handleSearchData={handleSearchData}
      />
      {defaultData.length < 1 ? (
        <Box>
          <Flex align="center" justify="center" mt="6rem">
            <FallbackIcon />
          </Flex>
          <Text fontSize="1.6rem" textAlign="center" fontFamily="medium">
            No data for audit trail yet
          </Text>
        </Box>
      ) : (
        <Box>
          {' '}
          {loading ? (
            <SpinningText />
          ) : (
            <Box>
              {showSearch && tableData.length < 1 ? (
                <Box>
                  <Flex align="center" justify="center" mt="6rem">
                    <FallbackIcon />
                  </Flex>
                  <Text
                    fontSize="1.6rem"
                    textAlign="center"
                    fontFamily="medium"
                  >
                    No company with that name found
                  </Text>
                </Box>
              ) : (
                <Table
                  defaultData={showSearch ? showSearchData : defaultData}
                  currentPage={currentPage}
                  totalPages={totalPages}
                  totalData={totalData}
                  myPage={page}
                  setPage={setPage}
                />
              )}
            </Box>
          )}
        </Box>
      )}

      <Modal
        showRightModal={showRightModal}
        setShowRightModal={setShowRightModal}
        currentRequest={currentRequest}
      />
      <ExportAuditTrail open={open} setOpen={setOpen} />
    </Box>
  );
};

export { AuditTrail };
