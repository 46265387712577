/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/display-name */
import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Box, Flex, Text, Image } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

import Coins from '../../assets/images/Coins.png';
import Atm from '../../assets/images/Atm.png';
import Smile from '../../assets/images/Smiling.png';
import Background from '../../assets/images/background.svg';
import MainImage from '../../assets/images/cover.svg';
import MobileImage from '../../assets/images/mobilecover.svg';
import { SolidBtn } from '../common/buttons';

const Hero = () => {
  const [active, setActive] = useState(true);
  const history = useHistory();

  // switch active
  (() => {
    setTimeout(() => {
      setActive(!active);
    }, 5000);
  })();

  return (
    <>
      <StyledBox>
        <CarouselProvider
          naturalSlideHeight={40}
          naturalSlideWidth={100}
          interval={5000}
          isPlaying={true}
          totalSlides={2}
          className="slider"
        >
          <Slider>
            <Slide index={0}>
              <StyledBox>
                <Box>
                  <Image
                    src={Background}
                    w="100vw"
                    position="absolute"
                    left="0"
                    right="0"
                  />
                  <Box>
                    <TextBox>
                      <Text
                        color="#444444"
                        fontFamily="bold"
                        fontSize="5.2rem"
                        lineHeight="120%"
                      >
                        Grow your business with our <br /> seamless API's and
                        get a <br />
                        discount on every transaction
                      </Text>
                      <Text
                        mt="2.4rem"
                        color="#626363"
                        fontSize="2rem"
                        fontFamily="medium"
                        lineHeight="150%"
                      >
                        Awake bridges the gap between telecommunication
                        companies <br /> and merchant businesses in Nigeria. Our
                        airtime, data and utility <br /> API makes topping up
                        prepaid mobile users simple and borderless.
                      </Text>
                      <SolidBtn
                        onClick={() => history.push('/merchant/signup')}
                        marginTop="3.2rem"
                        w="16.6rem"
                      >
                        Become a merchant
                      </SolidBtn>
                    </TextBox>
                    <Image
                      src={MainImage}
                      h="112.5rem"
                      position="absolute"
                      right="0"
                      top="-20%"
                    />
                  </Box>
                </Box>
              </StyledBox>
            </Slide>
            <Slide index={1}>
              <Box textAlign="center" mt="10.9rem">
                <Text
                  color="#444444"
                  fontFamily="bold"
                  fontSize="4.3rem"
                  lineHeight="110%"
                >
                  Earn big by becoming a <br /> merchant
                </Text>
                <Text
                  color="#939AA3"
                  fontFamily="medium"
                  fontSize="1.8rem"
                  lineHeight="140%"
                  mt="2.3rem"
                >
                  With our top-up software, you get to sell credit, data bundle
                  plans and utilities to a <br /> large number of mobile phone
                  users and operators.
                </Text>
              </Box>
              <Flex mt="5.6rem" align="center" justifyContent="center">
                <Image src={Coins} />
                <Image src={Atm} ml=".8rem" mr=".8rem" />
                <Image src={Smile} />
              </Flex>
            </Slide>
          </Slider>
          <Box w="5rem" margin="0 auto">
            <Box
              d="flex"
              justifyContent="space-between"
              position="relative"
              marginTop="-10rem"
            >
              <ButtonBack>
                <Box
                  border="1px solid #0041BE"
                  h="1rem"
                  w="1rem"
                  borderRadius="50%"
                  d="flex"
                  justifyContent="center"
                  alignItems="center"
                  onClick={() => setActive(true)}
                >
                  {active ? (
                    <Box h=".6rem" w=".6rem" bg="#0041BE" borderRadius="50%" />
                  ) : (
                    ''
                  )}
                </Box>
              </ButtonBack>
              <ButtonNext>
                <Box
                  border="1px solid #0041BE"
                  h="1rem"
                  w="1rem"
                  borderRadius="50%"
                  d="flex"
                  justifyContent="center"
                  alignItems="center"
                  onClick={() => setActive(false)}
                >
                  {!active ? (
                    <Box h=".6rem" w=".6rem" bg="#0041BE" borderRadius="50%" />
                  ) : (
                    ''
                  )}
                </Box>
              </ButtonNext>
            </Box>
          </Box>
        </CarouselProvider>
      </StyledBox>
      <MobileBox>
        <CarouselProvider
          naturalSlideHeight={40}
          naturalSlideWidth={100}
          totalSlides={2}
          isPlaying={true}
          className="slider"
        >
          <Slider>
            <Slide index={0}>
              <Image src={MobileImage} />
              <Box textAlign="center">
                <Text
                  mt="1.2rem"
                  color="#444444"
                  fontFamily="bold"
                  fontSize="1.8rem"
                  lineHeight="130%"
                >
                  Grow your business with our seamless <br /> API's and get a
                  discounton every <br /> transaction
                </Text>
                <Text
                  mt="1.2rem"
                  color="#626363"
                  fontFamily="medium"
                  fontSize="1.4rem"
                  lineHeight="150%"
                >
                  Awake bridges the gap between <br /> telecommunication
                  companies and merchant <br /> businesses in Nigeria. Our
                  airtime, data and
                  <br />
                  utility API makes topping up prepaid mobile <br /> users
                  simple and borderless.
                </Text>
              </Box>
              <Box width="100%" d="flex" justifyContent="center" mt="2.4rem">
                <SolidBtn
                  onClick={() => history.push('/merchant/signup')}
                  w="16.6rem"
                >
                  Become a merchant
                </SolidBtn>
              </Box>
            </Slide>
            <Slide index={1}>
              <Box textAlign="center" mt="3.2rem">
                <Text
                  color="#444444"
                  fontFamily="bold"
                  fontSize="1.8rem"
                  lineHeight="129%"
                >
                  Earn big by becoming a <br /> merchant
                </Text>
                <Text
                  color="#939AA3"
                  fontFamily="medium"
                  fontSize="1.4rem"
                  lineHeight="140%"
                  mt=".8rem"
                >
                  With our top-up software, you get to sell <br /> credit, data
                  bundle plans and utilities to a <br /> large number of mobile
                  phone users and <br /> operators.
                </Text>
              </Box>
              <Box mt="3.3rem">
                <Flex justify="center">
                  <Image src={Atm} w="80%" h="80%" />
                </Flex>
                <Flex mt="1%" justifyContent="center" width="100%">
                  <Box d="flex" justifyContent="space-between" width="80%">
                    <Image src={Coins} w="49.3%" h="80%" />
                    <Image src={Smile} w="49.3%" h="80%" />
                  </Box>
                </Flex>
              </Box>
            </Slide>
          </Slider>
        </CarouselProvider>
      </MobileBox>
    </>
  );
};

const StyledBox = styled(Box)`
  height: 72.2rem;
  width: 100%;
  .horizontalSlider___281Ls {
    height: 72rem !important;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

const TextBox = styled(Box)`
  padding-left: 5rem;
  padding-top: 16.7rem;
  position: relative;
  @media only screen and (min-width: 1441px) {
    padding-left: 18.4rem;
  }
`;

const MobileBox = styled(Box)`
  height: 60rem;
  width: 100%;
  .horizontalSlider___281Ls {
    height: 60rem !important;
  }
  @media (min-width: 768px) {
    display: none;
  }
`;

export { Hero };
