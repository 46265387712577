import React from 'react';
import { Box, Flex, Text, Image, Button } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { ReactComponent as Phone } from '../../assets/icons/services/phone.svg';
import { ReactComponent as Utility } from '../../assets/icons/services/utility.svg';
import { ReactComponent as Data } from '../../assets/icons/services/Group.svg';
import IMac from '../../assets/icons/services/iMac.png';
import dot1 from '../../assets/icons/services/Group1.svg';
import dot3 from '../../assets/icons/services/Group3.svg';

const Services = () => {
  return (
    <Box w="100%" overflow="hidden">
      <Box
        px={{ base: '2rem', lg: '1rem' }}
        bgColor="#fff"
        py="10rem"
        maxW="1140px"
        m="0 auto"
      >
        <Text
          fontFamily="medium"
          color="#c4c4c4"
          lineHeight="120%"
          letterSpacing="-0.02em"
          mb=".4rem"
        >
          What we offer
        </Text>
        <Text
          lineHeight="120%"
          letterSpacing="-0.02em"
          fontFamily="regular"
          color="#444"
          fontSize="4rem"
          mb="4.8rem"
        >
          Our <br />
          Services
        </Text>
        <Flex
          direction={{ base: 'column', lg: 'row' }}
          justify={{ base: 'center' }}
        >
          <ServiceCard>
            <Phone />
            <Text fontFamily="bold" color="#808080" fontSize="2rem" my="1.2rem">
              Airtime recharge
            </Text>
            <Text
              fontFamily="medium"
              color="#808080"
              fontSize="1.6rem"
              className="desc"
            >
              We offer cross border top ups on airtime recharge on mobile
              networks in Nigeria (MTN,9Mobile,Airtel,Glo).
            </Text>
          </ServiceCard>
          <ServiceCard>
            <Data />
            <Text fontFamily="bold" color="#808080" fontSize="2rem" my="1.2rem">
              Data subscription
            </Text>
            <Text
              fontFamily="medium"
              color="#808080"
              fontSize="1.6rem"
              className="desc"
            >
              We provide cost effective data services on data bundles and top
              ups for businesses looking to provide additional value to their
              customer base.
            </Text>
          </ServiceCard>
          <ServiceCard>
            <Utility />
            <Text fontFamily="bold" color="#808080" fontSize="2rem" my="1.2rem">
              Utility bills payment
            </Text>
            <Text
              fontFamily="medium"
              color="#808080"
              fontSize="1.6rem"
              className="desc"
            >
              We offer seamless processing and payments on all utility billing
              options across the country.
            </Text>
          </ServiceCard>
        </Flex>
      </Box>

      <Box bgColor="#F8F9FB" py="5rem">
        <Box maxW="1140px" mx="auto" pos="relative" px="3rem">
          <Box
            zIndex="1"
            maxW="89.4rem"
            minH={{ base: '45rem', sm: '65rem', md: '83rem' }}
            borderRadius="9.6rem"
            bg="linear-gradient(165.86deg, #0041BE 10.06%, #9057C3 99.84%)"
            mx="auto"
            p={{ base: '4.8rem 4rem 0', md: '4.8rem 7rem 0' }}
            pos="relative"
          >
            <Image
              src={dot1}
              pos="absolute"
              top="-2rem"
              right="-5rem"
              display={{ base: 'none', md: 'block' }}
            />
            <Image
              src={dot1}
              pos="absolute"
              bottom="-5rem"
              left="-5rem"
              display={{ base: 'none', md: 'block' }}
            />
            <Text
              as="h3"
              lineHeight="120%"
              letterSpacing="-0.02em"
              fontFamily="bold"
              color="#fff"
              fontSize={{ base: '1.8rem', sm: '3rem', md: '4.8rem' }}
              textAlign="center"
            >
              Awake allows you to <br />
              make bulk orders on <br />
              utilities
              <Text as="span" color="#fcb700" ml="10px">
                without <br />
                Stress.
              </Text>
            </Text>
            <Text
              m="1.6rem 0 3.6rem"
              textAlign="center"
              color="rgba(255, 255, 255, 0.8)"
              lineHeight="150%"
              letterSpacing="-0.02em"
              fontFamily="medium"
            >
              Become one of our merchants today and start earning big with us
            </Text>
            <Flex justify="center" align="center">
              <Button
                as="a"
                href="/merchant/signup"
                background="transparent"
                color="#fff"
                lineHeight="140%"
                letterSpacing="-0.01em"
                fontFamily="bold"
                border="1.5px solid #FDB600"
                borderRadius="4px"
                p="1rem 5rem"
                fontSize="1.6rem"
                h="4.2rem"
                _hover={{ background: '#FDB600' }}
              >
                Sign up
              </Button>
            </Flex>
            <Box
              pt="3.9rem"
              pos="absolute"
              bottom={{ base: '-5rem', md: '-10rem' }}
              left="50%"
              transform="translateX(-50%)"
              mx="auto"
              w={{ base: '99%', md: '95%', lg: '80%' }}
            >
              <Image
                display={{ base: 'none', md: 'block' }}
                pos="absolute"
                top="2%"
                right="10%"
                src={dot3}
              />
              <Image src={IMac} zIndex="2" w="100%" maxW="100%" />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export { Services };

const ServiceCard = styled(Box)`
  flex: 1;
  margin: 2rem 1rem;
  padding: 4.3rem 1.6rem;
  border: 1px solid #e4e4e4;
  width: 100%;
  border-radius: 8rem;
  border-top-right-radius: 0;
  transition: 0.3s;

  @media (max-width: 480px) {
    margin: 2rem 0;
  }

  &:hover {
    background-color: #0041be;
    box-shadow: 0px 6px 15px rgba(0, 65, 190, 0.3);
    transition: 0.3s;
    p {
      color: #fff;
    }
    .desc {
      color: rgba(255, 255, 255, 0.7);
    }
    svg {
      path {
        fill: #fff;
      }
    }
  }
`;
