import React from 'react';
import styled from '@emotion/styled';
import { Doughnut, defaults } from 'react-chartjs-2';
import { Box, Text, Flex, HStack, SimpleGrid } from '@chakra-ui/react';

import { SolidBtn } from '../../common/buttons';
import { nFormatter } from '../../../utils';
import { ReactComponent as FallbackIcon } from '../../../assets/icons/fallback.svg';

const NetworkCharts = ({
  networkRequested,
  handleOpen,
  handleClose,
  status,
}) => {
  defaults.global.legend = false;

  const capitalize = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  const donutLabels = (data) => {
    const colors = [];
    const labels = [];
    data.map((e) => {
      labels.push(capitalize(e.product_name || ''));
      if (e.product_name === 'glo') {
        colors.push('#2EAE65');
      } else if (e.product_name === 'mtn') {
        colors.push('#FDB600');
      } else if (e.product_name === 'airtel') {
        colors.push('#FB3640');
      } else {
        colors.push('#000');
      }
    });
    return { labels, colors };
  };

  const chartLabelAndColor = donutLabels(networkRequested || []);

  const donutDetails = networkRequested.map((e) => {
    return Number(e.sum);
  });

  const networkData = {
    labels: chartLabelAndColor.labels,
    datasets: [
      {
        backgroundColor: chartLabelAndColor.colors,
        data: donutDetails,
      },
    ],
  };

  const arrayMax = (arr) => {
    return arr.reduce((p, v) => {
      return Number(p.sum) > Number(v.sum) ? p : v;
    });
  };

  const topProvider = networkRequested.length > 0 && arrayMax(networkRequested);

  return (
    <>
      <Box
        pt="3.2rem"
        pl="3.2rem"
        pr="3.2rem"
        w="47%"
        h="54.3rem"
        boxShadow="0px 0px 8px rgba(0, 0, 0, 0.1)"
      >
        <Box d="flex" alignItems="center" justifyContent="space-between">
          <Text
            color="#212D43"
            fontSize="2rem"
            fontFamily="medium"
            lineHeight="2.4rem"
          >
            Top network service requested
          </Text>
          <Flex w="12.1rem" bg="#F8F9FB">
            <SolidBtn
              h="100%"
              backgroundColor={!status ? 'brand.blue' : 'transparent'}
              color={!status ? '#fff' : '#939AA3'}
              fontSize="1.4rem"
              fontFamily="medium"
              py="1rem"
              onClick={handleClose}
            >
              Airtime
            </SolidBtn>
            <SolidBtn
              h="100%"
              backgroundColor={status ? 'brand.blue' : 'transparent'}
              color={status ? '#fff' : '#939AA3'}
              fontSize="1.4rem"
              fontFamily="medium"
              py="1rem"
              onClick={handleOpen}
            >
              Data
            </SolidBtn>
          </Flex>
        </Box>
        <Box>
          {networkRequested.length < 1 ? (
            <Box>
              <Flex align="center" justify="center" mt="4rem">
                <FallbackIcon />
              </Flex>
              <Text fontSize="1.6rem" textAlign="center" fontFamily="medium">
                No data top network service requested
              </Text>
            </Box>
          ) : (
            <Box d="flex">
              <DoughnutDiv>
                <Doughnut
                  data={networkData}
                  options={{ maintainAspectRatio: false, cutoutPercentage: 90 }}
                />
                <DoughnutText
                  w="11rem"
                  position="absolute"
                  top="-56%"
                  right="0%"
                  left="0%"
                  textAlign="center"
                >
                  <Text
                    color="brand.blue"
                    fontFamily="medium"
                    fontSize="1.8rem"
                    lineHeight="1.7rem"
                    mb="0.7rem"
                    textAlign="center"
                    textTransform="capitalize"
                  >
                    <Text>{topProvider.product_name}</Text>
                  </Text>
                  <Text
                    color="#939AA3"
                    fontSize="1.6rem"
                    fontFamily="medium"
                    lineHeight="1.7rem"
                    textAlign="center"
                  >
                    Top provider
                  </Text>
                </DoughnutText>
              </DoughnutDiv>
              <AmountDiv>
                <SimpleGrid columns={2} spacing={10}>
                  {networkRequested.map((network, index) => (
                    <HStack key={index + 1} mr="3rem">
                      <Box>
                        <Text
                          color="#808080"
                          fontFamily="bold"
                          fontSize="2rem"
                          lineHeight="2.4rem"
                        >
                          {nFormatter(network.sum)}
                        </Text>
                        <Text
                          color="#939AA3"
                          fontSize="1.3rem"
                          lineHeight="1.6rem"
                          fontFamily="regular"
                          mt="0.2rem"
                        >
                          <Text mr=".5rem" as="span" textTransform="capitalize">
                            {network.product_name}
                          </Text>
                          total revenue
                        </Text>
                      </Box>
                    </HStack>
                  ))}
                </SimpleGrid>
                <Box d="flex">
                  <Box mt="6.9rem" d="flex" flexDirection="column">
                    <Flex wrap="wrap" spacing="3rem">
                      {chartLabelAndColor.colors.map((color, index) => (
                        <HStack mr="3rem" key={index + 1}>
                          <Box bg={color} w="1.3rem" h="1.3rem" />
                          <Text
                            ml="1.2rem"
                            color="#979797"
                            fontFamily="regular"
                            fontSize="1.4rem"
                            lineHeight="1.7rem"
                          >
                            {chartLabelAndColor.labels[index]}
                          </Text>
                        </HStack>
                      ))}
                    </Flex>
                  </Box>
                </Box>
              </AmountDiv>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

const DoughnutDiv = styled(Box)`
  margin-top: 12rem;
  width: 18rem;
  height: 18rem;
  @media screen and (min-width: 1441px) {
    width: 25.5rem;
    height: 25.5rem;
    margin-top: 10rem;
  }
`;
const DoughnutText = styled(Box)`
  position: relative;
  bottom: 70%;
  left: 22%;
  width: 11rem;
  @media screen and (min-width: 1441px) {
    position: relative;
    bottom: 65%;
    left: 30%;
  }
`;

const AmountDiv = styled(Box)`
  margin-top: 15rem;
  margin-left: 1rem;
  @media screen and (min-width: 1441px) {
    margin-top: 13.7rem;
    margin-left: 4rem;
  }
`;

export { NetworkCharts };
