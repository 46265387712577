import React, { useState, useRef } from 'react';
import { Box, Text, Skeleton, Flex } from '@chakra-ui/react';
import { Doughnut, defaults } from 'react-chartjs-2';
import Picker from 'react-month-picker';
import { useQuery } from 'react-query';

import { pickerMonths } from '../../../utils';
import { DatePickerTrigger } from '../../common/form';
import { getTopServiceSubscription } from '../../../queries';
import { ReactComponent as FallbackIcon } from '../../../assets/icons/fallback.svg';

const TopService = () => {
  defaults.global.legend = false;

  const pickAMonth = useRef();

  const [startDate, setStartDate] = useState({
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
  });

  const { isLoading, isError, data } = useQuery(
    ['topService', startDate],
    getTopServiceSubscription
  );

  if (isLoading || isError) {
    return <Skeleton h="50rem" w="35%" />;
  }

  const doughnutData = (() => {
    const { airtime = 0, data: dataBundle = 0 } = data;
    let content = null;

    if ((airtime || 0) > (dataBundle || 0)) {
      content = {
        title: 'Airtime',
        value: airtime,
      };
    } else {
      content = {
        title: 'Data',
        value: dataBundle,
      };
    }

    return {
      chartData: {
        labels: ['Airtime', 'Data'],
        datasets: [
          {
            backgroundColor: ['#FDB600', '#3558C7'],
            data: [Math.round(airtime), Math.round(dataBundle)],
          },
        ],
      },
      content,
    };
  })();

  return (
    <Box
      pl="4rem"
      pt="3.2rem"
      pr="4.5rem"
      h="50rem"
      w="42%"
      boxShadow="0px 0px 4px rgba(0, 0, 0, 0.15)"
    >
      <Text
        color="#212D43"
        fontFamily="medium"
        fontSize="2rem"
        lineHeight="2.4rem"
      >
        Top service subscription
      </Text>
      <Box d="flex" w="100%" justifyContent="flex-end">
        <Box>
          <Picker
            ref={pickAMonth}
            value={startDate}
            lang={pickerMonths}
            onChange={(year, month) => setStartDate({ year, month })}
          >
            <DatePickerTrigger
              value={{
                month: pickerMonths[startDate.month - 1],
                year: startDate.year,
              }}
              onClick={() => pickAMonth.current.show()}
            />
          </Picker>
        </Box>
      </Box>
      {data.airtime === 0 && data.data === 0 ? (
        <Box>
          <Flex align="center" justify="center" mt="4rem">
            <FallbackIcon />
          </Flex>
          <Text fontSize="1.6rem" textAlign="center" fontFamily="medium">
            No Data for top service subscription yet
          </Text>
        </Box>
      ) : (
        <Box d="flex">
          <Box w="23.5rem" h="23.5rem" mt="5rem">
            <Doughnut
              data={doughnutData.chartData}
              options={{ maintainAspectRatio: false, cutoutPercentage: 90 }}
            />
            <Box position="relative" bottom="60%" right="0%" left="36%">
              <Text
                color="brand.blue"
                fontFamily="regular"
                fontSize="1.4rem"
                lineHeight="1.7rem"
                ml="1rem"
              >
                {doughnutData.content.title}
              </Text>
              <Text fontFamily="bold" fontSize="3.2rem" lineHeight="3.8rem">
                {!doughnutData.content.value
                  ? '0'
                  : Math.round(doughnutData.content.value)}
                %
              </Text>
            </Box>
          </Box>
          <Box
            mt="14.4rem"
            position="relative"
            left="4rem"
            d="flex"
            flexDirection="column"
          >
            <Box d="flex" alignItems="center">
              <Box bg="#FDB600" w="1.3rem" h="1.3rem" />
              <Text
                ml="1.2rem"
                color="#979797"
                fontFamily="regular"
                fontSize="1.4rem"
                lineHeight="1.7rem"
              >
                Airtime
              </Text>
            </Box>
            <Box mt="1.6rem" d="flex" alignItems="center">
              <Box bg="brand.blue" w="1.3rem" h="1.3rem" />
              <Text
                ml="1.2rem"
                color="#979797"
                fontFamily="regular"
                fontSize="1.4rem"
                lineHeight="1.7rem"
              >
                Data
              </Text>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export { TopService };
