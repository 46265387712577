import instance from '../axios.config';

const getAllAdmins = async (key, page = 1) => {
  const { data } = await instance.get(`/admin?page=${page}`);

  return data;
};

const searchAllAdmins = async (name) => {
  const { data } = await instance.get(`/admin/search?name=${name}`);

  return data;
};

const deleteAdmin = async (id) => {
  const { data } = await instance.delete(`/admin/${id}`);

  return data;
};

export { getAllAdmins, searchAllAdmins, deleteAdmin };
