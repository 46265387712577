import { handleError } from '../../utils';

const { default: instance } = require('../axios.config');

const getProductList = async (key, page = 1) => {
  try {
    const { data } = await instance.get(`/merchant/discount?page=${page}`);

    return data.data;
  } catch (error) {
    handleError(error);
  }
};

export { getProductList };
