import React, { useState } from 'react';
import { Box, Text, Flex, FormControl, Select } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { toast } from 'react-toastify';

import { CenterModal } from '../../common/modals';
import { CustomInput } from '../../common/form';
import { SolidBtn, OutlineBtn } from '../../common/buttons';
import { adminTopup } from '../../../queries';

const TopupModal = ({ toggleRefetch, open, setOpen }) => {
  const initialState = {
    network: '',
    amount: '',
  };
  const [state, setState] = useState(initialState);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handlePrompt = () => {
    setOpen(false);
    setIsOpen(true);
  };

  const onTopUp = async (data) => {
    try {
      setIsLoading(true);
      const res = await adminTopup(state);
      toast.success(res.message);
      setIsLoading(false);
      setIsOpen(false);
      setState(initialState);
      toggleRefetch(true);
    } catch (error) {
      setIsLoading(false);
      toast.error(error.response.data.message);
    }
  };

  const disableBtn = state.amount === '' || state.network === '';

  return (
    <>
      <CenterModal
        showModal={open}
        setShowModal={setOpen}
        title="Top-up wallet"
      >
        <FormControl>
          <Select
            value={state.network}
            onChange={handleChange}
            name="network"
            h="5.5rem"
            fontFamily="regular"
            fontSize="1.4rem"
            lineHeight="1.7rem"
            placeholder="Network"
            color="#939AA3"
            bg="#F8F9FB"
            focusBorderColor="1px solid #1449f8"
          >
            <option value="mtn">Mtn</option>
            <option value="glo">Glo</option>
            <option value="airtel">Airtel</option>
            <option value="9mobile">9mobile</option>
          </Select>
        </FormControl>
        <CustomInput
          type="number"
          hideIcon
          label="Amount"
          id="amount"
          name="amount"
          value={state.amount}
          onChange={handleChange}
          style={{ margin: '1.6rem 0 3.2rem' }}
        />
        <SolidBtn isDisabled={disableBtn} onClick={handlePrompt}>
          Send request
        </SolidBtn>
      </CenterModal>
      <StyledWrapper>
        <CenterModal showModal={isOpen} setShowModal={setIsOpen}>
          <Box textAlign="center">
            <Text
              color="#212D43"
              fontFamily="bold"
              fontSize="1.8rem"
              lineHeight="2.2rem"
            >
              This request is irreversible
            </Text>
            <Text
              color="#444444"
              fontFamily="medium"
              fontSize="1.5rem"
              lineHeight="1.8rem"
              mt="1.7rem"
            >
              Are you sure to proceed with this transaction
            </Text>
          </Box>
          <Flex mt="5.8rem" w="100%" justify="center" align="center">
            <Flex w="80%" justify="space-between">
              <OutlineBtn
                borderColor="#C4C4C4"
                color="#444444"
                onClick={() => setIsOpen(false)}
                w="10.7rem"
              >
                No
              </OutlineBtn>
              <SolidBtn onClick={onTopUp} isLoading={isLoading} w="10.7rem">
                Yes
              </SolidBtn>
            </Flex>
          </Flex>
        </CenterModal>
      </StyledWrapper>
    </>
  );
};
const StyledWrapper = styled(Box)`
  .closeBtn {
    display: none;
  }
`;

export { TopupModal };
