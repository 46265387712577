import React, { useContext, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Text,
  useClipboard,
  Input,
  Link,
} from '@chakra-ui/react';
import styled from '@emotion/styled';

import { ReactComponent as CopyIcon } from '../../assets/icons/copyIcon.svg';
import { getApiKey } from '../../queries';
import { CenterModal } from '../../components/common/modals';
import { OutlineBtn, SolidBtn } from '../../components/common/buttons';
import { AuthContext } from '../../context/auth';

const ApiDocumentation = () => {
  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { hasCopied, onCopy } = useClipboard(value);

  const { authState } = useContext(AuthContext);

  const handleGetApi = async () => {
    try {
      setLoading(true);
      const { data } = await getApiKey();
      setLoading(false);
      setIsOpen(false);
      setValue(data.data);
    } catch (err) {
      setLoading(false);
      setIsOpen(false);
      throw err;
    }
  };

  return (
    <Box bgColor="#fff">
      <Text fontFamily="bold" fontSize="32px" color="#212d43" lineHeight="38px">
        API documentation
      </Text>
      <Box
        mt="5.2rem"
        boxShadow="0px 0px 4px rgba(0, 0, 0, 0.15)"
        h="70vh"
        w="100%"
        p="8.5rem 4rem"
      >
        <Text
          letterSpacing="-0.02em"
          fontFamily="regular"
          fontSize="16px"
          color="#939AA3"
          borderBottom="1px solid #F3F3F3"
          paddingBottom="3.2rem"
        >
          Welcome to Awake!
        </Text>
        <Flex mt="6.4rem">
          <Box w="48%">
            <Text
              letterSpacing="-0.02em"
              fontFamily="medium"
              lineHeight="1.7rem"
              color="#444444"
            >
              API Documentation
            </Text>
            <Text
              m="1.6rem 0 2.4rem"
              letterSpacing="-0.02em"
              fontFamily="regular"
              lineHeight="140%"
              color="#808080"
            >
              Our documentation contains the APIs required to integrate <br />{' '}
              with your platform.
            </Text>
            <Box
              letterSpacing="-0.02em"
              fontFamily="medium"
              lineHeight="140%"
              color="#444444"
              bgColor="#F8F9FB"
              border="1px solid #E4E4E4"
              borderRadius="4px"
              p="1rem 2.5rem 1rem 2.6rem"
              as={Link}
              href="https://docs.awakeinterlink.ng"
              target="__blank"
            >
              Go to Docs
            </Box>
          </Box>
          <Box w="52%">
            <Text
              letterSpacing="-0.02em"
              fontFamily="medium"
              lineHeight="1.7rem"
              color="#444444"
            >
              Your API Keys
            </Text>
            <Text
              m="1.6rem 0 2.4rem"
              letterSpacing="-0.02em"
              fontFamily="regular"
              lineHeight="140%"
              color={value !== '' ? '#f30303' : '#808080'}
            >
              Copy your personal keys immediately
            </Text>
            <Flex
              w="459px"
              h="4rem"
              border="1px solid #E4E4E4"
              borderRadius="4px"
              align="center"
            >
              <Flex
                bgColor="#F3F3F3"
                h="3.8rem"
                w="8.5rem"
                align="center"
                justify="center"
                borderRight="1px solid #E4E4E4"
              >
                <Text
                  letterSpacing="-0.02em"
                  fontFamily="medium"
                  lineHeight="1.7rem"
                  color="#444444"
                >
                  API Key
                </Text>
              </Flex>
              <Flex
                bgColor="#fff"
                h="3.8rem"
                w="30.7rem"
                align="center"
                p="0 1rem"
              >
                <Input
                  value={value}
                  fontFamily="regular"
                  border="none"
                  p="0"
                  fontSize="1.4rem"
                  isReadOnly
                  color="#444"
                />
              </Flex>
              <Button
                w="6.7rem"
                letterSpacing="-0.02em"
                fontFamily="regular"
                fontSize="1.4rem"
                lineHeight="140%"
                color="#808080"
                bgColor="#F3F3F3"
                h="3.8rem"
                borderLeft="1px solid #E4E4E4"
                borderRadius="0"
                _focus={{ boxShadow: 'none' }}
                leftIcon={<CopyIcon />}
                onClick={onCopy}
                isDisabled={!value}
              >
                {hasCopied ? 'Copied' : 'Copy'}
              </Button>
            </Flex>
            {/* <ApiSecret /> */}

            <Button
              letterSpacing="-0.02em"
              fontFamily="medium"
              fontSize="1.4rem"
              lineHeight="140%"
              color="brand.blue"
              h="4rem"
              bgColor="#fff"
              p="0 .9rem"
              border="1px solid #0041BE"
              borderRadius="4px"
              mt="2.4rem"
              _focus={{ boxShadow: 'none' }}
              onClick={() => setIsOpen(true)}
            >
              {authState.hasApiKey ? 'Regenerate Keys' : 'Generate Keys'}
            </Button>
          </Box>
        </Flex>
      </Box>
      <StyledWrapper>
        <CenterModal
          title="This action is irreversible are you sure you want to proceed?"
          showModal={isOpen}
          setShowModal={setIsOpen}
        >
          <Text color="#f00" fontFamily="regular" textAlign="center" mb="5rem">
            Remember to copy key immediately
          </Text>
          <Box d="flex" justifyContent="space-between" alignItems="center">
            <OutlineBtn
              borderColor="#939AA3"
              color="#939AA3"
              w="15rem"
              onClick={() => setIsOpen(false)}
              _hover={{ background: '#939AA3', color: '#fff' }}
            >
              No
            </OutlineBtn>
            <SolidBtn onClick={handleGetApi} isLoading={loading} w="15rem">
              Yes
            </SolidBtn>
          </Box>
        </CenterModal>
      </StyledWrapper>
    </Box>
  );
};

const StyledWrapper = styled(Box)`
  .closeBtn {
    display: none;
  }
`;

export { ApiDocumentation };
