import { handleError } from '../../utils';
import instance from '../axios.config';

const getSingleMerchantDiscount = async (key, id, page = 1) => {
  try {
    const { data } = await instance.get(
      `/admin/merchant/${id}/discount?page=${page}`
    );

    return data.data;
  } catch (error) {
    handleError(error);
  }
};

const getAllFlatDiscounts = async (key, page = 1) => {
  try {
    const { data } = await instance.get(`/admin/product?page=${page}`);

    return data.data;
  } catch (error) {
    handleError(error);
  }
};

const editSingleMerchantDiscount = async (body) => {
  try {
    if (body.status === 'no') {
      const { data } = await instance.post(
        `/admin/merchant/${body.id}/discount`,
        body.data
      );
      return data;
    } else {
      const { data } = await instance.patch(
        `/admin/discount/${body.discountId}`,
        {
          flatDiscount: body.data.flatDiscount,
        }
      );
      return data;
    }
  } catch (error) {
    handleError(error);
  }
};

const editDiscount = async (id, body) => {
  try {
    const { data } = await instance.patch(`admin/product/${id}`, body);

    return data.data;
  } catch (error) {
    handleError(error);
  }
};

export {
  getSingleMerchantDiscount,
  editSingleMerchantDiscount,
  getAllFlatDiscounts,
  editDiscount,
};
