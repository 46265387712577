import React, { useEffect, useState, useContext } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { ReactComponent as ResetIcon3 } from '../../assets/icons/reset3.svg';
import { ReactComponent as ResetIcon4 } from '../../assets/icons/reset4.svg';
import { ReactComponent as ShowPassword } from '../../assets/icons/show.svg';
import { LockIcon } from '../../components/login';
import { CustomInput } from '../../components/common/form/Input';
import { SolidBtn } from '../../components/common/buttons';
import { Link, useLocation } from 'react-router-dom';
import { merchantResetPassword } from '../../queries';
import { toast } from 'react-toastify';
import queryString from 'query-string';
import jwtDecode from 'jwt-decode';
import { LinkExpired } from '../../components/auth';
import { AuthContext } from '../../context/auth';

const ResetPassword = () => {
  const [isSubmit, setIsSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [isExpired, setIsExpired] = useState(false);
  const [passwordInputType, setpasswordInputType] = useState('password');

  const { authState } = useContext(AuthContext);

  useEffect(() => {
    if (authState?.isVerified) {
      history.push('/merchant/login');
    }
  }, []);

  const location = useLocation();
  const { token } = queryString.parse(location.search);

  const handleSubmit = async () => {
    if (!isSubmit) {
      try {
        if (!password) {
          toast.error('Please enter a new password');
          return;
        }

        setIsLoading(true);
        const { exp } = jwtDecode(token);

        if (Date.now() >= exp * 1000) {
          setIsExpired(true);
          return;
        }

        await merchantResetPassword({ password }, token);
        setIsLoading(false);
        setIsSubmit(true);
        toast.success('Password reset complete');
      } catch (error) {
        setIsLoading(false);
        toast.error(error.response?.data?.message || 'Failed to set password');
      }
    }
  };

  return (
    <>
      {isExpired ? (
        <LinkExpired />
      ) : (
        <Box bgColor="#F8F9FB" w="100vw" h="100vh" pt="10rem">
          <Box bgColor="#fff" w="70rem" h="50rem" m="auto" pt="88px">
            <Box w="36rem" m="auto" textAlign="center">
              <Flex
                w="7.4rem"
                h="7.4rem"
                bgColor="rgba(0, 65, 190, 0.1)"
                m="auto"
                borderRadius="50%"
                align="center"
                justify="center"
              >
                {isSubmit ? <ResetIcon4 /> : <ResetIcon3 />}
              </Flex>
              <Box mt=".8rem">
                <Text
                  fontFamily="medium"
                  fontSize="18px"
                  lineHeight="22px"
                  letterSpacing="-0.02em"
                  color="#444"
                >
                  {isSubmit ? 'Password reset successful' : 'Set new password'}
                </Text>
                <Text
                  mt="1rem"
                  fontFamily="regular"
                  lineHeight="140%"
                  color="#808080"
                  letterSpacing="-0.02em"
                  fontSize="13px"
                >
                  {isSubmit
                    ? 'You can now login into your dashboard with your new password'
                    : 'Enter your new password different from the previous one and must be strong and easy to remember '}
                </Text>
              </Box>
              <Box mt="3rem">
                {!isSubmit && (
                  <CustomInput
                    id="password"
                    label="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    css={{ margin: 0 }}
                    type={passwordInputType}
                    name="password"
                    style={{ marginTop: '2rem' }}
                    rightAddon={
                      <ShowPassword
                        onClick={() =>
                          passwordInputType === 'password'
                            ? setpasswordInputType('text')
                            : setpasswordInputType('password')
                        }
                      />
                    }
                  >
                    <LockIcon />
                  </CustomInput>
                )}
                <SolidBtn
                  mt="2rem"
                  as={isSubmit ? Link : 'button'}
                  to={isSubmit ? '/merchant/login' : ''}
                  fontFamily="medium"
                  isLoading={isLoading ? true : false}
                  onClick={handleSubmit}
                >
                  {isSubmit ? 'Login' : 'Set new password'}
                </SolidBtn>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export { ResetPassword };
