import React from 'react';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import { Box, Text, Flex } from '@chakra-ui/react';

import { ReactComponent as FallbackIcon } from '../../../assets/icons/fallback.svg';
import { CustomTable, TableWrapper } from '../../common/tables';
import { nFormatter } from '../../../utils';

const DoughnutComponent = ({ recentMerchants }) => {
  const merchants = (() => {
    return recentMerchants?.map((item) => {
      return {
        col1: item.company_name,
        col2: dayjs(item.date_approved).format('MMM D, YYYY'),
        col3: nFormatter(item.amount),
        col4: dayjs(item.date_joined).format('MMM D, YYYY'),
      };
    });
  })();

  const defaultColumns = [
    {
      Header: 'Merchant name',
      accessor: 'col1',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Date Approved',
      accessor: 'col2',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Amount',
      accessor: 'col3',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Date Joined',
      accessor: 'col4',
      disableSortBy: true,
      disableFilters: true,
    },
  ];

  return (
    <Box
      w="55%"
      pl="3.3rem"
      pr="2.4rem"
      pt="3rem"
      boxShadow="0px 0px 4px rgba(0, 0, 0, 0.15)"
      h="50rem"
    >
      <Box d="flex" alignItems="center" justifyContent="space-between">
        <Text
          color="#212D43"
          fontSize="2rem"
          lineHeight="2.4rem"
          fontFamily="medium"
        >
          Recent Approved Merchants
        </Text>
      </Box>
      <Box mt="3.7rem">
        {recentMerchants.length < 1 ? (
          <Box>
            <Flex align="center" justify="center" mt="6rem">
              <FallbackIcon />
            </Flex>
            <Text fontSize="1.6rem" textAlign="center" fontFamily="medium">
              No recent merchant yet
            </Text>
          </Box>
        ) : (
          <Box h="40rem" overflowY="scroll">
            <MyTableWrapper>
              <CustomTable data={merchants} columns={defaultColumns} />
            </MyTableWrapper>
          </Box>
        )}
      </Box>
    </Box>
  );
};

const MyTableWrapper = styled(TableWrapper)`
  thead {
    background-color: #0041be;
  }
  th {
    color: #fff;
  }
`;

export { DoughnutComponent };
