import React, { useState } from 'react';
import {
  Box,
  Fade,
  Flex,
  HStack,
  Image,
  Skeleton,
  Text,
} from '@chakra-ui/react';
import { useQuery } from 'react-query';

import { SolidBtn } from '../../common/buttons';

import ArrowUp from '../../../assets/icons/arrowup.svg';
import ArrowDown from '../../../assets/icons/arrowdown.svg';

import { getSalesRevenueStats } from '../../../queries/admin/salesRevenue';
import { nFormatter } from '../../../utils';

function RevenueStats() {
  const [statsPeriod, setStatsPeriod] = useState('month');

  const { isLoading, isError, data: revenueStats } = useQuery(
    ['revenueStats', statsPeriod],
    getSalesRevenueStats
  );

  if (isLoading || isError) {
    return <SkeletonLoader />;
  }

  return (
    <Fade in={true}>
      <Box d="flex" w="100%" justifyContent="flex-end">
        <Flex w="24.7rem" borderRadius="4px" bg="#F8F9FB">
          <SolidBtn
            h="100%"
            backgroundColor={statsPeriod === 'all' ? '#2EAE65' : 'transparent'}
            color={statsPeriod === 'all' ? '#fff' : '#939AA3'}
            fontSize="1.4rem"
            fontFamily="medium"
            py="1rem"
            onClick={() => setStatsPeriod('all')}
          >
            All
          </SolidBtn>
          <SolidBtn
            h="100%"
            backgroundColor={
              statsPeriod === 'month' ? '#2EAE65' : 'transparent'
            }
            color={statsPeriod === 'month' ? '#fff' : '#939AA3'}
            fontSize="1.4rem"
            fontFamily="medium"
            py="1rem"
            onClick={() => setStatsPeriod('month')}
          >
            Month
          </SolidBtn>
          <SolidBtn
            h="100%"
            backgroundColor={statsPeriod === 'week' ? '#2EAE65' : 'transparent'}
            color={statsPeriod === 'week' ? '#fff' : '#939AA3'}
            fontSize="1.4rem"
            fontFamily="medium"
            py="1rem"
            onClick={() => setStatsPeriod('week')}
          >
            Week
          </SolidBtn>
          <SolidBtn
            h="100%"
            backgroundColor={statsPeriod === 'day' ? '#2EAE65' : 'transparent'}
            color={statsPeriod === 'day' ? '#fff' : '#939AA3'}
            fontSize="1.4rem"
            fontFamily="medium"
            py="1rem"
            onClick={() => setStatsPeriod('day')}
          >
            Day
          </SolidBtn>
        </Flex>
      </Box>
      <Box
        d="flex"
        justifyContent="space-between"
        border="1px solid #F3F3F3"
        h="15.7rem"
        w="100%"
        maxW="100%"
        mt="3.3rem"
      >
        <Box borderLeft="1px solid rgba(0,0,0,.0)" h="10.9rem" mt="2.4rem" />
        <Box py="2.4rem">
          <Box
            maxW="7rem"
            h="2.5rem"
            borderRadius="20px"
            d="flex"
            alignItems="center"
            justifyContent="center"
            bg={
              revenueStats?.percentageIncrease?.totalData === 0
                ? '#e1e1e1'
                : revenueStats?.percentageIncrease?.totalData > 0
                ? '#d5efe0'
                : '#f9dbdb'
            }
          >
            <Text
              color={
                revenueStats?.percentageIncrease?.totalData === 0
                  ? '#000'
                  : revenueStats?.percentageIncrease?.totalData > 0
                  ? '#2EAE65'
                  : '#DC444C'
              }
              fontSize="1.3rem"
              fontFamily="regular"
              lineHeight="1.6rem"
            >
              {Math.round(revenueStats?.percentageIncrease?.totalData) || 0}%
            </Text>
            {revenueStats?.percentageIncrease?.totalData === 0 ? (
              ''
            ) : revenueStats?.percentageIncrease?.totalData > 0 ? (
              <Image src={ArrowUp} ml="0.5rem" alt="arrow icon" />
            ) : (
              <Image src={ArrowDown} ml="0.5rem" alt="arrow icon" />
            )}
          </Box>
          <Text
            mt="2rem"
            fontFamily="bold"
            fontSize="2.4rem"
            lineHeight="3.4rem"
            mb="0.4rem"
            color="#0041BE"
          >
            {nFormatter(revenueStats.counts.totalData, 2, 1000)}
          </Text>
          <Text
            color="rgba(0, 19, 80, 0.7)"
            fontSize="1.4rem"
            lineHeight="1.7rem"
            fontFamily="regular"
          >
            Total data
          </Text>
        </Box>
        <Box borderLeft="1px solid #E8EEF4" h="10.9rem" mt="2.4rem" />
        <Box py="2.4rem">
          <Box
            maxW="7rem"
            h="2.5rem"
            borderRadius="20px"
            d="flex"
            alignItems="center"
            justifyContent="center"
            bg={
              revenueStats?.percentageIncrease?.totalAirtime === 0
                ? '#e1e1e1'
                : revenueStats?.percentageIncrease?.totalAirtime > 0
                ? '#d5efe0'
                : '#f9dbdb'
            }
          >
            <Text
              color={
                revenueStats?.percentageIncrease?.totalAirtime === 0
                  ? '#000'
                  : revenueStats?.percentageIncrease?.totalAirtime > 0
                  ? '#2EAE65'
                  : '#DC444C'
              }
              fontSize="1.3rem"
              fontFamily="regular"
              lineHeight="1.6rem"
            >
              {Math.round(revenueStats?.percentageIncrease?.totalAirtime) || 0}%
            </Text>
            {revenueStats?.percentageIncrease?.totalAirtime === 0 ? (
              ''
            ) : revenueStats?.percentageIncrease?.totalAirtime > 0 ? (
              <Image src={ArrowUp} ml="0.5rem" alt="arrow icon" />
            ) : (
              <Image src={ArrowDown} ml="0.5rem" alt="arrow icon" />
            )}
          </Box>
          <Text
            mt="2rem"
            fontFamily="bold"
            fontSize="2.4rem"
            lineHeight="3.4rem"
            mb="0.4rem"
            color="#fbd600"
          >
            {nFormatter(revenueStats.counts.totalAirtime, 2, 1000)}
          </Text>
          <Text
            color="rgba(0, 19, 80, 0.7)"
            fontSize="1.4rem"
            lineHeight="1.7rem"
            fontFamily="regular"
          >
            Total airtime
          </Text>
        </Box>
        <Box borderLeft="1px solid #E8EEF4" h="10.9rem" mt="2.4rem" />
        <Box py="2.4rem">
          <Text color="#808080" fontFamily="medium">
            Mtn
          </Text>
          <HStack spacing="3rem">
            <Box>
              <Text
                mt="2rem"
                fontFamily="medium"
                fontSize="2.4rem"
                lineHeight="3.4rem"
                mb="0.4rem"
              >
                {nFormatter(
                  revenueStats.counts.allNetworkTotal.mtnDataTotal,
                  2,
                  1000
                )}
              </Text>
              <Text
                color="#939AA3"
                fontSize="1.4rem"
                lineHeight="1.7rem"
                fontFamily="regular"
              >
                Data
              </Text>
            </Box>
            <Box>
              <Text
                mt="2rem"
                fontFamily="medium"
                fontSize="2.4rem"
                lineHeight="3.4rem"
                mb="0.4rem"
              >
                {nFormatter(
                  revenueStats.counts.allNetworkTotal.mtnAirtimeTotal,
                  2,
                  1000
                )}
              </Text>
              <Text
                color="#939AA3"
                fontSize="1.4rem"
                lineHeight="1.7rem"
                fontFamily="regular"
              >
                Airtime
              </Text>
            </Box>
          </HStack>
        </Box>
        <Box borderLeft="1px solid #E8EEF4" h="10.9rem" mt="2.4rem" />
        <Box py="2.4rem">
          <Text color="#808080" fontFamily="medium">
            Airtel
          </Text>
          <HStack spacing="3rem">
            <Box>
              <Text
                mt="2rem"
                fontFamily="medium"
                fontSize="2.4rem"
                lineHeight="3.4rem"
                mb="0.4rem"
              >
                {nFormatter(
                  revenueStats.counts.allNetworkTotal.airtelData,
                  2,
                  1000
                )}
              </Text>
              <Text
                color="#939AA3"
                fontSize="1.4rem"
                lineHeight="1.7rem"
                fontFamily="regular"
              >
                Data
              </Text>
            </Box>
            <Box>
              <Text
                mt="2rem"
                fontFamily="medium"
                fontSize="2.4rem"
                lineHeight="3.4rem"
                mb="0.4rem"
              >
                {nFormatter(
                  revenueStats.counts.allNetworkTotal.airtelAirtime,
                  2,
                  1000
                )}
              </Text>
              <Text
                color="#939AA3"
                fontSize="1.4rem"
                lineHeight="1.7rem"
                fontFamily="regular"
              >
                Airtime
              </Text>
            </Box>
          </HStack>
        </Box>
        <Box borderLeft="1px solid #E8EEF4" h="10.9rem" mt="2.4rem" />
        <Box py="2.4rem">
          <Text color="#808080" fontFamily="medium">
            Etisalat
          </Text>
          <HStack spacing="3rem">
            <Box>
              <Text
                mt="2rem"
                fontFamily="medium"
                fontSize="2.4rem"
                lineHeight="3.4rem"
                mb="0.4rem"
              >
                {nFormatter(
                  revenueStats.counts.allNetworkTotal.etisalatData,
                  2,
                  1000
                )}
              </Text>
              <Text
                color="#939AA3"
                fontSize="1.4rem"
                lineHeight="1.7rem"
                fontFamily="regular"
              >
                Data
              </Text>
            </Box>
            <Box>
              <Text
                mt="2rem"
                fontFamily="medium"
                fontSize="2.4rem"
                lineHeight="3.4rem"
                mb="0.4rem"
              >
                {nFormatter(
                  revenueStats.counts.allNetworkTotal.etisalatAirtime,
                  2,
                  1000
                )}
              </Text>
              <Text
                color="#939AA3"
                fontSize="1.4rem"
                lineHeight="1.7rem"
                fontFamily="regular"
              >
                Airtime
              </Text>
            </Box>
          </HStack>
        </Box>
        <Box borderLeft="1px solid #E8EEF4" h="10.9rem" mt="2.4rem" />
        <Box py="2.4rem">
          <Text color="#808080" fontFamily="medium">
            Glo
          </Text>
          <HStack spacing="3rem">
            <Box>
              <Text
                mt="2rem"
                fontFamily="medium"
                fontSize="2.4rem"
                lineHeight="3.4rem"
                mb="0.4rem"
              >
                {nFormatter(
                  revenueStats.counts.allNetworkTotal.gloDataTotal,
                  2,
                  1000
                )}
              </Text>
              <Text
                color="#939AA3"
                fontSize="1.4rem"
                lineHeight="1.7rem"
                fontFamily="regular"
              >
                Data
              </Text>
            </Box>
            <Box>
              <Text
                mt="2rem"
                fontFamily="medium"
                fontSize="2.4rem"
                lineHeight="3.4rem"
                mb="0.4rem"
              >
                {nFormatter(
                  revenueStats.counts.allNetworkTotal.gloAirtimeTotal,
                  2,
                  1000
                )}
              </Text>
              <Text
                color="#939AA3"
                fontSize="1.4rem"
                lineHeight="1.7rem"
                fontFamily="regular"
              >
                Airtime
              </Text>
            </Box>
          </HStack>
        </Box>
        <Box borderLeft="1px solid rgba(0,0,0,.0)" h="10.9rem" mt="2.4rem" />
      </Box>
    </Fade>
  );
}

export { RevenueStats };

function SkeletonLoader() {
  return (
    <>
      <Box d="flex" w="100%" justifyContent="flex-end">
        <HStack h="30px" spacing="1rem" w="24.7rem" borderRadius="4px">
          <Skeleton flex="1" h="100%" />
          <Skeleton flex="1" h="100%" />
          <Skeleton flex="1" h="100%" />
          <Skeleton flex="1" h="100%" />
        </HStack>
      </Box>

      <Box
        d="flex"
        justifyContent="space-between"
        border="1px solid #F3F3F3"
        h="15.7rem"
        w="100%"
        mt="3.3rem"
      >
        <Box borderLeft="1px solid rgba(0,0,0,.0)" h="10.9rem" mt="2.4rem" />
        <Box px="2rem" py="2.4rem" flex="1">
          <Skeleton
            w="7rem"
            h="2.5rem"
            borderRadius="20px"
            d="flex"
            alignItems="center"
            justifyContent="center"
          />
          <Skeleton mt="2rem" mb="1rem" w="100%" h="4rem" />
          <Skeleton h="2rem" />
        </Box>
        <Box borderLeft="1px solid rgba(0,0,0,.0)" h="10.9rem" mt="2.4rem" />
        <Box px="2rem" py="2.4rem" flex="1">
          <Skeleton
            w="7rem"
            h="2.5rem"
            borderRadius="20px"
            d="flex"
            alignItems="center"
            justifyContent="center"
          />
          <Skeleton mt="2rem" mb="1rem" w="100%" h="4rem" />
          <Skeleton h="2rem" />
        </Box>
        <Box borderLeft="1px solid rgba(0,0,0,.0)" h="10.9rem" mt="2.4rem" />
        <Box px="2rem" py="2.4rem" flex="1">
          <Skeleton
            w="7rem"
            h="2.5rem"
            borderRadius="20px"
            d="flex"
            alignItems="center"
            justifyContent="center"
          />
          <Skeleton mt="2rem" mb="1rem" w="100%" h="4rem" />
          <Skeleton h="2rem" />
        </Box>
        <Box borderLeft="1px solid rgba(0,0,0,.0)" h="10.9rem" mt="2.4rem" />
        <Box px="2rem" py="2.4rem" flex="1">
          <Skeleton
            w="7rem"
            h="2.5rem"
            borderRadius="20px"
            d="flex"
            alignItems="center"
            justifyContent="center"
          />
          <Skeleton mt="2rem" mb="1rem" w="100%" h="4rem" />
          <Skeleton h="2rem" />
        </Box>
        <Box borderLeft="1px solid rgba(0,0,0,.0)" h="10.9rem" mt="2.4rem" />
        <Box px="2rem" py="2.4rem" flex="1">
          <Skeleton
            w="7rem"
            h="2.5rem"
            borderRadius="20px"
            d="flex"
            alignItems="center"
            justifyContent="center"
          />
          <Skeleton mt="2rem" mb="1rem" w="100%" h="4rem" />
          <Skeleton h="2rem" />
        </Box>
        <Box borderLeft="1px solid rgba(0,0,0,.0)" h="10.9rem" mt="2.4rem" />
        <Box px="2rem" py="2.4rem" flex="1">
          <Skeleton
            w="7rem"
            h="2.5rem"
            borderRadius="20px"
            d="flex"
            alignItems="center"
            justifyContent="center"
          />
          <Skeleton mt="2rem" mb="1rem" w="100%" h="4rem" />
          <Skeleton h="2rem" />
        </Box>
      </Box>
    </>
  );
}
