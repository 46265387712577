import React from 'react';
import {
  Box,
  Flex,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
} from '@chakra-ui/react';
import styled from '@emotion/styled';

import { ReactComponent as OpenIcon } from '../../../assets/icons/accordion.svg';
import { ReactComponent as ClosedIcon } from '../../../assets/icons/closedacc.svg';
import { RightModal } from '../../../components/common/modals';

function BankDetailsModal({ showRightModal, setShowRightModal, bankDetails }) {
  return (
    <RightModal
      showModal={showRightModal}
      setShowModal={setShowRightModal}
      p="0"
    >
      <Box pt="2.4rem" pl="3.9rem" pr="14.6rem">
        <Text
          fontFamily="bold"
          color="brand.blue"
          fontSize="3.2rem"
          lineHeight="3.8rem"
        >
          Bank details
        </Text>
        <Text
          color="#808080"
          fontFamily="medium"
          fontSize="1.4rem"
          lineHeight="2rem"
          mt="2.4rem"
        >
          Choose from any of the banks and the deposit the amount you wish to
          fund your wallet it.
        </Text>
      </Box>
      <Box mt="3rem">
        <Accordion border="1px solid white" allowToggle>
          {bankDetails?.map((acc) => (
            <AccordionItem key={acc.id}>
              {({ isExpanded }) => (
                <>
                  <AccordionButton
                    _focus={{ boxShadow: 'none' }}
                    _hover={{ bg: 'white' }}
                  >
                    <Box pt="2.2rem" pb="1.6rem" flex="1" textAlign="left">
                      <Text
                        color="#212D43"
                        fontFamily="medium"
                        fontSize="2rem"
                        lineHeight="2.4rem"
                        pl="3rem"
                        textTransform="capitalize"
                      >
                        {acc.bank_name}
                      </Text>
                    </Box>
                    {isExpanded ? (
                      <Box pr="5rem">
                        <OpenIcon />
                      </Box>
                    ) : (
                      <Box pr="5rem">
                        <ClosedIcon />
                      </Box>
                    )}
                  </AccordionButton>
                  <AccordionPanel pb={4}>
                    <Box borderBottom="1px solid #E4E4E4" />
                    <Box pl="3.1rem">
                      <Flex mt="2.2rem" align="center">
                        <Text
                          color="#af8989"
                          fontFamily="regular"
                          fontSize="1.4rem"
                          lineHeight="140.8%"
                        >
                          Account number:
                        </Text>
                        <Text
                          fontFamily="medium"
                          fontSize="1.4rem"
                          lineHeight="2rem"
                          ml="3.2rem"
                        >
                          {acc.account_number}
                        </Text>
                      </Flex>
                      <Flex mt="1.6rem" mb="1.6rem" align="center">
                        <Text
                          color="#af8989"
                          fontFamily="regular"
                          fontSize="1.4rem"
                          lineHeight="140.8%"
                        >
                          Account name:
                        </Text>
                        <Text
                          fontFamily="medium"
                          fontSize="1.4rem"
                          lineHeight="2rem"
                          ml="4.6rem"
                        >
                          {acc.account_name}
                        </Text>
                      </Flex>
                    </Box>
                  </AccordionPanel>
                  <Box borderBottom="1px solid #E4E4E4" />
                </>
              )}
            </AccordionItem>
          ))}
        </Accordion>
        <StyleBox>
          <Text
            fontFamily="bold"
            fontSize="1.4rem"
            lineHeight="140.8%"
            color="#939AA3"
            mb=".8rem"
          >
            NOTE:
          </Text>
          <Text fontSize="1.4rem" fontFamily="medium" color="#444444">
            Write Awake ltd on the deposit slip
          </Text>
          <Text
            color="#939AA3"
            fontSize="1.4rem"
            fontFamily="medium"
            lineHeight="180%"
          >
            Depositors Name:
            <Text
              as="span"
              fontSize="1.4rem"
              fontFamily="medium"
              lineHeight="180%"
              color="#444444"
              ml=".5rem"
            >
              Awake login email, eg Emma@email.com
            </Text>
          </Text>
          <Text
            color="#939AA3"
            fontSize="1.4rem"
            fontFamily="medium"
            lineHeight="180%"
          >
            Phone number:
            <Text
              as="span"
              fontSize="1.4rem"
              fontFamily="medium"
              lineHeight="180%"
              color="#444444"
              ml=".5rem"
            >
              Your registered phone number with awake
            </Text>
          </Text>
          <Text
            color="#939AA3"
            fontSize="1.4rem"
            fontFamily="medium"
            lineHeight="180%"
          >
            Amount:
            <Text
              as="span"
              fontSize="1.4rem"
              fontFamily="medium"
              lineHeight="180%"
              color="#444444"
              ml=".5rem"
            >
              How much you are funding your wallet with. E.g ₦200,000
            </Text>
          </Text>
          <Text mt="2rem" fontSize="1.4rem" fontFamily="medium" color="#E24D4D">
            Mobile money transfer should not be done to the account number above
          </Text>
        </StyleBox>
      </Box>
    </RightModal>
  );
}

export { BankDetailsModal };

const StyleBox = styled(Box)`
  margin-top: 3rem;
  padding-left: 3.9rem;

  @media screen and (min-width: 1441px) {
    margin-top: 5rem;
  }
`;
