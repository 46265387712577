import React from 'react';
import { Box, Flex, HStack, Skeleton, Text, VStack } from '@chakra-ui/react';

export function ChartSkeletonLoader({ title }) {
  return (
    <Box
      pr="4.4rem"
      pl="8.6rem"
      pt="3.2rem"
      pb="5rem"
      mt="4rem"
      w="100%"
      boxShadow="0px 0px 4px rgba(0, 0, 0, 0.15)"
    >
      <Flex align="center" justify="space-between">
        <Text
          color="#808080"
          fontFamily="medium"
          fontSize="2rem"
          lineHeight="2.4rem"
        >
          {title}
        </Text>
        <HStack h="30px" spacing="1rem" w="24.7rem" borderRadius="4px">
          <Skeleton flex="1" h="100%" />
          <Skeleton flex="1" h="100%" />
          <Skeleton flex="1" h="100%" />
          <Skeleton flex="1" h="100%" />
        </HStack>
      </Flex>
      <Skeleton h="32.4rem" mt="8.8rem" />
    </Box>
  );
}

export function TableSkeleton({ title }) {
  return (
    <Box
      p="4rem"
      mt="4rem"
      w="100%"
      maxW="100%"
      overflowX="scroll"
      boxShadow="0px 0px 4px rgba(0, 0, 0, 0.15)"
      bg="#fff"
    >
      <Box w="100%">
        <Text color="#626363" fontSize="3.2rem" fontFamily="medium" mb="4rem">
          {title}
        </Text>
      </Box>
      <Box w="332px" ml="auto" mb="5rem" />
      <VStack spacing="1rem">
        <Skeleton w="100%" h="5rem" />
        <Skeleton w="100%" h="5rem" />
        <Skeleton w="100%" h="5rem" />
        <Skeleton w="100%" h="5rem" />
        <Skeleton w="100%" h="5rem" />
        <Skeleton w="100%" h="5rem" />
        <Skeleton w="100%" h="5rem" />
        <Skeleton w="100%" h="5rem" />
        <Skeleton w="100%" h="5rem" />
        <Skeleton w="100%" h="5rem" />
      </VStack>
    </Box>
  );
}
