import React, { useContext, useState } from 'react';
import { Box, Flex, Text, Link as CLink, HStack } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import { CustomInput } from '../../components/common/form';
import { OutlineBtn, SolidBtn } from '../../components/common/buttons';
import { updateMerchantProfile, setWalletMinimumAmount } from '../../queries';
import { toast } from 'react-toastify';
import { AuthContext, UPDATE } from '../../context/auth';
import { removeEmpty } from '../../utils';

function Settings() {
  const { authState, dispatch } = useContext(AuthContext);

  const [state, setState] = useState({
    first_name: authState.first_name,
    last_name: authState.last_name,
    address: authState?.address || '',
    phone_number: authState?.phoneNumber || '',
  });

  const [disabled, setDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [minimumLoading, setMinimumLoading] = useState(false);
  const [amount, setAmount] = useState(authState.minWalletBal);

  const enable = () => setDisabled(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);

      const cleaned = removeEmpty(state);

      const res = await updateMerchantProfile(cleaned);

      dispatch({
        type: UPDATE,
        payload: {
          first_name: state.first_name,
          last_name: state.last_name,
          address: state.address,
          phoneNumber: state.phone_number,
        },
      });

      setIsLoading(false);
      toast.success(res.message);
      setDisabled(true);
    } catch (err) {
      setIsLoading(false);
      toast.error(err.response.data.message);
    }
  };

  const handleAmountChange = (e) => {
    const { value } = e.target;
    setAmount(() => value);
  };

  const handleSetMinimum = async () => {
    if (!amount || amount < 1) {
      toast.error('Negetive amount not allowed');
      return;
    }

    const data = {
      amount: +amount,
    };
    try {
      setMinimumLoading(true);
      const res = await setWalletMinimumAmount(data);
      dispatch({
        type: UPDATE,
        payload: {
          minWalletBal: amount,
        },
      });

      toast.success(res.message);
      setAmount('');
      setMinimumLoading(false);
    } catch (err) {
      setMinimumLoading(false);
      toast.error(err?.response?.data?.message);
    }
  };

  return (
    <Box w="100%" h="100%">
      <Text color="#212D43" fontFamily="bold" fontSize="3.2rem">
        Settings
      </Text>
      <Flex
        bg="#fff"
        boxShadow="0px 0px 4px rgba(0, 0, 0, 0.15)"
        w="100%"
        my="5.2rem"
      >
        <Box borderRight="1px solid #F3F3F3" minW="265px" pt="65px">
          <SideLink
            d="flex"
            pl="2.6rem"
            py="7px"
            alignItems="center"
            _hover={{
              textDecoration: 'none',
              background: '#f6f7fa',
            }}
          >
            <Box ml="2.2rem">
              <Text
                fontFamily="medium"
                color="#444444"
                fontSize="2.4rem"
                className="main-text"
              >
                General settings
              </Text>
              <Text fontFamily="regular" color="#939AA3" fontSize="1.3rem">
                Update your profile
              </Text>
            </Box>
          </SideLink>
        </Box>
        <Box flex="1" p="4rem" pt="80px" mb="10rem">
          <Flex align="center" justify="space-between" maxW="772px">
            <Text color="#444" fontFamily="medium" fontSize="1.4rem">
              Update your profile here
            </Text>
            <OutlineBtn onClick={enable} border="none" w="120px">
              <EditIcon />
              <Text as="span" pl=".8rem">
                Edit profile
              </Text>
            </OutlineBtn>
          </Flex>
          <Box mt="4rem">
            <HStack spacing="4rem">
              <CustomInput
                hideIcon
                value={state.first_name}
                name="first_name"
                onChange={handleChange}
                label="First name"
                placeholder=""
                w="360px"
                style={{ marginTop: 0 }}
                isDisabled={disabled}
              />
              <CustomInput
                hideIcon
                value={state.last_name}
                onChange={handleChange}
                name="last_name"
                label="Last name"
                placeholder=""
                w="360px"
                style={{ marginTop: 0 }}
                isDisabled={disabled}
              />
            </HStack>
            <HStack spacing="4rem" mt="2rem">
              <CustomInput
                hideIcon
                label="Email"
                value={authState.email}
                onChange={handleChange}
                name="email"
                placeholder=""
                w="360px"
                style={{ marginTop: 0 }}
                isDisabled={true}
              />
              <CustomInput
                hideIcon
                value={state.address}
                onChange={handleChange}
                name="address"
                label="Address"
                placeholder=""
                w="360px"
                style={{ marginTop: 0 }}
                isDisabled={disabled}
              />
            </HStack>
            <HStack spacing="4rem" mt="2rem">
              <CustomInput
                hideIcon
                type="number"
                value={state.phone_number}
                onChange={handleChange}
                name="phone_number"
                label="Phone number"
                placeholder=""
                w="360px"
                style={{ marginTop: 0 }}
                isDisabled={disabled}
              />
            </HStack>
            <SolidBtn
              isDisabled={disabled}
              onClick={handleSubmit}
              mt="5.5rem"
              w="160px"
              isLoading={isLoading}
            >
              UPDATE
            </SolidBtn>
          </Box>
          <Box w="755px" h="1px" bg="#f3f3f3" my="4rem" />
          <HStack spacing="4rem" align="flex-start">
            <Box w="360px" pr="4rem">
              <Text color="#444" fontFamily="medium" fontSize="2.4rem">
                Set wallet minimum amount
              </Text>
              <Text
                color="#939AA3"
                fontFamily="regular"
                fontSize="1.3rem"
                mt="1rem"
              >
                You can set the minimum amount to have in your wallet and get
                notification when the amount is reached for you to top-up your
                wallet.
              </Text>
            </Box>
            <Box w="360px">
              <HStack spacing="1.6rem">
                <CustomInput
                  hideIcon
                  type="number"
                  name="amount"
                  label="Set amount"
                  placeholder=""
                  w="270px"
                  value={amount}
                  onChange={handleAmountChange}
                  style={{ marginTop: 0 }}
                />
                <SolidBtn
                  onClick={handleSetMinimum}
                  isDisabled={amount?.length <= 0}
                  isLoading={minimumLoading}
                  w="103px"
                >
                  Set
                </SolidBtn>
              </HStack>
            </Box>
          </HStack>
        </Box>
      </Flex>
    </Box>
  );
}

export { Settings };

const SideLink = styled(CLink)`
  padding-right: 2rem;

  svg {
    fill: #808080;
  }

  &:hover,
  &.selected {
    border-right: 2px solid #0041be;
    background: #f6f7fa;

    .main-text {
      color: #0041be;
    }

    svg {
      fill: #0041be;
    }
  }
`;
