import React, { useState } from 'react';
import { Box, Text, Flex, Input, Textarea } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { RightModal } from '../../common/modals';
import { SolidBtn } from '../../common/buttons';
import { toast } from 'react-toastify';
import { createTicket } from '../../../queries';

const TicketModal = ({ isOpen, setIsOpen, refetch }) => {
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setisLoading] = useState(false);

  const submitTicket = async () => {
    if (!title || !message) {
      toast.error('Subject and message is required');
      return;
    }

    try {
      setisLoading(true);
      await createTicket({ title, message });
      setisLoading(false);
      toast.success('Ticket created successfully!');
      setTitle('');
      setMessage('');
      refetch();
      setIsOpen(false);
    } catch (err) {
      setisLoading(false);
      toast.error(err.response?.data?.message || 'Failed to create ticket');
    }
  };

  return (
    <>
      <RightModal showModal={isOpen} setShowModal={setIsOpen} p="0">
        <Box pt="6.4rem" pl="4.3rem" pr="10.5rem" mb="4.8rem">
          <Text
            fontFamily="bold"
            fontSize="3.2rem"
            lineHeight="3.8rem"
            letterSpacing="0.02em"
          >
            Compose <br /> new ticket
          </Text>
          <Text
            color="#808080"
            fontFamily="medium"
            fontSize="1.4rem"
            lineHeight="2rem"
            mt="1.6rem"
          >
            Send new complaint to Awake admin for any request or confirmation of
            payment or others.
          </Text>
        </Box>
        <Box borderTop="1px solid #E4E4E4" />
        <Flex pl="4.3rem" align="center" mt="2.2rem" mb="2.2rem">
          <Text
            color="#979797"
            fontFamily="medium"
            fontSize="1.4rem"
            lineHeight="2rem"
          >
            Subject:
          </Text>
          <Input
            ml="1.6rem"
            w="20rem"
            border="none"
            focusBorderColor="none"
            bg="white"
            name="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            fontSize="1.5rem"
            fontFamily="regular"
          />
        </Flex>
        <Box borderTop="1px solid #E4E4E4" />
        <Box pl="4rem" pr="2rem" pt="2.2rem">
          <StyleTextarea
            placeholder="Enter message"
            resize="none"
            name="message"
            value={message}
            px="0"
            fontFamily="regular"
            _focus={{ border: 'none' }}
            onChange={(e) => setMessage(e.target.value)}
          />
          <Box d="flex" w="100%" justifyContent="flex-end" mt="2rem">
            <SolidBtn
              onClick={submitTicket}
              ml="2.56rem"
              w="10.6rem"
              isLoading={isLoading}
            >
              Send
            </SolidBtn>
          </Box>
        </Box>
        <Box borderTop="1px solid #E4E4E4" mt="1.7rem" />
      </RightModal>
    </>
  );
};

const StyleTextarea = styled(Textarea)`
  height: 25.5rem;
  border: none;
  font-size: 1.4rem;

  @media screen and (min-width: 1441px) {
    height: 40.5rem;
  }
`;

export { TicketModal };
