import React, { useState } from 'react';
import styled from '@emotion/styled';
import { toast } from 'react-toastify';
import { Box, Text, Textarea, Input } from '@chakra-ui/react';

import { ReactComponent as Upload } from '../../../assets/icons/upload.svg';
import { getSingleTicket, replyTicket } from '../../../queries';
import { SolidBtn } from '../../common/buttons';

const Message = ({ message, setActiveTicket }) => {
  const [text, setText] = useState('');
  const [isSending, setIsSending] = useState(false);

  const handleFile = async (e) => {
    await sendMessage(e.target.files[0], 'file');
    e.target.value = null;
  };

  const sendMessage = async (content, type) => {
    let body;

    if (content.size > 3000000) {
      toast.error('Please upload a file smaller than 3 MB');
    } else {
      if (type === 'file') {
        const formData = new FormData();
        formData.append('message', content);
        body = formData;
      } else {
        body = content;
      }

      try {
        setIsSending(true);
        await replyTicket(body, message.id, type);
        toast.success('Message Sent');
        setText('');
        const data = await getSingleTicket(message.id);
        setActiveTicket(data);
        setIsSending(false);
      } catch (err) {
        toast.error(err.response?.data?.message || 'Failed to send message');
        setIsSending(false);
      }
    }
  };

  return (
    <>
      <Box>
        <Box p="2.4rem 3rem">
          <Text
            fontFamily="bold"
            letterSpacing="-0.02em"
            lineHeight="140%"
            color="#939AA3"
          >
            REPLY THIS TICKET
          </Text>
          <Box position="relative">
            <Textarea
              m="1.6rem 0 1.2rem"
              p="1rem"
              fontFamily="regular"
              fontSize="14px"
              resize="none"
              border="1px solid #E4E4E4"
              height="144px"
              borderRadius="0"
              name="message"
              value={text}
              onChange={(e) => setText(e.target.value)}
              _focus={{
                borderColor: 'brand.blue',
                boxShadow: '0 0 0 1px #0041BE',
              }}
              disabled={isSending}
            ></Textarea>
            <Box position="absolute" bottom="10%" right="0">
              <label htmlFor="hiddenFile">
                <Box
                  alignItems="center"
                  d="flex"
                  w="11.5rem"
                  h="3.5rem"
                  bg="#E4E4E4"
                  cursor="pointer"
                >
                  <Upload style={{ marginLeft: '1rem' }} />
                  <Text
                    color="#808080"
                    fontFamily="regular"
                    fontSize="1.2rem"
                    lineHeight="130%"
                    ml="1rem"
                  >
                    Upload files
                  </Text>
                </Box>
                <StyleInput
                  id="hiddenFile"
                  type="file"
                  onChange={handleFile}
                  disabled={isSending}
                  accept="image/*, .jpeg, .jpg, .png, .pdf"
                />
              </label>
            </Box>
          </Box>
          <Text fontFamily="regular" fontSize="1.4rem" id="labelText" />
          <SolidBtn
            fontFamily="bold"
            letterSpacing="-0.02em"
            lineHeight="19px"
            color="#fff"
            borderRadius="8px"
            w="17rem"
            h="4.2rem"
            fontSize="16px"
            bgColor="brand.blue"
            _focus={{ boxShadow: 'none' }}
            isDisabled={isSending}
            isLoading={isSending}
            onClick={() => sendMessage(text, 'text')}
          >
            SEND REPLY
          </SolidBtn>
        </Box>
      </Box>
    </>
  );
};

const StyleInput = styled(Input)`
  display: none;
  width: 100%;
`;

export { Message };
