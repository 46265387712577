import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { NavLink, useHistory, useRouteMatch } from 'react-router-dom';
import { Flex, Box, Text, keyframes } from '@chakra-ui/react';
import { ReactComponent as DropDownIcon } from '../../../../assets/icons/dropdown.svg';
import { ReactComponent as DropDownIconWhite } from '../../../../assets/icons/dropdownWhite.svg';
import { AuthContext, LOGOUT } from '../../../../context/auth';

const ExpandedLinks = ({ toggleDropdown, isDropdownOpen }) => {
  const match = useRouteMatch('/admin/applications');
  const match2 = useRouteMatch('/admin/merchant');
  const { dispatch, authState } = useContext(AuthContext);
  const history = useHistory();

  const logout = () => {
    dispatch({ type: LOGOUT, payload: '' });
    history.push('/admin/login');
  };

  return (
    <>
      <Box bg="brand.blue" w="188px">
        <Box
          mt="100px"
          borderBottom="1px solid rgba(255, 255, 255, 0.1)"
          pb="1.5rem"
        >
          <Flex h="31px" w="100%" align="center" pl="16px" mb="11px">
            <Box
              as={NavLink}
              to="/admin/overview"
              color="#C4C4C4"
              fontFamily="regular"
              _hover={{ fontFamily: 'medium', color: '#C4C4C4' }}
            >
              Overview
            </Box>
          </Flex>
          <Flex h="31px" w="100%" align="center" pl="16px" mb="11px">
            <Flex align="center">
              <Text
                as={NavLink}
                to="/admin/merchants"
                color="#C4C4C4"
                fontFamily="regular"
                _hover={{ fontFamily: 'medium', color: '#c4c4c4' }}
                id="merchant"
                className={(match || match2) && 'active'}
              >
                Merchants
              </Text>
              <Box
                ml="18px"
                cursor="pointer"
                onClick={toggleDropdown}
                transform={isDropdownOpen && 'rotate(90deg)'}
              >
                {isDropdownOpen ? <DropDownIconWhite /> : <DropDownIcon />}
              </Box>
            </Flex>
          </Flex>
          {isDropdownOpen && (
            <MotionFlex
              w="100%"
              h="21px"
              align="center"
              pl="16px"
              mb="11px"
              mt="-5px"
            >
              <Box
                as={NavLink}
                to="/admin/applications"
                color="#C4C4C4"
                fontFamily="regular"
                _hover={{ fontFamily: 'medium', color: '#c4c4c4' }}
                className="medium"
              >
                New Applications
              </Box>
            </MotionFlex>
          )}
          <Flex h="31px" w="100%" align="center" pl="16px" mb="11px">
            <Box
              as={NavLink}
              to="/admin/revenue"
              color="#C4C4C4"
              fontFamily="regular"
              _hover={{ fontFamily: 'medium', color: '#c4c4c4' }}
            >
              Sales Revenue
            </Box>
          </Flex>
          <Flex h="31px" w="100%" align="center" pl="16px" mb="11px">
            <Box
              as={NavLink}
              to="/admin/fund-request"
              color="#C4C4C4"
              fontFamily="regular"
              _hover={{ fontFamily: 'medium', color: '#c4c4c4' }}
            >
              Fund Request
            </Box>
          </Flex>
          <Flex h="31px" w="100%" align="center" pl="16px" mb="11px">
            <Box
              as={NavLink}
              to="/admin/audit-trail"
              color="#C4C4C4"
              fontFamily="regular"
              _hover={{ fontFamily: 'medium', color: '#c4c4c4' }}
            >
              Audit Trail
            </Box>
          </Flex>
          {authState.isSuperAdmin && (
            <Flex h="31px" w="100%" align="center" pl="16px" mb="11px">
              <Box
                as={NavLink}
                to="/admin/user-management"
                color="#C4C4C4"
                fontFamily="regular"
                _hover={{ fontFamily: 'medium', color: '#c4c4c4' }}
              >
                User Management
              </Box>
            </Flex>
          )}
        </Box>
        <Box mt="4.7rem">
          <Flex h="31px" w="100%" align="center" pl="16px" mb="11px">
            <Box
              as={NavLink}
              to="/admin/top-up"
              color="#C4C4C4"
              fontFamily="regular"
              _hover={{ fontFamily: 'medium', color: '#c4c4c4' }}
            >
              Top-Up
            </Box>
          </Flex>
          <Flex h="31px" w="100%" align="center" pl="16px" mb="11px">
            <Box
              as={NavLink}
              to="/admin/discount"
              color="#C4C4C4"
              fontFamily="regular"
              _hover={{ fontFamily: 'medium', color: '#c4c4c4' }}
            >
              Discount
            </Box>
          </Flex>
          <Flex h="31px" w="100%" align="center" pl="16px" mb="11px">
            <Box
              as={NavLink}
              to="/admin/ticket"
              color="#C4C4C4"
              fontFamily="regular"
              _hover={{ fontFamily: 'medium', color: '#c4c4c4' }}
            >
              Ticket
            </Box>
          </Flex>
          <Flex h="31px" w="100%" align="center" pl="16px" mb="11px">
            <Box
              as={NavLink}
              to="/admin/settings"
              color="#C4C4C4"
              fontFamily="regular"
              _hover={{ fontFamily: 'medium', color: '#c4c4c4' }}
            >
              Settings
            </Box>
          </Flex>
        </Box>
        <Box mt="150px">
          <Flex h="31px" w="100%" align="center" pl="16px" mb="11px">
            <Box
              color="#C4C4C4"
              fontFamily="regular"
              cursor="pointer"
              onClick={logout}
              _hover={{ fontFamily: 'medium', color: '#c4c4c4' }}
            >
              Logout
            </Box>
          </Flex>
        </Box>
      </Box>
    </>
  );
};

export { ExpandedLinks };

const example = keyframes`
    from {
      height: 0;
    }
    to {
      height: 21px;
    }
`;

const MotionFlex = styled(Flex)`
  animation: ${example} 0.5s;
`;
