import React, { useState, useEffect, useRef } from 'react';
import { Box, Text, Flex } from '@chakra-ui/react';
import { Bar, defaults } from 'react-chartjs-2';
import Picker from 'react-month-picker';
import { useQuery } from 'react-query';

import { ReactComponent as FallbackIcon } from '../../../assets/icons/fallback.svg';
import { ChartSkeletonLoader } from '../../common/loaders';
import { getTopBuyingMerchant } from '../../../queries';
import { nFormatter, pickerMonths } from '../../../utils';
import { DatePickerTrigger } from '../../common/form';

const TopBuying = ({ buyingMerchant }) => {
  defaults.global.legend = false;

  const pickAMonth = useRef();

  const [chartStepSIze, setChartStepSIze] = useState(0);
  const [chartData, setChartData] = useState([]);
  const [startDate, setStartDate] = useState({
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
  });

  const { isLoading, isError, data } = useQuery(
    ['getTopBuyingMerchant', startDate],
    getTopBuyingMerchant
  );

  useEffect(() => {
    const amounts = data?.map((item) => item?.amount || 0);
    const companyNames = data?.map((item) => item?.company_name || '');

    const maxAmt = Math.max(amounts);

    setChartStepSIze(maxAmt / 5);

    setChartData({
      labels: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ],
      datasets: [
        {
          categoryPercentage: 1.0,
          barThickness: 8,
          backgroundColor: '#0041BE',
          data: amounts,
        },
      ],
      companyNames,
    });
  }, [data]);

  if (isLoading || isError) {
    return <ChartSkeletonLoader title="Top buying Merchant" />;
  }

  return (
    <>
      <Box
        pr="4.4rem"
        pl="8.6rem"
        pt="3.2rem"
        pb="5rem"
        mt="4rem"
        w="100%"
        boxShadow="0px 0px 4px rgba(0, 0, 0, 0.15)"
        maxW="100%"
      >
        <Box d="flex" alignItems="center" justifyContent="space-between">
          <Text
            color="#808080"
            fontFamily="medium"
            fontSize="2rem"
            lineHeight="2.4rem"
          >
            Top buying Merchant
          </Text>
          <Box>
            <Picker
              ref={pickAMonth}
              value={startDate}
              lang={pickerMonths}
              onChange={(year, month) => setStartDate({ year, month })}
            >
              <DatePickerTrigger
                value={{
                  month: pickerMonths[startDate.month - 1],
                  year: startDate.year,
                }}
                onClick={() => pickAMonth.current.show()}
              />
            </Picker>
          </Box>
        </Box>
        {data.length < 1 ? (
          <Box>
            <Flex align="center" justify="center" mt="4rem">
              <FallbackIcon />
            </Flex>
            <Text fontSize="1.6rem" textAlign="center" fontFamily="medium">
              No Data for top buying merchant yet
            </Text>
          </Box>
        ) : (
          <Box h="32.4rem" w="98%" mt="8.8rem">
            <Bar
              data={chartData}
              options={{
                maintainAspectRatio: false,
                responsive: true,
                tooltips: {
                  callbacks: {
                    label: (tooltipItem, data) =>
                      `${data.companyNames[tooltipItem.index]} - ${nFormatter(
                        tooltipItem.yLabel
                      )}`,
                  },
                },
                scales: {
                  xAxes: [
                    {
                      gridLines: {
                        display: false,
                      },
                    },
                  ],
                  yAxes: [
                    {
                      ticks: {
                        beginAtZero: true,
                        stepSize: chartStepSIze,
                        userCallback: (value) => nFormatter(value),
                      },
                    },
                  ],
                },
              }}
            />
          </Box>
        )}
      </Box>
    </>
  );
};

export { TopBuying };
