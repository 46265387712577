import React, { useState, useEffect } from 'react';
import {
  Box,
  Text,
  Flex,
  Image,
  HStack,
  VStack,
  Textarea,
} from '@chakra-ui/react';
import { toast } from 'react-toastify';
import styled from '@emotion/styled';
import dayjs from 'dayjs';

import { CustomInput } from '../../components/common/form/Input';
import {
  CustomTable,
  SelectColumnFilter,
  TableWrapper,
} from '../../components/common/tables';
import { useQuery } from 'react-query';
import { ReactComponent as DownloadIcon } from '../../assets/icons/download.svg';
import { ReactComponent as FallbackIcon } from '../../assets/icons/fallback.svg';
import Search from '../../assets/icons/search_icon.svg';
import { ReactComponent as Export } from '../../assets/icons/Export.svg';
import { RightModal, CenterModal } from '../../components/common/modals';
import { OutlineBtn, SolidBtn } from '../../components/common/buttons';
import {
  updateFundRequest,
  getAllFundRequest,
  searchFundRequest,
} from '../../queries';
import { SpinningText } from '../../components/common/loaders';
import { ExportFundRequest } from '../../components/export';
import { nFormatter } from '../../utils';
import { ErrorOccured } from '../../components/common/errors';

const FundRequest = () => {
  const [page, setPage] = useState(1);
  const [showDetails, setShowDetails] = useState(false);
  const [showDeclineModal, setShowDeclineModal] = useState(false);
  const [currentRequest, setCurrentRequest] = useState(null);
  const [reason, setReason] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [name, setName] = useState('');
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showSearch, setShowSearch] = useState(false);

  const { isLoading: dataLoading, data, isError, refetch } = useQuery(
    ['getAllFundRequest', page],
    getAllFundRequest
  );

  useEffect(() => {
    refetch();
    setShowSearch(false);
  }, [page]);

  const totalPages = data?.data?.totalPages;
  const totalData = data?.data?.total;
  const currentPage = data?.data?.currentPage;

  // Search handler
  const handleSearchData = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const data = await searchFundRequest(name);
      setLoading(false);
      setShowSearch(true);
      setTableData(data.data);
    } catch (err) {
      setLoading(false);
      setShowSearch(false);
      throw err;
    }
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setReason(() => value);
  };

  // handle open export modal
  const handleOpen = () => {
    setOpen(true);
  };

  if (dataLoading) {
    return <SpinningText />;
  }

  if (isError) {
    return <ErrorOccured />;
  }

  const handleApprove = async (id) => {
    const data = {
      status: 'approved',
    };
    try {
      setIsLoading(true);
      const res = await updateFundRequest(data, id);
      setIsLoading(false);
      toast.success(res.message);
      setShowDetails(false);
      refetch();
    } catch (err) {
      setIsLoading(false);
      toast.error(err.response.data.message);
    }
  };

  const handleDecline = async (id) => {
    const data = {
      status: 'declined',
      response: reason,
    };
    try {
      setIsLoading(true);
      const res = await updateFundRequest(data, id);
      setIsLoading(false);
      toast.success(res.message);
      setReason('');
      setShowDetails(false);
      setShowDeclineModal(false);
      refetch();
    } catch (err) {
      setIsLoading(false);
      toast.error(err.response.data.message);
    }
  };

  const handleClick = (e) => {
    setCurrentRequest(e);
    setShowDetails(true);
  };

  const defaultData = data?.data.requests.map((e, id) => {
    return {
      col1: e.company_name,
      col2: e.amount,
      col3: e.fund_request_created_at,
      col4: e.status,
      col5: e.status === 'pending' && (
        <Text
          textDecor="underline"
          fontFamily="regular"
          fontSize="1.5rem"
          lineHeight="1.8rem"
          color="brand.blue"
          onClick={() => handleClick(e)}
          cursor="pointer"
        >
          View
        </Text>
      ),
    };
  });

  const showSearchData = tableData?.map((e, id) => {
    return {
      col1: e.company_name,
      col2: e.amount,
      col3: e.fund_request_created_at,
      col4: e.status,
      col5: e.status === 'pending' && (
        <Text
          textDecor="underline"
          fontFamily="regular"
          fontSize="1.5rem"
          lineHeight="1.8rem"
          color="brand.blue"
          onClick={() => handleClick(e)}
          cursor="pointer"
        >
          View
        </Text>
      ),
    };
  });

  const defaultColumns = [
    {
      Header: 'Company name',
      accessor: 'col1',
      Cell: ({ value }) => (
        <Text
          fontFamily="regular"
          fontWeight="bold!important"
          ml="2rem"
          textTransform="capitalize"
        >
          {value}
        </Text>
      ),
      disableFilters: true,
    },
    {
      Header: 'Amount',
      accessor: 'col2',
      Cell: ({ value }) => nFormatter(value),
      disableFilters: true,
    },
    {
      Header: 'Date Requested',
      accessor: 'col3',
      Cell: ({ value }) => dayjs(value).format('MMM D, YYYY'),
      disableFilters: true,
    },
    {
      Header: 'Status',
      accessor: 'col4',
      Cell: ({ value }) => (
        <Box
          d="flex"
          alignItems="center"
          justifyContent="center"
          w="8.9rem"
          h="3.2rem"
          bgColor={
            value === 'pending'
              ? 'rgba(255, 138, 0, 0.1)'
              : 'rgba(226, 77, 77, 0.1)'
          }
          borderRadius="8px"
        >
          <Text
            color={value === 'pending' ? '#FF8A00' : '#E24D4D'}
            fontFamily="medium"
            fontSize="1.5rem"
            lineHeight="1.8rem"
            textTransform="capitalize"
          >
            {value}
          </Text>
        </Box>
      ),
      Filter: SelectColumnFilter,
      disableSortBy: true,
    },
    {
      Header: '',
      accessor: 'col5',
      disableFilters: true,
      disableSortBy: true,
    },
  ];

  return (
    <>
      <Box>
        <Flex justify="space-between">
          <Text
            color="#212D43"
            lineHeight="3.8rem"
            fontSize="3.2rem"
            fontFamily="bold"
          >
            Fund Request
          </Text>
        </Flex>
        <Flex mt="5rem" alignItems="center" justify="space-between">
          <form onSubmit={handleSearchData}>
            <CustomInput
              style={{ marginTop: 0 }}
              w="33.2rem"
              borderRadius="4px"
              label="Search"
              value={name}
              onChange={(e) => setName(e.target.value)}
            >
              <Image src={Search} alt="search_icon" />
            </CustomInput>
          </form>
          <Flex alignItems="center" justifyContent="space-between">
            <HStack spacing="3.5rem">
              <Export cursor="pointer" onClick={handleOpen} />
            </HStack>
          </Flex>
        </Flex>
        <Box
          boxShadow=" 0px 0px 4px rgba(0, 0, 0, 0.15)"
          p="1rem 4rem 6rem 4.2rem"
          minH="60vh"
          mt="2.1rem"
        >
          {defaultData.length < 1 ? (
            <Box>
              <Flex align="center" justify="center" mt="4rem">
                <FallbackIcon />
              </Flex>
              <Text fontSize="1.6rem" textAlign="center" fontFamily="medium">
                No Data for fund request yet
              </Text>
            </Box>
          ) : (
            <Box>
              {loading ? (
                <SpinningText />
              ) : (
                <Box>
                  {showSearch && tableData.length < 1 ? (
                    <Box>
                      <Flex align="center" justify="center" mt="4rem">
                        <FallbackIcon />
                      </Flex>
                      <Text
                        fontSize="1.6rem"
                        textAlign="center"
                        fontFamily="medium"
                      >
                        No company with that name found
                      </Text>
                    </Box>
                  ) : (
                    <TableWrapper>
                      <CustomTable
                        data={showSearch ? showSearchData : defaultData}
                        columns={defaultColumns}
                        currentPage={currentPage}
                        totalPages={totalPages}
                        totalData={totalData}
                        myPage={page}
                        setPage={setPage}
                      />
                    </TableWrapper>
                  )}
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Box>
      <RightModal showModal={showDetails} setShowModal={setShowDetails}>
        <Text
          textTransform="capitalize"
          fontFamily="bold"
          fontSize="3rem"
          lineHeight="3.8rem"
        >
          {currentRequest?.company_name}
        </Text>
        <FundRequestDetails
          setShowDeclineModal={setShowDeclineModal}
          setShowDetails={setShowDetails}
          handleSubmit={handleApprove}
          isLoading={isLoading}
          fundRequestData={currentRequest}
        />
      </RightModal>
      <RightModal
        title="Reason for declining payment."
        showModal={showDeclineModal}
        setShowModal={setShowDeclineModal}
      >
        <Textarea
          placeholder="Type reason"
          mt="3.2rem"
          resize="none"
          value={reason}
          onChange={handleChange}
          fontFamily="regular"
          fontSize="1.4rem"
          p="1rem"
          h="5rem"
          _focus={{ outline: 'none', border: '1px solid #0041be' }}
        />
        <SolidBtn
          onClick={() => handleDecline(currentRequest?.fund_request_id)}
          isLoading={isLoading}
          w="10rem"
          mt="2rem"
        >
          Submit
        </SolidBtn>
      </RightModal>
      <ExportFundRequest open={open} setOpen={setOpen} />
    </>
  );
};

export { FundRequest };

function FundRequestDetails({
  setShowDeclineModal,
  setShowDetails,
  isLoading,
  handleSubmit,
  fundRequestData,
}) {
  const [isOpen, setIsOpen] = useState(false);

  const openDeclineModal = () => {
    setShowDeclineModal(true);
    setShowDetails(false);
  };

  return (
    <>
      <HStack mt="4rem" justify="space-between" align="flex-start">
        <VStack align="flex-start" w="50%">
          <Text color="#939AA3" fontSize="1.4rem" fontFamily="medium">
            Company address:
          </Text>
          <Text color="#626363" fontSize="1.4rem" fontFamily="bold">
            {fundRequestData?.company_address}
          </Text>
        </VStack>
        <VStack align="flex-start" w="40%">
          <Text color="#939AA3" fontSize="1.4rem" fontFamily="medium">
            Email:
          </Text>
          <Text color="#626363" fontSize="1.4rem" fontFamily="bold">
            {fundRequestData?.merchant_email}
          </Text>
        </VStack>
      </HStack>
      <Box h="1px" background="#e4e4e4" w="100%" mt="4rem" mb="2.4rem" />
      <VStack align="flex-start" w="40%">
        <Text color="#939AA3" fontSize="1.4rem" fontFamily="medium">
          Amount:
        </Text>
        <Text color="#626363" fontSize="2rem" fontFamily="bold">
          {nFormatter(fundRequestData?.amount)}
        </Text>
      </VStack>
      <Flex justify="space-between" align="center" mt="3.3rem">
        <Text color="#444444" fontSize="1.4rem" fontFamily="bold">
          Download payment receipt
        </Text>
        <SolidBtn
          w="108px"
          color="#2EAE65"
          backgroundColor="rgba(46, 171, 101, .15)"
        >
          <DownloadIcon />
          <Text
            as="a"
            href={fundRequestData?.proof_url}
            pl="1rem"
            fontFamily="medium"
          >
            Download
          </Text>
        </SolidBtn>
      </Flex>
      <HStack
        spacing="16px"
        mt="4rem"
        ml="auto"
        w="auto"
        justify="space-between"
      >
        <OutlineBtn
          color="#fb3640"
          borderColor="#fb3640"
          onClick={openDeclineModal}
          w="170px"
        >
          Decline Request
        </OutlineBtn>
        <SolidBtn w="170px" onClick={() => setIsOpen(true)}>
          Approve Request
        </SolidBtn>
      </HStack>
      <StyledWrapper>
        <CenterModal
          title="Are you sure you want to approve this?"
          showModal={isOpen}
          setShowModal={setIsOpen}
        >
          <Box d="flex" justifyContent="space-between" alignItems="center">
            <OutlineBtn
              borderColor="#939AA3"
              color="#939AA3"
              w="15rem"
              onClick={() => setIsOpen(false)}
              _hover={{ background: '#939AA3', color: '#fff' }}
            >
              No
            </OutlineBtn>
            <SolidBtn
              onClick={() => handleSubmit(fundRequestData?.fund_request_id)}
              isLoading={isLoading}
              w="15rem"
            >
              Yes
            </SolidBtn>
          </Box>
        </CenterModal>
      </StyledWrapper>
    </>
  );
}

const StyledWrapper = styled(Box)`
  .closeBtn {
    display: none;
  }
`;
