import { Box, Flex, HStack, Text } from '@chakra-ui/react';
import React, { useState, useRef } from 'react';
import Picker from 'react-month-picker';
import { Bar, defaults } from 'react-chartjs-2';
import { useQuery } from 'react-query';
import dayjs from 'dayjs';

import 'react-month-picker/css/month-picker.css';
import { DatePickerTrigger } from '../../common/form';
import { nFormatter, pickerMonths, pickerYears } from '../../../utils';

import { SolidBtn } from '../../common/buttons';
import { ChartSkeletonLoader } from '../../common/loaders';
import { getProductSales } from '../../../queries';
import { ReactComponent as FallbackIcon } from '../../../assets/icons/fallback.svg';

function ProductSales() {
  const [statsPeriod, setStatsPeriod] = useState('month');
  const [startDate, setStartDate] = useState({
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
  });

  const pickAMonth = useRef();

  const { isLoading, isError, data } = useQuery(
    ['getProductSales', startDate, statsPeriod],
    getProductSales
  );

  if (isLoading || isError) {
    return <ChartSkeletonLoader title="Product Sales" />;
  }

  defaults.global.legend = false;

  const chartData = {
    labels:
      statsPeriod === 'month'
        ? [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
          ]
        : statsPeriod === 'week'
        ? data.data.map((item) => `${dayjs(item.week).format('MMM-DD')}th`)
        : data.data.map((item) => `${dayjs(item.day).format('MMM-DD')}th`),
    datasets: [
      {
        label: 'Data',
        backgroundColor: '#0041BE',
        barThickness: 10,
        minBarLength: 2,
        barValueSpacing: 20,
        data: data.data.map((item) => item.top_product),
      },
      {
        label: 'Airtime',
        backgroundColor: '#FDB600',
        barThickness: 10,
        minBarLength: 2,
        barValueSpacing: 20,
        data: data.airtime.map((item) => item.top_product),
      },
    ],
    options: {
      scales: {
        xAxes: [
          {
            categoryPercentage: 1.0,
            barPercentage: 1.0,
          },
        ],
      },
    },
  };

  return (
    <Box p="4rem" w="50%" boxShadow="0px 0px 4px rgba(0, 0, 0, 0.15)" bg="#fff">
      <Flex justify="space-between" mb="7rem">
        <Text fontSize="2.4rem" color="#626363" fontFamily="medium">
          Product Sales
        </Text>
        <Box>
          <Flex bg="#F8F9FB">
            <SolidBtn
              h="100%"
              backgroundColor={
                statsPeriod === 'month' ? '#2EAE65' : 'transparent'
              }
              color={statsPeriod === 'month' ? '#fff' : '#939AA3'}
              fontSize="1.4rem"
              fontFamily="medium"
              py="1rem"
              onClick={() => setStatsPeriod('month')}
            >
              Month
            </SolidBtn>
            <SolidBtn
              h="100%"
              backgroundColor={
                statsPeriod === 'week' ? '#2EAE65' : 'transparent'
              }
              color={statsPeriod === 'week' ? '#fff' : '#939AA3'}
              fontSize="1.4rem"
              fontFamily="medium"
              py="1rem"
              onClick={() => setStatsPeriod('week')}
            >
              Week
            </SolidBtn>
            <SolidBtn
              h="100%"
              backgroundColor={
                statsPeriod === 'day' ? '#2EAE65' : 'transparent'
              }
              color={statsPeriod === 'day' ? '#fff' : '#939AA3'}
              fontSize="1.4rem"
              fontFamily="medium"
              py="1rem"
              onClick={() => setStatsPeriod('day')}
            >
              Day
            </SolidBtn>
          </Flex>
          <Box mt="3.2rem">
            <Picker
              ref={pickAMonth}
              years={pickerYears}
              value={startDate}
              lang={pickerMonths}
              onChange={(year, month) => setStartDate({ year, month })}
            >
              <DatePickerTrigger
                value={{
                  month: pickerMonths[startDate.month - 1],
                  year: startDate.year,
                }}
                onClick={() => pickAMonth.current.show()}
              />
            </Picker>
          </Box>
        </Box>
      </Flex>
      {data.airtime.length < 1 && data.data.length < 1 ? (
        <Box>
          <Flex align="center" justify="center" mt="4rem">
            <FallbackIcon />
          </Flex>
          <Text fontSize="1.6rem" textAlign="center" fontFamily="medium">
            No data
          </Text>
        </Box>
      ) : (
        <Box>
          <Bar
            data={chartData}
            options={{
              // maintainAspectRatio: false,
              responsive: true,
              scales: {
                xAxes: [
                  {
                    categoryPercentage: 1.0,
                    gridLines: {
                      display: false,
                    },
                  },
                ],
                yAxes: [
                  {
                    ticks: {
                      beginAtZero: false,
                      userCallback: (value) => nFormatter(value),
                    },
                  },
                ],
              },
            }}
          />
          <HStack ml="4rem" spacing="4rem" mt="4rem">
            <Box d="flex" alignItems="center">
              <Box bg="#FDB600" w="1.3rem" h="1.3rem" />
              <Text
                ml="1.2rem"
                color="#979797"
                fontFamily="regular"
                fontSize="1.4rem"
                lineHeight="1.7rem"
              >
                Airtime
              </Text>
            </Box>
            <Box ml="4rem" d="flex" alignItems="center">
              <Box bg="brand.blue" w="1.3rem" h="1.3rem" />
              <Text
                ml="1.2rem"
                color="#979797"
                fontFamily="regular"
                fontSize="1.4rem"
                lineHeight="1.7rem"
              >
                Data
              </Text>
            </Box>
          </HStack>
        </Box>
      )}
    </Box>
  );
}

export { ProductSales };
