import instance from '../axios.config';

const addBank = async (body) => {
  const { data } = await instance.post('/admin/bank', body);

  return data;
};

const updateBank = async (body, id) => {
  const { data } = await instance.put(`/admin/bank/${id}`, body);

  return data;
};

const updateMerchantProfile = async (body) => {
  const { data } = await instance.put(`/merchant/profile`, body);

  return data;
};

const updateAdminProfile = async (body) => {
  const { data } = await instance.put(`/admin/profile`, body);

  return data;
};

const getBank = async () => {
  const { data } = await instance.get('/merchant/bank');

  return data.data;
};

const removeBank = async (id) => {
  const { data } = await instance.delete(`/admin/bank/${id}`);
  return data;
};

export {
  addBank,
  updateBank,
  updateMerchantProfile,
  updateAdminProfile,
  getBank,
  removeBank,
};
