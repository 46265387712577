import React, { useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { Box, Text, Flex, Image, Divider, Textarea } from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { Doughnut, defaults } from 'react-chartjs-2';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { useQuery } from 'react-query';
import Picker from 'react-month-picker';

import {
  getSingleMerchantProfileAndDocument,
  sendMessageToMerchant,
  getSingleMerchantChart,
  getSingleMerchantTable,
} from '../../queries';
import Message from '../../assets/icons/message.svg';
import { SolidBtn } from '../../components/common/buttons';
import { CustomTable, TableWrapper } from '../../components/common/tables';
import { RightModal } from '../../components/common/modals';
import { SpinningText } from '../../components/common/loaders';
import { nFormatter } from '../../utils';
import { ErrorOccured } from '../../components/common/errors';
import { ReactComponent as DownloadIcon } from '../../assets/icons/download.svg';
import { ReactComponent as FallbackIcon } from '../../assets/icons/fallback.svg';
import { DatePickerTrigger } from '../../components/common/form';
import { pickerMonths, pickerYears } from '../../utils';

const SingleMerchant = () => {
  defaults.global.legend = false;
  const pickAMonth = useRef();

  const { id } = useParams();
  const initialState = {
    message: '',
  };

  const [isOpen, setIsOpen] = useState(false);
  const [state, setState] = useState(initialState);
  const [submitting, setSubmitting] = useState(false);
  const [startDate, setStartDate] = useState({
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
  });

  const { isLoading, isError, data } = useQuery(
    ['getSingleMerchantProfileAndDocument', id],
    getSingleMerchantProfileAndDocument
  );

  const {
    isLoading: historyLoading,
    isError: historyError,
    data: historyData,
  } = useQuery(['getSingleMerchantTable', id], getSingleMerchantTable);
  const { data: summaryData } = useQuery(
    ['getSingleMerchantChart', id, startDate],
    getSingleMerchantChart
  );

  if (isLoading || historyLoading) {
    return <SpinningText />;
  }

  if (isError || historyError) {
    return <ErrorOccured />;
  }

  const merchantId = data?.profileData.id;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const disableBtn = state.message === '';

  const sendMessage = async () => {
    try {
      setSubmitting(true);
      const res = await sendMessageToMerchant(merchantId, state);
      setSubmitting(false);
      setIsOpen(false);
      setState(initialState);
      toast.success(res.message);
    } catch (error) {
      setSubmitting(false);
      toast.error(error.response.data.message);
    }
  };

  const discountRoute = `/admin/merchant/${id}/discount`;

  const doughnutData = (() => {
    const airtime = summaryData?.find(
      (item) => item.product_type === 'airtime'
    );
    const dataBundle = summaryData?.find(
      (item) => item.product_type === 'data'
    );

    const sum =
      (airtime?.sum ? +airtime?.sum : 0) +
      (dataBundle?.sum ? +dataBundle?.sum : 0);

    return {
      chartData: {
        labels: ['Airtime', 'Data'],
        datasets: [
          {
            backgroundColor: ['#FDB600', '#3558C7'],
            data: [airtime?.sum || 0, dataBundle?.sum || 0],
          },
        ],
      },
      sum,
      dataBundle: dataBundle?.sum || 0,
      airtime: airtime?.sum || 0,
    };
  })();

  const defaultData = historyData?.map((e) => {
    return {
      col1: e.product_name,
      col2: nFormatter(e.amount),
      col3: e.product_type,
      col4: (
        <Text
          color={e.tx_status.startsWith('s') ? '#2EAE65' : '#E24D4D'}
          fontFamily="regular"
          fontSize="1.5rem"
          lineHeight="1.8rem"
          fontWeight="bold!important"
          textTransform="capitalize"
        >
          {e.tx_status}
        </Text>
      ),
      col5: dayjs(e.created_at).format('MMM D, YYYY'),
    };
  });

  const columns = [
    {
      Header: 'Network',
      accessor: 'col1',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Amount',
      accessor: 'col2',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Service type',
      accessor: 'col3',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Status',
      accessor: 'col4',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Date',
      accessor: 'col5',
      disableSortBy: true,
      disableFilters: true,
    },
  ];

  const toggleModal = () => {
    setIsOpen({ isOpen: true });
  };
  return (
    <Box>
      <Flex justify="space-between" alignItems="center">
        <Text
          fontFamily="bold"
          fontSize="3.2rem"
          lineHeight="3.8rem"
          textTransform="capitalize"
        >
          {data?.profileData?.first_name
            ? `${data?.profileData?.first_name}'s `
            : ''}
          Profile
        </Text>
        <Box mt="5rem" d="flex" alignItems="center">
          <Divider
            h="4rem"
            mr="2rem"
            orientation="vertical"
            borderColor="rgba(53, 88, 199, 0.4)"
          />
          <Box cursor="pointer" d="flex" onClick={toggleModal}>
            <Image src={Message} alt="message_icon" mr="1rem" />
            <Text
              fontFamily="medium"
              fontSize="1.4rem"
              lineHeight="1.7rem"
              color="#626363"
            >
              Send message
            </Text>
          </Box>
          <Divider
            h="4rem"
            ml="2rem"
            mr="2rem"
            orientation="vertical"
            borderColor="rgba(53, 88, 199, 0.4)"
          />
          <Text
            fontFamily="regular"
            fontSize="1.4rem"
            lineHeight="1.7rem"
            color="#939AA3"
          >
            Status:
            <Text
              as="span"
              color="#2EAE65"
              fontSize="1.4rem"
              lineHeight="1.7rem"
              fontFamily="regular"
              ml="0.5rem"
              textTransform="capitalize!important"
            >
              {data?.profileData?.account_status || 'Pending'}
            </Text>
          </Text>
          <SolidBtn
            as={Link}
            to={discountRoute}
            w="17rem"
            h="4.2rem"
            borderRadius="8px"
            ml="5rem"
          >
            View discount rate
          </SolidBtn>
        </Box>
      </Flex>
      <Box bg="#F1F4FF" mt="4.3rem" pb="4rem">
        <Flex align="center" w="100%">
          <Box pt="3rem" pl="5rem" w="40%">
            <Text
              color="#212D43"
              fontFamily="bold"
              fontSize="2.4rem"
              lineHeight="2.9rem"
              textTransform="capitalize"
            >
              {data?.profileData?.company_name}
            </Text>
            <Text
              color="#939AA3"
              fontFamily="medium"
              fontSize="1.4rem"
              lineHeight="1.7rem"
              mt="2rem"
            >
              Joined:
              <Box
                as="span"
                color="#808080"
                fontSize="1.4rem"
                lineHeight="1.7rem"
                fontFamily="bold"
                ml="0.8rem"
              >
                {dayjs(data?.profileData?.created_at).format('MMM D, YYYY')}
              </Box>
            </Text>
            <Box d="flex">
              <Box minW="18rem" mr="5rem">
                <Text
                  mt="4rem"
                  color="#939AA3"
                  fontFamily="medium"
                  fontSize="1.4rem"
                  lineHeight="1.7rem"
                >
                  Company address:
                </Text>
                <Text
                  wordBreak="break-word"
                  fontFamily="bold"
                  fontSize="1.4rem"
                  lineHeight="140%"
                  color="#626363"
                  mt="0.6rem"
                  textTransform="capitalize"
                >
                  {data?.profileData?.company_address}
                </Text>
                {data?.profileData?.address && (
                  <>
                    <Text
                      mt="1.6rem"
                      color="#939AA3"
                      fontFamily="medium"
                      fontSize="1.4rem"
                      lineHeight="1.7rem"
                    >
                      Location:
                    </Text>
                    <Text
                      wordBreak="break-word"
                      fontFamily="bold"
                      fontSize="1.4rem"
                      lineHeight="140%"
                      color="#626363"
                      mt="0.6rem"
                    >
                      {data?.profileData?.address}
                    </Text>
                  </>
                )}
              </Box>
              <Box minW="18rem">
                <Text
                  mt="4rem"
                  color="#939AA3"
                  fontFamily="medium"
                  fontSize="1.4rem"
                  lineHeight="1.7rem"
                >
                  Email:
                </Text>
                <Text
                  wordBreak="break-word"
                  fontFamily="bold"
                  fontSize="1.4rem"
                  lineHeight="140%"
                  color="#626363"
                  mt="0.6rem"
                >
                  {data?.profileData?.email}
                </Text>
                {data?.profileData?.phone_number && (
                  <>
                    <Text
                      mt="1.6rem"
                      color="#939AA3"
                      fontFamily="medium"
                      fontSize="1.4rem"
                      lineHeight="1.7rem"
                    >
                      Phone no:
                    </Text>
                    <Text
                      wordBreak="break-word"
                      fontFamily="bold"
                      fontSize="1.4rem"
                      lineHeight="140%"
                      color="#626363"
                      mt="0.6rem"
                    >
                      {data?.profileData?.phone_number}
                    </Text>
                  </>
                )}
              </Box>
            </Box>
          </Box>
          <Box
            borderRight="2px solid #E8EEF4"
            h="29.1rem"
            mt="5rem"
            ml="5rem"
          />
          <Box d="flex" pl="4rem" pr="3rem" w="60%" position="relative">
            <Box>
              <Doughnut
                data={doughnutData.chartData}
                options={{
                  title: {
                    display: true,
                    fontSize: 20,
                  },
                  legend: {
                    display: false,
                    position: 'bottom',
                  },
                  cutoutPercentage: 90,
                }}
                width="300px"
                height="300px"
              />
              <Box position="relative" bottom="42%" left="30%">
                <Text
                  color="#212D43"
                  fontFamily="bold"
                  fontSize="2.4rem"
                  lineHeight="2.9rem"
                >
                  {nFormatter(doughnutData.sum)}
                </Text>
              </Box>
              <Box position="relative" bottom="42%" left="37%">
                <Text
                  mt="0.4rem"
                  color="rgba(0, 65, 190, 0.9)"
                  fontSize="1.4rem"
                  lineHeight="1.7rem"
                  fontFamily="regular"
                >
                  Total purchase
                </Text>
              </Box>
            </Box>
            <Box mt="10rem">
              <Text
                color="brand.blue"
                fontFamily="bold"
                fontSize="2.8rem"
                lineHeight="3.4rem"
              >
                {nFormatter(doughnutData.dataBundle)}
              </Text>
              <Text
                color="#939AA3"
                fontFamily="regular"
                fontSize="1.4rem"
                lineHeight="1.7rem"
              >
                Data purchase
              </Text>
              <Text
                color="#FDB600"
                fontFamily="bold"
                fontSize="2.8rem"
                lineHeight="3.4rem"
                mt="2.8rem"
              >
                {nFormatter(doughnutData.airtime)}
              </Text>
              <Text
                color="#939AA3"
                fontFamily="regular"
                fontSize="1.4rem"
                lineHeight="1.7rem"
              >
                Airtime purchase
              </Text>
              <Box d="flex" mt="8.4rem">
                <Box bg="#FDB600" mr="1.6rem" h="1.5rem" w="1.5rem" />
                <Text
                  color="#979797"
                  fontFamily="regular"
                  fontSize="1.5rem"
                  lineHeight="1.8rem"
                >
                  Airtime
                </Text>
                <Box
                  ml="4.9rem"
                  mr="1.6rem"
                  bg="brand.blue"
                  h="1.5rem"
                  w="1.5rem"
                />
                <Text
                  color="#979797"
                  fontFamily="regular"
                  fontSize="1.5rem"
                  lineHeight="1.8rem"
                >
                  Data
                </Text>
              </Box>
            </Box>
            <Box mt="2rem" d="flex" position="absolute" top="0" right="3.2rem">
              <Picker
                ref={pickAMonth}
                years={pickerYears}
                value={startDate}
                lang={pickerMonths}
                onChange={(year, month) => setStartDate({ year, month })}
              >
                <DatePickerTrigger
                  value={{
                    month: pickerMonths[startDate.month - 1],
                    year: startDate.year,
                  }}
                  onClick={() => pickAMonth.current.show()}
                />
              </Picker>
            </Box>
          </Box>
        </Flex>
      </Box>
      <Flex w="100%" justify="space-between" mt="4rem">
        <Box
          pt="1rem"
          pr="5rem"
          pl="3rem"
          w="63%"
          bg="#FFFFFF"
          boxShadow="0px 0px 4px rgba(0, 0, 0, 0.15)"
          h="45rem"
        >
          <Flex mt="0rem" align="center">
            <Text
              color="#626363"
              fontFamily="bold"
              fontSize="2.4rem"
              lineHeight="2.9rem"
              mt="2rem"
            >
              Transaction History
            </Text>
          </Flex>
          <TableDiv>
            <MyTableWrapper>
              {defaultData && defaultData.length >= 1 ? (
                <CustomTable data={defaultData} columns={columns} />
              ) : (
                <Box>
                  <Flex align="center" justify="center">
                    <FallbackIcon />
                  </Flex>
                  <Text
                    fontSize="1.6rem"
                    textAlign="center"
                    fontFamily="medium"
                  >
                    No data available
                  </Text>
                </Box>
              )}
            </MyTableWrapper>
          </TableDiv>
        </Box>
        <Box
          w="35%"
          bg="#FFFFFF"
          boxShadow="0px 0px 4px rgba(0, 0, 0, 0.15)"
          pr="3rem"
          pl="3rem"
          pt="2rem"
          pb="3rem"
          h="45rem"
        >
          <Text
            fontFamily="bold"
            fontSize="2.4rem"
            lineHeight="2.9rem"
            mb="7.5rem"
            color="#626363"
          >
            Documentation
          </Text>
          <DocDiv>
            {data?.documentData?.length < 1 ? (
              <Text fontFamily="regular" fontSize="1.4rem" textAlign="center">
                No data available
              </Text>
            ) : (
              data?.documentData?.map((e) => (
                <Box
                  key={e.id}
                  borderBottom="1px solid #E4E4E4"
                  d="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  pb="1.6rem"
                  pr="2rem"
                  mt="2rem"
                >
                  <Text
                    color="#212D43"
                    lineHeight="1.7rem"
                    fontSize="1.4rem"
                    fontFamily="medium"
                  >
                    {e.title}
                  </Text>
                  <SolidBtn
                    as="a"
                    href={e.document_url}
                    w="108px"
                    color="#2EAE65"
                    backgroundColor="rgba(46, 171, 101, .15)"
                  >
                    <DownloadIcon />
                    <Text pl="1rem" fontFamily="medium">
                      Download
                    </Text>
                  </SolidBtn>
                </Box>
              ))
            )}
          </DocDiv>
        </Box>
      </Flex>
      <RightModal
        title="Compose new message"
        showModal={isOpen}
        setShowModal={setIsOpen}
      >
        <Text fontFamily="regular">
          Send new message to the merchant for any request or to pass important
          information across.
        </Text>
        <Box borderBottom="1px solid #E4E4E4" mt="4.8rem" mb="1.2rem" />
        <Box d="flex" alignItems="center">
          <Text color="#979797" fontFamily="medium">
            To:
          </Text>
          <Box
            d="flex"
            alignItems="center"
            justifyContent="center"
            bg="#F3F3F3"
            h="4rem"
            minW="20.5rem"
            borderRadius="8px"
            ml="1.6rem"
          >
            <Text fontFamily="regular">
              {data?.profileData?.company_name.charAt(0).toUpperCase() +
                data?.profileData?.company_name.slice(1)}
            </Text>
          </Box>
        </Box>
        <Box borderTop="1px solid #E4E4E4" mt="1.2rem" mb="1.2rem" />
        <Text color="#979797" fontFamily="medium">
          From:
          <Box ml="1.6rem" as="span" color="#626363" fontFamily="regular">
            Awake admin
          </Box>
        </Text>
        <Box borderTop="1px solid #E4E4E4" mt="1.2rem" />
        <StyleTextarea
          fontFamily="regular"
          placeholder="Enter message"
          resize="none"
          name="message"
          value={state.message}
          onChange={handleChange}
        />
        <Box d="flex" w="100%" justifyContent="flex-end">
          <SolidBtn
            onClick={sendMessage}
            isDisabled={disableBtn}
            isLoading={submitting}
            w="10.6rem"
          >
            Send
          </SolidBtn>
        </Box>
        <Box borderTop="1px solid #E4E4E4" mt="1.7rem" />
      </RightModal>
    </Box>
  );
};

export { SingleMerchant };

const TableDiv = styled(Box)`
  height: 30rem;
  overflow-y: scroll;
  margin-top: 4rem;
`;

const DocDiv = styled(Box)`
  height: 30rem;
  overflow-y: auto;
`;
const StyleTextarea = styled(Textarea)`
  height: 30.5rem;
  border: none;
  font-size: 1.4rem;
  @media screen and (min-width: 1441px) {
    height: 46.5rem;
  }
`;
const MyTableWrapper = styled(TableWrapper)`
  thead {
    background-color: #0041be;
  }
  th {
    color: #fff;
  }
`;
