import React, { useState } from 'react';
import { Flex, Box } from '@chakra-ui/react';
import { ReactComponent as ToggleIcon } from '../../../assets/icons/toggleWhite.svg';
import { CollapsedIcons, ExpandedLinks, Icons } from './sidebarComponents';

const SideBar = ({ isOpen, setIsOpen }) => {
  const toggleOpen = () => setIsOpen(!isOpen);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  return (
    <Flex w="240px" h="100vh" pos="fixed">
      <Box
        bg="brand.darkBlue"
        w="52px"
        zIndex="5"
        borderRight="1px solid rgba(255, 255, 255, 0.08)"
      >
        <Flex h="60px" align="center" justify="center">
          {!isOpen && (
            <Box w="16px" h="16px" cursor="pointer" onClick={toggleOpen}>
              <ToggleIcon />
            </Box>
          )}
        </Flex>
        {isOpen ? (
          <Icons isDropdownOpen={isDropdownOpen} />
        ) : (
          <CollapsedIcons isDropdownOpen={isDropdownOpen} />
        )}
      </Box>
      {isOpen && (
        <ExpandedLinks
          toggleDropdown={toggleDropdown}
          isDropdownOpen={isDropdownOpen}
        />
      )}
    </Flex>
  );
};

export { SideBar };
