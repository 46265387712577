import React from 'react';
import { Box, Text, Flex } from '@chakra-ui/react';

import { ReactComponent as Envelope } from '../../../assets/images/envelope.svg';
import { SolidBtn } from '../../common/buttons';

const NoTicket = ({ openModal }) => {
  return (
    <>
      <Flex align="center" justify="space-between">
        <Text fontFamily="bold" fontSize="3.2rem" lineHeight="3.8rem">
          Ticket
        </Text>
        <SolidBtn onClick={openModal} w="17rem">
          Open a new Ticket
        </SolidBtn>
      </Flex>
      <Box mt="11.7rem" ml="13.3rem" d="flex">
        <Box mt="12.8rem">
          <Envelope />
        </Box>
        <Box ml="4.8rem" d="flex" flexDirection="column">
          <Text
            mb="8rem"
            fontFamily="regular"
            fontSize="1.8rem"
            lineHeight="2.2rem"
            color="#200E32"
          >
            You have no open ticket yet!
          </Text>
          <Text
            fontFamily="regular"
            fontWeight="300"
            fontSize="4rem"
            lineHeight="4.8rem"
            mb="2.4rem"
          >
            Have a complaint?
          </Text>
          <Box w="72.5rem" mb="3.5rem">
            <Text
              fontFamily="regular"
              fontSize="1.4rem"
              lineHeight="150%"
              color="#808080"
            >
              Amet minim mollit non deserunt ullamco est sit aliqua dolor do
              amet sint. Velit officia consequat duis enim velit mollit.
              Exercitation veniam consequat sunt nostrud amet. Amet minim mollit
              non deserunt ullamco est sit aliqua dolor do. Exercitation veniam
              consequat sunt nostrud amet. Amet minim mollit non.
            </Text>
          </Box>
          <Text
            fontFamily="medium"
            fontSize="1.4rem"
            lineHeight="150%"
            color="#808080"
          >
            Open a ticket now.
            <Text
              fontFamily="medium"
              fontSize="1.4rem"
              lineHeight="150%"
              color="#0041BE"
              textDecoration="underline"
              as="span"
              ml=".8rem"
              cursor="pointer"
              onClick={openModal}
            >
              Open ticket
            </Text>
          </Text>
        </Box>
      </Box>
    </>
  );
};

export { NoTicket };
