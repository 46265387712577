import React, { useState, useEffect } from 'react';
import { Box, Text, Flex } from '@chakra-ui/react';
import { useQuery } from 'react-query';
import dayjs from 'dayjs';

import { CustomInput } from '../../components/common/form';
import { CustomTable, TableWrapper } from '../../components/common/tables';
import { SolidBtn } from '../../components/common/buttons';
import { CenterModal } from '../../components/common/modals';
import { editDiscount, getAllFlatDiscounts } from '../../queries';
import { SpinningText } from '../../components/common/loaders';
import { ErrorOccured } from '../../components/common/errors';
import { capitalize } from '../../utils';
import { toast } from 'react-toastify';

function DiscountRate() {
  const [page, setPage] = useState(1);
  const [showEditModal, setShowEditModal] = useState(false);
  const [currentDiscount, setCurrentDiscount] = useState(null);

  const { isLoading, isError, data, refetch } = useQuery(
    ['getAllFlatDiscounts', page],
    getAllFlatDiscounts
  );

  useEffect(() => {
    refetch();
  }, [page]);

  const totalPages = data?.totalPages;
  const totalData = data?.total;
  const currentPage = data?.currentPage;

  if (isLoading) {
    return <SpinningText />;
  }

  if (isError) {
    return <ErrorOccured />;
  }

  const handleEdit = (discount) => {
    setCurrentDiscount(discount);
    setShowEditModal(true);
  };

  const tableData = data?.products?.map((e) => {
    return {
      col1: e.product_type,
      col2: e.product_name,
      col3: e.flat_discount,
      col4: dayjs(e.created_at).format('MMM D, YYYY'),
      col5: (
        <Text
          _hover={{ textDecor: 'underline' }}
          color="#FF8A00"
          cursor="pointer"
          onClick={() => handleEdit(e)}
        >
          Edit
        </Text>
      ),
    };
  });

  const tableCols = [
    {
      Header: 'Service Type',
      accessor: 'col1',
      disableFilters: true,
    },
    {
      Header: 'Network',
      accessor: 'col2',
      disableFilters: true,
    },
    {
      Header: 'Discount Rate (%)',
      accessor: 'col3',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Date Created',
      accessor: 'col4',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: '',
      accessor: 'col5',
      disableSortBy: true,
      disableFilters: true,
    },
  ];

  return (
    <>
      <Box w="100%">
        <Text color="#212D43" fontFamily="bold" fontSize="3.2rem">
          Discount rate
        </Text>
        <Box mt="5rem" />
        <Box mt="3rem" w="100%" mx="auto">
          <Flex
            w="100%"
            bg="#fff"
            boxShadow="0px 0px 4px rgba(0, 0, 0, 0.15);"
            p="2rem"
            minH="60vh"
          >
            <TableWrapper>
              <CustomTable
                data={tableData}
                columns={tableCols}
                currentPage={currentPage}
                totalPages={totalPages}
                totalData={totalData}
                myPage={page}
                setPage={setPage}
              />
            </TableWrapper>
          </Flex>
        </Box>
      </Box>
      <EditDiscountModal
        showEditModal={showEditModal}
        setShowEditModal={setShowEditModal}
        currentDiscount={currentDiscount}
        refetch={refetch}
      />
    </>
  );
}

function EditDiscountModal({
  showEditModal,
  setShowEditModal,
  currentDiscount,
  refetch,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [discount, setDiscount] = useState('');

  const handleChange = (e) => {
    const { value } = e.target;
    setDiscount(value);
  };

  const handleSubmit = async () => {
    if (discount < 1) {
      toast.error('Discount value cannot be less than 1');
      return;
    }

    const body = {
      flatDiscount: discount,
    };
    try {
      setIsLoading(true);
      await editDiscount(currentDiscount.id, body);
      setIsLoading(false);
      toast.success('Discount updated sucessfully');
      setDiscount('');
      setShowEditModal(false);
      refetch();
    } catch (err) {
      setIsLoading(false);
      toast.error(err.response?.data?.message);
    }
  };

  return (
    <CenterModal
      showModal={showEditModal}
      setShowModal={setShowEditModal}
      title="Edit discount"
    >
      <CustomInput
        style={{ marginTop: '1.6rem' }}
        hideIcon
        label={capitalize(currentDiscount?.product_type)}
        isDisabled
      />
      <CustomInput
        style={{ marginTop: '1.6rem' }}
        hideIcon
        label={capitalize(currentDiscount?.product_name)}
        isDisabled
      />
      <CustomInput
        style={{ marginTop: '1.6rem' }}
        type="number"
        hideIcon
        label="Discount rate (%)"
        value={currentDiscount?.flat_discount || discount}
        name="discount"
        onChange={handleChange}
      />
      <SolidBtn
        mt="3.2rem"
        onClick={handleSubmit}
        isLoading={isLoading}
        isDisabled={discount === ''}
      >
        Save
      </SolidBtn>
    </CenterModal>
  );
}

export { DiscountRate };
