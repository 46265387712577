import React, { useContext } from 'react';
import { Flex, Text, Avatar, Divider, Box, Image } from '@chakra-ui/react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { AuthContext } from '../../context/auth';
import { ReactComponent as ToggleIcon } from '../../assets/icons/toggle.svg';
import BackIcon from '../../assets/icons/back.png';

const Navbar = ({ isOpen, setIsOpen }) => {
  const { authState } = useContext(AuthContext);
  const toggleOpen = () => setIsOpen(!isOpen);
  const history = useHistory();
  const { pathname } = useLocation();

  const goBack = () => {
    history.go(-1);
  };

  const pathsToShowGoBack = [
    '/admin/discount',
    '/admin/revenue',
    '/admin/merchants',
    '/admin/applications',
    '/admin/user-management',
    '/admin/audit-trail',
    '/admin/fund-request',
    '/admin/ticket',
    '/admin/settings',
    '/admin/overview',
    '/admin/top-up',
    '/merchant/ticket',
    '/merchant/settings',
    '/merchant/documentation',
    '/merchant/wallet',
    '/merchant/overview',
    '/merchant/waiting',
    '/merchant/declined',
    '/merchant/products',
    '/merchant/transactions',
  ];

  const hideGoBack = pathsToShowGoBack.includes(pathname);

  return (
    <Box>
      <Flex
        h="60px"
        align="center"
        bg="#fff"
        w={isOpen ? 'calc(100% - 240px)' : 'calc(100% - 52px)'}
        justify="space-between"
        pos="fixed"
        zIndex="100"
        p="0 4rem"
        boxShadow="0px 4px 4px -5px rgba(0, 0, 0, 0.15)"
      >
        <Box onClick={toggleOpen} cursor="pointer">
          {isOpen && (
            <Box w="22px" h="18px" cursor="pointer">
              <ToggleIcon />
            </Box>
          )}
        </Box>
        <Flex
          w={isOpen ? '95%' : '100%'}
          align="center"
          justify={!hideGoBack ? 'space-between' : 'flex-end'}
        >
          {!hideGoBack ? (
            <Box onClick={() => goBack()} cursor="pointer" d="flex">
              <Image w="2rem" h="2rem" src={BackIcon} alt="back" />
              <Text fontFamily="medium" fontSize="1.4rem" ml="1rem">
                Back
              </Text>
            </Box>
          ) : (
            ''
          )}
          <Flex justify="flex-end" align="center">
            <Divider
              orientation="vertical"
              h="32px"
              border="1px solid #E4E4E4"
              ml="25px"
              mr="24px"
            />
            <Avatar
              width="32px"
              fontFamily="medium"
              name={`${authState.first_name} ${authState.last_name}`}
              src="#"
            />
            <Text
              as={Link}
              to={authState.is_admin ? '/admin/settings' : '/merchant/settings'}
              color="#939AA3"
              fontSize="14px"
              fontFamily="regular"
              fontWeight="500"
              ml="16px"
              textTransform="capitalize"
            >
              {authState.first_name} {authState.last_name}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};

export { Navbar };
