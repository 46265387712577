import { theme } from '@chakra-ui/react';

// Let's say you want to add custom colors
const customTheme = {
  ...theme,
  colors: {
    ...theme.colors,
    brand: {
      blue: '#0041BE',
      darkBlue: '#003FB9',
      header: '#212D43',
    },
  },
  fonts: {
    Xlight: '"TT Norms XLight", sans-serif',
    light: '"TT Norms Light", sans-serif',
    thin: '"TT Norms Thin", sans-serif',
    regular: '"TT Norms Regular", sans-serif',
    medium: '"TT Norms Medium", sans-serif',
    bold: '"TT Norms Bold", sans-serif',
    XBold: '"TT Norms XBold", sans-serif',
    black: '"TT Norms Black", sans-serif',
  },
};

export default customTheme;
