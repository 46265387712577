import { handleError } from '../../utils';

import instance from '../axios.config';

const getTxHistory = async (key, page = 1) => {
  try {
    const { data } = await instance.get(`/merchant/tx-history?page=${page}`);

    return data.data;
  } catch (error) {
    handleError(error);
  }
};

export { getTxHistory };
