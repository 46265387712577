import instance from '../axios.config';

const createSingleMerchantDiscount = async (body, id) => {
  const { data } = await instance.post(`/admin/merchant/${id}/discount`, {
    ...body,
  });

  return data;
};

const deleteSingleMerchantDiscount = async (id) => {
  const { data } = await instance.delete(`/admin/discount/${id}`);

  return data;
};

export { createSingleMerchantDiscount, deleteSingleMerchantDiscount };
