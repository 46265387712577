import React, { useState } from 'react';
import { Box, Text, Flex } from '@chakra-ui/react';
import { useQuery } from 'react-query';

import { getAdminOverviewData } from '../../queries';
import { SpinningText } from '../../components/common/loaders';
import { SolidBtn } from '../../components/common/buttons';
import {
  DoughnutComponent,
  TopBuying,
  NetworkCharts,
  Statboxes,
  CustomerGrowth,
  TopService,
} from '../../components/admin';
import { ErrorOccured } from '../../components/common/errors';

function Overview() {
  const { isLoading, isError, data } = useQuery(
    'getAdminOverviewData',
    getAdminOverviewData
  );
  const [isOpen, setIsOpen] = useState(false);
  const [stats, setStats] = useState('month');

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  if (isLoading) {
    return <SpinningText />;
  }

  if (isError) {
    return <ErrorOccured />;
  }

  return (
    <Box w="97.5%" mb="2rem">
      <Text fontFamily="bold" fontSize="3.2rem" lineHeight="3.8rem">
        Overview
      </Text>
      <Box mt="2rem">
        <Text
          color="#444444"
          fontSize="1.6rem"
          fontFamily="regular"
          lineHeight="1.9rem"
        >
          Hello,
          <Box
            as="span"
            color="#444444"
            fontSize="1.6rem"
            fontFamily="bold"
            lineHeight="1.9rem"
            ml=".5rem"
          >
            Admin
          </Box>
        </Text>
        <Text
          fontFamily="regular"
          color="#808080"
          fontSize="1.4rem"
          lineHeight="120%"
        >
          Here are the summary of what is happening on your board this Month.
        </Text>
      </Box>
      <Box d="flex" w="100%" justifyContent="flex-end">
        <Flex w="18.7rem" borderRadius="4px" bg="#F8F9FB">
          <SolidBtn
            h="100%"
            backgroundColor={stats === 'month' ? '#2EAE65' : 'transparent'}
            color={stats === 'month' ? '#fff' : '#939AA3'}
            fontSize="1.4rem"
            fontFamily="medium"
            py="1rem"
            onClick={() => setStats('month')}
          >
            Month
          </SolidBtn>
          <SolidBtn
            h="100%"
            backgroundColor={stats === 'week' ? '#2EAE65' : 'transparent'}
            color={stats === 'week' ? '#fff' : '#939AA3'}
            fontSize="1.4rem"
            fontFamily="medium"
            py="1rem"
            onClick={() => setStats('week')}
          >
            Week
          </SolidBtn>
          <SolidBtn
            h="100%"
            backgroundColor={stats === 'day' ? '#2EAE65' : 'transparent'}
            color={stats === 'day' ? '#fff' : '#939AA3'}
            fontSize="1.4rem"
            fontFamily="medium"
            py="1rem"
            onClick={() => setStats('day')}
          >
            Day
          </SolidBtn>
        </Flex>
      </Box>
      <Statboxes
        statisticsData={
          stats === 'month'
            ? data.monthlyStatisticsData
            : stats === 'week'
            ? data.weeklyStatisticsData
            : data.dailyStatisticsData
        }
      />
      <TopBuying />
      <Box d="flex" mt="4rem" justifyContent="space-between" w="100%">
        <DoughnutComponent recentMerchants={data.recentMerchants} />
        <TopService />
      </Box>
      <Box mt="4.3rem" d="flex" justifyContent="space-between" w="100%">
        <NetworkCharts
          networkRequested={
            isOpen ? data?.networkDataRequested : data?.networkAirtimeRequested
          }
          handleOpen={handleOpen}
          handleClose={handleClose}
          status={isOpen}
        />
        <CustomerGrowth />
      </Box>
    </Box>
  );
}

export { Overview };
