import axios from 'axios';
import instance from '../axios.config';

const getAllMerchants = async (key, page = 1) => {
  const { data } = await instance.get(`/admin/merchant?page=${page}`);

  return data;
};

const getSingleMerchantProfileAndDocument = async (key, id) => {
  const [profileRes, documentRes] = await axios.all([
    instance.get(`/admin/merchant/${id}/profile`),
    instance.get(`/admin/merchant/${id}/document`),
  ]);

  return {
    profileData: profileRes.data.data,
    documentData: documentRes.data.data,
  };
};

const getSingleMerchantChart = async (key, id, date) => {
  const { data } = await instance.get(
    `/admin/merchant/${id}/tx-summary?year=${date.year}-01-01&month=${date.year}-${date.month}-01`
  );

  return data.data;
};

const getSingleMerchantTable = async (key, id) => {
  const { data } = await instance.get(`/admin/merchant/${id}/tx-history`);

  return data.data;
};

const searchMerchants = async (name) => {
  const { data } = await instance.get(`/admin/merchant?q=%${name}%`);

  return data;
};

const sendMessageToMerchant = async (id, body) => {
  try {
    const { data } = await instance.post(`/admin/merchant/${id}/message`, body);

    return data;
  } catch (error) {
    handleError(error);
  }
};

export {
  getAllMerchants,
  getSingleMerchantProfileAndDocument,
  searchMerchants,
  sendMessageToMerchant,
  getSingleMerchantTable,
  getSingleMerchantChart,
};
