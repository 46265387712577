import React, { useState } from 'react';
import { Box, Text, Flex, Image, Checkbox } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import login from '../../assets/images/login.svg';
import { CustomInput, InputError } from '../../components/common/form/Input';
import {
  LockIcon,
  MailIcon,
  UserIcon,
  UserIcon2,
  DoubleUserIcon,
  LocationIcon,
  SuccessIcon,
} from '../../components/signup';
import { SolidBtn } from '../../components/common/buttons';
import { merchantSignup } from '../../queries';
import { toast } from 'react-toastify';
import { ReactComponent as ShowPassword } from '../../assets/icons/show.svg';

function Signup() {
  const [isLoading, setIsLoading] = useState(false);
  const [isSignupSuccessful, setIsSignupSuccessful] = useState(false);
  const { register, handleSubmit, errors } = useForm();
  const [passwordInputType, setpasswordInputType] = useState('password');
  const [termsChecked, setTermsChecked] = useState(false);

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      await merchantSignup(data);
      toast.success('Signup successful');
      setIsSignupSuccessful(true);
    } catch (error) {
      const message = error.response?.data?.message || 'Something went wrong';
      toast.error(message);
      setIsLoading(false);
    }
  };

  const updateTermsStatus = (e) => {
    if (e.target.checked) {
      setTermsChecked(true);
    } else {
      setTermsChecked(false);
    }
  };

  return (
    <Flex align="center" h="100vh" w="100vw" overflow="hidden">
      <Flex
        justify="center"
        align="flex-end"
        bg="brand.blue"
        w="55%"
        h="100%"
        color="white"
        pt="7.4rem"
        pl="5rem"
      >
        <Flex direction="column" justify="space-between" w="100%" maxW="690px">
          <Box mb="5rem" ml="2%">
            <Text lineHeight="4.3rem" fontSize="3.8rem" fontFamily="bold">
              Virtual airtime <br /> and data recharge <br /> made easy.
            </Text>
            <Text
              lineHeight="2.4rem"
              mt="2.3rem"
              fontSize="2rem"
              fontFamily="regular"
            >
              Awake is a mobile virtual credit company focused <br /> on
              bridging the gap between the telcos and <br /> businesses in
              Nigeria.
            </Text>
          </Box>
          <Box w="100%">
            <Image src={login} w="100%" maxW="100%" />
          </Box>
        </Flex>
      </Flex>
      <CustomFlex
        w="45%"
        h="100vh"
        p="4.8rem 4.8rem 0"
        overflowY="auto"
        justify="center"
      >
        {isSignupSuccessful ? (
          <CustomBox m="0 auto" textAlign="center">
            <Text
              color="#212D43"
              fontFamily="bold"
              fontSize="3.2rem"
              letterSpacing="-0.02em"
              mb=".7rem"
            >
              Success!
            </Text>
            <Text
              fontFamily="regular"
              lineHeight="140%"
              color="#808080"
              fontSize="16px"
            >
              Your account has been successfully created. You can <br />
              now sign in to view your dashboard
            </Text>
            <Flex mt="3.2rem" w="100%" justify="center">
              <SuccessIcon />
            </Flex>

            <SolidBtn
              as={Link}
              to="/merchant/login"
              fontFamily="medium"
              mt="4.8rem"
            >
              Sign in
            </SolidBtn>
          </CustomBox>
        ) : (
          <Box w="100%">
            <Box borderBottom="1px solid #EFEFEF" textAlign="right" pb="2rem">
              <Text
                as="span"
                color="#939AA3"
                letterSpacing="-0.02em"
                fontFamily="medium"
                mr="1.2rem"
              >
                Already have an account.
              </Text>
              <Text
                as={Link}
                to="/merchant/login"
                fontFamily="medium"
                color="brand.blue"
                letterSpacing="-0.02em"
              >
                Sign in
              </Text>
            </Box>
            <Box
              as="form"
              onSubmit={handleSubmit(onSubmit)}
              w="70%"
              m="10.2rem auto 0"
            >
              <Text fontSize="2.8rem" fontFamily="regular" color="brand.blue">
                Sign up
              </Text>
              <Text
                fontFamily="bold"
                mt="4rem"
                fontSize="2.4rem"
                color="#939AA3"
                letterSpacing="-0.02em"
              >
                Register now as a merchant
              </Text>
              <Text
                mt=".6rem"
                fontFamily="medium"
                color="#939AA3"
                lineHeight="140%"
              >
                Provide the below requested details to become a merchant with
                Awake company.
              </Text>
              <CustomInput
                id="firstName"
                label="First Name"
                name="firstName"
                inputRef={register({ required: true })}
              >
                <UserIcon />
              </CustomInput>
              {errors.firstName && <InputError text="This field is required" />}
              <CustomInput
                id="lastName"
                label="Last Name"
                name="lastName"
                style={{ marginTop: '2rem' }}
                inputRef={register({ required: true })}
              >
                <UserIcon2 />
              </CustomInput>
              {errors.lastName && <InputError text="This field is required" />}
              <CustomInput
                id="companyName"
                label="Company Name"
                name="companyName"
                style={{ marginTop: '2rem' }}
                inputRef={register({ required: true })}
              >
                <DoubleUserIcon />
              </CustomInput>
              {errors.companyName && (
                <InputError text="This field is required" />
              )}
              <CustomInput
                id="companyAddress"
                label="Company Address"
                name="companyAddress"
                style={{ marginTop: '2rem' }}
                inputRef={register({ required: true })}
              >
                <LocationIcon />
              </CustomInput>
              {errors.companyAddress && (
                <InputError text="This field is required" />
              )}
              <CustomInput
                id="email"
                label="Email"
                type="email"
                name="email"
                style={{ marginTop: '2rem' }}
                inputRef={register({
                  required: 'Email is required',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Invalid email address',
                  },
                })}
              >
                <MailIcon />
              </CustomInput>
              {errors.email && <InputError text="This field is required" />}
              <CustomInput
                id="password"
                label="Create Password"
                type={passwordInputType}
                name="password"
                style={{ marginTop: '2rem' }}
                inputRef={register({ required: true })}
                rightAddon={
                  <ShowPassword
                    onClick={() =>
                      passwordInputType === 'password'
                        ? setpasswordInputType('text')
                        : setpasswordInputType('password')
                    }
                  />
                }
              >
                <LockIcon />
              </CustomInput>
              {errors.password && <InputError text="This field is required" />}
              <SolidBtn
                type="submit"
                fontFamily="medium"
                mt="3.2rem"
                isDisabled={!termsChecked || isLoading}
              >
                Sign up
              </SolidBtn>
              <Flex p="1.8rem 0 7.2rem" align="start">
                <Box mt=".4rem">
                  <Checkbox
                    required
                    boxShadow="none"
                    size="lg"
                    w="14px"
                    id="customCheckbox"
                    onChange={updateTermsStatus}
                  ></Checkbox>
                </Box>
                <Text
                  fontFamily="medium"
                  lineHeight="140%"
                  color="#939AA3"
                  ml="1.5rem"
                >
                  By signing up I agree with
                  <Text as={Link} m="0 4px" color="brand.blue">
                    Terms and Conditions
                  </Text>
                  of the above organization.
                </Text>
              </Flex>
            </Box>
          </Box>
        )}
      </CustomFlex>
    </Flex>
  );
}

export { Signup };

const CustomFlex = styled(Flex)`
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const CustomBox = styled(Box)`
  width: 75%;
  margin-top: 15rem;
  @media (min-width: 1441px) {
    margin-top: 20rem;
    width: 60%;
  }
`;
