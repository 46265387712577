import { handleError } from '../../utils';
import instance from '../axios.config';

const uploadDoc = async (body, setPercentage) => {
  try {
    const options = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        const percent = Math.floor((loaded * 100) / total);

        if (percent <= 100) {
          setPercentage(percent);
        }
      },
    };

    const { data } = await instance.post('/merchant/document', body, options);

    return data;
  } catch (error) {
    handleError(error);
  }
};

export { uploadDoc };
