import { Box, Flex, HStack, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { defaults, Doughnut } from 'react-chartjs-2';
import styled from '@emotion/styled';

import { CustomTable, TableWrapper } from '../../../components/common/tables';
import { ReactComponent as FallbackIcon } from '../../../assets/icons/fallback.svg';
import dayjs from 'dayjs';
import { nFormatter } from '../../../utils';

function TransactionHistory({ topSellingProducts, lastFiveTx }) {
  defaults.global.legend = false;

  const doughnutData = (() => {
    const { airtime = 0, data: dataBundle = 0 } = topSellingProducts;

    let content = null;

    if (airtime > dataBundle) {
      content = {
        title: 'Airtime',
        value: airtime,
      };
    } else {
      content = {
        title: 'Data',
        value: dataBundle,
      };
    }

    return {
      chartData: {
        labels: ['Airtime', 'Data'],
        datasets: [
          {
            backgroundColor: ['#FDB600', '#3558C7'],
            data: [airtime?.toFixed(0), dataBundle?.toFixed(0)],
          },
        ],
      },
      content,
    };
  })();

  const transactions = (() => {
    return lastFiveTx.map((item) => {
      return {
        col1: <Text fontFamily="medium">{item.phone_number}</Text>,
        col2: `${nFormatter(item.amount)}`,
        col3: (
          <Text fontFamily="medium">
            {dayjs(item.created_at).format('MMM D, YYYY')}
            <Text as="span" opacity=".4" ml="1rem">
              {dayjs(item.created_at).format('HH:mm A')}
            </Text>{' '}
          </Text>
        ),
        col4: (
          <Text color="#2EAE65" fontFamily="medium">
            {item.tx_status}
          </Text>
        ),
      };
    });
  })();

  const tableCols = [
    {
      Header: 'Phone number',
      accessor: 'col1',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Amount',
      accessor: 'col2',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Date',
      accessor: 'col3',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Status',
      accessor: 'col4',
      disableSortBy: true,
      disableFilters: true,
    },
  ];

  return (
    <Box d="flex" mt="4rem" justifyContent="space-between" w="100%">
      <Box
        w="62%"
        pl="3.3rem"
        pr="2.4rem"
        pt="2.9rem"
        boxShadow="0px 0px 4px rgba(0, 0, 0, 0.15)"
        h="50rem"
      >
        <Box d="flex" alignItems="center" justifyContent="space-between">
          <Text
            color="#212D43"
            fontSize="2rem"
            lineHeight="2.4rem"
            fontFamily="medium"
          >
            Transaction History
          </Text>
        </Box>
        {lastFiveTx.length > 0 ? (
          <TransactionsTable>
            <CustomTable data={transactions} columns={tableCols} />
          </TransactionsTable>
        ) : (
          <Box>
            <Flex align="center" justify="center" mt="6rem">
              <FallbackIcon />
            </Flex>
            <Text fontSize="1.6rem" textAlign="center" fontFamily="medium">
              No transaction history yet
            </Text>
          </Box>
        )}
      </Box>
      <Box
        pl="4rem"
        pt="3.2rem"
        pr="4.5rem"
        h="50rem"
        w="36%"
        boxShadow="0px 0px 4px rgba(0, 0, 0, 0.15)"
      >
        <Text
          color="#212D43"
          fontFamily="medium"
          fontSize="2rem"
          lineHeight="2.4rem"
          textAlign="center"
        >
          Top service subscription
        </Text>
        <VStack>
          <Box w="23.5rem" h="23.5rem" mt="5rem" mb="7rem">
            {doughnutData?.chartData.datasets.length > 0 ? (
              <Doughnut
                data={doughnutData?.chartData}
                options={{ maintainAspectRatio: false, cutoutPercentage: 90 }}
              />
            ) : (
              <Box>
                <Flex align="center" justify="center" mt="6rem">
                  <FallbackIcon />
                </Flex>
                <Text fontSize="1.6rem" textAlign="center" fontFamily="medium">
                  No data for top subscription yet
                </Text>
              </Box>
            )}

            {doughnutData?.chartData.datasets.length > 0 ? (
              <Box position="relative" bottom="60%" left="35%">
                <Text
                  color="brand.blue"
                  fontFamily="regular"
                  fontSize="1.4rem"
                  lineHeight="1.7rem"
                  ml="1rem"
                >
                  {doughnutData.content.title}
                </Text>
                <Text fontFamily="bold" fontSize="3.2rem" lineHeight="3.8rem">
                  {parseInt(doughnutData.content.value.toFixed(0))}%
                </Text>
              </Box>
            ) : (
              ''
            )}
          </Box>
          {doughnutData?.chartData.datasets.length > 0 ? (
            <HStack spacing="4rem">
              <Box d="flex" alignItems="center">
                <Box bg="#FDB600" w="1.3rem" h="1.3rem" />
                <Text
                  ml="1.2rem"
                  color="#979797"
                  fontFamily="regular"
                  fontSize="1.4rem"
                  lineHeight="1.7rem"
                >
                  Airtime
                </Text>
              </Box>
              <Box mt="1.6rem" d="flex" alignItems="center">
                <Box bg="brand.blue" w="1.3rem" h="1.3rem" />
                <Text
                  ml="1.2rem"
                  color="#979797"
                  fontFamily="regular"
                  fontSize="1.4rem"
                  lineHeight="1.7rem"
                >
                  Data
                </Text>
              </Box>
            </HStack>
          ) : (
            ''
          )}
        </VStack>
      </Box>
    </Box>
  );
}

export { TransactionHistory };

const TransactionsTable = styled(TableWrapper)`
  margin-top: 3.7rem;

  thead {
    background-color: #0041be;
  }
  th {
    color: #fff;
  }
`;
