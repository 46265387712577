import React, { useContext } from 'react';
import { ErrorOccured } from '../components/common/errors';
import { AuthContext } from '../context/auth/provider';

function Forbidden() {
  const { authState } = useContext(AuthContext);

  return (
    <ErrorOccured
      message="Access Denied"
      page={authState.is_admin ? '/admin/login' : '/merchant/login'}
    />
  );
}

export { Forbidden };
