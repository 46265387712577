import React, { useState, useEffect } from 'react';
import {
  Box,
  Text,
  Flex,
  Image,
  Textarea,
  Button,
  Input,
  HStack,
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { SpinningText } from '../../components/common/loaders';
import { useQuery } from 'react-query';
import { getAllMerchants, searchMerchants } from '../../queries';

import { CustomInput } from '../../components/common/form/Input';
import { CustomTable, TableWrapper } from '../../components/common/tables';
import { SolidBtn } from '../../components/common/buttons';
import Search from '../../assets/icons/search_icon.svg';
import { ReactComponent as Export } from '../../assets/icons/Export.svg';
import { ReactComponent as FallbackIcon } from '../../assets/icons/fallback.svg';
import { RightModal } from '../../components/common/modals';
import { broadcastMessage } from '../../queries/admin';
import { ExportMerchant } from '../../components/export/admin';
import { nFormatter } from '../../utils';
import { ErrorOccured } from '../../components/common/errors';

const Merchant = () => {
  const history = useHistory();
  const initialState = {
    subject: '',
    message: '',
  };
  const [page, setPage] = useState(1);
  const [name, setName] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [showSearch, setShowSearch] = useState(false);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState(initialState);
  const { isLoading, isError, data, refetch } = useQuery(
    ['getAllMerchants', page],
    getAllMerchants
  );

  useEffect(() => {
    refetch();
    setShowSearch(false);
  }, [page]);

  // Search handler
  const handleSearchData = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const data = await searchMerchants(name);
      setLoading(false);
      setShowSearch(true);
      setTableData(data.data);
    } catch (err) {
      setLoading(false);
      setShowSearch(false);
      throw err;
    }
  };

  if (isLoading) {
    return <SpinningText />;
  }

  if (isError) {
    return <ErrorOccured />;
  }

  const toggleModal = () => {
    setIsOpen({ isOpen: true });
  };

  // handle open export modal
  const handleOpen = () => {
    setOpen(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const notify = async () => {
    try {
      setSubmitting(true);
      const res = await broadcastMessage(state);
      setSubmitting(false);
      setIsOpen(false);
      setState(initialState);
      toast.success(res.message);
    } catch (error) {
      setSubmitting(false);
      toast.error(error.response.data.message);
    }
  };

  const totalPages = data?.data?.totalPages;
  const totalData = data?.data?.total;
  const currentPage = data?.data?.currentPage;

  const handleClick = async (id) => {
    history.push(`/admin/merchant/${id}`);
  };

  const disableBtn = state.subject === '' || state.message === '';

  const defaultData = data?.data?.merchants?.map((e, id) => {
    return {
      col1: e.company_name,
      col2: e.volume,
      col3: e.balance,
      col4: e.created_at,
      col5: e.account_status,
      col6: (
        <Button
          variant="link"
          fontFamily="regular"
          fontSize="14px"
          fontWeight="bold!important"
          color="brand.blue"
          outline="none"
          _focus={{
            boxShadow: 'none',
          }}
          onClick={() => handleClick(e.id)}
        >
          View
        </Button>
      ),
    };
  });

  const showSearchData = tableData?.map((e, id) => {
    return {
      col1: e.company_name,
      col2: e.volume,
      col3: e.balance,
      col4: e.created_at,
      col5: e.account_status,
      col6: (
        <Button
          variant="link"
          fontFamily="regular"
          fontSize="14px"
          fontWeight="bold!important"
          color="brand.blue"
          outline="none"
          _focus={{
            boxShadow: 'none',
          }}
          onClick={() => handleClick(e.id)}
        >
          View
        </Button>
      ),
    };
  });

  const defaultColumns = [
    {
      Header: 'Company name',
      accessor: 'col1',
      disableFilters: true,
    },
    {
      Header: 'Transaction volume',
      accessor: 'col2',
      Cell: ({ value }) => nFormatter(value),
      disableFilters: true,
    },
    {
      Header: 'Wallet balance',
      accessor: 'col3',
      Cell: ({ value }) => nFormatter(value),
      disableFilters: true,
    },
    {
      Header: 'Date joined',
      accessor: 'col4',
      Cell: ({ value }) => dayjs(value).format('MMM D, YYYY'),
      disableFilters: true,
    },
    {
      Header: 'Status',
      accessor: 'col5',
      Cell: ({ value }) => (
        <Box
          d="flex"
          alignItems="center"
          justifyContent="center"
          w="8.9rem"
          h="3.2rem"
          bg="#E8f6f0"
          borderRadius="8px"
        >
          <Text
            color={'#2EAE65'}
            fontFamily="regular"
            fontSize="1.5rem"
            lineHeight="1.8rem"
            fontWeight="bold!important"
            textTransform="capitalize"
          >
            {value}
          </Text>
        </Box>
      ),
      disableFilters: true,
    },
    {
      Header: '',
      accessor: 'col6',
      disableSortBy: true,
      disableFilters: true,
    },
  ];

  return (
    <Box>
      <Flex justify="space-between">
        <Text
          color="#212D43"
          lineHeight="3.8rem"
          fontSize="3.2rem"
          fontFamily="bold"
        >
          Merchant Management
        </Text>
        <SolidBtn onClick={toggleModal} borderRadius="8px" w="17rem" h="4.2rem">
          Broadcast message
        </SolidBtn>
      </Flex>
      <Flex mt="5rem" alignItems="center" justify="space-between">
        <form onSubmit={handleSearchData}>
          <CustomInput
            style={{ marginTop: 0 }}
            w="33.2rem"
            borderRadius="4px"
            label="Search"
            value={name}
            onChange={(e) => setName(e.target.value)}
          >
            <Image src={Search} alt="search_icon" />
          </CustomInput>
        </form>
        <Flex alignItems="center" justifyContent="space-between">
          <HStack spacing="3.5rem">
            <Export cursor="pointer" onClick={handleOpen} />
          </HStack>
        </Flex>
      </Flex>
      <Box
        boxShadow=" 0px 0px 4px rgba(0, 0, 0, 0.15)"
        pt="1rem"
        pl="4.2rem"
        pr="4rem"
        mt="2.1rem"
        overflow="auto"
        minH="60vh"
      >
        {defaultData.length < 1 ? (
          <Box>
            <Flex align="center" justify="center" mt="6rem">
              <FallbackIcon />
            </Flex>
            <Text fontSize="1.6rem" textAlign="center" fontFamily="medium">
              No merchant yet
            </Text>
          </Box>
        ) : (
          <Box>
            {loading ? (
              <SpinningText />
            ) : (
              <Box>
                {showSearch && tableData.length < 1 ? (
                  <Box>
                    <Flex align="center" justify="center" mt="6rem">
                      <FallbackIcon />
                    </Flex>
                    <Text
                      fontSize="1.6rem"
                      textAlign="center"
                      fontFamily="medium"
                    >
                      No merchant with that name found
                    </Text>
                  </Box>
                ) : (
                  <TableWrapper>
                    <CustomTable
                      data={showSearch ? showSearchData : defaultData}
                      columns={defaultColumns}
                      currentPage={currentPage}
                      totalPages={totalPages}
                      totalData={totalData}
                      myPage={page}
                      setPage={setPage}
                    />
                  </TableWrapper>
                )}
              </Box>
            )}
          </Box>
        )}
      </Box>
      <RightModal showModal={isOpen} setShowModal={setIsOpen} p="0">
        <Box pl="4rem">
          <Text
            color="#212D43"
            fontSize="3.2rem"
            lineHeight="3.8rem"
            fontFamily="bold"
            mt="6rem"
          >
            Compose <br /> new message
          </Text>
          <Text mt="1.6rem" color="#808080" fontFamily="regular">
            Send new message to the merchant for any request or to pass
            important information across.
          </Text>
        </Box>
        <Box borderBottom="1px solid #E4E4E4" mt="4.8rem" mb="1.2rem" />
        <Box d="flex" alignItems="center">
          <Text pl="4rem" color="#979797" fontFamily="medium">
            Subject:
          </Text>
          <Box ml="1.6rem">
            <Input
              fontFamily="regular"
              fontSize="1.5rem"
              border="none"
              focusBorderColor="none"
              name="subject"
              value={state.subject}
              onChange={handleChange}
            />
          </Box>
        </Box>
        <Box borderTop="1px solid #E4E4E4" mt="1.2rem" mb="1.2rem" />
        <Box pl="4rem" pr="2rem" pt="2.2rem">
          <StyleTextarea
            fontFamily="regular"
            fontSize="1.5rem"
            border="none"
            placeholder="Enter message"
            focusBorderColor="#E4E4E4"
            resize="none"
            name="message"
            value={state.message}
            onChange={handleChange}
          />
          <Box d="flex" w="100%" justifyContent="flex-end">
            <SolidBtn
              isDisabled={disableBtn}
              isLoading={submitting}
              onClick={notify}
              w="10.6rem"
              mt="2rem"
            >
              Send
            </SolidBtn>
          </Box>
        </Box>
        <Box borderTop="1px solid #E4E4E4" mt="1.7rem" />
      </RightModal>
      <ExportMerchant open={open} setOpen={setOpen} />
    </Box>
  );
};

export { Merchant };

const StyleTextarea = styled(Textarea)`
  height: 30.5rem;
  border: none;
  @media screen and (min-width: 1441px) {
    height: 46.5rem;
  }
`;
