import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthContext } from '../../context/auth';

function PrivateRoute({ path, exact, component }) {
  const { authState } = useContext(AuthContext);

  // Check is user is logged in
  if (!authState.token) {
    return <Redirect to="/merchant/login" />;
  }

  // Check if a merchant is trying to access an admin route
  if (path.startsWith('/admin') && !authState.is_admin) {
    return <Redirect to="/forbidden" />;
  }

  // Check if an admin is trying to access a merchant route
  if (path.startsWith('/merchant') && authState.is_admin) {
    return <Redirect to="/forbidden" />;
  }

  return <Route path={path} exact={exact} component={component} />;
}

export { PrivateRoute };
