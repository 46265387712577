import React, { useContext, useState } from 'react';
import { Box, Text, Flex, Image } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import loginImg from '../../assets/images/login.svg';
import { CustomInput, InputError } from '../../components/common/form/Input';
import { LockIcon, MailIcon } from '../../components/login';
import { SolidBtn } from '../../components/common/buttons';

import { ReactComponent as ShowPassword } from '../../assets/icons/show.svg';

import { merchantlogin } from '../../queries';
import { AuthContext, LOGIN } from '../../context/auth';

function Login() {
  const { dispatch } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const { register, handleSubmit, errors } = useForm();
  const [passwordInputType, setpasswordInputType] = useState('password');

  const onSubmit = async (inputData) => {
    try {
      setIsLoading(true);
      const { data } = await merchantlogin(inputData);

      dispatch({ type: LOGIN, payload: data });

      if (data.isVerified) {
        window.location.replace('/merchant/overview');
      } else if (Number(data.docCount < 3)) {
        window.location.replace('/merchant/upload');
      } else if (Number(data.docCount) >= 3 && data.comment) {
        window.location.replace('/merchant/declined');
      } else {
        window.location.replace('/merchant/waiting');
      }

      toast.success('Login successful');
    } catch (error) {
      const message = error.response?.data?.message || 'Something went wrong';
      toast.error(message);
      setIsLoading(false);
    }
  };

  return (
    <Flex align="center" h="100vh" overflow="hidden">
      <Flex
        justify="center"
        align="flex-end"
        bg="brand.blue"
        w="55%"
        h="100%"
        color="white"
        pt="7.4rem"
        pl="5rem"
        overflow="hidden"
      >
        <Flex direction="column" justify="space-between" w="100%" maxW="690px">
          <Box mb="5rem" ml="2%">
            <Text lineHeight="4.3rem" fontSize="3.8rem" fontFamily="bold">
              Virtual airtime <br /> and data recharge <br /> made easy.
            </Text>
            <Text
              lineHeight="2.4rem"
              mt="2.3rem"
              fontSize="2rem"
              fontFamily="regular"
            >
              Awake is a mobile virtual credit company focused <br /> on
              bridging the gap between the telcos and <br /> businesses in
              Nigeria.
            </Text>
          </Box>
          <Box w="100%">
            <Image src={loginImg} w="100%" maxW="100%" />
          </Box>
        </Flex>
      </Flex>
      <Flex w="45%" h="100%" justify="center" pos="relative">
        <Box
          borderBottom="1px solid #EFEFEF"
          textAlign="right"
          py="2rem"
          pr="2rem"
          pos="absolute"
          top="0"
          w="100%"
        >
          <Text
            as="span"
            color="#939AA3"
            letterSpacing="-0.02em"
            fontFamily="medium"
            mr="1.2rem"
          >
            Sign in as an admin ?
          </Text>
          <Text
            as={Link}
            to="/admin/login"
            fontFamily="medium"
            color="brand.blue"
            letterSpacing="-0.02em"
          >
            Sign in
          </Text>
        </Box>
        <Box as="form" mt="18.4rem" onSubmit={handleSubmit(onSubmit)}>
          <Text fontSize="2.8rem" fontFamily="regular" color="brand.blue">
            Sign In
          </Text>
          <Text fontFamily="bold" mt="4rem" fontSize="2.8rem" color="#212D43">
            Welcome
          </Text>
          <Text w="80%" fontFamily="regular" color="#212D43">
            You can now login into your dashboard with your email and password.
          </Text>
          <CustomInput
            id="email"
            label="Email"
            name="email"
            inputRef={register({
              required: 'Email is required',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'Invalid email address',
              },
            })}
          >
            <MailIcon />
          </CustomInput>
          {errors.email && <InputError text={errors.email.message} />}
          <CustomInput
            id="password"
            label="Password"
            type={passwordInputType}
            name="password"
            style={{ marginTop: '2rem' }}
            inputRef={register({
              required: 'Password is required',
            })}
            rightAddon={
              <ShowPassword
                onClick={() =>
                  passwordInputType === 'password'
                    ? setpasswordInputType('text')
                    : setpasswordInputType('password')
                }
              />
            }
          >
            <LockIcon />
          </CustomInput>
          {errors.password && <InputError text={errors.password.message} />}
          <SolidBtn type="submit" mt="3.2rem" isLoading={isLoading}>
            Sign in
          </SolidBtn>
          <Flex justify="space-between" mt="2.4rem">
            <Text
              color="brand.blue"
              fontFamily="regular"
              as={Link}
              to="/merchant/forgot-password"
            >
              I forgot my password
            </Text>
            <Box>
              <Text
                as="span"
                color="#939AA3"
                letterSpacing="-0.02em"
                fontFamily="medium"
                mr="1rem"
              >
                Don&apos;t have an account?.
              </Text>
              <Text
                as={Link}
                to="/merchant/signup"
                fontFamily="medium"
                color="brand.blue"
                letterSpacing="-0.02em"
              >
                Sign up
              </Text>
            </Box>
          </Flex>
        </Box>
      </Flex>
    </Flex>
  );
}

export { Login };
