import React, { useEffect, useState } from 'react';
import { HStack, Flex, Box, Text } from '@chakra-ui/react';
import { Bar, defaults } from 'react-chartjs-2';

import { ReactComponent as WalletIcon } from '../../../assets/icons/walletIconWhite.svg';
import { ReactComponent as ArrowCircle } from '../../../assets/icons/arrowCircle.svg';
import { ReactComponent as FallbackIcon } from '../../../assets/icons/fallback.svg';
import { nFormatter } from '../../../utils';
import { BankDetailsModal } from '../wallet';

function WalletInfo({
  walletBalance,
  amountFundedPerYear,
  setShowCenterModal,
  bankDetails,
}) {
  defaults.global.legend = false;
  const [chartStepSize, setChartStepSize] = useState(0);
  const [walletHistoryData, setWalletHistoryData] = useState(null);
  const [showRightModal, setShowRightModal] = useState(false);

  useEffect(() => {
    const labels = amountFundedPerYear.map((item) => item.year);

    const amounts = amountFundedPerYear.map((item) => Number(item.amount));

    const maxAmt = Math.max(amounts);

    setChartStepSize(maxAmt / 5);

    setWalletHistoryData({
      labels,
      datasets: [
        {
          barThickness: 8,
          backgroundColor: '#c4c4c4',
          data: amounts,
        },
      ],
    });
  }, []);

  return (
    <>
      <HStack spacing="4rem" w="100%" align="stretch" mt="6rem">
        <Flex
          bg="#fff"
          w="30%"
          p="5.6rem 5.1rem"
          boxShadow="0px 0px 4px rgba(0, 0, 0, 0.15)"
          justify="center"
          align="center"
        >
          <Box>
            <Text color="#c4c4c4" fontSize="1.4rem" fontFamily="medium">
              Wallet balance
            </Text>
            <Text color="#444444" fontSize="4rem" fontFamily="bold" mt="3.5rem">
              {nFormatter(walletBalance?.balance)}
            </Text>
            <Text
              color="brand.blue"
              fontSize="1.4rem"
              fontFamily="medium"
              opacity="0.45"
              mt="-0.5rem"
            >
              Available for spending
            </Text>
            <Flex
              align="center"
              justify="space-between"
              as="button"
              bg="brand.blue"
              py=".8rem"
              pl="3.4rem"
              pr=".3rem"
              mt="6rem"
              w="24rem"
              borderRadius="20rem"
              cursor="pointer"
              onClick={() => setShowCenterModal(true)}
            >
              <HStack align="center">
                <WalletIcon />
                <Text color="#fff" fontFamily="medium" fontSize="1.5rem">
                  Fund Wallet
                </Text>
              </HStack>
              <ArrowCircle />
            </Flex>
            <Text fontFamily="regular" color="#444" mt="2.4rem">
              View bank details{' '}
              <Text
                fontFamily="bold"
                fontStyle="italic"
                color="brand.blue"
                textDecor="underline"
                as="span"
                cursor="pointer"
                onClick={() => setShowRightModal(true)}
              >
                here
              </Text>
            </Text>
          </Box>
        </Flex>
        <Box
          boxShadow="0px 0px 4px rgba(0, 0, 0, 0.15)"
          w="70%"
          minH="100%"
          p="3rem"
        >
          <Flex align="center" justify="space-between">
            <Text
              color="#808080"
              fontFamily="medium"
              fontSize="1.6rem"
              lineHeight="2.4rem"
            >
              Wallet funding history
            </Text>
          </Flex>
          <Box w="100%" maxH="335px" mt="3rem">
            {walletHistoryData?.datasets.length < 1 ? (
              <Box>
                <Flex align="center" justify="center" mt="6rem">
                  <FallbackIcon />
                </Flex>
                <Text fontSize="1.6rem" textAlign="center" fontFamily="medium">
                  No data for funding history yet
                </Text>
              </Box>
            ) : (
              <Bar
                height={300}
                data={walletHistoryData}
                options={{
                  maintainAspectRatio: false,
                  responsive: true,
                  tooltips: {
                    callbacks: {
                      label: (tooltipItem, data) =>
                        'Amount: ' + nFormatter(tooltipItem.yLabel),
                    },
                  },
                  scales: {
                    xAxes: [
                      {
                        categoryPercentage: 1.0,
                        gridLines: {
                          display: false,
                        },
                      },
                    ],
                    yAxes: [
                      {
                        ticks: {
                          beginAtZero: true,
                          stepSize: chartStepSize,
                          userCallback: (value) => nFormatter(value),
                        },
                      },
                    ],
                  },
                }}
              />
            )}
          </Box>
        </Box>
      </HStack>
      <BankDetailsModal
        bankDetails={bankDetails}
        showRightModal={showRightModal}
        setShowRightModal={setShowRightModal}
      />
    </>
  );
}

export { WalletInfo };
