import React, { useState } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { toast } from 'react-toastify';

import { SolidBtn, OutlineBtn } from '../../common/buttons';
import { CustomInput } from '../../common/form/Input';
import { CenterModal } from '../../common/modals';
import { exportAuditTrail } from '../../../queries';

const ExportAuditTrail = ({ open, setOpen }) => {
  const initialState = {
    limit: '',
    start: '',
    end: '',
  };
  const [state, setState] = useState(initialState);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Export function
  const downloadData = () => {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style = 'display: none';
    return (data, fileName) => {
      const blob = new Blob([data], { type: 'octet/stream' });
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
    };
  };

  const handleExport = async () => {
    try {
      setLoading(true);
      const data = await exportAuditTrail({
        limit: state.limit,
        start: state.start,
        end: state.end,
      });
      downloadData()(data, ' audit_trail.csv');
      toast.success('Exported Successfully');
      setLoading(false);
      setOpen(false);
      setState(initialState);
    } catch (err) {
      setLoading(false);
      setOpen(false);
      throw err;
    }
  };

  const d1 = new Date(state.start);
  const d2 = new Date(state.end);
  const lessThan = d1.getTime() < d2.getTime();

  const disableBtn =
    state.start === '' ||
    state.end === '' ||
    state.limit === '' ||
    lessThan === false;

  return (
    <StyledWrapper>
      <CenterModal title="Export" showModal={open} setShowModal={setOpen}>
        <CustomInput
          type="number"
          hideIcon
          style={{ marginTop: 0 }}
          w="39.4rem"
          borderRadius="4px"
          label="Number of Records"
          value={state.limit}
          name="limit"
          onChange={handleChange}
        ></CustomInput>
        <CustomInput
          hideIcon
          w="39.4rem"
          borderRadius="4px"
          label="Date Range Start"
          type="date"
          mt="1.6rem"
          name="start"
          value={state.start}
          onChange={handleChange}
        ></CustomInput>
        <CustomInput
          hideIcon
          w="39.4rem"
          borderRadius="4px"
          label="Date Range End"
          type="date"
          mt="1.6rem"
          name="end"
          value={state.end}
          onChange={handleChange}
        ></CustomInput>
        <Box>
          <Text fontFamily="medium" color="#E24D4D">
            {disableBtn ? '* Start must be lesser than end' : ''}
          </Text>
        </Box>
        <Flex align="center" justify="center" mt="5.6rem">
          <OutlineBtn
            onClick={() => setOpen(false)}
            borderColor="#939AA3"
            color="#939AA3"
            h="4.5rem"
            w="9rem"
          >
            Close
          </OutlineBtn>
          <SolidBtn
            onClick={handleExport}
            isDisabled={disableBtn}
            isLoading={loading}
            ml="1.6rem"
            h="4.5rem"
            w="9rem"
          >
            Export
          </SolidBtn>
        </Flex>
      </CenterModal>
    </StyledWrapper>
  );
};

const StyledWrapper = styled(Box)`
  .closeBtn {
    display: none;
  }
`;

export { ExportAuditTrail };
