import React, { useState, useEffect, useContext } from 'react';
import { Box, Text, Flex, Image } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { useQuery } from 'react-query';

import { CustomInput, InputError } from '../../components/common/form/Input';
import { CustomTable, TableWrapper } from '../../components/common/tables';
import { ReactComponent as FallbackIcon } from '../../assets/icons/fallback.svg';
import { OutlineBtn, SolidBtn } from '../../components/common/buttons';
import Search from '../../assets/icons/search_icon.svg';
import { CenterModal } from '../../components/common/modals';
import {
  addAdmin,
  deleteAdmin,
  getAllAdmins,
  searchAllAdmins,
} from '../../queries';
import { toast } from 'react-toastify';
import { SpinningText } from '../../components/common/loaders';
import { ErrorOccured } from '../../components/common/errors';
import { AuthContext } from '../../context/auth/provider';
import { Redirect } from 'react-router-dom';
import styled from '@emotion/styled';

const UserManagement = () => {
  const { authState } = useContext(AuthContext);
  const [page, setPage] = useState(1);
  const [adminToDel, setAdminToDel] = useState(null);
  const [showAddAdminModal, setShowAddAdminModal] = useState(false);
  const [showDelAdminModal, setShowDelAdminModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState('');
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showSearch, setShowSearch] = useState(false);

  const { isLoading: dataLoading, isError, data, refetch } = useQuery(
    ['getAllAdmins', page],
    getAllAdmins
  );
  const { register, handleSubmit, errors } = useForm();

  useEffect(() => {
    refetch();
    setShowSearch(false);
  }, [page]);

  const totalPages = data?.data?.totalPages;
  const totalData = data?.data?.total;
  const currentPage = data?.data?.currentPage;

  if (!authState.isSuperAdmin) {
    return <Redirect to="/forbidden" />;
  }

  if (dataLoading) {
    return <SpinningText />;
  }

  if (isError) {
    return <ErrorOccured />;
  }

  // Search handler
  const handleSearchData = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const data = await searchAllAdmins(name);
      setLoading(false);
      setShowSearch(true);
      setTableData(data.data);
    } catch (err) {
      setLoading(false);
      setShowSearch(false);
      throw err;
    }
  };

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      const res = await addAdmin(data);
      toast.success(res.message);
      setIsLoading(false);
      setShowAddAdminModal(false);
      refetch();
    } catch (error) {
      setIsLoading(false);
      toast.error(error.response.data.message);
    }
  };

  const handleAdminDelete = (id) => {
    setAdminToDel(id);
    setShowDelAdminModal(true);
  };

  const handleDelete = async () => {
    try {
      setIsLoading(true);
      await deleteAdmin(adminToDel);
      setShowDelAdminModal(false);
      refetch();
      setIsLoading(false);
      toast.success('Admin deleted succesfully');
    } catch (error) {
      toast.error(error.response?.data?.message || 'Something went wrong');
      setIsLoading(false);
    }
  };

  const defaultData = data?.data?.admins?.map((e, id) => {
    return {
      col1: e.name,
      col2: e.email,
      col3: e.last_login_time,
      col4: e.created_at,
      col5: (
        <Box
          d="flex"
          alignItems="center"
          justifyContent="center"
          w="8.9rem"
          h="3.2rem"
          bg="#f00"
          cursor="pointer"
          onClick={() => handleAdminDelete(e.admin_id)}
          borderRadius="8px"
        >
          <Text
            color="white"
            fontFamily="regular"
            fontSize="1.5rem"
            lineHeight="1.8rem"
          >
            Delete
          </Text>
        </Box>
      ),
    };
  });

  const showSearchData = tableData?.map((e, id) => {
    return {
      col1: `${e.first_name} ${e.last_name}`,
      col2: e.email,
      col3: e.last_login_time,
      col4: e.created_at,
      col5: (
        <Box
          d="flex"
          alignItems="center"
          justifyContent="center"
          w="8.9rem"
          h="3.2rem"
          bg="#f00"
          cursor="pointer"
          onClick={() => setShowDelAdminModal(true)}
          borderRadius="8px"
        >
          <Text
            color="white"
            fontFamily="regular"
            fontSize="1.5rem"
            lineHeight="1.8rem"
          >
            Delete
          </Text>
        </Box>
      ),
    };
  });

  const defaultColumns = [
    {
      Header: 'User name',
      accessor: 'col1',
      disableFilters: true,
    },
    {
      Header: 'Email',
      accessor: 'col2',
      disableFilters: true,
    },
    {
      Header: 'Last logged in',
      accessor: 'col3',
      Cell: ({ value }) => (
        <Box d="flex">
          <Text
            fontFamily="regular"
            fontWeight="bold!important"
            fontSize="1.4rem"
            lineHeight="1.8rem"
          >
            {value ? dayjs(value).format('MMM D, YYYY') : 'Not logged in yet'}
          </Text>
          <Text
            ml="1rem"
            fontFamily="regular"
            fontSize="1.4rem"
            lineHeight="1.8rem"
          >
            {value ? dayjs(value).format('HH:mm A') : ''}
          </Text>
        </Box>
      ),
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Date added',
      accessor: 'col4',
      Cell: ({ value }) => dayjs(value).format('MMM D, YYYY'),
      disableFilters: true,
    },
    {
      Header: '',
      accessor: 'col5',
      disableSortBy: true,
      disableFilters: true,
    },
  ];

  return (
    <Box>
      <Flex justify="space-between">
        <Text
          color="#212D43"
          lineHeight="3.8rem"
          fontSize="3.2rem"
          fontFamily="bold"
        >
          User Management
        </Text>
        <SolidBtn
          onClick={() => setShowAddAdminModal(true)}
          borderRadius="8px"
          w="17rem"
          h="4.2rem"
        >
          Add administrator
        </SolidBtn>
      </Flex>
      <form onSubmit={handleSearchData}>
        <CustomInput
          w="33.2rem"
          borderRadius="4px"
          label="Search"
          value={name}
          onChange={(e) => setName(e.target.value)}
        >
          <Image src={Search} alt="search_icon" />
        </CustomInput>
      </form>
      <Box
        boxShadow=" 0px 0px 4px rgba(0, 0, 0, 0.15)"
        p="1rem 4rem 1rem 4.2rem"
        mt="2.1rem"
        minH="60vh"
      >
        {defaultData.length < 1 ? (
          <Box>
            <Flex align="center" justify="center" mt="4rem">
              <FallbackIcon />
            </Flex>
            <Text fontSize="1.6rem" textAlign="center" fontFamily="medium">
              No user yet
            </Text>
          </Box>
        ) : (
          <Box>
            {loading ? (
              <SpinningText />
            ) : (
              <Box>
                {showSearch && tableData.length < 1 ? (
                  <Box>
                    <Flex align="center" justify="center" mt="4rem">
                      <FallbackIcon />
                    </Flex>
                    <Text
                      fontSize="1.6rem"
                      textAlign="center"
                      fontFamily="medium"
                    >
                      No user with that name found
                    </Text>
                  </Box>
                ) : (
                  <TableWrapper>
                    <CustomTable
                      data={showSearch ? showSearchData : defaultData}
                      columns={defaultColumns}
                      currentPage={currentPage}
                      totalPages={totalPages}
                      totalData={totalData}
                      myPage={page}
                      setPage={setPage}
                    />
                  </TableWrapper>
                )}
              </Box>
            )}
          </Box>
        )}
      </Box>
      {/* Add new admin modal */}
      <CenterModal
        showModal={showAddAdminModal}
        setShowModal={setShowAddAdminModal}
        ml="auto"
        title="Add new administrator"
      >
        <Box as="form" onSubmit={handleSubmit(onSubmit)}>
          <CustomInput
            hideIcon
            id="firstName"
            label="Firstname"
            name="firstName"
            style={{ marginTop: '2rem' }}
            inputRef={register({
              required: 'Firstname is required',
            })}
          />
          {errors.firstName && <InputError text={errors.firstName.message} />}
          <CustomInput
            hideIcon
            id="lastName"
            label="Lastname"
            name="lastName"
            style={{ marginTop: '2rem' }}
            inputRef={register({
              required: 'Lastname is required',
            })}
          />
          {errors.lastName && <InputError text={errors.lastName.message} />}
          <CustomInput
            hideIcon
            id="email"
            label="Email"
            name="email"
            style={{ marginTop: '2rem' }}
            inputRef={register({
              required: 'Email is required',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'Invalid email address',
              },
            })}
          />
          {errors.email && <InputError text={errors.email.message} />}
          <SolidBtn type="submit" isLoading={isLoading} mt="3.2rem">
            Add
          </SolidBtn>
        </Box>
      </CenterModal>

      {/* Delete confirmation modal */}
      <StyledWrapper>
        <CenterModal
          title="This action is irreversible are you sure you want to proceed?"
          align="center"
          showModal={showDelAdminModal}
          setShowModal={setShowDelAdminModal}
        >
          <Box d="flex" justifyContent="space-between" alignItems="center">
            <OutlineBtn
              borderColor="#939AA3"
              color="#939AA3"
              w="15rem"
              onClick={() => setShowDelAdminModal(false)}
              _hover={{ background: '#939AA3', color: '#fff' }}
            >
              No
            </OutlineBtn>
            <SolidBtn onClick={handleDelete} isLoading={loading} w="15rem">
              Yes
            </SolidBtn>
          </Box>
        </CenterModal>
      </StyledWrapper>
    </Box>
  );
};

export { UserManagement };

const StyledWrapper = styled(Box)`
  .closeBtn {
    display: none;
  }
`;
