import React from 'react';
import { Box, HStack, Text } from '@chakra-ui/react';

import {
  ProductSales,
  RevenueStats,
  TopBuyingMerchant,
  TotalRevenueBarChart,
  SalesHistory,
} from '../../components/admin';

function SalesRevenue() {
  return (
    <Box w="100%" maxW="100%">
      <Text fontFamily="bold" fontSize="3.2rem" lineHeight="3.8rem">
        Sales Revenue
      </Text>
      <RevenueStats />
      <TotalRevenueBarChart />
      <HStack spacing="4rem" mt="4rem" w="100%">
        <ProductSales />
        <TopBuyingMerchant />
      </HStack>
      <SalesHistory />
    </Box>
  );
}

export { SalesRevenue };
