import { Box, Flex, Text, Image } from '@chakra-ui/react';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import React from 'react';
import { Message } from './Message';
import Download from '../../../assets/icons/download.svg';

function Messages({ messages, isOpen, setActiveTicket }) {
  return (
    <Box h="100%" overflow="hidden" pb="3rem">
      <Flex p="0 30px 16px" align="center" justify="space-between">
        <Box>
          <Text color="#939AA3" fontFamily="medium">
            #ID:
            <Text as="span" fontFamily="medium" ml="10px">
              {messages[0].id}
            </Text>
          </Text>
        </Box>
      </Flex>
      <Box overflowY="scroll" height="100%">
        <Flex
          p="1.7rem 3rem"
          bgColor={isOpen ? ' #2EAE65' : '#979797'}
          align="center"
          justify="space-between"
        >
          <Text
            textTransform="capitalize"
            fontFamily="medium"
            fontSize="18px"
            color="#fff"
            maxW="70%"
          >
            {messages[0].title}
          </Text>
          <Text fontFamily="medium" color="rgba(255,255,255,0.6)">
            Date:
            <Text as="span" ml="8px">
              {dayjs(messages[0].updated_at).format('DD-MM-YYYY')}
            </Text>
          </Text>
        </Flex>
        <MessageBox>
          {messages.map((el, i) => (
            <Box key={i} p="2.4rem 3rem" borderBottom="1px solid #F3F3F3">
              <Flex justify="space-between" align="center">
                <Box>
                  <Text fontFamily="medium" fontSize="16px" lineHeight="19px">
                    {el.admin_reply ? 'Awake Admin' : 'Me'}
                  </Text>
                  <Text
                    fontFamily="regular"
                    letterSpacing="-0.02em"
                    color="#939AA3"
                    mt="2px"
                    lineHeight="21px"
                  >
                    {el.admin_reply ? 'Admin' : 'Merchant'}
                  </Text>
                </Box>
                <Text fontFamily="medium" color="#C4C4C4" lineHeight="17px">
                  Date:
                  <Text as="span" ml="8px">
                    {dayjs(el.updated_at).format('DD-MM-YYYY')}
                  </Text>
                </Text>
              </Flex>
              <Box mt="24px" w="90%">
                {el.message.includes('https://') ? (
                  <Box>
                    <Box
                      as="a"
                      href={el?.message}
                      w="10.8rem"
                      h="3.2rem"
                      d="flex"
                      alignItems="center"
                      justifyContent="center"
                      bg="#e0f3e8"
                      borderRadius="8px"
                    >
                      <Image src={Download} alt="download icon" />
                      <Text
                        color="#2EAE65"
                        fontFamily="regular"
                        fontSize="1.4rem"
                        lineHeight="1.7rem"
                        ml="1rem"
                      >
                        Download
                      </Text>
                    </Box>
                    <Image src={el.message} w="250px" alt="img" />
                  </Box>
                ) : (
                  <Text
                    textTransform=""
                    fontFamily="regular"
                    color="#fffff"
                    lineHeight="20px"
                  >
                    {el.message}
                  </Text>
                )}
              </Box>
            </Box>
          ))}
          <Box>
            <Message setActiveTicket={setActiveTicket} message={messages[0]} />
          </Box>
        </MessageBox>
      </Box>
    </Box>
  );
}

export { Messages };

const MessageBox = styled(Box)`
  @media (min-width: 1441px) {
    height: 40rem;
  }
`;
