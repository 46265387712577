import React, { useState } from 'react';
import { Box, Flex, Text, Button, Image } from '@chakra-ui/react';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';

import { Message } from './Message';
import { closeTicket } from '../../../queries/admin';
import { CenterModal } from '../../common/modals';

function Messages({ messages, isOpen, setActiveTicket, refetch, setIsOpen }) {
  const [open, setOpen] = useState(false);
  const close = async (id) => {
    try {
      const res = await closeTicket(id);
      setIsOpen(false);
      toast.success(res.message);

      refetch();
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
  return (
    <Box h="100%" overflow="hidden" pb="3rem">
      <Flex p="0 30px 16px" align="center" justify="space-between">
        <Box>
          <Text color="#939AA3" fontFamily="medium">
            #ID:
            <Text as="span" fontFamily="medium" ml="10px">
              {messages[0].id}
            </Text>
          </Text>
        </Box>
        <Button
          variant="link"
          textDecoration="none!important"
          color={isOpen ? '#E24D4D' : '#c4c4c4'}
          fontFamily="bold"
          fontSize="14px"
          onClick={() => close(messages[0].id)}
        >
          {isOpen ? 'Close Ticket' : 'Ticket Closed'}
        </Button>
      </Flex>
      <Box h="100%" overflowY="scroll">
        <Flex
          p="1.7rem 3rem"
          bgColor={isOpen ? ' #2EAE65' : '#979797'}
          align="center"
          justify="space-between"
        >
          <Text
            textTransform="capitalize"
            fontFamily="medium"
            fontSize="18px"
            color="#fff"
            maxW="70%"
          >
            {messages[0].title}
          </Text>
          <Text fontFamily="medium" color="rgba(255,255,255,0.6)">
            Date:
            <Text as="span" ml="8px">
              {dayjs(messages[0].updated_at).format('DD-MM-YYYY')}
            </Text>
          </Text>
        </Flex>
        <MessageBox>
          {messages.map((el, i) => (
            <Box key={i} p="2.4rem 3rem" borderBottom="1px solid #F3F3F3">
              <Flex justify="space-between" align="center">
                <Box>
                  <Text fontFamily="medium" fontSize="16px" lineHeight="19px">
                    {el.admin_reply ? 'Admin' : el.company_name}
                  </Text>
                  <Text
                    fontFamily="regular"
                    letterSpacing="-0.02em"
                    color="#939AA3"
                    mt="2px"
                    lineHeight="21px"
                  >
                    {el.admin_reply ? 'Admin' : 'Merchant'}
                  </Text>
                </Box>
                <Text fontFamily="medium" color="#C4C4C4" lineHeight="17px">
                  Date:
                  <Text as="span" ml="8px">
                    {dayjs(el.updated_at).format('DD-MM-YYYY')}
                  </Text>
                </Text>
              </Flex>
              <Box mt="24px" w="90%">
                {el.message.includes('https://') ? (
                  <Image
                    cursor="pointer"
                    src={el.message}
                    h="350px"
                    w="250px"
                    alt="img"
                  />
                ) : (
                  <Text
                    textTransform=""
                    fontFamily="regular"
                    color="#fffff"
                    lineHeight="20px"
                  >
                    {el.message}
                  </Text>
                )}
              </Box>
            </Box>
          ))}
          <Box>
            {isOpen ? (
              <Message
                setActiveTicket={setActiveTicket}
                message={messages[0]}
              />
            ) : (
              ''
            )}
          </Box>
        </MessageBox>
      </Box>
      <CenterModal
        title="Image"
        showModal={open}
        setShowModal={setOpen}
      ></CenterModal>
    </Box>
  );
}

export { Messages };

const MessageBox = styled(Box)`
  @media (min-width: 1441px) {
    height: 40rem;
  }
`;
