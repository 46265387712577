import instance from '../axios.config';

const exportMerchant = async ({ limit, start, end }) => {
  const data = await instance.get(
    `/admin/export?type=merchant&limit=${limit}&between=${start}&and=${end}`
  );

  return data.data;
};

const exportFundRequest = async ({ limit, start, end }) => {
  const data = await instance.get(
    `/admin/export?type=fund-request&limit=${limit}&between=${start}&and=${end}`
  );

  return data.data;
};

const exportAuditTrail = async ({ limit, start, end }) => {
  const data = await instance.get(
    `/admin/export?type=audit&limit=${limit}&between=${start}&and=${end}`
  );

  return data.data;
};

export { exportMerchant, exportFundRequest, exportAuditTrail };
