import styled from '@emotion/styled';
import React from 'react';

function SpinningText() {
  return (
    <Wrapper>
      <section className="spinner-container">
        <div className="spinner">
          <span className="spinner__leter" letter="L">
            L
          </span>
          <span className="spinner__leter" letter="o">
            o
          </span>
          <span className="spinner__leter" letter="a">
            a
          </span>
          <span className="spinner__leter" letter="d">
            d
          </span>
          <span className="spinner__leter" letter="i">
            i
          </span>
          <span className="spinner__leter" letter="n">
            n
          </span>
          <span className="spinner__leter" letter="g">
            g
          </span>
        </div>
      </section>
    </Wrapper>
  );
}

export { SpinningText };

const Wrapper = styled.div`
  .spinner-container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .spinner-container .spinner {
    font-size: 3rem;
  }
  .spinner-container .spinner__leter {
    font-family: 'TT Norms Medium', sans-serif;
    position: relative;
    color: rgba(0, 65, 190, 0.25);
    text-transform: uppercase;
  }
  .spinner-container .spinner__leter:before {
    content: attr(letter);
    position: absolute;
    top: -0.6rem;
    left: 0;
    color: #0041be;
    animation: load-animation 2.8s infinite;
    animation-fill-mode: backwards;
    text-transform: uppercase;
  }
  .spinner-container .spinner__leter:nth-of-type(2):before {
    animation-delay: 0.4s;
  }
  .spinner-container .spinner__leter:nth-of-type(3):before {
    animation-delay: 0.8s;
  }
  .spinner-container .spinner__leter:nth-of-type(4):before {
    animation-delay: 1.2s;
  }
  .spinner-container .spinner__leter:nth-of-type(5):before {
    animation-delay: 1.6s;
  }
  .spinner-container .spinner__leter:nth-of-type(6):before {
    animation-delay: 2s;
  }
  .spinner-container .spinner__leter:nth-of-type(7):before {
    animation-delay: 2.4s;
  }
  .spinner-container .spinner__leter:nth-of-type(8):before {
    animation-delay: 2.8s;
  }
  .spinner-container .spinner__leter:nth-of-type(9):before {
    animation-delay: 3.2s;
  }
  .spinner-container .spinner__leter:nth-of-type(10):before {
    animation-delay: 3.6s;
  }
  @keyframes load-animation {
    0%,
    80%,
    to {
      transform: rotateY(-90deg);
      opacity: 0;
    }
    5% {
      opacity: 0.5;
    }
    20%,
    50% {
      transform: rotateY(0);
      opacity: 1;
    }
  }
`;
