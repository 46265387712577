import { handleError } from '../../utils';
import instance from '../axios.config';

const getAuditTrail = async (key, page = 2) => {
  try {
    const { data } = await instance.get(
      `/admin/fund-request?status=approved&page=${page}`
    );

    return data;
  } catch (error) {
    handleError(error);
  }
};

const searchAuditTrail = async (name) => {
  try {
    const { data } = await instance.get(
      `/admin/fund-request?status=approved&q=%${name}%`
    );

    return data;
  } catch (error) {
    handleError(error);
  }
};

export { getAuditTrail, searchAuditTrail };
