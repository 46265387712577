import instance from '../axios.config';
import { handleError } from '../../utils';

const setWalletMinimumAmount = async (body) => {
  try {
    const { data } = await instance.patch('/merchant', body);

    return data;
  } catch (error) {
    handleError(error);
  }
};

export { setWalletMinimumAmount };
