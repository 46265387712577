/* eslint-disable max-len */
import React from 'react';

function MailIcon() {
  return (
    <svg
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 8.4114C13 10.2249 11.544 11.6939 9.7305 11.7004H9.724H3.2825C1.4755 11.7004 0 10.2379 0 8.4244V8.4179C0 8.4179 0.0039 5.541 0.0091 4.0941C0.00975 3.8224 0.32175 3.6703 0.5343 3.8393C2.0787 5.06455 4.84055 7.2986 4.875 7.32785C5.3365 7.6977 5.9215 7.90635 6.5195 7.90635C7.1175 7.90635 7.7025 7.6977 8.164 7.3207C8.19845 7.29795 10.8986 5.13085 12.4664 3.88545C12.6796 3.7158 12.9928 3.8679 12.9935 4.13895C13 5.5748 13 8.4114 13 8.4114Z"
        fill={focus ? '#0041BE' : '#939AA3'}
      />
      <path
        opacity="0.3"
        d="M12.6595 1.7381C12.0966 0.6773 10.989 0 9.76961 0H3.28261C2.06321 0 0.955607 0.6773 0.392707 1.7381C0.266607 1.97535 0.326407 2.2711 0.536357 2.4388L5.36261 6.29915C5.70061 6.57215 6.11011 6.708 6.51961 6.708C6.52221 6.708 6.52416 6.708 6.52611 6.708C6.52806 6.708 6.53066 6.708 6.53261 6.708C6.94211 6.708 7.35161 6.57215 7.68961 6.29915L12.5159 2.4388C12.7258 2.2711 12.7856 1.97535 12.6595 1.7381Z"
        fill={focus ? '#0041BE' : '#3558C7'}
      />
    </svg>
  );
}

function LockIcon() {
  return (
    <svg
      width="13"
      height="16"
      viewBox="0 0 13 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.3"
        d="M10.622 4.05292V5.26115C10.2646 5.13956 9.86826 5.06997 9.46419 5.06997H9.45642V4.05292C9.45642 2.45469 8.13544 1.14705 6.50364 1.14705H6.49587C4.86407 1.14705 3.54309 2.44016 3.53532 4.03763V5.06997H3.52755C3.12349 5.06997 2.73496 5.13956 2.36975 5.26115V4.05292C2.37752 1.80546 4.22689 0 6.4881 0H6.51141C8.77262 0 10.622 1.81999 10.622 4.05292Z"
        fill={focus ? '#0041BE' : '#939AA3'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.46444 5.07007C9.8685 5.07007 10.2648 5.13966 10.6222 5.26124C12.0054 5.73536 13 7.02082 13 8.54182V11.8224C13 13.7341 11.4148 15.2941 9.46444 15.2941H3.52779C1.58518 15.2941 0 13.7341 0 11.8224V8.54182C0 7.02082 0.99462 5.73536 2.36999 5.26124C2.73521 5.13966 3.12373 5.07007 3.52779 5.07007H9.46444ZM6.49612 11.7C6.87687 11.7 7.17992 11.4018 7.17992 11.0271V9.32946C7.17992 8.96317 6.87687 8.66417 6.49612 8.66417C6.12314 8.66417 5.82009 8.96317 5.82009 9.32946V11.0271C5.82009 11.4018 6.12314 11.7 6.49612 11.7Z"
        fill={focus ? '#0041BE' : '#939AA3'}
      />
    </svg>
  );
}

export { MailIcon, LockIcon };
