import React from 'react';
import { Box, Image, Text } from '@chakra-ui/react';
import commafy from 'commafy';

import { nFormatter } from '../../../utils';
import ArrowUp from '../../../assets/icons/arrowup.svg';
import ArrowDown from '../../../assets/icons/arrowdown.svg';

function Statboxes({ statisticsData }) {
  return (
    <Box
      d="flex"
      justifyContent="space-between"
      border="1px solid #F3F3F3"
      h="15.7rem"
      w="100%"
      mt="3.3rem"
      pl="4rem"
      pr="4rem"
    >
      <Box flex="1" pt="2.4rem" d="flex" flexDirection="column">
        <Box
          maxW="7rem"
          h="2.5rem"
          borderRadius="20px"
          d="flex"
          alignItems="center"
          justifyContent="center"
          bg={
            statisticsData.percentageIncrease.fund === 0
              ? '#e1e1e1'
              : statisticsData.percentageIncrease.fund > 0
              ? '#d5efe0'
              : '#f9dbdb'
          }
        >
          <Text
            color={
              statisticsData.percentageIncrease.fund === 0
                ? '#000'
                : statisticsData.percentageIncrease.fund > 0
                ? '#2EAE65'
                : '#DC444C'
            }
            fontSize="1.3rem"
            fontFamily="regular"
            lineHeight="1.6rem"
          >
            {Math.round(statisticsData.percentageIncrease.fund) || 0}%
          </Text>
          {statisticsData.percentageIncrease.fund === 0 ? (
            ''
          ) : statisticsData.percentageIncrease.fund > 0 ? (
            <Image src={ArrowUp} ml="0.5rem" alt="arrow icon" />
          ) : (
            <Image src={ArrowDown} ml="0.5rem" alt="arrow icon" />
          )}
        </Box>
        <Text
          mt="2rem"
          fontFamily="bold"
          fontSize="2.8rem"
          lineHeight="3.4rem"
          mb="0.4rem"
        >
          {nFormatter(statisticsData.counts.fund)}
        </Text>
        <Text
          color="rgba(0, 19, 80, 0.7)"
          fontSize="1.4rem"
          lineHeight="1.7rem"
          fontFamily="regular"
        >
          Total revenue
        </Text>
      </Box>
      <Box borderLeft="1px solid #E8EEF4" h="10.9rem" mt="2.4rem" />
      <Box flex="1" pt="2.4rem" pl="4rem" d="flex" flexDirection="column">
        <Box
          h="2.5rem"
          maxW="7rem"
          borderRadius="20px"
          alignItems="center"
          d="flex"
          justifyContent="center"
          bg={
            statisticsData.percentageIncrease.merchants === 0
              ? '#e1e1e1'
              : statisticsData.percentageIncrease.merchants > 0
              ? '#d5efe0'
              : '#f9dbdb'
          }
        >
          <Text
            color={
              statisticsData.percentageIncrease.merchants === 0
                ? '#000'
                : statisticsData.percentageIncrease.merchants > 0
                ? '#2EAE65'
                : '#DC444C'
            }
            fontSize="1.3rem"
            fontFamily="regular"
            lineHeight="1.6rem"
          >
            {Math.round(statisticsData.percentageIncrease.merchants) || 0}%
          </Text>
          {statisticsData.percentageIncrease.merchants === 0 ? (
            ''
          ) : statisticsData.percentageIncrease.merchants > 0 ? (
            <Image src={ArrowUp} ml="0.5rem" alt="arrow icon" />
          ) : (
            <Image src={ArrowDown} ml="0.5rem" alt="arrow icon" />
          )}
        </Box>
        <Text
          mt="2rem"
          fontFamily="bold"
          fontSize="2.8rem"
          lineHeight="3.4rem"
          mb="0.4rem"
        >
          {commafy(statisticsData.counts.merchants) || 0}
        </Text>
        <Text
          color="rgba(0, 19, 80, 0.7)"
          fontSize="1.4rem"
          lineHeight="1.7rem"
          fontFamily="regular"
        >
          Total number of merchants
        </Text>
      </Box>
      <Box borderLeft="1px solid #E8EEF4" h="10.9rem" mt="2.4rem" />
      <Box flex="1" pt="2.4rem" pl="4rem" d="flex" flexDirection="column">
        <Box
          maxW="7rem"
          h="2.5rem"
          borderRadius="20px"
          d="flex"
          alignItems="center"
          justifyContent="center"
          bg={
            statisticsData.percentageIncrease.pendingMerchant === 0
              ? '#e1e1e1'
              : statisticsData.percentageIncrease.pendingMerchant > 0
              ? '#d5efe0'
              : '#f9dbdb'
          }
        >
          <Text
            color={
              statisticsData.percentageIncrease.pendingMerchant === 0
                ? '#000'
                : statisticsData.percentageIncrease.pendingMerchant > 0
                ? '#2EAE65'
                : '#DC444C'
            }
            fontSize="1.3rem"
            fontFamily="regular"
            lineHeight="1.6rem"
          >
            {Math.round(statisticsData.percentageIncrease.pendingMerchant) || 0}
            %
          </Text>
          {statisticsData.percentageIncrease.pendingMerchant === 0 ? (
            ''
          ) : statisticsData.percentageIncrease.pendingMerchant > 0 ? (
            <Image src={ArrowUp} ml="0.5rem" alt="arrow icon" />
          ) : (
            <Image src={ArrowDown} ml="0.5rem" alt="arrow icon" />
          )}
        </Box>
        <Text
          mt="2rem"
          fontFamily="bold"
          fontSize="2.8rem"
          lineHeight="3.4rem"
          mb="0.4rem"
        >
          {commafy(statisticsData.counts.pendingMerchant) || 0}
        </Text>
        <Text
          color="rgba(0, 19, 80, 0.7)"
          fontSize="1.4rem"
          lineHeight="1.7rem"
          fontFamily="regular"
        >
          New applications
        </Text>
      </Box>
      <Box borderLeft="1px solid #E8EEF4" h="10.9rem" mt="2.4rem" />
      <Box flex="1" pt="2.4rem" pl="4rem" d="flex" flexDirection="column">
        <Box
          maxW="7rem"
          h="2.5rem"
          borderRadius="20px"
          d="flex"
          alignItems="center"
          justifyContent="center"
          bg={
            statisticsData.percentageIncrease.openTicket === 0
              ? '#e1e1e1'
              : statisticsData.percentageIncrease.openTicket > 0
              ? '#d5efe0'
              : '#f9dbdb'
          }
        >
          <Text
            color={
              statisticsData.percentageIncrease.openTicket === 0
                ? '#000'
                : statisticsData.percentageIncrease.openTicket > 0
                ? '#2EAE65'
                : '#DC444C'
            }
            fontSize="1.3rem"
            fontFamily="regular"
            lineHeight="1.6rem"
          >
            {Math.round(statisticsData.percentageIncrease.openTicket) || 0}%
          </Text>
          {statisticsData.percentageIncrease.openTicket === 0 ? (
            ''
          ) : statisticsData.percentageIncrease.openTicket > 0 ? (
            <Image src={ArrowUp} ml="0.5rem" alt="arrow icon" />
          ) : (
            <Image src={ArrowDown} ml="0.5rem" alt="arrow icon" />
          )}
        </Box>
        <Text
          mt="2rem"
          fontFamily="bold"
          fontSize="2.8rem"
          lineHeight="3.4rem"
          mb="0.4rem"
        >
          {commafy(statisticsData.counts.openTicket) || 0}
        </Text>
        <Text
          color="rgba(0, 19, 80, 0.7)"
          fontSize="1.4rem"
          lineHeight="1.7rem"
          fontFamily="regular"
        >
          Total number of open tickets
        </Text>
      </Box>
      <Box borderLeft="1px solid #E8EEF4" h="10.9rem" mt="2.4rem" />
      <Box flex="1" pt="2.4rem" pl="4rem" d="flex" flexDirection="column">
        <Box
          maxW="7rem"
          h="2.5rem"
          borderRadius="20px"
          d="flex"
          alignItems="center"
          justifyContent="center"
          bg={
            statisticsData.percentageIncrease.closedTicket === 0
              ? '#e1e1e1'
              : statisticsData.percentageIncrease.closedTicket > 0
              ? '#d5efe0'
              : '#f9dbdb'
          }
        >
          <Text
            color={
              statisticsData.percentageIncrease.closedTicket === 0
                ? '#000'
                : statisticsData.percentageIncrease.closedTicket > 0
                ? '#2EAE65'
                : '#DC444C'
            }
            fontSize="1.3rem"
            fontFamily="regular"
            lineHeight="1.6rem"
          >
            {Math.round(statisticsData.percentageIncrease.closedTicket) || 0}%
          </Text>
          {statisticsData.percentageIncrease.closedTicket === 0 ? (
            ''
          ) : statisticsData.percentageIncrease.closedTicket > 0 ? (
            <Image src={ArrowUp} ml="0.5rem" alt="arrow icon" />
          ) : (
            <Image src={ArrowDown} ml="0.5rem" alt="arrow icon" />
          )}
        </Box>
        <Text
          mt="2rem"
          fontFamily="bold"
          fontSize="2.8rem"
          lineHeight="3.4rem"
          mb="0.4rem"
        >
          {commafy(statisticsData.counts.closedTicket) || 0}
        </Text>
        <Text
          color="rgba(0, 19, 80, 0.7)"
          fontSize="1.4rem"
          lineHeight="1.7rem"
          fontFamily="regular"
        >
          Total number of closed tickets
        </Text>
      </Box>
    </Box>
  );
}

export { Statboxes };
