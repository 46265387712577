import React, { useState, useEffect } from 'react';
import { Text, Flex, Box, Button, Input } from '@chakra-ui/react';
import { getVisiblePages } from '../../../utils';
import { ReactComponent as PaginationIcon } from '../../../assets/icons/paginationIcon.svg';

const Pagination = ({
  totalData,
  pageCount,
  totalPages,
  myPage,
  setPage,
  page,
}) => {
  const [goToPage, setGoToPage] = useState('');
  const [activePage, setActivePage] = useState('');

  const [visiblePages] = useState(getVisiblePages(myPage, totalPages));

  useEffect(() => {
    setActivePage(() => myPage);
  }, [activePage]);

  const handleNext = () => {
    setActivePage(() => myPage + 1);
    setPage(() => myPage + 1);
  };
  const handlePrevious = () => {
    setActivePage(() => myPage - 1);
    setPage(() => myPage - 1);
  };
  // const handleFirst = () => {
  //   setPage(() => 1);
  // };
  // const handleLast = () => {
  //   setPage(() => pageCount);
  // };
  const handleChange = (e) => {
    const { value } = e.target;
    setGoToPage(() => value);
  };
  const handlePageNav = (page) => {
    setActivePage(() => page);
    setPage(() => page);
  };
  const handleGoTo = () => {
    if (goToPage <= pageCount) {
      setActivePage(() => +goToPage);
      setPage(() => +goToPage);
    }
  };

  const handlePageLength = (() => {
    if (myPage === 1) {
      return `1 - ${page.length}`;
    } else if (myPage > 1) {
      const multiplier = (myPage - 1) * 10;
      const startNum = 1 + multiplier;
      const endNum = page.length + multiplier;
      return `${startNum} - ${endNum}`;
    }
  })();

  return (
    <Flex my="7rem" justify="space-between" align="center">
      <Box>
        <Text fontFamily="medium" color="#444444" fontSize="1.4rem">
          Showing {handlePageLength} of {totalData}
        </Text>
      </Box>
      <Flex>
        <Flex align="baseline">
          <Button
            h="2.4rem"
            w="3rem"
            mr="1.8rem"
            bgColor="transparent"
            onClick={handlePrevious}
            isDisabled={myPage === 1}
            transform="rotate(-180deg)"
            fontSize="1.4rem"
          >
            <PaginationIcon />
          </Button>
          <Flex align="center">
            {visiblePages.map((page, index, array) => {
              return (
                <Flex
                  _notFirst={{ marginLeft: '.8rem' }}
                  _notLast={{ marginRight: '.8rem' }}
                  align="center"
                  justify="center"
                  className={page === activePage ? 'activePagination' : ''}
                  key={index}
                  onClick={() => handlePageNav(page)}
                >
                  <Text fontFamily="medium" cursor="pointer">
                    {array[index - 1] + 2 < page ? `...${page}` : page}
                  </Text>
                </Flex>
              );
            })}
          </Flex>
          <Button
            ml="1.8rem"
            h="2.4rem"
            w="3rem"
            bgColor="transparent"
            onClick={handleNext}
            isDisabled={myPage === pageCount}
            fontSize="1.4rem"
          >
            <PaginationIcon />
          </Button>
        </Flex>
        <Box>
          <Text as="span" fontFamily="regular" ml="4.8rem" color="#A7A7A7">
            Go to page
          </Text>
          <Input
            type="number"
            value={goToPage}
            onChange={handleChange}
            w="6rem"
            h="2.4rem"
            mx=".8rem"
            border="0.83px solid #BDC4CC"
            fontFamily="medium"
            fontSize="1.4rem"
          />
          <Button
            bgColor="transparent"
            fontFamily="medium"
            onClick={handleGoTo}
            fontSize="1.4rem"
            isDisabled={goToPage > pageCount}
          >
            Go
          </Button>
        </Box>
      </Flex>
    </Flex>
  );
};

export { Pagination };
