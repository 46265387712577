import {
  Box,
  Flex,
  HStack,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import styled from '@emotion/styled';
import dayjs from 'dayjs';

import {
  CustomTable,
  NumberRangeColumnFilter,
  SelectColumnFilter,
  TableWrapper,
} from '../../common/tables';
import { ReactComponent as Export } from '../../../assets/icons/Export.svg';
import { ReactComponent as OptionsIcon } from '../../../assets/icons/options.svg';
import { ReactComponent as Download } from '../../../assets/icons/download.svg';
import { ReactComponent as FallbackIcon } from '../../../assets/icons/fallback.svg';
import { ExportWallet } from '../../export/merchant';
import { nFormatter } from '../../../utils';
import { deleteFundRequest } from '../../../queries';
import { toast } from 'react-toastify';

function WalletHistory({
  walletTopups,
  myPage,
  totalData,
  currentPage,
  totalPages,
  setPage,
  refetch,
  setShowEditModal,
  setRequestToEdit,
}) {
  const defaultData = (() => {
    return walletTopups?.map((item) => {
      return {
        amount: Number(item.amount),
        col2: item.created_at,
        col3: item.status,
        col4: (
          <Link
            href={item.proof_url}
            w="10.8rem"
            h="3.2rem"
            d="flex"
            alignItems="center"
            justifyContent="center"
            bg="#e0f3e8"
            borderRadius="8px"
          >
            <Download />
            <Text
              color="#2EAE65"
              fontFamily="regular"
              fontSize="1.4rem"
              lineHeight="1.7rem"
              ml="1rem"
            >
              Download
            </Text>
          </Link>
        ),
        col5:
          item.status === 'pending' ? (
            <Menu>
              <MenuButton
                cursor="pointer"
                className="options"
                as={OptionsIcon}
              ></MenuButton>
              <MenuList p="2rem 1rem">
                <MenuItem
                  onClick={() => handleEdit(item.id)}
                  color="#808080"
                  p="1rem 2rem"
                  borderRadius="8px"
                >
                  Edit
                </MenuItem>
                <MenuItem
                  onClick={() => handleDelete(item.id)}
                  color="#808080"
                  p="1rem 2rem"
                  borderRadius="8px"
                >
                  Delete
                </MenuItem>
              </MenuList>
            </Menu>
          ) : (
            'No action.'
          ),
      };
    });
  })();

  const defaultColumns = [
    {
      Header: 'Amount',
      accessor: 'amount',
      Cell: ({ value }) => nFormatter(value),
      Filter: NumberRangeColumnFilter,
      disableSortBy: false,
      disableFilters: true,
    },
    {
      Header: 'Date',
      accessor: 'col2',
      Cell: ({ value }) => (
        <Text fontFamily="regular" fontWeight="bold !important">
          {dayjs(value).format('MMM D, YYYY')}
          <Text as="span" ml="10px" color="#808080" fontWeight="bold!important">
            {dayjs(value).format('HH:mm A')}
          </Text>
        </Text>
      ),
      sortType: (a, b) => {
        const a1 = new Date(a).getTime();
        const b1 = new Date(b).getTime();
        if (a1 < b1) {
          return 1;
        } else if (a1 > b1) {
          return -1;
        } else {
          return 0;
        }
      },
      disableFilters: true,
    },
    {
      Header: 'Status',
      accessor: 'col3',
      Cell: ({ value }) => (
        <Text
          fontFamily="regular"
          color={
            value === 'approved'
              ? '#2EAE65'
              : value === 'pending'
              ? '#FF8A00'
              : '#E24D4D'
          }
          fontWeight="bold !important"
        >
          {value}
        </Text>
      ),
      Filter: SelectColumnFilter,
      disableSortBy: true,
    },
    {
      Header: 'Payment Proof',
      accessor: 'col4',
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'Actions',
      accessor: 'col5',
      disableFilters: true,
      disableSortBy: true,
    },
  ];

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleDelete = async (id) => {
    try {
      await deleteFundRequest(id);
      toast.success('Deleted fund request successfully');
      refetch();
    } catch (error) {
      toast.error(
        error.response?.data?.message || 'Failed to delete. Please try later'
      );
    }
  };

  const handleEdit = (id) => {
    setShowEditModal(true);
    setRequestToEdit(id);
  };

  return (
    <Box
      mt="3.9rem"
      bgColor="#fff"
      boxShadow="0px 0px 4px rgba(0, 0, 0, 0.15)"
      p="4.2rem 4rem 10rem"
    >
      <Flex align="center" justify="space-between" mb="3.2rem">
        <Text fontSize="2rem" fontFamily="medium" color="rgba(33, 45, 67, 0.8)">
          Wallet top-up history
        </Text>
        {defaultData.length < 1 ? (
          ''
        ) : (
          <HStack spacing="3.5rem">
            <Export onClick={handleOpen} cursor="pointer" />
          </HStack>
        )}
      </Flex>
      {defaultData.length < 1 ? (
        <Box>
          <Flex align="center" justify="center" mt="6rem">
            <FallbackIcon />
          </Flex>
          <Text fontSize="1.6rem" textAlign="center" fontFamily="medium">
            No data for top-up history yet
          </Text>
        </Box>
      ) : (
        <TransactionsTable>
          <CustomTable
            data={defaultData}
            columns={defaultColumns}
            myPage={myPage}
            setPage={setPage}
            currentPage={currentPage}
            totalData={totalData}
            totalPages={totalPages}
          />
        </TransactionsTable>
      )}
      <ExportWallet open={open} setOpen={setOpen} />
    </Box>
  );
}

export { WalletHistory };

const TransactionsTable = styled(TableWrapper)`
  thead {
    background-color: #0041be;
  }

  th {
    color: #fff !important;
  }
`;
