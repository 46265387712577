import instance from '../axios.config';

const merchantlogin = async (body) => {
  const { data } = await instance.post('/merchant/login', body);

  return data;
};

const merchantSignup = async (body) => {
  const { data } = await instance.post('/merchant/sign-up', body);

  return data.data;
};

const addAdmin = async (body) => {
  const { data } = await instance.post('/admin/sign-up', body);

  return data;
};

const adminLogin = async (body) => {
  const { data } = await instance.post('/login', body);

  return data;
};

const merchantForgotPassword = async (body) => {
  const { data } = await instance.post('/merchant/forgot-password', body);

  return data;
};

const merchantResetPassword = async (body, token) => {
  const { data } = await instance.patch('/merchant/reset-password', body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data;
};

const adminForgotPassword = async (body) => {
  const { data } = await instance.post('/admin/forgot-password', body);

  return data;
};

const adminResetPassword = async (body, token) => {
  const { data } = await instance.patch('/admin/reset-password', body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data;
};

export {
  merchantlogin,
  merchantSignup,
  addAdmin,
  adminLogin,
  merchantResetPassword,
  merchantForgotPassword,
  adminForgotPassword,
  adminResetPassword,
};
