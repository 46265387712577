import { Flex, Text } from '@chakra-ui/react';
import React from 'react';

import { ReactComponent as CalendarIcon } from '../../../assets/icons/Calendar.svg';
import { ReactComponent as ChevronIcon } from '../../../assets/icons/chevron.svg';

function DatePickerTrigger({ value, onClick }) {
  return (
    <Flex align="center" onClick={onClick} cursor="pointer">
      <CalendarIcon />
      <Flex ml="1rem" w="107px" justify="center" bg="#f3f3f3" py="1rem">
        <Text
          as="span"
          color="#212D43"
          fontFamily="medium"
          fontSize="1.2rem"
          pr=".5rem"
        >
          {value.month},
        </Text>
        <Flex
          as="p"
          align="center"
          color="#808080"
          fontFamily="medium"
          fontSize="1.2rem"
          whiteSpace="nowrap"
        >
          {value.year}
          <ChevronIcon
            style={{ transform: 'rotate(90deg)', marginLeft: '.5rem' }}
          />
        </Flex>
      </Flex>
    </Flex>
  );
}

export { DatePickerTrigger };
