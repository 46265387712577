import React, { useState } from 'react';
import styled from '@emotion/styled';
import {
  Box,
  Flex,
  Text,
  Image,
  Collapse,
  VStack,
  Link as ChakraLink,
} from '@chakra-ui/react';
import { Link, useLocation } from 'react-router-dom';

import logo from '../../assets/images/logo-1.png';
import ham from '../../assets/icons/ham.svg';
import close from '../../assets/icons/x.svg';

const NavBar = () => {
  const { pathname } = useLocation();
  const [showMobileNav, toggleMobileNav] = useState(false);

  return (
    <>
      <Box bgColor="#0041be">
        <Flex
          justify="space-between"
          align="center"
          h="102px"
          w="100%"
          maxW="1140px"
          px={{ base: '3.2rem', lg: '1rem' }}
          mx="auto"
        >
          <Box>
            <Link to="/" _focus={{ boxShadow: 'none !important' }}>
              <Image maxW="120px" src={logo} />
            </Link>
          </Box>
          <Flex justifyContent="space-between" alignItems="center">
            <Box display={{ base: 'none', lg: 'block' }}>
              <Flex>
                <HyperLink
                  className={pathname === '/' && 'active-page'}
                  fontFamily="medium"
                  to="/"
                >
                  Home
                </HyperLink>
                <HyperLinkNav
                  fontFamily="medium"
                  href="https://docs.awakeinterlink.ng/"
                  target="blank"
                >
                  Developers API
                </HyperLinkNav>
                <HyperLink
                  fontFamily="medium"
                  to="/contact-us"
                  className={pathname === '/contact-us' && 'active-page'}
                >
                  Contact us
                </HyperLink>
                <HyperLink fontFamily="medium" to="/merchant/login">
                  Sign in
                </HyperLink>
              </Flex>
            </Box>
            <Flex alignItems="center">
              <Text
                fontFamily="medium"
                fontSize="16px"
                lineHeight="140%"
                textAlign="center"
                letterSpacing="-0.01em"
                color="#FFFFFF"
                opacity="0.5"
                display={{ base: 'none', md: 'block' }}
                mr="2rem"
              >
                Become a merchant!
              </Text>
              <LinkButton
                fontFamily="bold"
                _hover={{ textDecoration: 'none !important' }}
                to="/merchant/signup"
              >
                Sign up
              </LinkButton>
            </Flex>
            <Box display={{ base: 'block', lg: 'none' }} ml="3.2rem">
              <Image
                onClick={() => toggleMobileNav(!showMobileNav)}
                src={ham}
                alt="Ham icon"
              />
            </Box>
          </Flex>
        </Flex>
      </Box>
      <MobileNav show={showMobileNav} toggleMobileNav={toggleMobileNav} />
    </>
  );
};

export { NavBar };

const HyperLink = styled(Link)`
  display: block;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.8);
  margin-right: 40px;
  text-decoration: none;
  font-family: 'TT Norms Medium', sans-serif;

  &:hover {
    text-decoration: none;
  }

  &:focus {
    box-shadow: none;
  }

  &.active-page {
    color: #fdb600 !important;
  }
`;

const HyperLinkNav = styled(ChakraLink)`
  display: block;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.8);
  margin-right: 40px;
  text-decoration: none;
  font-family: 'TT Norms Medium', sans-serif;

  &:hover {
    text-decoration: none;
  }

  &:focus {
    box-shadow: none;
  }

  &.active-page {
    color: #fdb600 !important;
  }
`;

const LinkButton = styled(Link)`
  display: block;
  background: #fdb600;
  border-radius: 2px;
  padding: 10px 33px;
  outline: none;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  color: #ffffff;
  margin: 0 0.3em 0.3em 0;
  font-family: 'TT Norms Bold', sans-serif;

  &:hover {
    text-decoration: none !important;
    background-color: #f1ca66;
  }

  @media (max-width: 480px) {
    padding: 10px 15px;
    font-size: 1.2rem;
    display: none;
  }
`;

function MobileNav({ show, toggleMobileNav }) {
  const { pathname } = useLocation();

  return (
    <Collapse in={show} animateOpacity>
      <Box
        pos="fixed"
        top="0"
        left="0"
        zIndex="100"
        w="100%"
        h="100vh"
        bgColor="#0041be"
      >
        <Image
          position="absolute"
          top="4.4rem"
          right="3.2rem"
          src={close}
          onClick={() => toggleMobileNav()}
        />
        <VStack spacing="3.2rem" align="flex-start" ml="3.2rem" mt="11.2rem">
          <HyperLink
            className={pathname === '/' && 'active-page'}
            fontFamily="medium"
            to="/"
          >
            Home
          </HyperLink>
          <HyperLinkNav
            fontFamily="medium"
            href="https://docs.awakeinterlink.ng/"
            target="blank"
          >
            Developers API
          </HyperLinkNav>
          <HyperLink
            fontFamily="medium"
            to="/contact-us"
            className={pathname === '/contact-us' && 'active-page'}
          >
            Contact us
          </HyperLink>
          <HyperLink fontFamily="medium" to="/merchant/login">
            Sign in
          </HyperLink>
        </VStack>
      </Box>
    </Collapse>
  );
}
