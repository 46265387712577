import React, { useState, useEffect } from 'react';
import {
  Box,
  Text,
  Image,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Textarea,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';

import {
  getAllApplications,
  getMerchantDocuments,
  approveMerchant,
  searchApplications,
  addCommentToMerchantApplication,
} from '../../queries';
import { CustomInput } from '../../components/common/form/Input';
import { CustomTable, TableWrapper } from '../../components/common/tables';
import { CenterModal, RightModal } from '../../components/common/modals';
import Search from '../../assets/icons/search_icon.svg';
import Download from '../../assets/icons/download.svg';
import { SpinningText } from '../../components/common/loaders';
import { ReactComponent as FallbackIcon } from '../../assets/icons/fallback.svg';
import { ReactComponent as OptionsIcon } from '../../assets/icons/options.svg';
import { ErrorOccured } from '../../components/common/errors';
import { OutlineBtn, SolidBtn } from '../../components/common/buttons';
import styled from '@emotion/styled';

const Applications = () => {
  const [page, setPage] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [showActivationModal, setShowActivationModal] = useState(false);
  const [showAddCommentModal, setShowAddCommentModal] = useState(false);
  const [currentRequest, setCurrentRequest] = useState(null);
  const [name, setName] = useState('');
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const { isLoading, isError, data, refetch } = useQuery(
    ['getAllApplications', page],
    getAllApplications
  );

  const totalPages = data?.data?.totalPages;
  const totalData = data?.data?.total;
  const currentPage = data?.data?.currentPage;

  useEffect(() => {
    refetch();
    setShowSearch(false);
  }, [page]);

  // Search handler
  const handleSearchData = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const data = await searchApplications(name);
      setLoading(false);
      setShowSearch(true);
      setTableData(data.data);
    } catch (err) {
      setLoading(false);
      setShowSearch(false);
      throw err;
    }
  };

  if (isLoading) {
    return <SpinningText />;
  }

  if (isError) {
    return <ErrorOccured />;
  }

  const toggleModal = () => {
    setIsOpen(true);
  };

  const handleOpenModal = (e) => {
    setCurrentRequest(e);
    toggleModal();
  };

  const handleActivateConfirmation = (e) => {
    setCurrentRequest(e);
    setShowActivationModal(true);
  };

  const handleAddComment = (e) => {
    setCurrentRequest(e);
    setShowAddCommentModal(true);
  };

  const defaultData = data?.data.merchants.map((e, id) => {
    return {
      col1: e.company_name,
      col2: e.email,
      col3: e.created_at,
      col4: (
        <Text
          color="#FF8A00"
          fontFamily="medium"
          fontSize="1.5rem"
          lineHeight="1.8rem"
        >
          {e.account_status}
        </Text>
      ),
      col5: (
        <Text
          _hover={{
            textDecor: 'underline',
          }}
          fontFamily="medium"
          fontSize="1.5rem"
          lineHeight="1.8rem"
          color="brand.blue"
          cursor="pointer"
          onClick={() => handleOpenModal(e)}
        >
          View
        </Text>
      ),
      col6: (
        <Menu>
          <MenuButton
            cursor="pointer"
            className="options"
            as={OptionsIcon}
          ></MenuButton>
          <MenuList p="2rem 1rem">
            <MenuItem
              onClick={() => handleActivateConfirmation(e)}
              color="#808080"
              p="1rem 2rem"
              borderRadius="8px"
            >
              Activate
            </MenuItem>
            <MenuItem
              onClick={() => handleAddComment(e)}
              color="#808080"
              p="1rem 2rem"
              borderRadius="8px"
            >
              Add comment
            </MenuItem>
          </MenuList>
        </Menu>
      ),
    };
  });

  const showSearchData = tableData?.map((e, id) => {
    return {
      col1: e.company_name,
      col2: e.email,
      col3: e.created_at,
      col4: (
        <Text
          color="#FF8A00"
          fontFamily="medium"
          fontSize="1.5rem"
          lineHeight="1.8rem"
        >
          {e.account_status}
        </Text>
      ),
      col5: (
        <Text
          _hover={{
            textDecor: 'underline',
          }}
          fontFamily="medium"
          fontSize="1.5rem"
          lineHeight="1.8rem"
          color="brand.blue"
          cursor="pointer"
          onClick={() => handleOpenModal(e)}
        >
          View
        </Text>
      ),
      col6: (
        <Menu>
          <MenuButton
            cursor="pointer"
            className="options"
            as={OptionsIcon}
          ></MenuButton>
          <MenuList p="2rem 1rem">
            <MenuItem
              onClick={() => handleActivateConfirmation(e)}
              color="#808080"
              p="1rem 2rem"
              borderRadius="8px"
            >
              Activate
            </MenuItem>
            <MenuItem
              onClick={() => handleAddComment(e)}
              color="#808080"
              p="1rem 2rem"
              borderRadius="8px"
            >
              Delete
            </MenuItem>
          </MenuList>
        </Menu>
      ),
    };
  });

  const defaultColumns = [
    {
      Header: 'Company name',
      accessor: 'col1',
      disableFilters: true,
    },
    {
      Header: 'Email',
      accessor: 'col2',
      disableFilters: true,
    },
    {
      Header: 'Date',
      accessor: 'col3',
      Cell: ({ value }) => dayjs(value).format('MMM D, YYYY'),
      disableFilters: true,
    },
    {
      Header: 'Status',
      accessor: 'col4',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Document',
      accessor: 'col5',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: '',
      accessor: 'col6',
      disableSortBy: true,
      disableFilters: true,
    },
  ];

  return (
    <Box>
      <Text
        color="#212D43"
        lineHeight="3.8rem"
        fontSize="3.2rem"
        fontFamily="bold"
      >
        New Applications
      </Text>
      <form onSubmit={handleSearchData}>
        <CustomInput
          w="33.2rem"
          borderRadius="4px"
          label="Search"
          value={name}
          onChange={(e) => setName(e.target.value)}
        >
          <Image src={Search} alt="search_icon" />
        </CustomInput>
      </form>
      <Box
        boxShadow=" 0px 0px 4px rgba(0, 0, 0, 0.15)"
        p="1rem 4rem 6rem 4.2rem"
        minH="60vh"
        mt="2.1rem"
        overflow="auto"
      >
        {defaultData.length < 1 ? (
          <Box>
            <Flex align="center" justify="center" mt="6rem">
              <FallbackIcon />
            </Flex>
            <Text fontSize="1.6rem" textAlign="center" fontFamily="medium">
              No new application
            </Text>
          </Box>
        ) : (
          <Box>
            {loading ? (
              <SpinningText />
            ) : (
              <Box>
                {showSearch && tableData.length < 1 ? (
                  <Box>
                    <Flex align="center" justify="center" mt="4rem">
                      <FallbackIcon />
                    </Flex>
                    <Text
                      fontSize="1.6rem"
                      textAlign="center"
                      fontFamily="medium"
                    >
                      No company with that name found
                    </Text>
                  </Box>
                ) : (
                  <TableWrapper>
                    <CustomTable
                      data={showSearch ? showSearchData : defaultData}
                      columns={defaultColumns}
                      currentPage={currentPage}
                      totalPages={totalPages}
                      totalData={totalData}
                      myPage={page}
                      setPage={setPage}
                    />
                  </TableWrapper>
                )}
              </Box>
            )}
          </Box>
        )}
      </Box>
      <Modal
        currentRequest={currentRequest}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
      <ConfirmActivationModal
        currentRequest={currentRequest}
        setShowActivationModal={setShowActivationModal}
        showActivationModal={showActivationModal}
        refetch={refetch}
      />
      <AddCommentModal
        currentRequest={currentRequest}
        setShowAddCommentModal={setShowAddCommentModal}
        showAddCommentModal={showAddCommentModal}
      />
    </Box>
  );
};

export { Applications };

const Modal = ({ currentRequest, isOpen, setIsOpen }) => {
  const { data } = useQuery(
    ['getMerchantDocuments', currentRequest?.id],
    getMerchantDocuments
  );

  return (
    <RightModal
      title={currentRequest?.company_name}
      showModal={isOpen}
      setShowModal={setIsOpen}
    >
      <Text fontFamily="regular">{currentRequest?.email}</Text>
      <Text fontFamily="regular">
        {dayjs(currentRequest?.created_at).format('DD-MM-YYYY')}
      </Text>
      <Text
        fontFamily="regular"
        fontSize="2.4rem"
        lineHeight="2.9rem"
        mt="5.6rem"
        mb="6.3rem"
      >
        View and download documents
      </Text>
      {data?.data.map((download) => (
        <Box
          key={download?.id}
          d="flex"
          alignItems="center"
          justifyContent="space-between"
          borderBottom="1px solid #E4E4E4"
          pb="0.8rem"
          mb="1.4rem"
        >
          <Text
            fontFamily="medium"
            color="#212D43"
            fontSize="1.4rem"
            lineHeight="1.7rem"
          >
            {download?.title}
          </Text>
          <Box
            as="a"
            href={download?.document_url}
            w="10.8rem"
            h="3.2rem"
            d="flex"
            alignItems="center"
            justifyContent="center"
            bg="#e0f3e8"
            borderRadius="8px"
          >
            <Image src={Download} alt="download icon" />
            <Text
              color="#2EAE65"
              fontFamily="regular"
              fontSize="1.4rem"
              lineHeight="1.7rem"
              ml="1rem"
            >
              Download
            </Text>
          </Box>
        </Box>
      ))}
      {data?.data.length < 1 && (
        <Text fontFamily="regular">No document has been uploaded</Text>
      )}
    </RightModal>
  );
};

const ConfirmActivationModal = ({
  currentRequest,
  setShowActivationModal,
  showActivationModal,
  refetch,
}) => {
  const [loading, setLoading] = useState(false);

  const handleActivate = async () => {
    try {
      setLoading(true);
      const res = await approveMerchant(currentRequest.id);
      toast.success(res.message);
      setLoading(false);
      setShowActivationModal(false);
      refetch();
    } catch (err) {
      setLoading(false);
      setShowActivationModal(false);
      toast.error(err.response?.data?.message || 'Error Occured');
    }
  };

  return (
    <StyledWrapper>
      <CenterModal
        title="Are you sure you want to activate this merchant?"
        align="center"
        showModal={showActivationModal}
        setShowModal={setShowActivationModal}
      >
        <Box d="flex" justifyContent="space-between" alignItems="center">
          <OutlineBtn
            borderColor="#939AA3"
            color="#939AA3"
            w="15rem"
            onClick={() => setShowActivationModal(false)}
            _hover={{ background: '#939AA3', color: '#fff' }}
          >
            No
          </OutlineBtn>
          <SolidBtn onClick={handleActivate} isLoading={loading} w="15rem">
            Yes
          </SolidBtn>
        </Box>
      </CenterModal>
    </StyledWrapper>
  );
};

const AddCommentModal = ({
  currentRequest,
  setShowAddCommentModal,
  showAddCommentModal,
}) => {
  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState('');

  const handleActivate = async () => {
    try {
      setLoading(true);
      const res = await addCommentToMerchantApplication(
        currentRequest.id,
        comment
      );
      toast.success(res.message);
      setLoading(false);
      setShowAddCommentModal(false);
      setComment('');
    } catch (err) {
      setLoading(false);
      toast.error(err.response?.data?.message || 'Something went wrong');
    }
  };

  return (
    <CenterModal
      title="Add comment"
      showModal={showAddCommentModal}
      setShowModal={setShowAddCommentModal}
    >
      <Textarea
        placeholder="Enter message"
        fontSize="1.4rem"
        fontFamily="regular"
        value={comment}
        onChange={({ target }) => setComment(target.value)}
      />
      <Box
        mt="2rem"
        d="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <SolidBtn
          isDisabled={!comment}
          w="100%"
          onClick={handleActivate}
          isLoading={loading}
        >
          Send
        </SolidBtn>
      </Box>
    </CenterModal>
  );
};

const StyledWrapper = styled(Box)`
  .closeBtn {
    display: none;
  }
`;
