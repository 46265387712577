import React, { useEffect, useContext } from 'react';
import { Box, Flex, HStack, Text } from '@chakra-ui/react';
import { Link, useHistory } from 'react-router-dom';
import { AuthContext } from '../../context/auth';

import { ReactComponent as HourglassIcon } from '../../assets/icons/hourglass.svg';
import { SolidBtn } from '../../components/common/buttons';

function WaitingScreen() {
  const { authState } = useContext(AuthContext);
  const history = useHistory();

  useEffect(() => {
    if (authState.isVerified) {
      history.push('/merchant/login');
    }
  }, []);

  return (
    <Flex w="100%" h="100vh" justify="center" align="center">
      <HStack spacing="4rem" align="center">
        <HourglassIcon style={{ width: '200px' }} />
        <Box>
          <Text fontFamily="regular" fontSize="4rem" color="#FDB600">
            Awaiting Approval
          </Text>
          <Text
            mt="1rem"
            mb="3rem"
            color="#808080"
            fontFamily="regular"
            maxW="500px"
          >
            Your documents were successfully uploaded and under review. You will
            be able to login into your dashboard once your documents are
            verified.
            <br />
            <br />
            Thanks for your patience.
          </Text>
          <SolidBtn as={Link} to="/merchant/login" w="170px">
            Return to login
          </SolidBtn>
        </Box>
      </HStack>
    </Flex>
  );
}

export { WaitingScreen };
