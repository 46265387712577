import React from 'react';
import { Button } from '@chakra-ui/react';

function SolidBtn(props) {
  return (
    <Button
      w="100%"
      py="2.3rem"
      backgroundColor="brand.blue"
      color="#fff"
      borderRadius="4px"
      fontSize="1.4rem"
      fontFamily={props.fontFamily || 'medium'}
      _hover={{
        opacity: 0.8,
      }}
      _focus={{
        borderColor: 'transparent',
      }}
      _disabled={{
        backgroundColor: '#939AA3',
        cursor: 'not-allowed',
      }}
      {...props}
    >
      {props.children}
    </Button>
  );
}

export { SolidBtn };
