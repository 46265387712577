import React, { useState } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { SolidBtn } from '../../components/common/buttons';
import { toast } from 'react-toastify';

import { NavBar } from './NavBar';
import { CustomInput, InputError } from '../../components/common/form/Input';
import bgImage from '../../assets/icons/services/ContactJumbo.png';
import { ReactComponent as Location } from '../../assets/icons/services/Location.svg';
import { ReactComponent as Mail } from '../../assets/icons/services/mail.svg';
import { ReactComponent as Phone } from '../../assets/icons/services/Calling.svg';
import { createContact } from '../../queries';
import { Footer } from './Footer';

const Contact = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { register, handleSubmit, errors, reset } = useForm();

  const onSubmit = async (inputData) => {
    try {
      setIsLoading(true);
      const data = await createContact(inputData);
      toast.success(data);
      reset();
      setIsLoading(false);
    } catch (error) {
      toast.error('Error sending your message');
      setIsLoading(false);
    }
  };

  return (
    <>
      <NavBar />
      <Box>
        <Flex
          bgImg={`url(${bgImage})`}
          bgPosition="center"
          bgRepeat="no-repeat"
          bgSize="cover"
          h={{ base: '30rem', lg: '61rem' }}
          align="flex-end"
        >
          <Box
            maxWidth="1440px"
            w="100%"
            margin="0 auto"
            pl={{ base: '1rem', lg: '18.4rem' }}
            pb={{ base: '2rem', lg: '10.5rem' }}
          >
            <Text
              color="#fff"
              fontFamily="bold"
              fontSize={{ base: '2rem', lg: '4rem' }}
              lineHeight="110%"
              letterSpacing="-0.01em"
              mb="2.4rem"
            >
              CONTACT US
            </Text>
            <Text
              color="#e4e4e4"
              fontFamily="medium"
              fontSize={{ base: '1.4rem', lg: '1.8rem' }}
              lineHeight="155%"
              letterSpacing="-0.02em"
            >
              Need more clarity on any of our services and want to talk to
              <br />
              someone? We are here for you.
            </Text>
          </Box>
        </Flex>
        <Flex
          direction={{ base: 'column', lg: 'row' }}
          maxWidth="1440px"
          margin="0 auto"
          p={{ base: '0', lg: '7.2rem 18.4rem 0' }}
          justify="space-between"
        >
          <Box mt="2.1rem" pl={{ base: '1rem', lg: '0' }}>
            <Text
              lineHeight="140%"
              letterSpacing="-0.01em"
              fontFamily="bold"
              color="#444"
              fontSize={{ base: '2rem', lg: '2.4rem' }}
              mb="5.8rem"
            >
              Send your query to us via email, <br />
              or call us on
            </Text>
            <Box>
              <Flex mb="2.5rem" align="center">
                <Location />
                <Text
                  lineHeight="178%"
                  fontFamily="medium"
                  color="#626363"
                  fontSize={{ base: '1.5rem', lg: '1.6rem' }}
                  ml={{ base: '2rem', lg: '4rem' }}
                >
                  NO 64 U.I Secretariat Road, Kongi Bus stop Bodija
                  <br /> Ibadan, Oyo State Nigeria.
                </Text>
              </Flex>
              <Flex mb="2.5rem" align="center">
                <Mail />
                <Text
                  as="a"
                  href="mailto:info@awakeventures.com"
                  lineHeight="130%"
                  fontFamily="medium"
                  color="#626363"
                  fontSize={{ base: '1.5rem', lg: '1.6rem' }}
                  ml={{ base: '2rem', lg: '4rem' }}
                >
                  info@awakeventures.com
                </Text>
              </Flex>
              <Flex mb="2.5rem" align="center">
                <Phone />
                <Text
                  lineHeight="130%"
                  fontFamily="medium"
                  color="#626363"
                  fontSize={{ base: '1.5rem', lg: '1.6rem' }}
                  ml={{ base: '2rem', lg: '4rem' }}
                  as="a"
                  href="tel:08032031717"
                >
                  +234 708 0099 345
                </Text>
              </Flex>
            </Box>
          </Box>
          <Box
            bgColor="brand.blue"
            borderRadius=".4rem"
            w={{ base: '100%', lg: '47.8rem' }}
            p="5.3rem 2.5rem 5.4rem 2.4rem"
            zIndex="50"
          >
            <Text
              lineHeight="120%"
              fontFamily="medium"
              color="#fff"
              fontSize="2.4rem"
              letterSpacing="-0.02em"
              mb="2.4rem"
            >
              Want to leave us a message?
            </Text>
            <Text
              lineHeight="155%"
              fontFamily="medium"
              color="rgba(228, 228, 228, 0.8)"
              fontSize="1.6rem"
              letterSpacing="-0.005em"
              mb="8.4rem"
            >
              Need more clarity on any of our services and want to talk to
              someone? We are here for you.
            </Text>
            <Box as="form" onSubmit={handleSubmit(onSubmit)}>
              <CustomInput
                id="email"
                hideIcon
                labelFamily="medium"
                labelColor="#c4c4c4"
                inputColor="#c4c4c4"
                borderBottom="1px solid #939AA3"
                bgColor="none"
                borderRadius="none"
                label="Email address"
                name="email"
                color="#c4c4c4"
                inputRef={register({
                  required: 'Email is required',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Invalid email address',
                  },
                })}
                hideRightIcon
              />
              {errors.email && (
                <InputError color="#E24D4D" text={errors.email.message} />
              )}
              <CustomInput
                id="message"
                hideIcon
                label="Message"
                labelFamily="medium"
                labelColor="#c4c4c4"
                inputColor="#c4c4c4"
                borderBottom="1px solid #939AA3"
                bgColor="none"
                borderRadius="none"
                type="message"
                name="message"
                color="#c4c4c4"
                style={{ marginTop: '8.7rem' }}
                inputRef={register({
                  required: 'Message is required',
                })}
                hideRightIcon
              />
              {errors.message && (
                <InputError color="#E24D4D" text={errors.message.message} />
              )}
              <SolidBtn
                type="submit"
                mt="2.4rem"
                h="4.2rem"
                w="12rem"
                bgColor="#fff"
                color="brand.blue"
                borderRadius="4px"
                isLoading={isLoading}
              >
                Contact Us
              </SolidBtn>
            </Box>
          </Box>
        </Flex>
        <Box>
          <Box m="-12rem 0 8rem">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1468.8854863606537!2d3.9113622366006835!3d7.427918019833171!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1039ed56cf2c0001%3A0xdab98069dda23edf!2sAwake%20Interlink%20Building!5e0!3m2!1sen!2sng!4v1608114754181!5m2!1sen!2sng"
              width="100%"
              height="600px"
              frameBorder="0"
              aria-hidden="false"
              tabIndex="0"
            ></iframe>
          </Box>
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export { Contact };
