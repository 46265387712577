import { Box, Flex, Text, Image } from '@chakra-ui/react';
import React from 'react';

import { ReactComponent as ArrowUp } from '../../../assets/icons/arrowup.svg';
import { ReactComponent as ArrowDown } from '../../../assets/icons/arrowdown.svg';
import WalletIcon from '../../../assets/icons/walletIconFull.svg';
import { SolidBtn } from '../../common/buttons';
import { nFormatter } from '../../../utils';

function Balance({ walletStats, setShowCenterModal }) {
  return (
    <Flex justify="space-between" mt="1.6rem">
      <Flex
        w="61.3%"
        boxShadow="0px 0px 4px rgba(0, 0, 0, 0.1)"
        bgColor="#fff"
        p="2.4rem 4rem"
      >
        <Box w="33.3%" borderRight=" 1px solid#E8EEF4">
          <Flex
            bgColor=" rgba(46, 174, 101, 0)"
            borderRadius="2rem"
            w="5.4rem"
            h="2.5rem"
            align="center"
            justify="center"
          ></Flex>
          <Text
            mt="2.9rem"
            fontFamily="bold"
            fontSize="22px"
            lineHeight="34px"
            letterSpacing="-0.02em"
            color="#444"
            whiteSpace="nowrap"
          >
            {nFormatter(walletStats?.[0]?.balance)}
          </Text>
          <Text
            mt="4px"
            color="rgba(0, 19, 80, 0.7)"
            lineHeight="17px"
            letterSpacing="-0.02em"
            fontFamily="regular"
          >
            Balance
          </Text>
        </Box>
        <Box w="33.3%" borderRight=" 1px solid#E8EEF4" pl="3.2rem">
          <Flex
            bgColor={
              walletStats?.[2].change === 0
                ? '#e1e1e1'
                : walletStats?.[2].change > 0
                ? '#d5efe0'
                : '#f9dbdb'
            }
            borderRadius="2rem"
            w="5.4rem"
            h="2.5rem"
            align="center"
            justify="center"
          >
            <Text
              color={
                walletStats?.[2].change === 0
                  ? '#000'
                  : walletStats?.[2].change > 0
                  ? '#2EAE65'
                  : '#DC444C'
              }
              fontFamily="bold"
              fontSize="13px"
              lineHeight="16px"
              letterSpacing="-0.02em"
              mr=".5rem"
            >
              {parseInt(walletStats?.[2].change || 0)}%
            </Text>

            {walletStats?.[2].change > 0 && <ArrowUp />}
            {walletStats?.[2].change < 0 && <ArrowDown />}
          </Flex>

          <Text
            mt="2.9rem"
            fontFamily="bold"
            fontSize="22px"
            lineHeight="34px"
            letterSpacing="-0.02em"
            color="#444"
            whiteSpace="nowrap"
          >
            {nFormatter(walletStats?.[2].totalCredit)}
          </Text>
          <Text
            mt="4px"
            color="rgba(0, 19, 80, 0.7)"
            lineHeight="17px"
            letterSpacing="-0.02em"
            fontFamily="regular"
          >
            Total credit
          </Text>
        </Box>
        <Box w="33.3%" pl="3.2rem">
          <Flex
            bgColor={
              walletStats?.[1].change === 0
                ? '#e1e1e1'
                : walletStats?.[1].change > 0
                ? '#d5efe0'
                : '#f9dbdb'
            }
            borderRadius="2rem"
            w="5.4rem"
            h="2.5rem"
            align="center"
            justify="center"
          >
            <Text
              color={
                walletStats?.[1].change === 0
                  ? '#000'
                  : walletStats?.[1].change > 0
                  ? '#2EAE65'
                  : '#DC444C'
              }
              fontFamily="bold"
              fontSize="13px"
              lineHeight="16px"
              letterSpacing="-0.02em"
              mr=".5rem"
            >
              {parseInt(walletStats?.[1].change || 0)}%
            </Text>

            {walletStats?.[1].change > 0 && <ArrowUp />}
            {walletStats?.[1].change < 0 && <ArrowDown />}
          </Flex>
          <Text
            mt="2.9rem"
            fontFamily="bold"
            fontSize="22px"
            lineHeight="34px"
            letterSpacing="-0.02em"
            color="#444"
          >
            {nFormatter(walletStats?.[1].totalDebit)}
          </Text>
          <Text
            mt="4px"
            color="rgba(0, 19, 80, 0.7)"
            lineHeight="17px"
            letterSpacing="-0.02em"
            fontFamily="regular"
          >
            Total debit
          </Text>
        </Box>
      </Flex>
      <Flex
        w="34.9%"
        bgColor="#D9EEE2"
        borderRadius="4px"
        p="1.6rem 2.4rem 1.8rem 2.9rem"
        justify="space-between"
      >
        <Box w="40%">
          <Image w="100%" h="100%" src={WalletIcon} />
        </Box>
        <Box w="55%" flexShrink="0">
          <Text
            color="#2EAE65"
            lineHeight="24px"
            fontSize="20px"
            letterSpacing="-0.02em"
            fontFamily="bold"
          >
            Initiate fund request
          </Text>
          <Text
            mt=".8rem"
            color="#939AA3"
            lineHeight="17px"
            letterSpacing="-0.02em"
            fontFamily="regular"
          >
            Fund your wallet now.
          </Text>
          <SolidBtn
            mt="2.5rem"
            bgColor="brand.blue"
            borderRadius="4px"
            h="4rem"
            w="14rem"
            color="#fff"
            fontSize="14px"
            fontFamily="regular"
            _focus={{ boxShadow: 'none' }}
            onClick={() => setShowCenterModal(true)}
          >
            Fund wallet
          </SolidBtn>
        </Box>
      </Flex>
    </Flex>
  );
}

export { Balance };
