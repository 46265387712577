import axios from 'axios';
import instance from '../axios.config';
import { handleError } from '../../utils';

const getTopupData = async () => {
  try {
    const [topupStatisticsData, topupHistoryData] = await axios.all([
      instance.get('/admin/wallet/top-up-statistics'),
      instance.get('/admin/wallet/top-up-history'),
    ]);

    return {
      topupStatisticsData: topupStatisticsData.data.data,
      topupHistoryData: topupHistoryData.data.data,
    };
  } catch (error) {
    handleError(error);
  }
};

const getTopupStatistics = async () => {
  try {
    const { data } = await instance.get('/admin/wallet/top-up-statistics');

    return data.data;
  } catch (error) {
    handleError(error);
  }
};

const getTopupHistory = async (key, page = 1) => {
  try {
    const { data } = await instance.get(
      `/admin/wallet/top-up-history?page=${page}`
    );

    return data.data;
  } catch (error) {
    handleError(error);
  }
};

const adminTopup = async (body) => {
  const { data } = await instance.put('/admin/wallet/top-up', body);
  return data;
};

export { getTopupData, adminTopup, getTopupHistory, getTopupStatistics };
