import React from 'react';
import { Box, Flex, Text, Image } from '@chakra-ui/react';
import Airtel from '../../assets/images/ourPartners/airtel.svg';
import Mtn from '../../assets/images/ourPartners/mtn.svg';
import Glo from '../../assets/images/ourPartners/glo.svg';
import Etisalat from '../../assets/images/ourPartners/9mobile.svg';
import Dstv from '../../assets/images/ourPartners/dstv.svg';
import Gotv from '../../assets/images/ourPartners/gotv.svg';
import Ikeja from '../../assets/images/ourPartners/ikeja.svg';

const OurPartners = () => {
  return (
    <Box align="center" px={{ base: '2rem', lg: '1rem' }}>
      <Box>
        <Text fontSize="3.2rem" fontFamily="regular" color="#444">
          Our Partners
        </Text>
      </Box>{' '}
      <Flex
        align="center"
        justifyContent="space-around"
        bgColor="#F3F3F3"
        maxW="1140px"
        mx="auto"
        borderRadius="2px"
        marginTop="1.5rem"
        wrap="wrap"
        p="1.8rem 3rem"
      >
        <Image mx="1rem" my="1rem" src={Airtel} />
        <Image mx="1rem" my="1rem" src={Mtn} />
        <Image mx="1rem" my="1rem" src={Glo} />
        <Image mx="1rem" my="1rem" src={Etisalat} />
        <Image mx="1rem" my="1rem" src={Dstv} />
        <Image mx="1rem" my="1rem" src={Gotv} />
        <Image mx="1rem" my="1rem" src={Ikeja} />
      </Flex>
    </Box>
  );
};

export { OurPartners };
