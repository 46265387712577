import React, { useContext } from 'react';
import { Flex, Box, Tooltip } from '@chakra-ui/react';
import { NavLink, useHistory } from 'react-router-dom';
import { ReactComponent as ToggleIcon } from '../../../assets/icons/toggleWhite.svg';
import { ReactComponent as OverviewIcon } from '../../../assets/icons/overviewIcon.svg';
import { ReactComponent as WalletIcon } from '../../../assets/icons/walletIcon.svg';
import { ReactComponent as TicketIcon } from '../../../assets/icons/ticketIcon.svg';
import { ReactComponent as SettingsIcon } from '../../../assets/icons/settingsIcon.svg';
import { ReactComponent as LogoutIcon } from '../../../assets/icons/logoutIcon.svg';
import { ReactComponent as HchartIcon } from '../../../assets/icons/hchart.svg';
import { ReactComponent as TransactionIcon } from '../../../assets/icons/Transaction.svg';
import { ReactComponent as ApiIcon } from '../../../assets/icons/api.svg';
import { AuthContext, LOGOUT } from '../../../context/auth';

const SideBar = ({ isOpen, setIsOpen }) => {
  const toggleOpen = () => setIsOpen(!isOpen);
  const history = useHistory();

  const { dispatch } = useContext(AuthContext);

  const logout = () => {
    dispatch({ type: LOGOUT, payload: '' });
    history.push('/merchant/login');
  };

  return (
    <Flex w="240px" h="100vh" pos="fixed">
      <Box
        bg="brand.darkBlue"
        w="52px"
        zIndex="5"
        borderRight="1px solid rgba(255, 255, 255, 0.08)"
      >
        <Flex h="60px" align="center" justify="center">
          {!isOpen && (
            <Box w="16px" h="16px" cursor="pointer" onClick={toggleOpen}>
              <ToggleIcon />
            </Box>
          )}
        </Flex>
        {isOpen ? (
          <Box>
            <Box mt="40px">
              <Flex
                h="31px"
                w="100%"
                align="center"
                justify="center"
                mb="11px"
                as={NavLink}
                to="/merchant/overview"
                name="icon"
              >
                <OverviewIcon />
              </Flex>
              <Flex
                h="31px"
                w="100%"
                align="center"
                justify="center"
                mb="11px"
                as={NavLink}
                to="/merchant/wallet"
                name="icon"
              >
                <WalletIcon />
              </Flex>
              <Flex
                h="31px"
                w="100%"
                align="center"
                justify="center"
                mb="11px"
                as={NavLink}
                to="/merchant/transactions"
                name="icon"
              >
                <TransactionIcon />
              </Flex>
              <Flex
                h="31px"
                w="100%"
                align="center"
                justify="center"
                mb="11px"
                as={NavLink}
                to="/merchant/products"
                name="icon"
              >
                <HchartIcon />
              </Flex>

              <Flex
                h="31px"
                w="100%"
                align="center"
                justify="center"
                mb="11px"
                as={NavLink}
                to="/merchant/documentation"
                name="icon"
              >
                <ApiIcon />
              </Flex>
            </Box>
            <Box mt="74px">
              <Flex
                as={NavLink}
                h="31px"
                w="100%"
                align="center"
                justify="center"
                mb="11px"
                to="/merchant/ticket"
                name="icon"
              >
                <TicketIcon />
              </Flex>
              <Flex
                h="31px"
                w="100%"
                align="center"
                justify="center"
                as={NavLink}
                to="/merchant/settings"
                name="icon"
              >
                <SettingsIcon />
              </Flex>
            </Box>
            <Box mt="150px">
              <Flex
                h="31px"
                w="100%"
                align="center"
                justify="center"
                cursor="pointer"
                onClick={logout}
                name="icon"
              >
                <LogoutIcon />
              </Flex>
            </Box>
          </Box>
        ) : (
          <Box>
            <Box mt="40px">
              <Tooltip
                shouldWrapChildren
                label="Overview"
                placement="right-end"
                fontFamily="regular"
                fontSize="12px"
                ml="-5px"
              >
                <Flex
                  h="31px"
                  w="100%"
                  align="center"
                  justify="center"
                  mb="11px"
                  as={NavLink}
                  to="/merchant/overview"
                  name="icon"
                >
                  <OverviewIcon />
                </Flex>
              </Tooltip>
              <Tooltip
                shouldWrapChildren
                label="Wallet"
                placement="right-end"
                fontFamily="regular"
                fontSize="12px"
                ml="-5px"
              >
                <Flex
                  h="31px"
                  w="100%"
                  align="center"
                  justify="center"
                  mb="11px"
                  as={NavLink}
                  to="/merchant/wallet"
                  name="icon"
                >
                  <WalletIcon />
                </Flex>
              </Tooltip>
              <Tooltip
                shouldWrapChildren
                label="Transactions"
                placement="right-end"
                fontFamily="regular"
                fontSize="12px"
                ml="-5px"
              >
                <Flex
                  h="31px"
                  w="100%"
                  align="center"
                  justify="center"
                  mb="11px"
                  as={NavLink}
                  to="/merchant/transactions"
                  name="icon"
                >
                  <TransactionIcon />
                </Flex>
              </Tooltip>
              <Tooltip
                shouldWrapChildren
                label="Product list"
                placement="right-end"
                fontFamily="regular"
                fontSize="12px"
                ml="-5px"
              >
                <Flex
                  h="31px"
                  w="100%"
                  align="center"
                  justify="center"
                  mb="11px"
                  as={NavLink}
                  to="/merchant/products"
                  name="icon"
                >
                  <HchartIcon />
                </Flex>
              </Tooltip>
              <Tooltip
                shouldWrapChildren
                label="API documentation"
                placement="right-end"
                fontFamily="regular"
                fontSize="12px"
                ml="-5px"
              >
                <Flex
                  h="31px"
                  w="100%"
                  align="center"
                  justify="center"
                  mb="11px"
                  as={NavLink}
                  to="/merchant/documentation"
                  name="icon"
                >
                  <ApiIcon />
                </Flex>
              </Tooltip>
            </Box>
            <Box mt="74px">
              <Tooltip
                shouldWrapChildren
                label="Ticket"
                placement="right-end"
                fontFamily="regular"
                fontSize="12px"
                ml="-5px"
              >
                <Flex
                  as={NavLink}
                  h="31px"
                  w="100%"
                  align="center"
                  justify="center"
                  mb="11px"
                  to="/merchant/ticket"
                  name="icon"
                >
                  <TicketIcon />
                </Flex>
              </Tooltip>
              <Tooltip
                shouldWrapChildren
                label="Settings"
                placement="right-end"
                fontFamily="regular"
                fontSize="12px"
                ml="-5px"
              >
                <Flex
                  h="31px"
                  w="100%"
                  align="center"
                  justify="center"
                  as={NavLink}
                  to="/merchant/settings"
                  name="icon"
                >
                  <SettingsIcon />
                </Flex>
              </Tooltip>
            </Box>
            <Box mt="150px">
              <Tooltip
                shouldWrapChildren
                label="Logout"
                placement="right-end"
                fontFamily="regular"
                fontSize="12px"
                ml="-5px"
              >
                <Flex
                  h="31px"
                  w="100%"
                  align="center"
                  justify="center"
                  as={NavLink}
                  to="/merchant/logout"
                  name="icon"
                >
                  <LogoutIcon />
                </Flex>
              </Tooltip>
            </Box>
          </Box>
        )}
      </Box>
      {isOpen && (
        <Box bg="brand.blue" w="188px">
          <Box
            mt="100px"
            borderBottom="1px solid rgba(255, 255, 255, 0.1)"
            pb="1.5rem"
          >
            <Flex h="31px" w="100%" align="center" pl="16px" mb="11px">
              <Box
                as={NavLink}
                to="/merchant/overview"
                color="#C4C4C4"
                fontFamily="regular"
                _hover={{ fontFamily: 'medium', color: '#C4C4C4' }}
              >
                Overview
              </Box>
            </Flex>
            <Flex h="31px" w="100%" align="center" pl="16px" mb="11px">
              <Box
                as={NavLink}
                to="/merchant/wallet"
                color="#C4C4C4"
                fontFamily="regular"
                _hover={{ fontFamily: 'medium', color: '#c4c4c4' }}
              >
                Wallet
              </Box>
            </Flex>
            <Flex h="31px" w="100%" align="center" pl="16px" mb="11px">
              <Box
                as={NavLink}
                to="/merchant/transactions"
                color="#C4C4C4"
                fontFamily="regular"
                _hover={{ fontFamily: 'medium', color: '#c4c4c4' }}
              >
                Transactions
              </Box>
            </Flex>
            <Flex h="31px" w="100%" align="center" pl="16px" mb="11px">
              <Box
                as={NavLink}
                to="/merchant/products"
                color="#C4C4C4"
                fontFamily="regular"
                _hover={{ fontFamily: 'medium', color: '#c4c4c4' }}
              >
                Product list
              </Box>
            </Flex>
            <Flex h="31px" w="100%" align="center" pl="16px" mb="11px">
              <Box
                as={NavLink}
                to="/merchant/documentation"
                color="#C4C4C4"
                fontFamily="regular"
                _hover={{ fontFamily: 'medium', color: '#c4c4c4' }}
              >
                API documentation
              </Box>
            </Flex>
          </Box>
          <Box mt="4.7rem">
            <Flex h="31px" w="100%" align="center" pl="16px" mb="11px">
              <Box
                as={NavLink}
                to="/merchant/ticket"
                color="#C4C4C4"
                fontFamily="regular"
                _hover={{ fontFamily: 'medium', color: '#c4c4c4' }}
              >
                Ticket
              </Box>
            </Flex>
            <Flex h="31px" w="100%" align="center" pl="16px" mb="11px">
              <Box
                as={NavLink}
                to="/merchant/settings"
                color="#C4C4C4"
                fontFamily="regular"
                _hover={{ fontFamily: 'medium', color: '#c4c4c4' }}
              >
                Settings
              </Box>
            </Flex>
          </Box>
          <Box mt="150px">
            <Flex h="31px" w="100%" align="center" pl="16px" mb="11px">
              <Box
                cursor="pointer"
                onClick={logout}
                color="#C4C4C4"
                fontFamily="regular"
                _hover={{ fontFamily: 'medium', color: '#c4c4c4' }}
              >
                Logout
              </Box>
            </Flex>
          </Box>
        </Box>
      )}
    </Flex>
  );
};

export { SideBar };
