import React, { useContext } from 'react';
import { Flex, Box, keyframes, Tooltip } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { NavLink, useHistory, useRouteMatch } from 'react-router-dom';
import { ReactComponent as OverviewIcon } from '../../../../assets/icons/overviewIcon.svg';
import { ReactComponent as MerchantIcon } from '../../../../assets/icons/merchants.svg';
import { ReactComponent as ChartIcon } from '../../../../assets/icons/chartIcon.svg';
import { ReactComponent as WalletIcon } from '../../../../assets/icons/walletIcon.svg';
import { ReactComponent as DocumentIcon } from '../../../../assets/icons/documentIcon.svg';
import { ReactComponent as RoleIcon } from '../../../../assets/icons/role.svg';
import { ReactComponent as DiscountIcon } from '../../../../assets/icons/discount.svg';
import { ReactComponent as TopUpIcon } from '../../../../assets/icons/topup.svg';
import { ReactComponent as TicketIcon } from '../../../../assets/icons/ticketIcon.svg';
import { ReactComponent as SettingsIcon } from '../../../../assets/icons/settingsIcon.svg';
import { ReactComponent as LogoutIcon } from '../../../../assets/icons/logoutIcon.svg';
import { AuthContext, LOGOUT } from '../../../../context/auth';

const CollapsedIcons = ({ isDropdownOpen }) => {
  const match = useRouteMatch('/admin/applications');
  const match2 = useRouteMatch('/admin/merchant');
  const { dispatch, authState } = useContext(AuthContext);
  const history = useHistory();

  const logout = () => {
    dispatch({ type: LOGOUT, payload: '' });
    history.push('/admin/login');
  };

  return (
    <Box>
      <Box mt="40px">
        <Tooltip
          shouldWrapChildren
          label="Overview"
          placement="right-end"
          fontFamily="regular"
          fontSize="12px"
          ml="-5px"
        >
          <Flex
            h="31px"
            w="100%"
            align="center"
            justify="center"
            mb="11px"
            as={NavLink}
            to="/admin/overview"
            name="icon"
          >
            <OverviewIcon />
          </Flex>
        </Tooltip>
        <Tooltip
          shouldWrapChildren
          label="Merchants"
          placement="right-end"
          fontFamily="regular"
          fontSize="12px"
          ml="-5px"
        >
          <Flex
            h="31px"
            w="100%"
            align="center"
            justify="center"
            mb="11px"
            as={NavLink}
            to="/admin/merchants"
            name="icon"
            className={(match || match2) && 'active'}
          >
            <MerchantIcon />
          </Flex>
        </Tooltip>
        {isDropdownOpen && (
          <MotionFlex
            w="100%"
            h="21px"
            align="center"
            pl="16px"
            mb="11px"
            mt="-5px"
          ></MotionFlex>
        )}
        <Tooltip
          shouldWrapChildren
          label="Sales Revenue"
          placement="right-end"
          fontFamily="regular"
          fontSize="12px"
          ml="-5px"
        >
          <Flex
            h="31px"
            w="100%"
            align="center"
            justify="center"
            mb="11px"
            as={NavLink}
            to="/admin/revenue"
            name="icon"
          >
            <ChartIcon />
          </Flex>
        </Tooltip>
        <Tooltip
          shouldWrapChildren
          label="Fund Request"
          placement="right-end"
          fontFamily="regular"
          fontSize="12px"
          ml="-5px"
        >
          <Flex
            h="31px"
            w="100%"
            align="center"
            justify="center"
            mb="11px"
            as={NavLink}
            to="/admin/fund-request"
            name="icon"
          >
            <WalletIcon />
          </Flex>
        </Tooltip>
        <Tooltip
          shouldWrapChildren
          label="Audit Trail"
          placement="right-end"
          fontFamily="regular"
          fontSize="12px"
          ml="-5px"
        >
          <Flex
            h="31px"
            w="100%"
            align="center"
            justify="center"
            mb="11px"
            as={NavLink}
            to="/admin/audit-trail"
            name="icon"
          >
            <DocumentIcon />
          </Flex>
        </Tooltip>
        {authState.isSuperAdmin && (
          <Tooltip
            shouldWrapChildren
            label="User Management"
            placement="right-end"
            fontFamily="regular"
            fontSize="12px"
            ml="-5px"
          >
            <Flex
              h="31px"
              w="100%"
              align="center"
              justify="center"
              pr="8px"
              as={NavLink}
              to="/admin/user-management"
              name="icon"
            >
              <RoleIcon />
            </Flex>
          </Tooltip>
        )}
      </Box>
      <Box mt="74px">
        <Tooltip
          shouldWrapChildren
          label="Top-Up"
          placement="right-end"
          fontFamily="regular"
          fontSize="12px"
          ml="-5px"
        >
          <Flex
            h="31px"
            w="100%"
            align="center"
            justify="center"
            mb="11px"
            as={NavLink}
            to="/admin/top-up"
            name="icon"
          >
            <TopUpIcon />
          </Flex>
        </Tooltip>
        <Tooltip
          shouldWrapChildren
          label="Discount"
          placement="right-end"
          fontFamily="regular"
          fontSize="12px"
          ml="-5px"
        >
          <Flex
            h="31px"
            w="100%"
            align="center"
            justify="center"
            mb="11px"
            as={NavLink}
            to="/admin/discount"
            name="icon"
          >
            <DiscountIcon />
          </Flex>
        </Tooltip>
        <Tooltip
          shouldWrapChildren
          label="Ticket"
          placement="right-end"
          fontFamily="regular"
          fontSize="12px"
          ml="-5px"
        >
          <Flex
            as={NavLink}
            h="31px"
            w="100%"
            align="center"
            justify="center"
            mb="11px"
            to="/admin/ticket"
            name="icon"
          >
            <TicketIcon />
          </Flex>
        </Tooltip>
        <Tooltip
          shouldWrapChildren
          label="Settings"
          placement="right-end"
          fontFamily="regular"
          fontSize="12px"
          ml="-5px"
        >
          <Flex
            h="31px"
            w="100%"
            align="center"
            justify="center"
            as={NavLink}
            to="/admin/settings"
            name="icon"
          >
            <SettingsIcon />
          </Flex>
        </Tooltip>
      </Box>
      <Box mt="150px">
        <Tooltip
          shouldWrapChildren
          label="Logout"
          placement="right-end"
          fontFamily="regular"
          fontSize="12px"
          ml="-5px"
        >
          <Flex
            h="31px"
            w="100%"
            align="center"
            justify="center"
            cursor="pointer"
            onClick={logout}
            name="icon"
          >
            <LogoutIcon />
          </Flex>
        </Tooltip>
      </Box>
    </Box>
  );
};

export { CollapsedIcons };

const example = keyframes`
    from {
      height: 0;
    }
    to {
      height: 21px;
    }
`;

const MotionFlex = styled(Flex)`
  animation: ${example} 0.5s;
`;
