import React, { useState, useEffect } from 'react';
import { Box, Text, HStack, Flex } from '@chakra-ui/react';
import { useQuery } from 'react-query';
import dayjs from 'dayjs';

import {
  CustomTable,
  SelectColumnFilter,
  TableWrapper,
} from '../../components/common/tables';
import { getTxHistory } from '../../queries';
import { SpinningText } from '../../components/common/loaders/Loader';
import { ReactComponent as Export } from '../../assets/icons/Export.svg';
import { ReactComponent as FallbackIcon } from '../../assets/icons/fallback.svg';
import { ExportTransaction } from '../../components/export/merchant';
import { nFormatter } from '../../utils';
import { ErrorOccured } from '../../components/common/errors';

function TransactionHistory() {
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);
  const { isLoading, data, refetch, isError } = useQuery(
    ['getTxHistory', page],
    getTxHistory
  );

  useEffect(() => {
    refetch();
  }, [page]);

  const handleOpen = () => {
    setOpen(true);
  };

  if (isLoading) {
    return <SpinningText />;
  }

  if (isError) {
    return <ErrorOccured />;
  }

  const totalPages = data?.totalPages;
  const totalData = data?.total;
  const currentPage = data?.currentPage;

  const defaultData = (() => {
    const list = data.txLog.map((item) => ({
      col1: `${item.phone_number}`,
      col2: item.amount,
      col3: item.product,
      col4: item.created_at,
      col5: item.tx_status,
      col6: item.tx_ref,
      col7: item.debit_amount,
    }));

    return list;
  })();

  const defaultColumns = [
    {
      Header: 'Reference',
      accessor: 'col6',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Phone number',
      accessor: 'col1',
      disableSortBy: true,
      disableFilters: true,
    },
    {
      Header: 'Amount',
      accessor: 'col2',
      Cell: ({ value }) => nFormatter(value),
      disableFilters: true,
    },
    {
      Header: 'Discounted Amount',
      accessor: 'col7',
      Cell: ({ value }) => nFormatter(value),
      disableFilters: true,
    },
    {
      Header: 'Product',
      accessor: 'col3',
      disableFilters: true,
    },
    {
      Header: 'Date',
      Cell: ({ value }) => (
        <Text fontFamily="regular" fontWeight="bold !important">
          {dayjs(value).format('MMM D, YYYY')}
          <Text as="span" ml="10px" color="#808080" fontWeight="bold!important">
            {dayjs(value).format('HH:mm A')}
          </Text>
        </Text>
      ),
      accessor: 'col4',
      disableFilters: true,
    },
    {
      Header: 'Status',
      Cell: ({ value }) => (
        <Text
          fontFamily="regular"
          color={value === 'successful' ? '#2EAE65' : '#E24D4D'}
          fontWeight="bold !important"
        >
          {value}
        </Text>
      ),
      accessor: 'col5',
      Filter: SelectColumnFilter,
      disableSortBy: true,
    },
  ];

  return (
    <Box>
      <Box d="flex" justifyContent="space-between">
        <Text
          color="brand.header"
          lineHeight="3.8rem"
          fontSize="3.2rem"
          fontFamily="bold"
        >
          Transaction History
        </Text>
        {defaultData.length < 1 ? (
          ''
        ) : (
          <HStack spacing="3.5rem">
            <Export onClick={handleOpen} cursor="pointer" />
          </HStack>
        )}
      </Box>
      <Box
        boxShadow=" 0px 0px 4px rgba(0, 0, 0, 0.15)"
        p="1rem 4rem 4rem 4.2rem"
        mt="5rem"
        mb="8rem"
      >
        {defaultData.length < 1 ? (
          <Box>
            <Flex align="center" justify="center" mt="6rem">
              <FallbackIcon />
            </Flex>
            <Text fontSize="1.6rem" textAlign="center" fontFamily="medium">
              No data for transaction history yet
            </Text>
          </Box>
        ) : (
          <TableWrapper>
            <CustomTable
              data={defaultData}
              columns={defaultColumns}
              currentPage={currentPage}
              totalPages={totalPages}
              totalData={totalData}
              myPage={page}
              setPage={setPage}
            />
          </TableWrapper>
        )}
      </Box>
      <ExportTransaction open={open} setOpen={setOpen} />
    </Box>
  );
}

export { TransactionHistory };
