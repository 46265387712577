import axios from 'axios';
import { handleError } from '../../utils';
import instance from '../axios.config';

const customerGrowthData = async (key, growthPeriod) => {
  try {
    let url = `/admin/overview/customer-growth`;

    if (growthPeriod !== 'month') url += `?period=${growthPeriod}`;

    const { data } = await instance.get(url);

    return data.data;
  } catch (error) {
    handleError(error);
  }
};

const getAdminOverviewData = async () => {
  try {
    const [
      monthlyStatisticsData,
      weeklyStatisticsData,
      dailyStatisticsData,
      buyingMerchant,
      recentMerchants,
      serviceSubscription,
      networkAirtimeRequested,
      networkDataRequested,
      dailyCustomerGrowth,
      weeklyCustomerGrowth,
      monthlyCustomerGrowth,
    ] = await axios.all([
      instance.get('/admin/overview/statistics?type=month'),
      instance.get('/admin/overview/statistics?type=week'),
      instance.get('/admin/overview/statistics?type=day'),
      instance.get('/admin/overview/top-buying'),
      instance.get('/admin/overview/recent-merchant'),
      instance.get('/admin/overview/top-service'),
      instance.get('/admin/overview/top-network?type=airtime'),
      instance.get('/admin/overview/top-network?type=data'),
      instance.get('/admin/overview/customer-growth?period=day'),
      instance.get('/admin/overview/customer-growth?period=week'),
      instance.get('/admin/overview/customer-growth?period=month'),
    ]);

    return {
      monthlyStatisticsData: monthlyStatisticsData.data.data,
      weeklyStatisticsData: weeklyStatisticsData.data.data,
      dailyStatisticsData: dailyStatisticsData.data.data,
      buyingMerchant: buyingMerchant.data.data,
      recentMerchants: recentMerchants.data.data,
      serviceSubscription: serviceSubscription.data.data,
      networkAirtimeRequested: networkAirtimeRequested.data.data,
      networkDataRequested: networkDataRequested.data.data,
      dailyCustomerGrowth: dailyCustomerGrowth.data.data,
      weeklyCustomerGrowth: weeklyCustomerGrowth.data.data,
      monthlyCustomerGrowth: monthlyCustomerGrowth.data.data,
    };
  } catch (error) {
    handleError(error);
  }
};

const getTopBuyingMerchant = async (key, date) => {
  try {
    const url = `/admin/overview/top-buying?year=${date.year}-01-01`;
    const { data } = await instance.get(url);

    return data.data;
  } catch (error) {
    handleError(error);
  }
};

const getTopServiceSubscription = async (key, date) => {
  try {
    const url = `/admin/overview/top-service?month=${date.year}-${date.month}-01`;

    const { data } = await instance.get(url);

    return data.data;
  } catch (error) {
    handleError(error);
  }
};

const getCustomerGrowth = async (key, date, period) => {
  try {
    const { data } = await instance.get(
      `/admin/overview/customer-growth?period=${period}&year=${date.year}-01-01&month=${date.year}-${date.month}-01`
    );

    return data.data;
  } catch (error) {
    handleError(error);
  }
};
export {
  getAdminOverviewData,
  customerGrowthData,
  getTopBuyingMerchant,
  getTopServiceSubscription,
  getCustomerGrowth,
};
