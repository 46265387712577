import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import dayjs from 'dayjs';
import { Box, Text, Flex } from '@chakra-ui/react';
import { getAuditTrail } from '../../../queries';
import { nFormatter } from '../../../utils';
import {
  CustomTable,
  SelectColumnFilter,
  TableWrapper,
} from '../../../components/common/tables';
import { TableSkeleton } from '../../common/loaders/Skeletons';
import { ReactComponent as FallbackIcon } from '../../../assets/icons/fallback.svg';

function SalesHistory() {
  const [page, setPage] = useState(1);

  useEffect(() => {
    refetch();
  }, [page]);

  const { isLoading, isError, data, refetch } = useQuery(
    ['getAuditTrail', page],
    getAuditTrail
  );

  const totalPages = data?.data?.totalPages;
  const totalData = data?.data?.total;
  const currentPage = data?.data?.currentPage;

  if (isLoading || isError) {
    return <TableSkeleton title="Sales History" />;
  }

  const defaultData = data?.data.requests.map((e, id) => {
    return {
      col1: e.company_name,
      col3: e.amount,
      col4: 'Bank Transfer',
      col5: e.fund_request_created_at,
      col6: e.fund_request_updated_at,
    };
  });

  return (
    <Box
      p="4rem"
      mt="4rem"
      w="100%"
      maxW="100%"
      overflowX="scroll"
      boxShadow="0px 0px 4px rgba(0, 0, 0, 0.15)"
      bg="#fff"
    >
      <Box w="100%">
        <Text color="#626363" fontSize="3.2rem" fontFamily="medium" mb="4rem">
          Sales History
        </Text>
      </Box>
      <Box w="332px" ml="auto" mb="5rem" />
      {data?.data.requests.length < 1 ? (
        <Box>
          <Flex align="center" justify="center" mt="4rem">
            <FallbackIcon />
          </Flex>
          <Text fontSize="1.6rem" textAlign="center" fontFamily="medium">
            No data
          </Text>
        </Box>
      ) : (
        <Table
          defaultData={defaultData}
          currentPage={currentPage}
          totalPages={totalPages}
          totalData={totalData}
          myPage={page}
          setPage={setPage}
        />
      )}
    </Box>
  );
}

export { SalesHistory };

function Table({
  defaultData,
  currentPage,
  totalData,
  totalPages,
  myPage,
  setPage,
}) {
  const defaultColumns = [
    {
      Header: 'Company name',
      accessor: 'col1',
      disableFilters: true,
    },
    {
      Header: 'Amount',
      accessor: 'col3',
      Cell: ({ value }) => nFormatter(value),
      disableFilters: true,
    },
    {
      Header: 'Payment type',
      accessor: 'col4',
      Filter: SelectColumnFilter,
      disableSortBy: true,
    },
    {
      Header: 'Request date',
      accessor: 'col5',
      Cell: ({ value }) => (
        <Text fontFamily="regular" fontWeight="bold!important">
          {dayjs(value).format('MMM D, YYYY')}
          <Text as="span" ml="10px" color="#808080" fontWeight="bold!important">
            {dayjs(value).format('HH:mm A')}
          </Text>
        </Text>
      ),
      disableFilters: true,
    },
    {
      Header: 'Approval date',
      accessor: 'col6',
      Cell: ({ value }) => (
        <Text fontFamily="regular" fontWeight="bold!important">
          {dayjs(value).format('MMM D, YYYY')}
          <Text as="span" ml="10px" color="#808080" fontWeight="bold!important">
            {dayjs(value).format('HH:mm A')}
          </Text>
        </Text>
      ),
      disableFilters: true,
    },
  ];
  return (
    <Box pt="1rem" overflow="auto" maxW="100%">
      <TableWrapper>
        <CustomTable
          data={defaultData}
          columns={defaultColumns}
          currentPage={currentPage}
          totalPages={totalPages}
          totalData={totalData}
          myPage={myPage}
          setPage={setPage}
        />
      </TableWrapper>
    </Box>
  );
}
