import React, { useState } from 'react';
import { Flex, Text } from '@chakra-ui/react';

function DropdownTrigger(props) {
  const [open, setOpen] = useState(false);

  return (
    <Flex
      justify="center"
      align="center"
      bg="brand.blue"
      color="#fff"
      borderRadius="4px"
      position="relative"
      cursor="pointer"
      userSelect="none"
      _hover={{
        filter: 'brightness(1.2)',
      }}
    >
      <Text
        py="2rem"
        px="1.5rem"
        fontFamily="regular"
        cursor="pointer"
        onClick={() => setOpen(!open)}
      >
        Dropdown
      </Text>

      {open && props.children}
    </Flex>
  );
}

export { DropdownTrigger };
