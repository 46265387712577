import React, { useEffect, useState, useContext } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { ReactComponent as ResetIcon1 } from '../../assets/icons/reset1.svg';
import { ReactComponent as ResetIcon2 } from '../../assets/icons/reset2.svg';
import { MailIcon } from '../../components/login';
import { CustomInput } from '../../components/common/form/Input';
import { SolidBtn } from '../../components/common/buttons';
import { Link } from 'react-router-dom';
import { merchantForgotPassword } from '../../queries';
import { toast } from 'react-toastify';
import { AuthContext } from '../../context/auth';

const ForgotPassword = () => {
  const [isSubmit, setIsSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');

  const { authState } = useContext(AuthContext);

  useEffect(() => {
    if (authState?.isVerified) {
      history.push('/merchant/login');
    }
  }, []);

  const handleSubmit = async () => {
    if (!isSubmit) {
      try {
        if (!email) {
          toast.error('Email is required');
          return;
        }

        setIsLoading(true);
        await merchantForgotPassword({ email });
        setIsLoading(false);
        setIsSubmit(true);
        toast.success('Check email for password link');
      } catch (error) {
        setIsLoading(false);
        toast.error(
          error.response?.data?.message || 'Failed to reset password'
        );
      }
    }
  };

  return (
    <Box bgColor="#F8F9FB" w="100vw" h="100vh" pt="10rem">
      <Box bgColor="#fff" w="70rem" h="50rem" m="auto" pt="88px">
        <Box w="36rem" m="auto" textAlign="center">
          <Flex
            w="7.4rem"
            h="7.4rem"
            bgColor="rgba(0, 65, 190, 0.1)"
            m="auto"
            borderRadius="50%"
            align="center"
            justify="center"
          >
            {isSubmit ? <ResetIcon2 /> : <ResetIcon1 />}
          </Flex>
          <Box mt=".8rem">
            <Text
              fontFamily="medium"
              fontSize="18px"
              lineHeight="22px"
              letterSpacing="-0.02em"
              color="#444"
            >
              {isSubmit ? 'Reset link sent' : 'Reset password'}
            </Text>
            <Text
              mt="1rem"
              fontFamily="regular"
              lineHeight="140%"
              color="#808080"
              letterSpacing="-0.02em"
              fontSize="13px"
            >
              {isSubmit
                ? 'Check your inbox for the reset link to reset your password.'
                : 'Enter your email address in the form below and will send you further instructions on how to reset your password.'}
            </Text>
          </Box>
          <Box mt="3rem">
            {!isSubmit && (
              <CustomInput
                id="email"
                label="Email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                css={{ margin: 0 }}
              >
                <MailIcon />
              </CustomInput>
            )}
            <SolidBtn
              mt="2rem"
              as={isSubmit ? Link : 'button'}
              to={isSubmit ? '/merchant/login' : ''}
              fontFamily="medium"
              isLoading={isLoading ? true : false}
              onClick={handleSubmit}
            >
              {isSubmit ? 'Ok' : 'Reset password'}
            </SolidBtn>
          </Box>
          {!isSubmit && (
            <Text
              mt="2rem"
              fontFamily="regular"
              color="#C4C4C4"
              lineHeight="140%"
              letterSpacing="-0.02em"
            >
              Remember my password now!
              <Text
                as={Link}
                to="/merchant/login"
                ml="1rem"
                color="brand.blue"
                fontFamily="medium"
              >
                Sign in
              </Text>
            </Text>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export { ForgotPassword };
