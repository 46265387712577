import React, { useState, useRef } from 'react';
import { Box, Flex, HStack, Text } from '@chakra-ui/react';
import Picker from 'react-month-picker';
import { Bar, defaults } from 'react-chartjs-2';
import { useQuery } from 'react-query';
import dayjs from 'dayjs';

import 'react-month-picker/css/month-picker.css';
import { DatePickerTrigger } from '../../common/form';
import { getTotalSalesRevenue } from '../../../queries/admin/salesRevenue';
import { nFormatter, pickerMonths, pickerYears } from '../../../utils';
import { ChartSkeletonLoader } from '../../common/loaders';
import { SolidBtn } from '../../common/buttons';
import { ReactComponent as FallbackIcon } from '../../../assets/icons/fallback.svg';

function TotalRevenueBarChart() {
  const [period, setPeriod] = useState('month');
  const [startDate, setStartDate] = useState({
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
  });

  const pickAMonth = useRef();

  const { isLoading, isError, data } = useQuery(
    ['totalRevenue', startDate, period],
    getTotalSalesRevenue
  );

  if (isLoading || isError) {
    return <ChartSkeletonLoader title="Total Revenue" />;
  }

  defaults.global.legend = false;

  const chartStepSize =
    Math.max(...data.map((item) => item.revenue_growth)) / 5;

  const productSaleChartData = {
    labels:
      period === 'month'
        ? [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
          ]
        : period === 'week'
        ? data.map((item) => `${dayjs(item.week).format('MMM-DD')}th`)
        : data.map((item) => `${dayjs(item.day).format('MMM-DD')}th`),
    datasets: [
      {
        label: 'Data',
        backgroundColor: '#0041BE',
        barThickness: 10,
        minBarLength: 2,
        barValueSpacing: 20,
        data: data.map((item) => Math.round(item.revenue_growth)),
      },
    ],
  };

  return (
    <Box
      pr="4.4rem"
      pl="8.6rem"
      pt="3.2rem"
      pb="5rem"
      mt="4rem"
      w="100%"
      boxShadow="0px 0px 4px rgba(0, 0, 0, 0.15)"
    >
      <Flex align="center" justify="space-between">
        <Text
          color="#808080"
          fontFamily="medium"
          fontSize="2rem"
          lineHeight="2.4rem"
        >
          Total Revenue
        </Text>
        <HStack spacing="4rem">
          <Flex w="20rem" borderRadius="4px" bg="#F8F9FB">
            <SolidBtn
              h="100%"
              backgroundColor={period === 'month' ? '#2EAE65' : 'transaparent'}
              color={period === 'month' ? '#fff' : '#939AA3'}
              fontSize="1.4rem"
              fontFamily="medium"
              py="1rem"
              w="10rem"
              onClick={() => setPeriod('month')}
            >
              Month
            </SolidBtn>
            <SolidBtn
              h="100%"
              backgroundColor={period === 'week' ? '#2EAE65' : 'transaparent'}
              color={period === 'week' ? '#fff' : '#939AA3'}
              fontSize="1.4rem"
              fontFamily="medium"
              py="1rem"
              w="10rem"
              onClick={() => setPeriod('week')}
            >
              Week
            </SolidBtn>
            <SolidBtn
              h="100%"
              backgroundColor={period === 'day' ? '#2EAE65' : 'transaparent'}
              color={period === 'day' ? '#fff' : '#939AA3'}
              fontSize="1.4rem"
              fontFamily="medium"
              py="1rem"
              w="10rem"
              onClick={() => setPeriod('day')}
            >
              Day
            </SolidBtn>
          </Flex>
          <Box>
            <Picker
              ref={pickAMonth}
              years={pickerYears}
              value={startDate}
              lang={pickerMonths}
              onChange={(year, month) => setStartDate({ year, month })}
            >
              <DatePickerTrigger
                value={{
                  month: pickerMonths[startDate.month - 1],
                  year: startDate.year,
                }}
                onClick={() => pickAMonth.current.show()}
              />
            </Picker>
          </Box>
        </HStack>
      </Flex>
      <Box h="32.4rem" mt="8.8rem">
        {data.length > 0 ? (
          <Bar
            data={productSaleChartData}
            options={{
              maintainAspectRatio: false,
              responsive: true,
              scales: {
                xAxes: [
                  {
                    categoryPercentage: 1.0,
                    gridLines: {
                      display: false,
                    },
                  },
                ],
                yAxes: [
                  {
                    ticks: {
                      beginAtZero: true,
                      stepSize: chartStepSize,
                      userCallback: (value) => nFormatter(value),
                    },
                  },
                ],
              },
            }}
          />
        ) : (
          <Box>
            <Flex align="center" justify="center" mt="6rem">
              <FallbackIcon />
            </Flex>
            <Text fontSize="1.6rem" textAlign="center" fontFamily="medium">
              No data
            </Text>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export { TotalRevenueBarChart };
