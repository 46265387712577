import React, { useState, useEffect } from 'react';
import { Box, Text, Flex, HStack } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { useQuery } from 'react-query';

import { TableSkeleton } from '../../common/loaders/Skeletons';
import { CustomTable, TableWrapper } from '../../common/tables';
import { ReactComponent as FallbackIcon } from '../../../assets/icons/fallback.svg';
import { ReactComponent as Export } from '../../../assets/icons/Export.svg';
import { getTopupHistory } from '../../../queries';
import { nFormatter } from '../../../utils';

const TopupTable = ({ shouldRefetch }) => {
  const [page, setPage] = useState(1);
  const { isLoading, data, refetch } = useQuery(
    ['getTopupHistory', page],
    getTopupHistory
  );

  useEffect(() => {
    refetch();
  }, [page, shouldRefetch]);

  if (isLoading) {
    return <TableSkeleton title="Top-up History" />;
  }

  const totalPages = data?.totalPages;
  const totalData = data?.total;
  const currentPage = data?.currentPage;

  const defaultData = data?.topUp.map((e, id) => {
    return {
      col1: e.network,
      col2: nFormatter(e.amount),
      col3: dayjs(e.created_at).format('MMM D, YYYY'),
    };
  });

  const defaultColumns = [
    {
      Header: 'Network',
      accessor: 'col1',
      disableFilters: true,
    },
    {
      Header: 'Amount funded',
      accessor: 'col2',
      disableFilters: true,
    },
    {
      Header: 'Date',
      accessor: 'col3',
      Cell: ({ value }) => dayjs(value).format('MMM D, YYYY'),
      disableFilters: true,
    },
  ];
  return (
    <>
      <Box w="100%" mt="4rem" boxShadow="0px 0px 4px rgba(0, 0, 0, 0.15)">
        <Box pt="3.2rem" pl="4.2rem" pr="4rem" pb="5.3rem">
          <Flex justify="space-between" align="center">
            <Text
              color="#626363"
              fontFamily="medium"
              fontSize="3.2rem"
              lineHeight="3.8rem"
            >
              Top-up history
            </Text>
            {defaultData?.length > 0 ? (
              <HStack spacing="3.5rem">
                <Export cursor="pointer" />
              </HStack>
            ) : (
              ''
            )}
          </Flex>
          {defaultData?.length < 1 ? (
            <Box>
              <Flex align="center" justify="center" mt="6rem">
                <FallbackIcon />
              </Flex>
              <Text fontSize="1.6rem" textAlign="center" fontFamily="medium">
                No data top-up history yet
              </Text>
            </Box>
          ) : (
            <Box mt="4.5rem">
              <TableWrapper>
                <CustomTable
                  data={defaultData}
                  columns={defaultColumns}
                  currentPage={currentPage}
                  totalPages={totalPages}
                  totalData={totalData}
                  myPage={page}
                  setPage={setPage}
                />
              </TableWrapper>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export { TopupTable };
